import { useField } from "formik";
import { ReactNode } from "react";

import { FormControl, FormControlProps } from "@chakra-ui/react";

interface FormNumberInputControlProps extends FormControlProps {
  readonly name: string;
  readonly children: ReactNode;
}

const FormNumberInputControl = ({
  name,
  children,
  ...props
}: FormNumberInputControlProps) => {
  const [_field, { touched, error }] = useField(name);

  return (
    <FormControl id={name} isInvalid={(error && touched) || false} {...props}>
      {children}
    </FormControl>
  );
};

export default FormNumberInputControl;
