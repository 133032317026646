import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Flex, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  OnboardingContainerV2,
  OnboardingInfoMessageContext,
  SlideAnimation,
} from "@/components/onboarding-v2";
import {
  CurrentContextDocument,
  SubmissionStatus,
  useTransitionCurrentStepMutation,
  useUpdateOnboardingMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";

import { PersonaCard } from "./PersonaCard";

const IVRequiredCountryCodes = [
  `US`,
  `CA`,
  `UK`,
  `IE`,
  `IN`,
  `PR`,
  `IL`,
  `AE`,
  `AU`,
];

const VerifyIdentityPage = () => {
  const { t } = useTranslation();
  const { resetReonboardingMessage } = useContext(OnboardingInfoMessageContext);
  const actor = useCurrentActor();
  const actorCountry = actor?.country?.name;
  const isIVRequired = IVRequiredCountryCodes.includes(actorCountry || ``);

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const [updateOnboardingMutation, isUpdatingOnboarding] = useMutationWithError(
    useUpdateOnboardingMutation({
      refetchQueries: [CurrentContextDocument],
      awaitRefetchQueries: true,
    }),
    `updateOnboarding`,
  );

  const onSkip = async () => {
    await updateOnboardingMutation({
      variables: {
        input: {
          identityVerificationSubmissionStatus: SubmissionStatus.Skipped,
        },
      },
    });

    await transitionCurrentStepMutation();
    resetReonboardingMessage();
  };

  return (
    <OnboardingContainerV2 metaTitle={t(`verify_your_identity`)}>
      <Flex direction="column" w="full" maxW="45rem" gap={8}>
        <VStack spacing={2} alignItems="flex-start">
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`verify_your_identity`)}
          </Text>
          <Text>{t(`we_are_required_by_law_to_verify_your_identity`)}</Text>
        </VStack>
        <SlideAnimation>
          <PersonaCard isLoading={isTransitioningCurrentStep} />
        </SlideAnimation>
        {!isIVRequired && (
          <HiiveButton
            alignSelf={{ base: `center`, md: `flex-end` }}
            variant="text-grey"
            observabilityLabel="[VerifyIdentityPage] Skip for now"
            onClick={onSkip}
            isLoading={isTransitioningCurrentStep || isUpdatingOnboarding}
          >
            {t(`skip_for_now`)}
          </HiiveButton>
        )}
      </Flex>
    </OnboardingContainerV2>
  );
};

export default VerifyIdentityPage;
