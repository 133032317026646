import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { BidPostingCardFragment, BidState } from "@/gql";
import { toTimestamp } from "@/utils";

type BidPostingCardBrokerFooterProps = {
  bid: BidPostingCardFragment;
};

export function BidPostingCardBrokerFooter({
  bid: { placedAt, insertedAt, expireAt, state },
}: BidPostingCardBrokerFooterProps) {
  const { t } = useTranslation(`bids`);

  const isExpired = state === BidState.Expired;
  const isInReview = state === BidState.InReview;

  const showExpiresAt = !!expireAt && !isExpired;

  return (
    <PostingCard.Footer>
      {isInReview ? (
        <Text textStyle="text-sm" whiteSpace="nowrap">
          {t(`submitted_to_hiive_for_review`, {
            timestamp: toTimestamp(insertedAt),
          })}
        </Text>
      ) : (
        <Text textStyle="text-sm" whiteSpace="nowrap">
          {t(`placed_by_hiive`, {
            timestamp: toTimestamp(placedAt),
          })}
        </Text>
      )}
      {showExpiresAt && (
        <Text textStyle="text-sm" whiteSpace="nowrap">
          {t(`expires`, {
            timestamp: toTimestamp(expireAt),
          })}
        </Text>
      )}
    </PostingCard.Footer>
  );
}
