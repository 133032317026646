export const textTokens = {
  "text-xs": {
    fontSize: `0.75rem`,
    lineHeight: `1.125rem`,
    fontWeight: `normal`,
  },
  "text-sm": {
    fontSize: `0.875rem`,
    lineHeight: `1.25rem`,
    fontWeight: `normal`,
  },
  "text-md": {
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
    fontWeight: `normal`,
  },
  "text-lg": {
    fontSize: `1.125rem`,
    lineHeight: `1.375rem`,
    fontWeight: `normal`,
  },
  "text-xl": {
    fontSize: `1.25rem`,
    lineHeight: `1.5rem`,
    fontWeight: `normal`,
  },
  "text-2xl": {
    fontSize: `1.375rem`,
    lineHeight: `1.625rem`,
    fontWeight: `normal`,
  },
  "text-3xl": {
    fontSize: `1.5rem`,
    lineHeight: `1.813rem`,
    fontWeight: `normal`,
  },
  "text-4xl": {
    fontSize: `2rem`,
    lineHeight: `2.375rem`,
    fontWeight: `normal`,
  },
};

export const headingTokens = {
  "heading-5xs": {
    fontWeight: `medium`,
    fontSize: `0.625rem`,
    lineHeight: `0.9375rem`,
  },
  "heading-4xs": {
    fontWeight: `medium`,
    fontSize: `0.6875rem`,
    lineHeight: `1rem`,
  },
  "heading-3xs": {
    fontWeight: 500,
    fontSize: `0.75rem`,
    lineHeight: `1.5rem`,
  },
  "heading-2xs": {
    textTransform: `uppercase`,
    fontWeight: `medium`,
    fontSize: `.813rem`,
    letterSpacing: `0.05rem`,
    lineHeight: `1rem`,
  },
  "heading-xs": {
    fontSize: `0.875rem`,
    lineHeight: `1.25rem`,
    fontWeight: 500,
  },
  "heading-sm": {
    fontSize: `.938rem`,
    fontWeight: `medium`,
    lineHeight: `1rem`,
    textTransform: `uppercase`,
    letterSpacing: `0.05rem`,
  },
  "heading-md": {
    fontWeight: `500`,
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
  },
  "heading-lg": {
    fontSize: `1.125rem`,
    lineHeight: `1.375rem`,
    fontWeight: `500`,
  },
  "heading-xl": {
    fontSize: `1.25rem`,
    lineHeight: `1.5rem`,
    fontWeight: `500`,
  },
  "heading-2xl": {
    fontSize: `1.375rem`,
    lineHeight: `1.625rem`,
    fontWeight: `500`,
  },
  "heading-3xl": {
    fontWeight: `500`,
    fontSize: `1.5rem`,
    lineHeight: `1.813rem`,
  },
  "heading-4xl": {
    fontSize: `2rem`,
    lineHeight: `2.375rem`,
    fontWeight: 500,
  },
  "heading-5xl": {
    fontSize: `2.625rem`,
    lineHeight: `normal`,
    fontWeight: 500,
  },
};

export const textStyles = {
  "primary-navigation": {
    fontSize: `0.9375rem`,
    fontWeight: `medium`,
    color: `white`,
  },
  "secondary-navigation": {
    fontSize: `0.9375rem`,
    fontWeight: `medium`,
    color: `h-salmon-pink`,
  },
  "company-card-company-name": {
    fontSize: { base: `3xl`, sm: `4xl` },
    fontWeight: `medium`,
    lineHeight: `shorter`,
    color: `h-dark-grey`,
  },
  "company-card-price": {
    fontSize: { base: `lg`, sm: `1.375rem` },
    fontWeight: `medium`,
    color: `white`,
    lineHeight: `shorter`,
  },
  "company-card-standing-bid-ask-last": {
    fontSize: { base: `xs`, sm: `sm` },
    fontWeight: `normal`,
    color: `white`,
  },
  "company-card-lot": {
    fontSize: `xs`,
    fontWeight: `medium`,
    color: `white`,
  },
  "content-header": {
    fontSize: `xl`,
    fontWeight: `medium`,
    color: `h-dark-grey`,
  },
  "content-text": {
    fontSize: { base: `sm`, sm: `md` },
    fontWeight: `normal`,
    color: `h-mid-grey`,
  },
  "content-text-link": {
    fontSize: `lg`,
    fontWeight: `normal`,
    textDecoration: `underline`,
    color: `h-dark-grey`,
  },
  "hero-button": {
    fontSize: `xl`,
    fontWeight: `normal`,
    color: `h-dark-grey`,
  },
  "primary-button": {
    fontSize: `0.9375rem`,
    fontWeight: `medium`,
    color: `h-dark-grey`,
  },
  "table-label": {
    fontSize: { base: `xs`, sm: `sm` },
    fontWeight: `medium`,
    textTransform: `uppercase`,
    color: `h-mid-grey`,
    lineHeight: `none`,
  },
  "table-content": {
    fontSize: `lg`,
    fontWeight: `normal`,
    color: `h-dark-grey`,
    lineHeight: `none`,
  },
  "transaction-card-header": {
    fontSize: `xl`,
    fontWeight: `medium`,
  },
  "transaction-card-price-volume": {
    fontSize: `xl`,
    fontWeight: `medium`,
    color: `h-dark-grey`,
  },
  "transaction-card-label": {
    fontSize: `lg`,
    fontWeight: `normal`,
    color: `h-pewter`,
  },
  "transaction-card-label-sm": {
    fontSize: `md`,
    fontWeight: `normal`,
    color: `h-pewter`,
  },
  "sort-filter-button-text": {
    fontSize: { base: `0.813rem`, sm: `md` },
    fontWeight: `medium`,
    color: `h-salmon-pink`,
    lineHeight: `none`,
  },
  "lozenge-text": {
    fontSize: `sm`,
    fontWeight: `normal`,
    color: `h-dark-grey`,
  },
  "modal-header-dark": {
    fontSize: `2xl`,
    fontWeight: `medium`,
    color: `h-dark-grey`,
  },
  "modal-header-light": {
    fontSize: `2xl`,
    fontWeight: `medium`,
    color: `h-white`,
  },
  "modal-deal-name": {
    fontSize: `2xl`,
    fontWeight: `normal`,
    color: `h-dark-grey`,
  },
  "form-label": {
    fontSize: `lg`,
    fontWeight: `normal`,
    color: `h-dark-grey`,
  },
  "messaging-name": {
    fontSize: `0.9375rem`,
    fontWeight: `medium`,
    color: `h-dark-grey`,
  },
  "messaging-name-unread": {
    fontSize: `0.9375rem`,
    fontWeight: `medium`,
    textDecoration: `underline`,
    color: `h-dark-grey`,
  },
  "messaging-name-read": {
    fontSize: `0.9375rem`,
    fontWeight: `normal`,
    color: `h-dark-grey`,
  },
  "messaging-message-body-date-time": {
    fontSize: `0.9375rem`,
    fontWeight: `normal`,
    color: `h-mid-grey`,
    lineHeight: `base`,
  },
  "bid-details-price-volume": {
    fontSize: { base: `3xl`, sm: `4xl` },
    fontWeight: `medium`,
    lineHeight: `7`,
  },
  "bid-details-content-label": {
    fontSize: `1.75rem`,
    fontWeight: `normal`,
  },
  "bid-details-total-price": {
    fontSize: `1.75rem`,
    fontWeight: `medium`,
    color: `h-mid-grey`,
    lineHeight: `none`,
  },
  "browse-company-name": {
    fontSize: `3xl`,
    fontWeight: `medium`,
    color: `h-dark-grey`,
    lineHeight: `none`,
  },
  // Deprecated
  "deprecated-text-2xs": {
    fontSize: `0.75rem`,
    fontWeight: `normal`,
  },
  "deprecated-text-xs": {
    fontSize: `xs`,
    fontWeight: `medium`,
  },
  "deprecated-text-sm": {
    fontSize: `sm`,
    fontWeight: `normal`,
  },
  "deprecated-text-md": {
    fontSize: `sm`,
    fontWeight: `medium`,
  },
  "deprecated-text-lg": {
    fontSize: `0.938rem`,
    fontWeight: `normal`,
  },
  "deprecated-text-xl": {
    fontSize: `md`,
    fontWeight: `normal`,
  },
  "deprecated-text-2xl": {
    fontSize: { base: `md`, md: `.938rem` },
    fontWeight: `normal`,
    lineHeight: `short`,
  },
  "deprecated-heading-xs": {
    fontSize: `.813rem`,
    fontWeight: `medium`,
  },
  "deprecated-heading-sm": {
    fontSize: `.938rem`,
    fontWeight: `medium`,
    lineHeight: `tall`,
  },
  "deprecated-heading-md": {
    fontSize: `.938rem`,
    fontWeight: `semibold`,
    lineHeight: `tall`,
  },
  "deprecated-heading-lg": {
    fontSize: { base: `.938rem`, md: `md` },
    fontWeight: `medium`,
    lineHeight: `shorter`,
  },
  "deprecated-heading-xl": {
    fontSize: { base: `md`, md: `1.063rem` },
    fontWeight: `medium`,
  },
  "deprecated-heading-2xl": {
    fontSize: { base: `1.063rem`, md: `1.188rem` },
    fontWeight: `medium`,
    lineHeight: `shorter`,
  },
  "deprecated-text-3xl": {
    fontSize: { base: `1.063rem`, md: `lg` },
    fontWeight: `normal`,
  },
  "deprecated-text-4xl": {
    fontSize: { base: `xl`, md: `2xl` },
    fontWeight: `normal`,
  },
  "deprecated-text-5xl": {
    fontSize: { base: `1.75rem`, md: `2rem` },
    fontWeight: `normal`,
  },
  "deprecated-heading-3xl": {
    fontSize: `1.375rem`,
    fontWeight: `medium`,
  },
  "deprecated-heading-4xl": {
    fontSize: { base: `xl`, md: `2xl` },
    fontWeight: `medium`,
  },
  "deprecated-heading-5xl": {
    fontSize: { base: `3xl`, md: `2.5rem` },
    fontWeight: `medium`,
  },
  "deprecated-heading-7xl": {
    fontSize: { base: `4xl`, md: `3.75rem` },
    fontWeight: `medium`,
  },
  sensitive: {
    color: `transparent`,
    textShadow: `0 0 8px #000`,
    filter: `blur(4px)`,
    webkitFilter: `blur(4px)`,
    opacity: `.8`,
  },
  "activity-card-heading": {
    fontSize: { base: `1rem`, sm: `1.125rem` },
    lineHeight: `1.375rem`,
    fontWeight: `500`,
  },
  ...textTokens,
  ...headingTokens,
};
