/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import currency from "currency.js";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid, Text } from "@chakra-ui/react";

import {
  FeeBreakdownCommissionInfo,
  FullDivider,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  ShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  FixedValueInput,
  FormikQL,
  FormNumberInput,
  MoneyInput,
} from "@/components/form";
import {
  BidSellerRoundingDisclaimer,
  ListingSellerRoundingDisclaimer,
} from "@/components/postings";
import {
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  ModifyCounterBidModalBidFragment,
  useModifyCounterBidMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import {
  countLabel,
  formatShares,
  getBidNumSharesActual,
  getNumOfShares,
  getPricePerShare,
  isEitherFund,
} from "@/utils";

interface ModifyCounterBidFormValues {
  readonly bidId: string;
  readonly counterPricePerShare: number;
  readonly counterNumShares: number;
}

const initialCounterPricePerShareValue = (
  bid: ModifyCounterBidModalBidFragment,
) =>
  !!bid.counterPricePerShare
    ? bid.counterPricePerShare / 100
    : bid.pricePerShare / 100;

const initialCounterNumSharesValue = (bid: ModifyCounterBidModalBidFragment) =>
  !!bid.counterNumShares ? bid.counterNumShares : bid.numSharesActual;

const createValidationSchema = (bid: ModifyCounterBidModalBidFragment) => {
  const share = countLabel(bid.listing);

  return Yup.object().shape({
    counterPricePerShare: Yup.number()
      .nullable()
      .min(
        bid.pricePerShare / 100,
        `Counter price per ${share} must be greater than bid price per ${share}.`,
      )
      .test({
        name: `checkCounterPricePerShare`,
        test: function (counterPricePerShare: number) {
          if (!bid.listing.acceptPartialBid && !counterPricePerShare) {
            return this.createError({
              message: `Counter price per ${share} is required.`,
            });
          }

          if (
            (!counterPricePerShare && !this.parent.counterNumShares) ||
            (counterPricePerShare === initialCounterPricePerShareValue(bid) &&
              this.parent.counterNumShares ===
                initialCounterNumSharesValue(bid))
          ) {
            return this.createError({
              message: `Must modify either the price per ${share} or number of ${share}s.`,
            });
          }

          return true;
        },
      }),
    counterNumShares: Yup.number()
      .nullable()
      .min(
        bid.numSharesActual,
        `Counter number of ${share}s must be greater than bid number of ${share}s.`,
      )
      .test({
        name: `checkCounterNumShares`,
        test: function (counterNumShares: number) {
          if (!bid.listing.acceptPartialBid) return true;

          if (
            (!counterNumShares && !this.parent.counterPricePerShare) ||
            (counterNumShares === initialCounterNumSharesValue(bid) &&
              this.parent.counterPricePerShare ===
                initialCounterPricePerShareValue(bid))
          ) {
            return this.createError({
              message: `Must modify either the number of ${share}s or price per ${share}.`,
            });
          }

          return true;
        },
      }),
  });
};

const createInitialValues = (
  bid: ModifyCounterBidModalBidFragment,
): ModifyCounterBidFormValues => ({
  bidId: bid.id,
  counterPricePerShare: initialCounterPricePerShareValue(bid),
  counterNumShares: initialCounterNumSharesValue(bid),
});

const mapVariables = ({
  counterPricePerShare,
  counterNumShares,
  bidId,
}: ModifyCounterBidFormValues) => ({
  bidId,
  input: {
    counterPricePerShare: currency(counterPricePerShare).intValue,
    counterNumShares,
  },
});
interface ModifyCounterBidModalProps {
  readonly bid: ModifyCounterBidModalBidFragment;
}

const ModifyCounterBidModal = ({ bid }: ModifyCounterBidModalProps) => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const { closeModal } = useModal();

  const mutation = useModifyCounterBidMutation({
    refetchQueries: [BidPageBidByIdDocument, BidPageMyActivityDocument],
  });

  const onSuccess = () => {
    successToast(`Counter bid modified.`);
    closeModal();
  };

  const actualNumberOfShares = getBidNumSharesActual(bid);

  const isBidDisclaimerVisible = actualNumberOfShares !== bid.numShares;

  const actualNumShares = getBidNumSharesActual(bid);
  const formattedNumShares = formatShares(actualNumShares);

  const canCounterNumShares =
    bid.listing.acceptPartialBid &&
    bid.listing.numSharesAvailable !== bid.numSharesActual;

  const listingNumShares = getNumOfShares(bid.listing, true);
  const listingPricePerShare = getPricePerShare(bid.listing);

  const counterBidNumShares = getNumOfShares(bid, false, true);
  const counterBidPricePerShare = getPricePerShare(bid, true);

  const numberOfLabel = isEitherFund(bid.listing)
    ? t(`number_of_fund_units`)
    : t(`number_of_shares`);

  const pricePerLabel = isEitherFund(bid.listing)
    ? t(`price_per_fund_unit`)
    : t(`price_per_share`);

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`modifyCounterBid`]}
        mapVariables={(values) => mapVariables(values)}
        initialValues={createInitialValues(bid)}
        validationSchema={createValidationSchema(bid)}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <HiiveModalHeader>
              <Text as="span">
                Modify Counter bid on {bid.listing.company.name}
                {` `}
              </Text>
              <Text as="span">Bid {bid.displayId}</Text>
            </HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} rowGap={7} columnGap={9} w="full">
                <GridItem colSpan={2}>
                  <ShareDetails variant="listing" title="Listing Details">
                    <ShareDetailsStats
                      numberOfShares={listingNumShares}
                      pricePerShare={listingPricePerShare}
                    />
                    <ListingSellerRoundingDisclaimer />
                  </ShareDetails>
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
                <GridItem colSpan={2}>
                  <ShareDetails variant="bid" title="Counter Bid Details">
                    <ShareDetailsStats
                      numberOfShares={counterBidNumShares}
                      pricePerShare={counterBidPricePerShare}
                    />
                    {isBidDisclaimerVisible && (
                      <BidSellerRoundingDisclaimer
                        numSharesOriginal={bid.numShares}
                      />
                    )}
                  </ShareDetails>
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  {canCounterNumShares ? (
                    <FormNumberInput
                      name="counterNumShares"
                      label={numberOfLabel}
                    />
                  ) : (
                    <FixedValueInput
                      label={numberOfLabel}
                      name="numShares"
                      fixedValue={formattedNumShares}
                    />
                  )}
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <MoneyInput
                    name="counterPricePerShare"
                    label={pricePerLabel}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FeeBreakdownCommissionInfo
                    numShares={values.counterNumShares}
                    pricePerShare={values.counterPricePerShare}
                    listingId={bid.listing.id}
                    disclaimerVariant="counterBid"
                  />
                </GridItem>
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  observabilityLabel="[ModifyCounterBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                observabilityLabel="[ModifyCounterBid/Submit]"
                submitText="Modify Counter"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default ModifyCounterBidModal;
