import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import * as Yup from "yup";

import {
  Box,
  HStack,
  Text,
  UnorderedList,
  VStack,
  ListItem,
} from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import {
  FormRadioTile,
  TileCheckboxInput,
  FormCheckboxInput,
} from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

import { InstitutionAccreditationFormProps } from "./InstitutionAccreditationForm";

const AccreditationConfirmationQuestionKey = `ch-institutional-confirm-v3`;
const ProfessionalClientKey = `ch-institutional-professional-v3`;
const InstitutionalClientKey = `ch-institutional-institution-v3`;

interface InstitutionAccreditationFormValues {
  readonly clientTypeKey?: string | null;
  readonly clientTypeDisclaimer: boolean | null;
  readonly confirmationDisclaimer: boolean | null;
}

export const CHInstitutionAccreditationForm = ({
  onSuccess,
  questionGroup,
}: InstitutionAccreditationFormProps) => {
  const actor = useCurrentActor();
  const [displaySecondDisclaimer, setDisplaySecondDisclaimer] = useState(false);
  const { t } = useTranslation();
  const mutation = useAnswerInstitutionAccreditationQuestionsMutation();
  const accQuestions = questionGroup?.questions || [];
  const accreditationConfirmationQuestion = accQuestions.find(
    (question: AccreditationQuestion) =>
      question.key === AccreditationConfirmationQuestionKey,
  );

  const validationSchema = Yup.object().shape({
    clientTypeKey: Yup.string()
      .oneOf([ProfessionalClientKey, InstitutionalClientKey])
      .required(t`required`),
    confirmationDisclaimer: Yup.boolean()
      .oneOf([true, null], t`required`)
      .nullable()
      .when(`$displaySecondDisclaimer`, {
        is: true,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      }),
    clientTypeDisclaimer: Yup.boolean()
      .oneOf([true], t`required`)
      .required(t`required`),
  });

  const previousAnswers = actor.institution?.accreditationAnswers;
  const hasPreviousAnswers = !!previousAnswers && previousAnswers.length > 0;
  const initialValues = {
    clientTypeKey: hasPreviousAnswers ? previousAnswers[0].question.key : null,
    confirmationDisclaimer: null,
    clientTypeDisclaimer: null,
  };

  const mapVariables = ({
    clientTypeKey,
  }: InstitutionAccreditationFormValues) => ({
    input: {
      answers: [{ key: clientTypeKey || `` }],
    },
  });

  const { handleSubmit, isLoading, control, formState, trigger, clearErrors } =
    useFormQL({
      mutation,
      mode: `onChange`,
      mapVariables,
      initialValues,
      validationSchema,
      yupContext: { displaySecondDisclaimer },
      onSuccess,
    });

  useEffect(() => {
    if (displaySecondDisclaimer) {
      trigger(`confirmationDisclaimer`).then(() => {
        clearErrors();
      });
    }
  }, [displaySecondDisclaimer]);

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      id="institution-accreditation-form"
    >
      <FormRadioTile.Group
        size="md"
        p={{ base: 4, lg: 6 }}
        borderRadius="md"
        borderColor="grey.200"
        borderWidth={0.5}
        boxShadow={shadows.card}
        bg="white"
        gap={4}
        w={{ base: `full`, lg: 160 }}
      >
        {!displaySecondDisclaimer ? (
          <VStack gap={10} alignItems="flex-start" w="full">
            <VStack gap={4} alignItems="flex-start" w="full">
              <VStack gap={2} w="full" alignItems="flex-start">
                <Text textStyle="heading-lg">
                  <Trans
                    i18nKey="ch_institution_client_type_title"
                    components={{ italic: <i /> }}
                  />
                </Text>
                <TileCheckboxInput.Tooltip>
                  <Text>
                    <Trans
                      i18nKey="ch_institution_client_type_description_1"
                      components={{ bold: <strong /> }}
                    />
                  </Text>
                </TileCheckboxInput.Tooltip>
                <TileCheckboxInput.Tooltip>
                  <Text>
                    <Trans
                      i18nKey="ch_institution_client_type_description_2"
                      components={{ bold: <strong /> }}
                    />
                  </Text>
                </TileCheckboxInput.Tooltip>
              </VStack>
              <HStack w="full">
                <FormRadioTile.Tile
                  name="clientTypeKey"
                  control={control}
                  value={ProfessionalClientKey}
                  alignItems="center"
                >
                  <Box justifyContent="center" alignItems="flex-start" p={1}>
                    <Text>{t`ch_institution_professional_client_type`}</Text>
                  </Box>
                </FormRadioTile.Tile>
                <FormRadioTile.Tile
                  name="clientTypeKey"
                  control={control}
                  value={InstitutionalClientKey}
                  alignItems="center"
                >
                  <Box justifyContent="center" alignItems="flex-start" p={1}>
                    <Text>{t`ch_institution_institutional_client_type`}</Text>
                  </Box>
                </FormRadioTile.Tile>
              </HStack>
            </VStack>
            <VStack alignItems="flex-start">
              <Text>{t`ch_institution_client_type_disclaimer`}</Text>
              <FormCheckboxInput
                name="clientTypeDisclaimer"
                label={<Text fontWeight={400}>{t`i_agree`}</Text>}
                control={control}
              />
            </VStack>
          </VStack>
        ) : (
          <VStack alignItems="flex-start">
            <Text textStyle="heading-lg">
              {accreditationConfirmationQuestion?.text}
            </Text>
            <TileCheckboxInput.Tooltip>
              <UnorderedList>
                <ListItem>{t`ch_institution_confirmation_disclaimer_description_bullet_1`}</ListItem>
                <ListItem>{t`ch_institution_confirmation_disclaimer_description_bullet_2`}</ListItem>

                <ListItem>{t`ch_institution_confirmation_disclaimer_description_bullet_3`}</ListItem>

                <ListItem>{t`ch_institution_confirmation_disclaimer_description_bullet_4`}</ListItem>
              </UnorderedList>
            </TileCheckboxInput.Tooltip>
            <FormCheckboxInput
              name="confirmationDisclaimer"
              label={<Text fontWeight={400}>{t`i_agree`}</Text>}
              control={control}
            />
          </VStack>
        )}
      </FormRadioTile.Group>
      <HStack p={2} mt={6} justifyContent="flex-end" w="full">
        <HiiveButton
          observabilityLabel="[InstitutionAccreditationPageV2/Submit]"
          variant="rounded-solid-salmon"
          size="xl"
          maxW="unset"
          form="institution-accreditation-form"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading}
          onClick={
            displaySecondDisclaimer
              ? handleSubmit
              : () => setDisplaySecondDisclaimer(true)
          }
          isDisabled={!formState?.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </HStack>
    </form>
  );
};

export const CHInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={450} />
    <Skeleton h={100} />
    <VStack alignItems="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </VStack>
  </VStack>
);
