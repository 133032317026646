import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

import { InputProps } from "@chakra-ui/react";

import { DatePicker } from "@/components/form";

type DateInputProps<TFieldValues extends FieldValues> = InputProps &
  ControllerRenderProps<TFieldValues, Path<TFieldValues>>;

const DateInput = <TFieldValues extends FieldValues>({
  name,
  value,
  ...dateInputProps
}: Omit<DateInputProps<TFieldValues>, `children`>) => (
  <DatePicker
    id={name}
    autoComplete="none"
    selected={(value && new Date(value)) || null}
    // @ts-expect-error due to date type mismatch
    onSelect={(date, _event) => dateInputProps.onChange(date)}
    {...dateInputProps}
  />
);

export default DateInput;
