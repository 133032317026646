import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserWithInstitutionFragment } from "@/gql";

export type OnboardingInfoMessageContext = {
  readonly header: string | null;
  readonly body: string | null;
  readonly resetReonboardingMessage: () => void;
};

const DEFAULT_ONBOARDING_INFO_MESSAGE: OnboardingInfoMessageContext = {
  header: null,
  body: null,
  resetReonboardingMessage: () => {},
};

export const OnboardingInfoMessageContext =
  createContext<OnboardingInfoMessageContext>({
    ...DEFAULT_ONBOARDING_INFO_MESSAGE,
  });

const OnboardingInfoMessageProvider = ({
  actor,
  children,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly children: ReactNode;
}) => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  const [reonboardingMessage, setReonboardingMessage] =
    useState<OnboardingInfoMessageContext>(DEFAULT_ONBOARDING_INFO_MESSAGE);

  const resetReonboardingMessage = () => {
    setReonboardingMessage(DEFAULT_ONBOARDING_INFO_MESSAGE);
  };

  useEffect(() => {
    if (actor?.onboarding?.reonboardingStartedAt) {
      setReonboardingMessage({
        header: t(`complete_profile_to_access`),
        body: t(`incomplete_profile`),
        resetReonboardingMessage,
      });
    }
  }, [actor?.onboarding?.reonboardingStartedAt, t]);

  const contextValue = useMemo(
    () => ({ ...reonboardingMessage, resetReonboardingMessage }),
    [reonboardingMessage],
  );

  return (
    <OnboardingInfoMessageContext.Provider value={contextValue}>
      {children}
    </OnboardingInfoMessageContext.Provider>
  );
};

export default OnboardingInfoMessageProvider;
