/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useFormikContext, useField } from "formik";
import find from "lodash/find";
import isNil from "lodash/isNil";
import pluralize from "pluralize";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid } from "@chakra-ui/react";

import {
  CompanyEligibilityCriteriaAccordion,
  FocusedShareDetails,
  FullDivider,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveNextButton,
  ShareDetailsStats,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { CreateListingSelectCompanyInput } from "@/components/listings";
import {
  ListingSellerRoundingDisclaimer,
  ListingTransactionSummary,
  ShareSeriesMakeupElement,
  ShareSeriesMakeupInput,
} from "@/components/postings";
import {
  CreateListingSelectCompanyInputCompanyFragment,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import { useRsuRestrictionAndEligibleBuyers } from "@/hooks/featureFlags";
import {
  checkContainsRestrictedStockUnit,
  constants,
  getAreFeesHighForListing,
  resetTouchedState,
  roundUp,
  sumShareSeriesMakeup,
} from "@/utils";

import {
  CreateListingSequenceModalFormValues,
  CreateListingLotFields,
} from ".";
import { CreateListingSequenceModalStepFormContext } from "./CreateListingSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";

export const useValidationSchema = () => {
  const { t } = useTranslation();

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  return Yup.object().shape({
    company: Yup.object().nullable().required(`Required`),
    pricePerShare: Yup.number()
      .nullable()
      .test({
        name: `checkListingMeetsMinValue`,
        params: {},
        message: minTransactionSizeError,
        test: function (pricePerShare: number) {
          const { shareSeriesMakeup } = this.parent;
          const numShares = sumShareSeriesMakeup(shareSeriesMakeup);
          return pricePerShare * numShares >= constants.min_listing_size.number;
        },
      }),
    shareSeriesMakeup: Yup.array()
      .max(
        constants.max_share_classes_for_listing,
        `Can specify at most ${
          constants.max_share_classes_for_listing
        } ${pluralize(`class`, constants.max_share_classes_for_listing)}`,
      )
      .of(
        Yup.object().shape({
          shareSeries: Yup.string().nullable().required(`Required`),
          numShares: Yup.number()
            .nullable()
            .required(`Required`)
            .min(1, `Must have at least 1 share`)
            .test({
              name: `checkListingMeetsMinValue`,
              params: {},
              message: minTransactionSizeError,
              test: function () {
                const shareSeriesMakeupValue: CreateListingSequenceModalFormValues =
                  find(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // yup doesn't fully type the context object for version < 1.0, we will need to migrate
                    this.from,
                    `value.shareSeriesMakeup`,
                  )?.value;

                const { pricePerShare, shareSeriesMakeup } =
                  shareSeriesMakeupValue;

                const numShares = sumShareSeriesMakeup(shareSeriesMakeup);

                if (!pricePerShare) return false;

                return (
                  pricePerShare * numShares >= constants.min_listing_size.number
                );
              },
            }),
        }),
      ),
  });
};

const ListingShareDetails = ({
  formValues,
}: {
  readonly formValues: CreateListingSequenceModalFormValues;
}) => {
  const { t } = useTranslation();
  const numShares = sumShareSeriesMakeup(formValues.shareSeriesMakeup);
  const pricePerShareCents = !isNil(formValues.pricePerShare)
    ? formValues.pricePerShare * 100
    : null;

  const displayedNumberOfShares = roundUp(numShares);

  return (
    <FocusedShareDetails.Header
      title={t(`listing_preview`)}
      variant="listing-preview"
    >
      <FocusedShareDetails.HeaderCard>
        <ShareDetailsStats
          numberOfShares={displayedNumberOfShares}
          pricePerShare={pricePerShareCents}
        />
      </FocusedShareDetails.HeaderCard>
      <ListingSellerRoundingDisclaimer />
    </FocusedShareDetails.Header>
  );
};

interface ShareDetailsProps
  extends StepPropsV2<StepKeys, CreateListingSequenceModalFormValues> {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}

const CreateListingShareDetails = ({
  initialCompany,
  values,
  stepRouter,
  errors,
}: ShareDetailsProps) => {
  const { company, shareSeriesMakeup } = values;
  const { stepControls } = stepRouter;

  const rsuRestrictionAndEligibleBuyers = useRsuRestrictionAndEligibleBuyers();

  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { touched, setTouched } = useFormikContext();

  const validationSchema = useValidationSchema();

  const minTransactionSizeError = t(`min_transaction_size_error`, {
    minSize: constants.min_listing_size.text,
  });

  const pricePerShareDollars = !isNil(values.pricePerShare)
    ? values.pricePerShare
    : null;

  const [, , { setValue: setTransferMethod }] = useField(`transferMethod`);

  const onSuccess = useCallback(() => {
    stepControls.nextStep();
    // Workaround for Formik setting every field in the form to touched
    // on submit. Get rid of me when we move to react-hook-form :'(
    resetTouchedState<CreateListingSequenceModalFormValues>({
      fields: touched,
      setTouched,
    });
  }, [stepControls, resetTouchedState]);

  useStepValidator({
    Context: CreateListingSequenceModalStepFormContext,
    stepKey: stepKeys.createListingShareDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const onShareSeriesMakeupChange = useCallback(
    (shareSeriesMakeup: ShareSeriesMakeupElement[]) => {
      const hasRestrictedStockUnit =
        checkContainsRestrictedStockUnit(shareSeriesMakeup);

      if (hasRestrictedStockUnit && rsuRestrictionAndEligibleBuyers) {
        setTransferMethod(TransferMethod.Other);
      }
    },
    [rsuRestrictionAndEligibleBuyers, setTransferMethod],
  );

  const isHighFeesWarningVisible =
    !isNil(values.pricePerShare) &&
    !errors.pricePerShare &&
    getAreFeesHighForListing({
      pricePerShare: values.pricePerShare,
      numberOfShares: sumShareSeriesMakeup(values.shareSeriesMakeup),
    });

  const eligibilityCriterionText =
    values.company?.companyEligibilityCriterion?.text ||
    initialCompany?.companyEligibilityCriterion?.text;

  const showEligibilityCriterionAccordion = eligibilityCriterionText && company;

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {!initialCompany && (
            <>
              <GridItem colSpan={2}>
                <CreateListingSelectCompanyInput
                  name="company"
                  label="Which company?"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FullDivider />
              </GridItem>
            </>
          )}
          {showEligibilityCriterionAccordion && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaAccordion
                companyName={company.name}
                markdown={eligibilityCriterionText}
              />
            </GridItem>
          )}

          <GridItem colSpan={2}>
            <CreateListingLotFields />
          </GridItem>

          <GridItem colSpan={2}>
            <ListingTransactionSummary
              isHighFeesWarningVisible={isHighFeesWarningVisible}
              pricePerShare={pricePerShareDollars}
              shareSeriesMakeup={shareSeriesMakeup}
              companyId={company?.id}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <ListingShareDetails formValues={values} />
          </GridItem>
          <GridItem colSpan={2}>
            <FullDivider />
          </GridItem>
          <GridItem colSpan={2}>
            <ShareSeriesMakeupInput
              excludedErrorMessages={[minTransactionSizeError]}
              onChange={onShareSeriesMakeupChange}
            />
          </GridItem>
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[CreateListing/ShareDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveNextButton
          type="submit"
          observabilityLabel="[CreateListing/ShareDetails/Submit]"
        />
      </HiiveModalFooter>
    </>
  );
};

export default CreateListingShareDetails;
