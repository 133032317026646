import { Form } from "formik";
import * as Yup from "yup";

import { ModalBody, Show } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  WithdrawCounterBidModalBidFragment,
  useWithdrawCounterBidMutation,
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

const validationSchema = Yup.object().shape({
  bidId: Yup.string().nullable().required(`Required`),
});

const mapVariables = ({ bidId }: { readonly bidId: string }) => ({ bidId });

const createInitialValues = (bid: WithdrawCounterBidModalBidFragment) => ({
  bidId: bid.id,
});

interface WithdrawCounterBidModalProps {
  readonly bid: WithdrawCounterBidModalBidFragment;
}

const WithdrawCounterBidModal = ({ bid }: WithdrawCounterBidModalProps) => {
  const { successToast } = useCustomToast();
  const { closeModal } = useModal();
  const mutation = useWithdrawCounterBidMutation({
    refetchQueries: [BidPageBidByIdDocument, BidPageMyActivityDocument],
  });

  const onSuccess = () => {
    successToast(`Counter bid withdrawn.`);

    closeModal();
  };

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`withdrawCounterBid`]}
        initialValues={createInitialValues(bid)}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form>
            <HiiveModalHeader>Withdraw Counter Bid</HiiveModalHeader>
            <ModalBody>
              Are you sure? You can&apos;t undo this action afterwards.
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  observabilityLabel="[WithdrawCounterBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                observabilityLabel="[WithdrawCounterBid/Submit]"
                isLoading={isSubmitting}
                submitText="Withdraw"
                type="submit"
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default WithdrawCounterBidModal;
