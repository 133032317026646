import { useField } from "formik";
import difference from "lodash/difference";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from "@chakra-ui/react";

import { CustomCheckbox } from "@/components/common";
import {
  StandingBidAcceptsTransferMethodsOptions,
  UserPermissionV2,
} from "@/gql";
import { iHaveEntityPermission, useCurrentActor } from "@/hooks";

const includesAll = (superset: readonly string[], subset: readonly string[]) =>
  difference(subset, superset).length === 0;

const StandingBidAcceptedTransferMethods = ({
  name,
  label,
}: {
  readonly name: string;
  readonly label?: string;
}) => {
  const { t } = useTranslation();

  const actor = useCurrentActor();

  const hasBidOtherPermission = iHaveEntityPermission(
    actor,
    UserPermissionV2.BidOther,
  );

  const [field, { touched, error }, { setValue }] = useField(name);

  const value = field.value as readonly string[];

  const getIsChecked = (options: readonly string[]) =>
    includesAll(value, options);

  const onChange =
    (options: readonly string[]) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue([...value, ...options]);

        return;
      }

      setValue(value.filter((x) => !options.includes(x)));
    };

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel htmlFor={name}>
        {label || t`place_standing_bid_accepted_transfer_methods_label`}
      </FormLabel>
      <Stack spacing={2}>
        <CustomCheckbox
          spacing={2}
          isChecked
          isDisabled
          onChange={onChange([StandingBidAcceptsTransferMethodsOptions.Direct])}
        >
          {t(`direct`)}
          <span style={{ fontSize: `.75em` }}>
            {` `}
            {t(`direct_text`)}
          </span>
        </CustomCheckbox>
        <CustomCheckbox
          spacing={2}
          isChecked={getIsChecked([
            StandingBidAcceptsTransferMethodsOptions.Fund,
          ])}
          onChange={onChange([StandingBidAcceptsTransferMethodsOptions.Fund])}
        >
          {t(`fund`)}
          <span style={{ fontSize: `.75em` }}>
            {` `}
            {t(`fund_text`)}
          </span>
        </CustomCheckbox>
        {hasBidOtherPermission && (
          <CustomCheckbox
            spacing={2}
            isChecked={getIsChecked([
              StandingBidAcceptsTransferMethodsOptions.Other,
            ])}
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptions.Other,
            ])}
          >
            {t(`other`)}
            <span style={{ fontSize: `.75em` }}>
              {` `}
              {t(`other_text`)}
            </span>
          </CustomCheckbox>
        )}
      </Stack>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default StandingBidAcceptedTransferMethods;
