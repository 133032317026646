import { filter, orderBy, pipe, update } from "lodash/fp";
import isNil from "lodash/isNil";

import { ActivityGroup, ActivitySection, WithQuery } from "@/components/common";
import {
  CompanyMarketActivityListingCard,
  CompanyMarketActivityStandingBidCard,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyPageMarketActivityCompanyFragment,
  CompanyPageMarketActivityListingFragment,
  CompanyPageMarketActivityStandingBidFragment,
  ListingState,
  StandingBidState,
  useCompanyPageMarketActivityCompanyByIdQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useOrderBookTruncation } from "@/hooks/featureFlags";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { MarketActivityContentV2 } from "./MarketActivityContentV2";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";

const Listings = ({
  listings,
  standingBids,
  company,
}: {
  readonly listings: readonly CompanyPageMarketActivityListingFragment[];
  readonly standingBids: readonly CompanyPageMarketActivityStandingBidFragment[];
  readonly company: CompanyPageMarketActivityCompanyFragment;
}) => (
  <ActivityGroup
    title="Sellers"
    emptyState={
      <ListingsEmptyState
        company={company}
        numberOfStandingBids={standingBids.length}
      />
    }
  >
    {listings.map((listing) => (
      <CompanyMarketActivityListingCard
        key={listing.id}
        listing={listing}
        company={company}
      />
    ))}
  </ActivityGroup>
);

const StandingBids = ({
  standingBids,
  listings,
  company,
}: {
  readonly standingBids: readonly CompanyPageMarketActivityStandingBidFragment[];
  readonly listings: readonly CompanyPageMarketActivityListingFragment[];
  readonly company: CompanyPageMarketActivityCompanyFragment;
}) => (
  <ActivityGroup
    title="Buyers"
    emptyState={
      <StandingBidsEmptyState
        company={company}
        numberOfListings={listings.length}
      />
    }
  >
    {standingBids.map((standingBid) => (
      <CompanyMarketActivityStandingBidCard
        key={standingBid.id}
        standingBid={standingBid}
      />
    ))}
  </ActivityGroup>
);

const byOpenOrConditionallySoldListings = ({
  state,
}: CompanyPageMarketActivityListingFragment) =>
  state === ListingState.Open || state === ListingState.ConditionallySold;

const byOpenOrConditionallyCompletedStandingBids = ({
  state,
}: CompanyPageMarketActivityStandingBidFragment) =>
  state === StandingBidState.Open ||
  state === StandingBidState.ConditionallyCompleted;

const orderByHighestBidFirst = orderBy([`pricePerShare`], [`desc`]);
const orderByLowestAskFirst = orderBy([`listingPricePerShare`], [`asc`]);

const byListingsNotByMyInstitution =
  (institutionId?: string | null) =>
  ({ sellerInstitutionId }: CompanyPageMarketActivityListingFragment) =>
    isNil(institutionId) || sellerInstitutionId !== institutionId;

const byStandingBidsNotByMyInstitution =
  (institutionId?: string | null) =>
  ({ buyerInstitutionId }: CompanyPageMarketActivityStandingBidFragment) =>
    isNil(institutionId) || buyerInstitutionId !== institutionId;

export const MarketActivityContent = withCurrentActor(
  ({
    company,
    actor,
  }: {
    readonly company: CompanyPageMarketActivityCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { institutionId } = actor;

    const { othersListings, othersStandingBids } = pipe([
      update(`othersListings`, filter(byOpenOrConditionallySoldListings)),
      update(`othersListings`, orderByLowestAskFirst),
      update(
        `othersListings`,
        filter(byListingsNotByMyInstitution(institutionId)),
      ),
      update(
        `othersStandingBids`,
        filter(byOpenOrConditionallyCompletedStandingBids),
      ),
      update(`othersStandingBids`, orderByHighestBidFirst),
      update(
        `othersStandingBids`,
        filter(byStandingBidsNotByMyInstitution(institutionId)),
      ),
    ])(company.activity);

    return (
      <ActivitySection title="Market Activity">
        <Listings
          listings={othersListings}
          standingBids={othersStandingBids}
          company={company}
        />
        <StandingBids
          standingBids={othersStandingBids}
          listings={othersListings}
          company={company}
        />
      </ActivitySection>
    );
  },
);

export const MarketActivity = ({
  companyId,
}: {
  readonly companyId: string;
}) => {
  const orderBookTruncationEnabled = useOrderBookTruncation();

  const query = useCompanyPageMarketActivityCompanyByIdQuery({
    variables: {
      id: companyId,
      includeMarketActivityV2: !!orderBookTruncationEnabled,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({ data: { companyById: company } }) =>
        orderBookTruncationEnabled ? (
          <MarketActivityContentV2 company={company} />
        ) : (
          <MarketActivityContent company={company} />
        )
      }
    </WithQuery>
  );
};
