import { Text } from "@chakra-ui/react";

import { MailtoLink, PleaseNote } from "@/components/common";
import { UnauthorizedModalBodyWrapper } from "@/components/modals";
import { InsufficientTradingPermissions } from "@/components/postings";
import {
  AcceptStandingBidModalCompanyFragment,
  AcceptStandingBidModalStandingBidFragment,
  StandingBidPermission,
  UserPermissionV2,
} from "@/gql";
import { iHaveEntityPermission, useIHavePermission } from "@/hooks";
import { getSecuritySpecialistContact } from "@/utils";

import { AcceptStandingBidSequenceModalContent } from "./AcceptStandingBidSequenceModalContent";

const AcceptStandingBidNotTradable = ({
  company,
}: {
  readonly company: AcceptStandingBidModalCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <PleaseNote>
      <Text textStyle="text-lg">
        Automated bid acceptance for{` `}
        {company.name}
        {` `}is not currently enabled. However, you may still accept this bid by
        contacting our{` `}
        <Text textStyle="heading-lg" as="span">
          {company.name} Security Specialist at{` `}
          <MailtoLink email={securitySpecialistEmail} textStyle="heading-lg">
            {securitySpecialistEmail}
          </MailtoLink>
        </Text>
      </Text>
    </PleaseNote>
  );
};

interface AcceptStandingBidSequenceModalAuthorizationProps {
  readonly standingBid: AcceptStandingBidModalStandingBidFragment;
}

export const AcceptStandingBidSequenceModalAuthorization = ({
  standingBid,
}: AcceptStandingBidSequenceModalAuthorizationProps) => {
  const authorizedToAcceptStandingBid = useIHavePermission(
    UserPermissionV2.AcceptStandingBid,
  );

  if (!authorizedToAcceptStandingBid)
    return (
      <UnauthorizedModalBodyWrapper
        title="Accept Standing Bid"
        observabilityLabelPrefix="AcceptStandingBid/Unauthorized"
      >
        <InsufficientTradingPermissions type="ACCEPT_STANDING_BID" />
      </UnauthorizedModalBodyWrapper>
    );

  if (
    !iHaveEntityPermission(standingBid, StandingBidPermission.AcceptStandingBid)
  )
    return (
      <UnauthorizedModalBodyWrapper
        title="Accept Standing Bid"
        observabilityLabelPrefix="AcceptStandingBid/NotTradable"
      >
        <AcceptStandingBidNotTradable company={standingBid.company} />
      </UnauthorizedModalBodyWrapper>
    );

  return <AcceptStandingBidSequenceModalContent standingBid={standingBid} />;
};
