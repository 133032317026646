import { FormListbox } from "@/components/form";
import { TransferMethod } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { transferMethodToLongString } from "@/utils";

type TransferMethodInputProps = {
  readonly name: string;
  readonly label: string;
  readonly onChange?: (item: TransferMethod) => void;
  readonly isDisabled?: boolean;
};

const allowedTransferMethods = (canSelectHiiveFund: boolean) =>
  [TransferMethod.Direct, TransferMethod.Fund]
    .concat(canSelectHiiveFund ? [TransferMethod.HiiveFund] : [])
    .concat([TransferMethod.Other]);

const TransferMethodInput = ({
  name,
  label,
  onChange,
  isDisabled,
}: TransferMethodInputProps) => {
  const actor = useCurrentActor();
  const transferMethods = allowedTransferMethods(actor.isHiiveUser);

  return (
    <FormListbox
      onSelectItem={onChange}
      name={name}
      label={label}
      itemToString={transferMethodToLongString}
      getItemKey={(item) => item}
      items={transferMethods}
      isDisabled={isDisabled}
    />
  );
};

export default TransferMethodInput;
