import { datadogRum } from "@datadog/browser-rum";
import { useCallback } from "react";

import { InvestorType } from "@/gql";

type IdentifiableUser = {
  id: string;
  institutionId?: string | null;
  investorType?: InvestorType | null;
  isHiiveUser?: boolean;
};

export function useIdentifyUser() {
  return useCallback((actor?: IdentifiableUser) => {
    if (actor?.id != null) {
      datadogRum.setUser({
        id: actor?.id,
        institution_id: actor?.institutionId,
        investor_type: actor?.investorType,
        is_hiive_user: actor?.isHiiveUser === true,
      });
    } else {
      datadogRum.clearUser();
    }
  }, []);
}
