import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardHeader, Text } from "@chakra-ui/react";

import { ExpiryDateCardContent } from "@/components/listings";
import { Nullable } from "@/utils";

import { ModifyListingFormValues } from "./types";

type ExpiryDateCardProps = {
  values: Nullable<ModifyListingFormValues>;
};

export const ExpiryDateCard = forwardRef<HTMLDivElement, ExpiryDateCardProps>(
  ({ values: { hasExpirationDate } }, ref) => {
    const { t } = useTranslation();
    return (
      <Card w="full" ref={ref}>
        <CardHeader>
          <Text
            textStyle="heading-sm"
            _before={{
              content: `counter(section) ". "`,
            }}
          >
            {t(`expiry_date`)}
          </Text>
        </CardHeader>
        <ExpiryDateCardContent hasExpirationDate={hasExpirationDate} />
      </Card>
    );
  },
);
