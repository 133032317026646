import { Box, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { InquiryPostingCardFragment } from "@/gql";

import { useDiscussionStatusText } from "./useDiscussionStatusText";

type InquiryPostingCardFooterProps = {
  discussion: InquiryPostingCardFragment;
};

export function InquiryPostingCardFooter({
  discussion,
}: InquiryPostingCardFooterProps) {
  const { state, numUnreadMessages, totalNumMessages } = discussion;

  const discussionStatusText = useDiscussionStatusText(state);

  const hasMessages = totalNumMessages > 0;

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm">{discussionStatusText}</Text>
      <Box flexGrow={1} />
      {hasMessages && (
        <MessagesBadge
          numUnreadMessages={numUnreadMessages}
          totalNumMessages={totalNumMessages}
        />
      )}
    </PostingCard.Footer>
  );
}
