import { createContext, ReactNode, useMemo } from "react";

import { RootConfigFragment, useConfigQuery } from "@/gql";

export type ConfigContext = RootConfigFragment;

export const ConfigContext = createContext<ConfigContext | undefined>(
  undefined,
);

export const ConfigProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const { data, loading } = useConfigQuery();
  const { config } = data || {};
  const contextValue = useMemo(() => config, [config, loading]);

  if (contextValue == null) {
    return null;
  }

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};
