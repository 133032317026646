import { useTranslation } from "react-i18next";

import { PostingCard } from "@/components/common";
import { InquiryPostingCardFragment } from "@/gql";

type InquiryPostingCardHeaderProps = {
  discussion: InquiryPostingCardFragment;
};

export function InquiryPostingCardHeader({
  discussion: { displayId },
}: InquiryPostingCardHeaderProps) {
  const { t } = useTranslation(`discussions`);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="discussion">
            {t(`inquiry`)}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{displayId}</PostingCard.HeaderTitle>
        </>
      }
    />
  );
}
