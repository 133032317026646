import { Text } from "@chakra-ui/react";

import { ActiveTransactionItemTransactionFragment } from "@/gql";
import { useTransactionDetails } from "@/hooks";

type ActiveTransactionItemStateBadgeProps = {
  transaction: ActiveTransactionItemTransactionFragment;
};

const ActiveTransactionItemStateBadge = ({
  transaction,
}: ActiveTransactionItemStateBadgeProps) => {
  const { getStateLabel } = useTransactionDetails();

  return (
    <Text color="teal.900" textStyle="heading-xs" fontWeight={400}>
      {getStateLabel(
        transaction.workflow?.lastCompletedMilestone
          ? transaction.workflow?.lastCompletedMilestone
          : transaction.state,
      )}
    </Text>
  );
};

export default ActiveTransactionItemStateBadge;
