import { ReactNode } from "react";

import { ModalFooter, ModalFooterProps } from "@chakra-ui/react";

import { ButtonFooterWrapper } from "@/components/common";

interface ExecutionModalFooterProps extends ModalFooterProps {
  readonly children: ReactNode;
}

const ExecutionModalFooter = ({
  children,
  ...footerProps
}: ExecutionModalFooterProps) => (
  <ModalFooter
    border="none"
    borderColor="grey.100"
    borderTop="1px solid"
    p="0"
    px={6}
    py={4}
    {...footerProps}
  >
    <ButtonFooterWrapper gap={4}>{children}</ButtonFooterWrapper>
  </ModalFooter>
);

export default ExecutionModalFooter;
