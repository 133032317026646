import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardFooter,
  Fade,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveButton,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  CompanyLastRoundDetailsCardSkeleton,
  MarketActivityBlock,
  PricingBlock,
  PricingChartWrapper,
} from "@/components/companies";
import { LastRoundDetailsCard } from "@/components/dashboard";
import {
  PostActivityUnaccreditedSellerDashboardPageOverviewPageCompanyFragment,
  usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery,
} from "@/gql";
import {
  getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard,
  makeUrl,
} from "@/utils";

const OverviewPageContent = ({
  company,
}: {
  readonly company: PostActivityUnaccreditedSellerDashboardPageOverviewPageCompanyFragment;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <VStack alignItems="flex-start" spacing={4} pt={{ base: 4, lg: 8 }}>
      <Text textStyle="heading-sm" textTransform="uppercase">
        {t(`company_overview`, {
          companyName: company.name,
        })}
      </Text>
      <Text textStyle="text-md">{t(`issuer_pricing_and_fundraising`)}</Text>
      <Card variant="darkened-footer" w="full">
        <CardBody>
          <HStack spacing={4} mb={5}>
            {!!company.logoUrl && <Image h="12" src={company.logoUrl} />}
            <Text textStyle="heading-4xl">{company.name}</Text>
          </HStack>
          <VStack w="full" spacing={6}>
            <VStack alignItems="flex-start" spacing={5} w="full">
              <Text textStyle="heading-sm">{t(`market_activity`)}</Text>
              <MarketActivityBlock company={company} />
            </VStack>
            <PricingBlock company={company} />
            <Fade in>
              {company.priceChartConfig.displayChart && (
                <PricingChartWrapper company={company} />
              )}
            </Fade>
            <LastRoundDetailsCard company={company} />
          </VStack>
        </CardBody>
        <CardFooter>
          <ButtonFooterWrapper>
            <HiiveButton
              variant="rounded-solid-salmon"
              size="xl"
              onClick={() => router.push(makeUrl(company))}
              observabilityLabel="[OverviewPageContent] Learn More"
            >
              {t(`learn_more`)}
            </HiiveButton>
          </ButtonFooterWrapper>
        </CardFooter>
      </Card>
    </VStack>
  );
};

const OverviewPageSkeleton = () => (
  <VStack alignItems="flex-start" spacing={4} pt={{ base: 4, lg: 8 }}>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" maxW="25%" />
      <Skeleton h="16px" maxW="75%" />
    </Flex>
    <Card w="full">
      <CardBody>
        <Skeleton h="40px" w="full" maxW="40%" mb={5} />
        <Skeleton h="16px" w="full" maxW="144px" mb={5} />
        <HStack maxW="45%" spacing={2} mb={6}>
          <Skeleton h="40px" maxW="122px" />
          <Skeleton h="40px" maxW="90px" />
        </HStack>
        <Skeleton h="92px" mb={6} />
        <CompanyLastRoundDetailsCardSkeleton />
      </CardBody>
    </Card>
  </VStack>
);

const OverviewPage = () => {
  const query =
    usePostActivityUnaccreditedSellerDashboardPageOverviewPageQuery();
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<OverviewPageSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity,
          unaccreditedSellerMyCompanyActivity,
        },
      }) => {
        const { myListing, myCompany } = unaccreditedSellerMyActivity;

        if (
          !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard(
            unaccreditedSellerMyActivity,
            unaccreditedSellerMyCompanyActivity,
          ) &&
          !myListing
        ) {
          router.replace(`/dashboard`);
          return null;
        }

        return <OverviewPageContent company={myCompany} />;
      }}
    </WithQuery>
  );
};

export default OverviewPage;
