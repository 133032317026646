import { useTranslation } from "react-i18next";

import { Flex } from "@chakra-ui/react";

import Meta from "@/components/Meta";
import { WithQuery } from "@/components/common";
import {
  CompanyLastRoundDetailsCard,
  CompanyLastRoundDetailsCardSkeleton,
  CompanyHistoricalPricingCardSkeleton,
  PricingChartWrapper,
} from "@/components/companies";
import {
  CompanyPageInfoCompanyFragment,
  useCompanyPageInfoCompanyByIdV2Query,
} from "@/gql";

import { CompanyDescriptionCard } from "./CompanyDescriptionCard";
import { CompanyDescriptionCardSkeleton } from "./CompanyDescriptionCardSkeleton";

type CompanyInfoProps = {
  readonly companyId: string;
};

type CompanyInfoContentProps = {
  readonly company: CompanyPageInfoCompanyFragment;
};

const CompanyInfoContent = ({ company }: CompanyInfoContentProps) => {
  const { t } = useTranslation(`company`);
  const { priceChartConfig } = company;

  return (
    <>
      <Meta
        title={t(`company_title`, {
          companyName: company.name,
        })}
      />
      <Flex direction="column" gap={4}>
        <CompanyDescriptionCard company={company} />
        {priceChartConfig.displayChart && (
          <PricingChartWrapper company={company} />
        )}
        <CompanyLastRoundDetailsCard company={company} />
      </Flex>
    </>
  );
};

const CompanyInfoSkeleton = () => (
  <Flex direction="column" gap={4}>
    <CompanyDescriptionCardSkeleton />
    <CompanyHistoricalPricingCardSkeleton />
    <CompanyLastRoundDetailsCardSkeleton />
  </Flex>
);

export const CompanyInfo = ({ companyId }: CompanyInfoProps) => {
  const query = useCompanyPageInfoCompanyByIdV2Query({
    variables: { id: companyId },
  });

  return (
    <WithQuery query={query} fallback={<CompanyInfoSkeleton />}>
      {({ data: { company } }) => <CompanyInfoContent company={company} />}
    </WithQuery>
  );
};
