import { useTranslation } from "react-i18next";

import { CardFooter, HStack } from "@chakra-ui/react";

import {
  calculateTotalOpenBids,
  ConditionallySoldOutBadge,
  PartiallySoldBadge,
  OpenBidsBadge,
  InquiriesBadge,
} from "@/components/companies";
import {
  ActivityCardListingCardFooterListingFragment,
  ListingState,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getIsBroker,
  getShowListingSolicitedState,
  toTimestamp,
} from "@/utils";

const ActivityCardListingCardFooterV2 = ({
  listing,
}: {
  readonly listing: ActivityCardListingCardFooterListingFragment;
}) => {
  const {
    acceptedBidPrices,
    bids,
    insertedAt,
    transferMethod,
    state,
    numActiveBids,
    numCounteredBids,
    numInquiries,
    numUnreadMessages,
  } = listing;

  const actor = useCurrentActor();
  const { t } = useTranslation();

  const isConditionallySold = state === ListingState.ConditionallySold;

  const isPartiallySold =
    state === ListingState.Open && acceptedBidPrices.length >= 1;

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const isInReview = state === ListingState.InReview;

  const isPlaced = getIsBroker(actor) && state === ListingState.Open;

  const hasOpenBids = totalOpenBids >= 1;

  const hasInquires = numInquiries >= 1;

  const showListingSolicitedState = getShowListingSolicitedState(
    listing,
    actor,
  );

  const hasFooter =
    hasInquires ||
    isPartiallySold ||
    isConditionallySold ||
    hasOpenBids ||
    isInReview ||
    isPlaced;

  if (!hasFooter || showListingSolicitedState) return null;

  return (
    <CardFooter
      px={3}
      py={3}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      {isInReview &&
        t(`listing_submitted_in_review`, { date: toTimestamp(insertedAt) })}
      {isPlaced &&
        t(`listing_placed_status`, { date: toTimestamp(insertedAt) })}
      <HStack
        wrap="wrap"
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={{ base: 2, md: 3 }}
      >
        {isConditionallySold && (
          <ConditionallySoldOutBadge transferMethod={transferMethod} />
        )}
        {isPartiallySold && (
          <PartiallySoldBadge transferMethod={transferMethod} />
        )}
        {hasOpenBids && (
          <OpenBidsBadge
            totalOpenBids={totalOpenBids}
            bids={bids}
            transferMethod={transferMethod}
          />
        )}
        {hasInquires && (
          <InquiriesBadge
            numInquiries={numInquiries}
            numUnreadMessages={numUnreadMessages}
          />
        )}
      </HStack>
    </CardFooter>
  );
};

export default ActivityCardListingCardFooterV2;
