import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { BidPostingCardFragment, InvestorType } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { makeUrl } from "@/utils";

import { BidPostingCardBody } from "./BidPostingCardBody";
import { BidPostingCardBrokerFooter } from "./BidPostingCardBrokerFooter";
import { BidPostingCardFooter } from "./BidPostingCardFooter";
import { BidPostingCardHeader } from "./BidPostingCardHeader";
import { BidPostingCardUnaccreditedSellerFooter } from "./BidPostingCardUnaccreditedSellerFooter";

type BidPostingCardProps = {
  bid: BidPostingCardFragment;
};

function BidPostingTraderCard({ bid }: BidPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidPostingCardHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
      <BidPostingCardFooter bid={bid} />
    </PostingCard>
  );
}

function BidPostingUnaccreditedSellerCard({ bid }: BidPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidPostingCardHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
      <BidPostingCardUnaccreditedSellerFooter bid={bid} />
    </PostingCard>
  );
}

function BidPostingBrokerCard({ bid }: BidPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidPostingCardHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
      <BidPostingCardBrokerFooter bid={bid} />
    </PostingCard>
  );
}

export function BidPostingCard({ bid }: BidPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.UnaccreditedSeller, () => (
      <BidPostingUnaccreditedSellerCard bid={bid} />
    ))
    .with(InvestorType.Broker, () => <BidPostingBrokerCard bid={bid} />)
    .otherwise(() => <BidPostingTraderCard bid={bid} />);
}
