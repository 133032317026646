import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { hasSubscription } from "@jumpn/utils-graphql";
import { Socket as PhoenixSocket } from "phoenix";

import { getApiUrl } from "@/hooks/useServerPreview";
import store from "@/state";

import errorLink from "./error-link";

const APOLLO_CACHE = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listCompanies: relayStylePagination(),
      },
    },
  },
});

const getApiHost = (url: string | null, port: string | number) =>
  url || process.env.NEXT_PUBLIC_API_HOST || `http://localhost:${port}/`;

const getApiWsHost = (url: string | null, port: string | number) =>
  url
    ? url.replace(/^(https|http)/, `wss`)
    : process.env.NEXT_PUBLIC_API_WS_HOST || `ws://localhost:${port}/`;

const createClient = (url?: string | null, sessionToken?: string) => {
  const port = process.env.NEXT_PUBLIC_PORT || 4000;
  const apiHost = getApiHost(url || null, port);
  const apiWsHost = getApiWsHost(url || null, port);

  const version = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
  const graphqlUri = `${apiHost}graphql`;
  const httpLink = createHttpLink({
    uri: graphqlUri,
  });

  const authToken = () => {
    if (sessionToken) {
      return sessionToken;
    }

    const { auth } = store.getState();
    const token = auth?.token;

    return token;
  };

  const authLink = setContext(
    (
      _: unknown,
      { headers }: { readonly headers: Record<string, string> },
    ) => ({
      headers: {
        ...headers,
        "x-hiive-client-version": version ? version.slice(0, 7) : null,
        authorization: authToken() ? `Bearer ${authToken()}` : null,
      },
    }),
  );

  const authedHttpLink = authLink.concat(httpLink);

  const absintheSocketLink = () => {
    const phoenixSocket = new PhoenixSocket(`${apiWsHost}socket`, {
      params: () => (authToken() ? { authToken: authToken() } : {}),
    });

    const absintheSocket = AbsintheSocket.create(phoenixSocket);
    return createAbsintheSocketLink(absintheSocket);
  };

  // websockets break SSR, therefore enabling it only in the browser
  const link = process.browser
    ? split(
        ({ query }) => hasSubscription(query),
        absintheSocketLink() as unknown as ApolloLink,
        authedHttpLink,
      )
    : authedHttpLink;

  const client = new ApolloClient({
    link: from([errorLink, link]),
    cache: APOLLO_CACHE,
  });

  return client;
};

const client = createClient(getApiUrl());

export { client, createClient };
