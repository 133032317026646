/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useField } from "formik";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  HStack,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
  Radio,
} from "@chakra-ui/react";

import {
  CompanyEligibilityCriteriaCheckbox,
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  EmailInput,
  OtherDetailsInput,
  QuestionTooltip,
  StepPropsV2,
  TextInput,
  FormRadioInput,
} from "@/components/form";
import {
  representedEmailSchema,
  representedNameSchema,
  ShareSeriesMakeupElement,
  TransferMethodInput,
} from "@/components/postings";
import {
  RepresentedListingAdditionalListingNotesTooltip,
  RepresentedUserTooltip,
} from "@/components/tooltip";
import {
  ShareSeries,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor, useModal, useStepValidator } from "@/hooks";
import { useRsuRestrictionAndEligibleBuyers } from "@/hooks/featureFlags";
import {
  checkContainsRestrictedStockUnit,
  checkTransferMethodManual,
  fromBool,
  toBool,
} from "@/utils";
import { pipe } from "@/utils/pipe";

import { CreateListingSequenceModalFormValues } from ".";
import {
  CreateListingSequenceModalStepFormContext,
  useCreateListingSequenceModalStepFormContext,
} from "./CreateListingSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, CreateListingSequenceModalFormValues> {}

interface ValidationSchemaOptions {
  readonly isEligibilityRequirementsCheckboxRequired: boolean;
  readonly isInformationDisclosureCheckboxRequired: boolean;
}

export const createValidationSchema = (
  actor: UserWithInstitutionFragment,
  options: ValidationSchemaOptions,
) =>
  Yup.object().shape({
    transferMethod: Yup.string().required(`Required`),
    confirmedEligibilityRequirements: Yup.boolean().when([], {
      is: () => options.isEligibilityRequirementsCheckboxRequired,
      then: Yup.boolean().oneOf([true], `Required`),
    }),
    confirmedInformationDisclosure: Yup.boolean().when([], {
      is: () => options.isInformationDisclosureCheckboxRequired,
      then: Yup.boolean().oneOf([true], `Required`),
    }),
    confirmed: Yup.boolean().oneOf([true], `Required`),
    hasExpirationDate: Yup.bool().required(`Required`),
    expireAt: Yup.string()
      .nullable()
      .test({
        name: `checkRequired`,
        message: `Required`,
        test: function () {
          if (this.parent.hasExpirationDate) {
            return !!this.parent.expireAt;
          }
          return true;
        },
      }),
    otherDetails: Yup.string().nullable(),
    muteNotifyWatchers: Yup.boolean().nullable(),
    manualSigningProcedure: Yup.boolean(),
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.boolean().required(`Required`),
  });

export const AdditionalDetails = ({
  values,
  stepRouter,
  isSubmitting,
}: AdditionalDetailsProps) => {
  const rsuRestrictionAndEligibleBuyers = useRsuRestrictionAndEligibleBuyers();

  const actor = useCurrentActor();

  const { closeModal } = useModal();
  const { stepControls } = stepRouter;
  const router = useRouter();
  const { t } = useTranslation();

  const [_field, _, { setValue: setManualSigningProcedure }] = useField(
    `manualSigningProcedure`,
  );

  const { submitMutation } = useCreateListingSequenceModalStepFormContext();
  const { company, shareSeriesMakeup, transferMethod } = values;

  const requiresInformationDisclosure =
    !!company?.requiresInformationDisclosure;

  const eligibilityCriterionText = company?.companyEligibilityCriterion?.text;
  const onlyCommonShares = shareSeriesMakeup.every(
    (shareSeriesMakeupElement) =>
      shareSeriesMakeupElement.shareSeries === ShareSeries.Common,
  );
  const showConfirmEligibilityRequirementsCheckbox =
    !isNil(eligibilityCriterionText) && onlyCommonShares;

  const validationSchema = createValidationSchema(actor, {
    isEligibilityRequirementsCheckboxRequired:
      showConfirmEligibilityRequirementsCheckbox,
    isInformationDisclosureCheckboxRequired: requiresInformationDisclosure,
  });

  const isTransferMethodManual = checkTransferMethodManual(transferMethod);

  const onSuccess = useCallback(async () => {
    const {
      createListing: { listing },
    } = await submitMutation();

    const newListingId = listing?.id;

    if (!newListingId) {
      return;
    }

    stepControls.nextStep();
    router.push(`/listings/${newListingId}`);
  }, [stepControls, router, submitMutation]);

  const handleChangeTransferMethod = useCallback(
    (value: TransferMethod) => {
      if (checkTransferMethodManual(value)) {
        setManualSigningProcedure(true);
      }
    },
    [setManualSigningProcedure],
  );

  const hasRestrictedStockUnit = useMemo(() => {
    const containsRestrictedStockUnit = pipe(shareSeriesMakeup)
      .step((shareSeriesElements) =>
        shareSeriesElements.filter(
          (element): element is ShareSeriesMakeupElement => !!element,
        ),
      )
      .step(checkContainsRestrictedStockUnit)
      .end();

    return !!rsuRestrictionAndEligibleBuyers && containsRestrictedStockUnit;
  }, [rsuRestrictionAndEligibleBuyers, shareSeriesMakeup]);

  useStepValidator({
    Context: CreateListingSequenceModalStepFormContext,
    stepKey: stepKeys.createListingAdditionalDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          <GridItem colSpan={2} data-testid="transfer-method-input">
            <TransferMethodInput
              onChange={handleChangeTransferMethod}
              name="transferMethod"
              label="How will these be transferred?"
              isDisabled={hasRestrictedStockUnit}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <FormRadioInput
              label={t(`end_date_question`)}
              name="hasExpirationDate"
              mapper={{ from: fromBool, to: toBool }}
              data-testid="create-listing-end-date-question"
              tooltipContent={t(
                `listing_additional_details_expiry_date_tooltip`,
              )}
            >
              <HStack spacing={12}>
                <Radio variant="base" value="true">
                  {t(`yes`)}
                </Radio>
                <Radio variant="base" value="false">
                  {t(`no`)}
                </Radio>
              </HStack>
            </FormRadioInput>
          </GridItem>
          {values.hasExpirationDate && (
            <GridItem colSpan={2}>
              <DateInput
                name="expireAt"
                label={t(`end_date_input_label`)}
                tooltipContent={t(`end_date_input_tooltip`)}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <OtherDetailsInput
              name="otherDetails"
              label="Additional listing notes"
              placeholder="Add details"
              tooltipContent={
                <RepresentedListingAdditionalListingNotesTooltip />
              }
            />
          </GridItem>
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptionsDropdown
                validationSchema={validationSchema}
                fieldNames={[
                  `representedEmail`,
                  `representedFirstName`,
                  `representedLastName`,
                  `manualSigningProcedure`,
                  `muteNotifyWatchers`,
                ]}
              >
                <VStack spacing={4} alignItems="flex-start">
                  {!!transferMethod && (
                    <CheckboxInput
                      isDisabled={isTransferMethodManual}
                      name="manualSigningProcedure"
                      label={t(`manual_signing_procedure`)}
                    />
                  )}
                  <CheckboxInput
                    name="muteNotifyWatchers"
                    label={t(`do_not_send_activity_notifications`)}
                  />
                  <Text
                    align="center"
                    textStyle="deprecated-heading-lg"
                    color="h-dark-grey"
                  >
                    {t(`represented_user_info`)}
                    <QuestionTooltip
                      translateY={1.5}
                      translateX={1.0}
                      transform="auto"
                      tooltipContent={<RepresentedUserTooltip />}
                    />
                  </Text>
                  <HStack gap={4} w="full" alignItems="top">
                    <TextInput
                      data-testid="represented-user-first-name"
                      name="representedFirstName"
                      placeholder={t(`first_name`)}
                    />
                    <TextInput
                      data-testid="represented-user-last-name"
                      name="representedLastName"
                      placeholder={t(`last_name`)}
                    />
                  </HStack>
                  <EmailInput
                    data-testid="represented-email"
                    name="representedEmail"
                    placeholder="Email"
                    type="email"
                  />
                  <CheckboxInput
                    name="notifyRepresentingEmail"
                    label="Notify the person being represented"
                  />
                </VStack>
              </HiiveAdvancedOptionsDropdown>
            </GridItem>
          )}
          {showConfirmEligibilityRequirementsCheckbox && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaCheckbox
                name="confirmedEligibilityRequirements"
                label={t(`agreed_to_eligibility_requirements`, {
                  companyName: company?.name,
                })}
                eligibilityCriterionText={eligibilityCriterionText}
              />
            </GridItem>
          )}
          {requiresInformationDisclosure && (
            <GridItem colSpan={2}>
              <CheckboxInput
                name="confirmedInformationDisclosure"
                label={t(`agreed_to_issuer_disclosure`, {
                  companyName: company?.name,
                })}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <CheckboxInput
              name="confirmed"
              label={t(`create_listing_intent_for_sale`)}
            />
          </GridItem>
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[CreateListing/AdditionalDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveBackButton
          observabilityLabel="[CreateListing/AdditionalDetails/Back]"
          onClick={stepControls.previousStep}
        />
        <HiiveSubmitButton
          observabilityLabel="[CreateListing/AdditionalDetails/Submit]"
          isLoading={isSubmitting}
          submitText="Add Listing"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};
