import { TileConfig } from "@/components/common";

import { ActivityCardBadge } from "./ActivityCardBadge";
import { Alert } from "./Alert";
import { BackToTop } from "./BackToTop";
import { Badge } from "./Badge";
import { Button } from "./Button";
import { CallOut } from "./CallOut";
import { Card } from "./Card";
import { Checkbox } from "./Checkbox";
import { CustomSelect } from "./CustomSelect";
import { Divider } from "./Divider";
import { Form } from "./Form";
import { FormLabel } from "./FormLabel";
import { FrontChatLauncher } from "./FrontChatLauncher";
import { Input } from "./Input";
import { Link } from "./Link";
import { List } from "./List";
import { Menu } from "./Menu";
import { Modal } from "./Modal";
import { NumberInput } from "./NumberInput";
import { PostingCardLabel } from "./PostingCardLabel";
import { Progress } from "./Progress";
import { Radio } from "./Radio";
import { RadioTile } from "./RadioTile";
import { ShareDetails } from "./ShareDetails";
import { ShareDetailsHeader } from "./ShareDetailsHeader";
import { Switch } from "./Switch";
import Table from "./Table";
import { Tabs } from "./Tabs";
import { Tag } from "./Tag";
import { Text } from "./Text";
import { Textarea } from "./Textarea";
import { Tooltip } from "./Tooltip";

const components = {
  ActivityCardBadge,
  Alert,
  BackToTop,
  Badge,
  Button,
  Card,
  Checkbox,
  CustomSelect,
  Divider,
  Form,
  FormLabel,
  FrontChatLauncher,
  Input,
  Link,
  List,
  Menu,
  Modal,
  NumberInput,
  Textarea,
  Progress,
  ShareDetails,
  ShareDetailsHeader,
  Radio,
  Switch,
  Tabs,
  Tag,
  Text,
  Tile: TileConfig,
  Tooltip,
  CallOut,
  RadioTile,
  Table,
  PostingCardLabel,
};

export default components;
