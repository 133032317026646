export default {
  highest_bid: `Highest Bid`,
  highest_bid_tooltip: `Bid is the highest priced bid on {{companyName}} listings or {{companyName}} standing bids that was opened this week.`,
  lowest_ask: `Lowest Ask`,
  lowest_ask_tooltip: `Ask is the lowest priced listing for {{companyName}} that was open this week.`,
  last_accepted_bid: `Last Accepted Bid`,
  last_accepted_bid_tooltip: `The most recent price at which a bid was accepted for {{companyName}}.`,
  overview: `Overview`,
  your_activity: `Your Activity`,
  market_activity: `Market Activity`,
  sellers: `Sellers`,
  buyers: `Buyers`,
  listings: `Listings`,
  bids: `Bids`,
  standing_bids: `Standing Bids`,
  inquiries: `Inquiries`,
  company_title: `Company - {{companyName}}`,
  your_activity_on_behalf_of_institution: `Your Activity on Behalf of {{legalName}}`,
  other_institution_activity: `Other {{legalName}} Activity`,
  institution_activity: `{{legalName}} Activity`,
  currently_no_activity: `You currently have no activity for {{name}}.`,
  no_active_standing_bids_orderbook: `No active standing bids for {{companyName}}. Add this company to your watchlist to get notified about new standing bids.`,
  no_active_listings_orderbook: `No active listings for {{companyName}}. Add this company to your watchlist to get notified about new listings.`,
  view_listings_over: `View listings over {{pricePerShare}}/sh`,
  view_standing_bids_under: `View standing bids under {{pricePerShare}}/sh`,
  any: `Any`,
  standing_bid: `Standing Bid`,
  posting_num_shares_price: `<b>{{ numSharesOriginal }} @ {{ pricePerShare }}<r>/sh</r></b>`,
  posting_partially_sold_num_shares_price: `<u><b>{{ numSharesOriginal }}</b></u><spacer/><b>{{ numSharesAvailable }} @ {{ pricePerShare }}<r>/sh</r></b>`,
  posting_sold_out_num_shares_price: `<u><b>{{ numSharesOriginal }} @ {{ pricePerShare }}</b>/sh<b></b></u>`,
  posting_lot_price: `<b>{{lot}}</b>`,
  posting_lot_price_sold_out: `<u><b>{{lot}}</b></u>`,
  standing_bid_shares_purchased: `{{ numSharesAccepted }} shares purchased`,
  transfer_method: `Transfer type`,
  share_type: `Share type`,
};
