import { ReactElement } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  Text,
  SystemProps,
} from "@chakra-ui/react";

import { TooltipFormLabel } from "@/components/form";

import { FormRadioTile } from ".";

type FormConditionalInputProps<TFieldValues extends FieldValues> = {
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly tooltipContent?: ReactElement;
  readonly control: Control<TFieldValues>;
  readonly gridTemplateColumns?: SystemProps["gridTemplateColumns"];
};

const FormConditionalInput = <TFieldValues extends FieldValues>({
  name,
  label,
  tooltipContent,
  control,
  gridTemplateColumns = {
    base: `1fr`,
    md: `1fr 1fr 1fr`,
  },
}: FormConditionalInputProps<TFieldValues>) => {
  const { t } = useTranslation();
  const {
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl id={name} isInvalid={invalid}>
      <TooltipFormLabel
        fontWeight="500"
        htmlFor={name}
        pb={0}
        tooltipContent={tooltipContent}
      >
        {label}
      </TooltipFormLabel>
      <FormRadioTile.Group
        size="sm"
        gridTemplateColumns={gridTemplateColumns}
        gridColumnGap={2}
        pt={2}
        px={0}
      >
        <FormRadioTile.Checkbox
          pl={0}
          name={name}
          control={control}
          data-testId={`${name}-FormConditionalInput-Yes`}
          value={true as PathValue<TFieldValues, Path<TFieldValues>>}
        >
          <Text>{t(`yes`)}</Text>
        </FormRadioTile.Checkbox>
        <FormRadioTile.Checkbox
          pl={0}
          name={name}
          control={control}
          data-testId={`${name}-FormConditionalInput-No`}
          value={false as PathValue<TFieldValues, Path<TFieldValues>>}
        >
          <Text>{t(`no`)}</Text>
        </FormRadioTile.Checkbox>
      </FormRadioTile.Group>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormConditionalInput;
