import { Money, SealCheck } from "@phosphor-icons/react";
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  SlideAnimation,
  InnerTileContainer,
  OnboardingInfoMessageContext,
} from "@/components/onboarding-v2";
import { FormRadioTile } from "@/components/react-hook-form";
import {
  InvestorType,
  UserWithInstitutionFragment,
  useTransitionCurrentStepMutation,
  useUpdateUserMutation,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

const initialValues = (actor: UserWithInstitutionFragment) => ({
  investorType: actor.investorType,
});

const mapVariables = ({
  investorType,
}: {
  investorType: InvestorType | null;
}) => ({
  input: { investorType },
});

const validationSchema = Yup.object().shape({
  investorType: Yup.mixed().oneOf(
    [InvestorType.Trader, InvestorType.UnaccreditedSeller],
    `Required`,
  ),
});

export const InvestorTypeForm = () => {
  const { t } = useTranslation();
  const { resetReonboardingMessage } = useContext(OnboardingInfoMessageContext);
  const actor = useCurrentActor();

  const mutation = useUpdateUserMutation();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
    resetReonboardingMessage();
  };

  const { handleSubmit, control, isLoading, formState } = useFormQL({
    mutation,
    mapVariables,
    initialValues: initialValues(actor),
    validationSchema,
    onSuccess,
  });

  const [grey700] = useColors([`grey.700`]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <FormRadioTile.Group
            size="lg"
            gridTemplateColumns={{ base: `1fr`, md: `1fr 1fr` }}
            bg="white"
            p={{ base: 4, lg: 7 }}
            borderRadius="md"
            borderColor="grey.200"
            borderWidth={0.5}
            boxShadow="card"
          >
            <FormRadioTile.Tile
              value={InvestorType.Trader}
              control={control}
              name="investorType"
            >
              <InnerTileContainer>
                <SealCheck width={40} height={40} fill={grey700} />

                <Text textStyle="heading-lg">{t(`individual_trader`)}</Text>
                <Text textStyle="text-md">
                  {t(`investor_type_individual_trader_description`)}
                </Text>
                <UnorderedList w="full" pl={4}>
                  <ListItem>{t(`individual_trader_view_offers`)}</ListItem>

                  <ListItem>
                    <Trans
                      i18nKey="individual_trader_buy_and_sell"
                      components={{ bold: <strong /> }}
                    />
                  </ListItem>
                </UnorderedList>
              </InnerTileContainer>
            </FormRadioTile.Tile>

            <FormRadioTile.Tile
              value={InvestorType.UnaccreditedSeller}
              control={control}
              name="investorType"
            >
              <InnerTileContainer>
                <Money size={40} color={grey700} />

                <Text textStyle="heading-lg">{t(`seller_only`)}</Text>
                <Text textStyle="text-md">{t(`seller_only_description`)}</Text>
                <UnorderedList w="full" pl={4}>
                  <ListItem>{t(`seller_view_offers`)}</ListItem>
                  <ListItem>
                    <Trans
                      i18nKey="seller_only_sell_capabilities"
                      components={{ bold: <strong /> }}
                    />
                  </ListItem>
                </UnorderedList>
              </InnerTileContainer>
            </FormRadioTile.Tile>
          </FormRadioTile.Group>
        </SlideAnimation>
        <Flex justifyContent="flex-end" w="full">
          <HiiveButton
            type="submit"
            isLoading={isLoading || isTransitioningCurrentStep}
            size="xl"
            w={{ base: `full`, sm: `unset` }}
            maxW="unset"
            variant="rounded-solid-salmon"
            observabilityLabel="[InvestorTypePage] Next"
            isDisabled={!formState.isValid}
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};
