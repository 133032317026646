/* eslint-disable func-names */

/* eslint-disable object-shorthand */
import currency from "currency.js";
import { Form } from "formik";
import * as Yup from "yup";

import { GridItem, ModalBody, Show, SimpleGrid, Text } from "@chakra-ui/react";

import {
  FeeBreakdownCommissionInfo,
  FullDivider,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  FixedValueInput,
  FormikQL,
  FormNumberInput,
  MoneyInput,
} from "@/components/form";
import {
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  CounterBidModalBidFragment,
  useCounterBidV2Mutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import {
  formatShares,
  getBidNumSharesActual,
  getCanCounterNumberOfShares,
} from "@/utils";

import { CounterBidModalBidShareDetails } from "./CounterBidModalBidShareDetails";
import { CounterBidModalListingShareDetails } from "./CounterBidModalListingShareDetails";

interface CounterBidFormValues {
  readonly bidId: string;
  readonly counterPricePerShare: number;
  readonly counterNumShares: number;
}

const mapVariables = (
  values: CounterBidFormValues,
  bid: CounterBidModalBidFragment,
) => {
  // check if seller updated the initial passed values. if updated pass the updated value if not pass null
  const counterPricePerShare =
    values.counterPricePerShare === bid.pricePerShare / 100
      ? null
      : currency(values.counterPricePerShare).intValue;
  const counterNumShares =
    values.counterNumShares === bid.numSharesActual
      ? null
      : values.counterNumShares;

  return {
    bidId: values.bidId,
    input: {
      counterPricePerShare,
      counterNumShares,
    },
  };
};

const createValidationSchema = (bid: CounterBidModalBidFragment) =>
  Yup.object().shape({
    counterPricePerShare: Yup.number()
      .nullable()
      .min(
        bid.pricePerShare / 100,
        `Counter price per share must be greater than bid price per share.`,
      )
      .test({
        name: `checkCounterPricePerShare`,
        test: function (counterPricePerShare: number) {
          if (!bid.listing.acceptPartialBid && !counterPricePerShare) {
            return this.createError({
              message: `Counter price per share is required.`,
            });
          }

          if (
            (!counterPricePerShare && !this.parent.counterNumShares) ||
            (counterPricePerShare === bid.pricePerShare / 100 &&
              this.parent.counterNumShares === bid.numSharesActual)
          ) {
            return this.createError({
              message: `Must counter either the price per share or number of shares.`,
            });
          }

          return true;
        },
      }),
    counterNumShares: Yup.number()
      .nullable()
      .min(
        bid.numSharesActual,
        `Counter number of shares must be greater than bid number of shares.`,
      )
      .test({
        name: `checkCounterNumShares`,
        test: function (counterNumShares: number) {
          if (!bid.listing.acceptPartialBid) return true;

          if (
            (!counterNumShares && !this.parent.counterPricePerShare) ||
            (counterNumShares === bid.numSharesActual &&
              this.parent.counterPricePerShare === bid.pricePerShare / 100)
          ) {
            return this.createError({
              message: `Must counter either the number of shares or price per share.`,
            });
          }

          return true;
        },
      }),
  });
const createInitialValues = (
  bid: CounterBidModalBidFragment,
): CounterBidFormValues => ({
  bidId: bid.id,
  counterPricePerShare: bid.pricePerShare / 100,
  counterNumShares: bid.numSharesActual,
});

interface CounterBidModalProps {
  readonly bid: CounterBidModalBidFragment;
}

const CounterBidModal = ({ bid }: CounterBidModalProps) => {
  const { successToast } = useCustomToast();
  const { closeModal } = useModal();

  const mutation = useCounterBidV2Mutation({
    refetchQueries: [BidPageBidByIdDocument, BidPageMyActivityDocument],
  });

  const onSuccess = () => {
    successToast(`Counter bid made.`);
    closeModal();
  };

  const actualNumShares = getBidNumSharesActual(bid);
  const formattedNumShares = formatShares(actualNumShares);

  const canCounterNumShares = getCanCounterNumberOfShares(bid);

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`counterBidV2`]}
        mapVariables={(values) => mapVariables(values, bid)}
        initialValues={createInitialValues(bid)}
        validationSchema={createValidationSchema(bid)}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <HiiveModalHeader>
              <Text as="span">
                Counter bid on {bid.listing.company.name}
                {` `}
              </Text>
              <Text as="span">Bid {bid.displayId}</Text>
            </HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} rowGap={7} columnGap={9} w="full">
                <GridItem colSpan={2}>
                  <CounterBidModalListingShareDetails listing={bid.listing} />
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
                <GridItem colSpan={2}>
                  <CounterBidModalBidShareDetails bid={bid} />
                </GridItem>
                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  {canCounterNumShares ? (
                    <FormNumberInput
                      name="counterNumShares"
                      label="Number of shares"
                    />
                  ) : (
                    <FixedValueInput
                      label="Number of shares"
                      name="numShares"
                      fixedValue={formattedNumShares}
                    />
                  )}
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <MoneyInput
                    name="counterPricePerShare"
                    label="Price per share"
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FeeBreakdownCommissionInfo
                    numShares={values.counterNumShares}
                    pricePerShare={values.counterPricePerShare}
                    listingId={bid.listing.id}
                    disclaimerVariant="counterBid"
                  />
                </GridItem>
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  observabilityLabel="[CounterBid/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                observabilityLabel="[CounterBid/Submit]"
                submitText="Make Counter"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default CounterBidModal;
