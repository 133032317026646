import { match } from "ts-pattern";

import { DocumentType, TransferMethod, ValidTransferMethodV2 } from "@/gql";

/**
 * Converts a document type into its corresponding short document title
 * @param documentType - the document type on a document that will be converted into a document title
 * @returns - appropriate document title
 */
export const getShortDocumentTitleByDocumentType = (
  documentType: DocumentType,
) =>
  match(documentType)
    .with(DocumentType.Stn, () => `STN`)
    .with(DocumentType.Loi, () => `LOI`)
    .otherwise((documentType) => {
      throw new Error(
        `Unknown documentType in getShortDocumentTitleByDocumentType: ${documentType}`,
      );
    });

/**
 * Converts a document type into its corresponding long document title
 * @param documentType - the document type on a document that will be converted into a document title
 * @returns - appropriate document title
 */
export const getLongDocumentTitleByDocumentType = (
  documentType: DocumentType,
) =>
  match(documentType)
    .with(DocumentType.Stn, () => `Share Transfer Notice`)
    .with(DocumentType.Loi, () => `Letter of Intent`)
    .otherwise((documentType) => {
      throw new Error(
        `Unknown documentType in getLongDocumentTitleByDocumentType: ${documentType}`,
      );
    });

/**
 * Converts a transfer method into its corresponding short document title
 * @param transferMethod - the transfer method on a transaction / listing that will be converted into a document title
 * @returns - appropriate document title
 */
export const getShortDocumentTitleByTransferMethod = (
  transferMethod: TransferMethod,
) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `STN`)
    .with(TransferMethod.Fund, () => `LOI`)
    .with(TransferMethod.HiiveFund, () => `LOI`)
    .with(TransferMethod.Unknown, () => `STN/LOI`)
    .with(TransferMethod.Other, () => `Agreement`)
    .otherwise(() => {
      throw new Error(
        `Unknown transfer method in getShortDocumentTitleByTransferMethod: ${transferMethod}`,
      );
    });

/**
 * Converts a transfer method into its corresponding long document title
 * @param transferMethod - the transfer method on a transaction / listing that will be converted into a document title
 * @returns - appropriate document title
 */
export const getLongDocumentTitleByTransferMethod = (
  transferMethod: TransferMethod | ValidTransferMethodV2,
) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `Share Transfer Notice`)
    .with(TransferMethod.Fund, () => `Letter of Intent`)
    .with(TransferMethod.HiiveFund, () => `Letter of Intent`)
    .with(TransferMethod.Other, () => `Other`)
    .with(
      TransferMethod.Unknown,
      () => `Share Transfer Notice / Letter of Intent`,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transfer method in getLongDocumentTitleByTransferMethod: ${transferMethod}`,
      );
    });

export const getFullDocumentTitleByTransferMethod = (
  transferMethod: TransferMethod,
) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => `STN (Share Transfer Notice)`)
    .with(TransferMethod.Fund, () => `LOI (Letter of Intent)`)
    .with(TransferMethod.HiiveFund, () => `LOI (Letter of Intent)`)
    .with(
      TransferMethod.Unknown,
      () => `STN (Share Transfer Notice) / LOI (Letter of Intent)`,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transfer method in getFullDocumentTitleByTransferMethod: ${transferMethod}`,
      );
    });
