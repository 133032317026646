import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  VStack,
  Text,
  UnorderedList,
  ListItem,
  HStack,
} from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import {
  FormCheckboxInput,
  FormRadioTile,
  TileCheckboxInput,
} from "@/components/react-hook-form";
import {
  AccreditationQuestion,
  useAnswerInstitutionAccreditationQuestionsMutation,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

import { InstitutionAccreditationFormProps } from "./InstitutionAccreditationForm";

const ConfirmationDisclaimerQuestionKey = `uk-institutional-confirm-v3`;
const NotMiFIDQuestionKey = `uk-institutional-not-mifid-v3`;

interface InstitutionAccreditationFormValues {
  readonly accreditationOptionKey?: string | null;
  readonly hasConfirmedAccreditation: boolean | null;
}

export const UKInstitutionAccreditationForm = ({
  onSuccess,
  questionGroup,
}: InstitutionAccreditationFormProps) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();
  const [displaySecondDisclaimer, setDisplaySecondDisclaimer] = useState(false);
  const mutation = useAnswerInstitutionAccreditationQuestionsMutation();

  const accQuestions = questionGroup?.questions || [];
  const confirmationQuestion = accQuestions.find(
    (question: AccreditationQuestion) =>
      question.key === ConfirmationDisclaimerQuestionKey,
  );

  const validationSchema = Yup.object().shape({
    accreditationOptionKey: Yup.string()
      .oneOf(accQuestions.map((q: AccreditationQuestion) => q.key))
      .required(t`required`),
    hasConfirmedAccreditation: Yup.boolean()
      .oneOf([true, null], t`required`)
      .nullable()
      .when(`$displaySecondDisclaimer`, {
        is: true,
        then: (schema) => schema.required(t`required`),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const previousAnswers = actor.institution?.accreditationAnswers;
  const hasPreviousAnswers = !!previousAnswers && previousAnswers.length > 0;
  const initialValues = {
    accreditationOptionKey: hasPreviousAnswers
      ? previousAnswers[0].question.key
      : null,
    hasConfirmedAccreditation: null,
  };

  const mapVariables = ({
    accreditationOptionKey,
  }: InstitutionAccreditationFormValues) => {
    const data = {
      input: {
        answers: [{ key: accreditationOptionKey || `` }],
      },
    };

    return data;
  };

  const { handleSubmit, isLoading, control, formState, trigger, clearErrors } =
    useFormQL({
      mutation,
      mode: `onChange`,
      mapVariables,
      initialValues,
      validationSchema,
      yupContext: { displaySecondDisclaimer },
      onSuccess,
    });

  useEffect(() => {
    if (displaySecondDisclaimer) {
      trigger(`hasConfirmedAccreditation`).then(() => {
        clearErrors();
      });
    }
  }, [displaySecondDisclaimer]);

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      id="institution-accreditation-form"
    >
      <FormRadioTile.Group
        size="md"
        p={{ base: 4, lg: 6 }}
        borderRadius="md"
        bg="white"
        borderColor="grey.200"
        borderWidth={0.5}
        boxShadow={shadows.card}
        gap={4}
        w={{ base: `full`, lg: 160 }}
      >
        {!displaySecondDisclaimer ? (
          <VStack alignItems="flex-start" gap={4} py={2}>
            <Text textStyle="heading-lg">
              {t`uk_institution_accreditation_question_title`}
            </Text>
            <VStack alignItems="flex-start">
              {accQuestions
                .filter(
                  (q: AccreditationQuestion) =>
                    q.key !== ConfirmationDisclaimerQuestionKey,
                )
                .map((q: AccreditationQuestion) => (
                  <FormRadioTile.Tile
                    key={q.id}
                    name="accreditationOptionKey"
                    control={control}
                    value={q.key}
                    py={3}
                    alignItems="flex-start"
                  >
                    <VStack justifyContent="center" alignItems="flex-start">
                      <Text>{q.text}</Text>
                      {q.key === NotMiFIDQuestionKey && (
                        <VStack
                          as={UnorderedList}
                          gap={1}
                          pt={1}
                          alignItems="flex-start"
                        >
                          <Text
                            textStyle="text-sm"
                            as={ListItem}
                          >{t`uk_institution_not_mifid_description_bullet_1`}</Text>
                          <Text
                            textStyle="text-sm"
                            as={ListItem}
                          >{t`uk_institution_not_mifid_description_bullet_2`}</Text>
                          <Text
                            textStyle="text-sm"
                            as={ListItem}
                          >{t`uk_institution_not_mifid_description_bullet_3`}</Text>
                          <Text
                            textStyle="text-sm"
                            as={ListItem}
                          >{t`uk_institution_not_mifid_description_bullet_4`}</Text>
                        </VStack>
                      )}
                    </VStack>
                  </FormRadioTile.Tile>
                ))}
            </VStack>
          </VStack>
        ) : (
          <VStack alignItems="flex-start" gap={4}>
            <Text textStyle="heading-lg">{confirmationQuestion?.text}</Text>
            <TileCheckboxInput.Tooltip>
              <VStack as={UnorderedList} gap={1} alignItems="flex-start">
                <Text
                  textStyle="text-sm"
                  as={ListItem}
                >{t`uk_institution_confirmation_disclaimer_bullet_1`}</Text>
                <Text
                  textStyle="text-sm"
                  as={ListItem}
                >{t`uk_institution_confirmation_disclaimer_bullet_2`}</Text>
                <Text
                  textStyle="text-sm"
                  as={ListItem}
                >{t`uk_institution_confirmation_disclaimer_bullet_3`}</Text>
                <Text
                  textStyle="text-sm"
                  as={ListItem}
                >{t`uk_institution_confirmation_disclaimer_bullet_4`}</Text>
              </VStack>
            </TileCheckboxInput.Tooltip>
            <FormCheckboxInput
              name="hasConfirmedAccreditation"
              label={<Text fontWeight={400}>{t`i_agree`}</Text>}
              control={control}
            />
          </VStack>
        )}
      </FormRadioTile.Group>
      <HStack p={2} mt={6} justifyContent="flex-end" w="full">
        <HiiveButton
          observabilityLabel="[InstitutionAccreditationPageV2/Submit]"
          variant="rounded-solid-salmon"
          size="xl"
          maxW="unset"
          form="institution-accreditation-form"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading}
          onClick={
            displaySecondDisclaimer
              ? handleSubmit
              : () => setDisplaySecondDisclaimer(true)
          }
          isDisabled={!formState?.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </HStack>
    </form>
  );
};

export const UKInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={75} />
    <Skeleton h={75} />
    <Skeleton h={250} />
    <Skeleton h={75} />
    <VStack alignItems="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </VStack>
  </VStack>
);
