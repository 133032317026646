import en from "./en";
import account from "./en/account";
import bids from "./en/bids";
import company from "./en/company";
import dashboard from "./en/dashboard";
import discussions from "./en/discussions";
import error from "./en/error";
import execution from "./en/execution";
import listings from "./en/listings";
import onboarding from "./en/onboarding";
import postings from "./en/postings";
import standingBids from "./en/standing-bids";
import transactions from "./en/transactions";
import { LocaleKey, TranslationKey, Resources } from "./types";

export const locales: Record<LocaleKey, Resources> = {
  en: {
    account,
    dashboard,
    error,
    execution,
    onboarding,
    postings,
    translation: en,
    transactions,
    company,
    standingBids,
    listings,
    bids,
    discussions,
  },
};

export type { TranslationKey, Resources };
