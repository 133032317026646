import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { SensitiveText, Tile, TileHeader, TileRow } from "@/components/common";
import {
  ListingPageListingStatusTileListingFragment,
  ListingState,
} from "@/gql";
import { useCurrentActor, useConfig } from "@/hooks";
import { format, getHasExpired } from "@/utils/datetime";

import { getListingStatusText } from "./getListingStatusText";

interface ListingStatusTileProps {
  readonly listing: ListingPageListingStatusTileListingFragment;
}

function shouldShowExpiry({
  state,
  expireAt,
}: ListingPageListingStatusTileListingFragment) {
  const validListingState = [ListingState.Open, ListingState.Expired].includes(
    state,
  );

  const hasExpiry = !!expireAt;

  return validListingState && hasExpiry;
}

const ListingStatusTile = ({ listing }: ListingStatusTileProps) => {
  const { t } = useTranslation();

  const { expireAt } = listing;

  const actor = useCurrentActor();
  const { stnLoiHoursValidHours } = useConfig() ?? {};

  const statusText = getListingStatusText(listing, {
    actor,
    stnLoiHoursValidHours,
  });

  const showStatus = !!statusText;
  const showExpiry = shouldShowExpiry(listing);

  if (!showStatus && !showExpiry) {
    return null;
  }

  return (
    <Tile
      py={2}
      gap={4}
      display="grid"
      gridTemplateColumns={{ base: `1fr`, md: `auto 1fr` }}
      alignItems="baseline"
      data-testid="listing-status-tile"
    >
      {showExpiry && (
        <TileRow>
          <TileHeader>
            {getHasExpired(expireAt) ? t(`expired`) : t(`expires`)}
          </TileHeader>
          <SensitiveText>{format(`Do MMM (h:mm A z)`, expireAt)}</SensitiveText>
        </TileRow>
      )}

      {showStatus && (
        <TileRow>
          <TileHeader>{t(`status`)}</TileHeader>
          <Text>{statusText}</Text>
        </TileRow>
      )}
    </Tile>
  );
};

export default ListingStatusTile;
