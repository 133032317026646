import { Card, CardBody, VStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { TransactionState, useUserActivityMyActivityQuery } from "@/gql";
import { useTransactionDetails } from "@/hooks";

import ActiveTransactionItemCard from "./ActiveTransactionItemCard";
import ActiveTransactionsCardHeader from "./ActiveTransactionsCardHeader";

const IndividualActiveTransactionsCard = () => {
  const { data, loading } = useUserActivityMyActivityQuery({
    fetchPolicy: `network-only`,
    variables: {
      isInstitution: false,
    },
  });
  const { isBuyer, isNotTerminal } = useTransactionDetails();

  const activeTransactions =
    data?.myActivity.myTransactions?.filter(isNotTerminal);

  if (activeTransactions?.length === 0 && !loading) return null;

  return (
    <Card w="full">
      <ActiveTransactionsCardHeader
        transactionsCount={activeTransactions?.length}
      />
      <CardBody as={VStack} gap={4} maxH="xs" overflowY="auto">
        {loading && (
          <VStack gap={4} w="full">
            <Skeleton h={10} />
            <Skeleton h={10} />
            <Skeleton h={10} />
          </VStack>
        )}
        {!loading &&
          activeTransactions
            ?.filter((transaction) =>
              isBuyer(transaction)
                ? transaction.state !== TransactionState.ClosedFeePaid
                : true,
            )
            .map((transaction) => (
              <ActiveTransactionItemCard
                key={transaction.id}
                taskCount={transaction.myTasks.length}
                transaction={transaction}
              />
            ))}
      </CardBody>
    </Card>
  );
};

export default IndividualActiveTransactionsCard;
