import { useDispatch } from "react-redux";

import { Session } from "@/gql";
import { setAuthFromSession } from "@/state/auth";
import { buildSessionPayload } from "@/utils";

export function useHandleLogin() {
  const dispatch = useDispatch();
  const handleLogin = (session: Session) => {
    const sessionPayload = buildSessionPayload(session);
    dispatch(setAuthFromSession(sessionPayload));
  };

  return { handleLogin };
}
