import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FocusLock } from "@chakra-ui/focus-lock";
import { css, HStack, Text, VStack } from "@chakra-ui/react";

import { InternalLink, Logo, TabProps } from "@/components/common";
import {
  NotificationMenu,
  useHoldingSwitcher,
  NavMenuLink,
} from "@/components/nav";
import { useLockBodyScroll, useRedirectCompanyLink } from "@/hooks";

import { MobileHoldingMenu } from "./MobileHoldingMenu";
import { MobileNavBarWrapper } from "./MobileNavBarWrapper";
import { UnaccreditedSellerNavFooterV2 } from "./UnaccreditedSellerNavFooterV2";
import { UnaccreditedSellerNavMenuV2 } from "./UnaccreditedSellerNavMenuV2";
import { XButton } from "./XButton";
import { TabKeys } from "./tabs";

export const UnaccreditedSellerNavigationTab = ({
  tabRouter: { tabControls },
}: TabProps<TabKeys>) => {
  const redirectCompanyLink = useRedirectCompanyLink();
  const handleClick = () => tabControls.jumpToTab(`index`);
  const { onLock, onUnlock } = useLockBodyScroll();
  const { holdings, setHoldingCompany, holdingCompany } = useHoldingSwitcher();
  const { t } = useTranslation();

  useEffect(() => {
    onLock();
    return () => {
      onUnlock();
    };
  }, []);

  if (!holdingCompany) return null;

  return (
    <FocusLock autoFocus>
      <VStack w="100vw" h="100vh" bg="white" data-dd-privacy="mask">
        <VStack
          css={css`
            min-height: 100vh;
            min-height: -webkit-fill-available;
          `}
          w="100vw"
          position="absolute"
        >
          <MobileNavBarWrapper>
            <HStack spacing={0} gap={4}>
              <XButton onClick={() => tabControls.jumpToTab(`index`)} />
              <InternalLink href={`/dashboard/${holdingCompany.companyId}`}>
                <Logo.Grey />
              </InternalLink>
            </HStack>
            <HStack spacing={0} gap={4}>
              <NotificationMenu />
            </HStack>
          </MobileNavBarWrapper>
          <VStack
            w="full"
            spacing={0}
            justifyContent="space-between"
            alignItems="flex-start"
            flex="1"
            pb={5}
          >
            <VStack w="full">
              <MobileHoldingMenu
                holdings={holdings}
                setHoldingCompany={setHoldingCompany}
                holdingCompany={holdingCompany}
                onClick={handleClick}
              />
              <VStack w="full">
                <NavMenuLink
                  href={`/dashboard/${holdingCompany.companyId}`}
                  onClick={handleClick}
                  textStyle="heading-lg"
                >
                  {t(`dashboard`)}
                </NavMenuLink>
                <VStack spacing={0} alignItems="flex-start" w="full" pl={4}>
                  <UnaccreditedSellerNavMenuV2
                    onClick={handleClick}
                    id={holdingCompany?.companyId}
                  />
                </VStack>
                <NavMenuLink
                  href={redirectCompanyLink(holdingCompany.company)}
                  onClick={handleClick}
                  textStyle="heading-lg"
                >
                  <HStack spacing={3}>
                    <Text>
                      {holdingCompany.company.name} {t(`overview`)}
                    </Text>
                  </HStack>
                </NavMenuLink>
              </VStack>
            </VStack>
            <UnaccreditedSellerNavFooterV2
              onClick={handleClick}
              holding={holdingCompany}
            />
          </VStack>
        </VStack>
      </VStack>
    </FocusLock>
  );
};
