import { Box, BoxProps, Text } from "@chakra-ui/react";

type ActivityCardPropertyPillProps = {
  readonly title?: string;
  readonly value: string;
} & BoxProps;

const ActivityCardPropertyPill = ({
  title,
  value,
  ...restProps
}: ActivityCardPropertyPillProps) => (
  <Box
    borderWidth=".5px"
    borderColor="grey.100"
    borderRadius="md"
    px="2"
    py="1"
    w={{ base: 100, sm: `32` }}
    {...restProps}
  >
    {title && (
      <Text
        textStyle="text-xs"
        color="grey.700"
        textAlign="left"
        verticalAlign="center"
        lineHeight="1rem"
      >
        {title}
      </Text>
    )}
    <Text textStyle="heading-4xs" color="grey.700" textAlign="left">
      {value}
    </Text>
  </Box>
);

export default ActivityCardPropertyPill;
