import i18next from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ModalBody, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormPasswordInput } from "@/components/react-hook-form";
import { useUpdatePasswordMutation } from "@/gql";
import { useCustomToast, useModal, useSignOut } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { ToastDuration } from "@/types/toast";
import { ROUTES } from "@/utils";
import constants from "@/utils/constants";

const { PASSWORD_REGEX } = constants;

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object().shape({
    currentPassword: Yup.string()
      .nullable()
      .required(t(`current_password_is_required`)),
    password: Yup.string()
      .nullable()
      .required(t(`password_is_required`))
      .matches(PASSWORD_REGEX, t(`password_regex_error`)),
    passwordConfirmation: Yup.string()
      .nullable()
      .required(t(`password_confirmation_is_required`))
      .oneOf([Yup.ref(`password`)], t(`passwords_must_match`)),
  });
interface ChangePasswordFormValues {
  readonly currentPassword: string;
  readonly password: string;
  readonly passwordConfirmation: string;
}

const initialValues: ChangePasswordFormValues = {
  currentPassword: ``,
  password: ``,
  passwordConfirmation: ``,
};

const mapVariables = (values: ChangePasswordFormValues) => ({
  input: {
    ...values,
  },
});

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const { errorToast } = useCustomToast();
  const mutation = useUpdatePasswordMutation();
  const { closeModal } = useModal();
  const signOut = useSignOut();

  const validationSchema = getValidationSchema(t);

  const onSuccess = () =>
    signOut({ signOutTo: ROUTES.ACCOUNT_PASSWORD_UPDATE_CONFIRMED });

  const onError = () => {
    errorToast(t(`password_change_error_message`), {
      duration: ToastDuration.Long,
    });
  };

  const { handleSubmit, isLoading, control } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
    onError,
  });

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit}>
        <HiiveModalHeader>
          <Text>{t(`change_password`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <VStack spacing={6}>
            <SimpleGrid columns={1} rowGap={9} w="full">
              <FormPasswordInput
                isRequired
                name="currentPassword"
                label={t(`current_password`)}
                placeholder={t(`enter_current_password`)}
                control={control}
              />

              <VStack spacing={2}>
                <FormPasswordInput
                  isRequired
                  name="password"
                  label={t(`new_password`)}
                  placeholder={t(`enter_new_password_modal`)}
                  control={control}
                />

                <FormPasswordInput
                  label=""
                  name="passwordConfirmation"
                  placeholder={t(`confirm_new_password_modal`)}
                  control={control}
                />
              </VStack>
            </SimpleGrid>
          </VStack>
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveCancelButton
              onCancel={closeModal}
              observabilityLabel="[ChangePassword/Cancel]"
            />
            <HiiveSubmitButton
              isLoading={isLoading}
              observabilityLabel="[ChangePassword/Submit]"
              submitText={t(`update`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default ChangePasswordModal;
