import { Box, Fade, Text } from "@chakra-ui/react";

import { ActivityCardNewBadge, PostingCard } from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { InquiryPostingCardFragment } from "@/gql";

import { useDiscussionStatusText } from "./useDiscussionStatusText";

type InquiryUnaccreditedSellerPostingCardFooterProps = {
  discussion: InquiryPostingCardFragment;
};

export function InquiryUnaccreditedSellerPostingCardFooter({
  discussion,
}: InquiryUnaccreditedSellerPostingCardFooterProps) {
  const { state, totalNumMessages } = discussion;

  const { getIsNewInquiry, getInquiryNumNewMessages } =
    useUnaccreditedSellerInlineNotifications();

  const isNewInquiry = getIsNewInquiry(discussion);
  const numUnreadMessages = getInquiryNumNewMessages(discussion);

  const hasMessages = totalNumMessages > 0;

  const discussionStatusText = useDiscussionStatusText(state);

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm">{discussionStatusText}</Text>
      <Box flexGrow={1} />
      {isNewInquiry && (
        <Fade in>
          <ActivityCardNewBadge />
        </Fade>
      )}
      {hasMessages && (
        <MessagesBadge
          numUnreadMessages={numUnreadMessages}
          totalNumMessages={totalNumMessages}
        />
      )}
    </PostingCard.Footer>
  );
}
