import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { GridItem, ModalBody, Show, SimpleGrid } from "@chakra-ui/react";

import {
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveButton,
} from "@/components/common";
import {
  FormConditionalInput,
  FormDateInput,
  FormTextAreaInput,
  FormCheckboxInput,
} from "@/components/react-hook-form";
import {
  BrokerStandingBidOtherDetailsTooltip,
  RepresentedStandingBidExpiryTooltip,
  StandingBidOtherDetailsTooltip,
} from "@/components/tooltip";
import { useCurrentActor, useModal } from "@/hooks";
import { constants, getIsBroker } from "@/utils";
import * as datetime from "@/utils/datetime";

import HiiveAdvancedOptions from "./HiiveAdvancedOptions";
import { PlaceStandingBidModalProps } from "./PlaceStandingBidModal";

export const AdditionalDetails = ({
  stepRouter,
  formProps: { watch, control, handleSubmit, formState },
}: Omit<PlaceStandingBidModalProps, `initialCompany`>) => {
  const { stepControls } = stepRouter;
  const actor = useCurrentActor();
  const { t } = useTranslation(`standingBids`);

  const company = watch(`company`);

  const { closeModal } = useModal();
  const isBroker = getIsBroker(actor);
  const maxExpiryDate = datetime
    .add(`day`, constants.max_standing_bid_expire_after_days, dayjs())
    .toDate();

  const { formName, submitText, otherDetailsTooltip } = isBroker
    ? {
        formName: `SubmitStandingBid`,
        submitText: `${t`submit_standing_bid`}`,
        otherDetailsTooltip: <BrokerStandingBidOtherDetailsTooltip />,
      }
    : {
        formName: `PlaceStandingBid`,
        submitText: `${t`place_standing_bid`}`,
        otherDetailsTooltip: <StandingBidOtherDetailsTooltip />,
      };

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          <GridItem colSpan={2}>
            <FormDateInput
              name="expireAt"
              control={control}
              label={t`expiration_date`}
              maxDate={maxExpiryDate}
              tooltipContent={<RepresentedStandingBidExpiryTooltip />}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <FormTextAreaInput
              control={control}
              name="otherDetails"
              label={t`additional_standing_bid_notes`}
              tooltipContent={otherDetailsTooltip}
            />
          </GridItem>
          {isBroker && (
            <>
              <GridItem colSpan={2}>
                <FormConditionalInput
                  name="affiliate"
                  label={t(`broker_submit_bid_affiliate_question`, {
                    companyName: company?.name,
                  })}
                  control={control}
                  gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr 1fr` }}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FormConditionalInput
                  name="solicited"
                  label={t`broker_submit_bid_unsolicited_question`}
                  control={control}
                  gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr 1fr` }}
                />
              </GridItem>
            </>
          )}
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptions
                control={control}
                required={actor.isHiiveUser}
              />
            </GridItem>
          )}
          {!isBroker && (
            <GridItem colSpan={2}>
              <FormCheckboxInput
                name="confirmed"
                label={t(`place_standing_bid_confirm_intent_prompt`)}
                control={control}
                helperText={t(`place_standing_bid_confirm_intent_description`)}
              />
            </GridItem>
          )}
        </SimpleGrid>
      </ModalBody>
      {isBroker && (
        <ModalBody>
          <GridItem colSpan={2}>
            <FormCheckboxInput
              name="confirmed"
              label={t(`broker_submit_bid_acknowledgement`)}
              control={control}
            />
          </GridItem>
        </ModalBody>
      )}
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel={`[${formName}/AdditionalDetails/Cancel]`}
            onCancel={closeModal}
          />
        </Show>
        <HiiveBackButton
          observabilityLabel={`[${formName}/AdditionalDetails/Back]`}
          onClick={stepControls.previousStep}
        />
        <HiiveButton
          variant="rounded-solid-salmon"
          observabilityLabel={`[${formName}/AdditionalDetails/Submit]`}
          size="xl"
          isLoading={formState.isSubmitting || formState.isLoading}
          onClick={handleSubmit}
          form="place-standing-bid-modal-form"
        >
          {submitText}
        </HiiveButton>
      </HiiveModalFooter>
    </>
  );
};
