import { ReactElement } from "react";

import { Box, Text } from "@chakra-ui/react";

import { HiiveButton } from "./HiiveButton";

const HiiveTab = ({
  isActive,
  onClick,
  children,
}: {
  readonly isActive: boolean;
  readonly onClick: () => void;
  readonly children: ReactElement | readonly ReactElement[] | string;
}) => (
  <HiiveButton
    _hover={{ color: `grey.900` }}
    _active={{ color: `grey.900` }}
    _focus={{ outline: `none` }}
    color={isActive ? `grey.900` : `grey.500`}
    backgroundColor="transparent"
    rounded="none"
    size="sm"
    fontWeight="normal"
    onClick={onClick}
    paddingLeft={0}
    observabilityLabel="[DashboardLatestMarketActivity] Tab"
  >
    <Box
      borderBottomColor={isActive ? `salmon.900` : `transparent`}
      borderBottomWidth="3px"
      paddingX="3px"
      paddingBottom="8px"
    >
      <Text textTransform="capitalize" textStyle="heading-md">
        {children}
      </Text>
    </Box>
  </HiiveButton>
);

export default HiiveTab;
