import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardFooter,
  GridItem,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ButtonFooterWrapper, HiiveButton } from "@/components/common";
import {
  MarketActivityBlock,
  PricingBlock,
  PricingChartWrapper,
} from "@/components/companies";
import { LastRoundDetailsCard } from "@/components/dashboard";
import { DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragment } from "@/gql";
import { makeUrl } from "@/utils";

const CompanyOverviewCard = ({
  company,
}: {
  readonly company: DefaultUnaccreditedSellerDashboardPageCompanyOverviewCardCompanyFragment;
}) => {
  const router = useRouter();

  const hasPriceGraph = company.priceChartConfig.displayChart;

  return (
    <Card>
      <CardBody>
        <SimpleGrid columns={12} gap={4}>
          <GridItem colSpan={{ base: 12, xl: 5 }} order={1}>
            <VStack
              alignItems="flex-start"
              justifyContent="space-between"
              h="full"
              spacing={5}
            >
              <HStack spacing={4}>
                {!!company.logoUrl && <Image h="12" src={company.logoUrl} />}
                <Text textStyle="heading-4xl">{company.name}</Text>
              </HStack>
              <VStack alignItems="flex-start" spacing={5}>
                <Text textStyle="heading-sm">Market Activity</Text>
                <MarketActivityBlock company={company} />
              </VStack>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 7 }} order={2}>
            <PricingBlock company={company} />
          </GridItem>
          <GridItem
            order={{ base: 4, xl: 3 }}
            colStart={{ base: 0, xl: hasPriceGraph ? 0 : 6 }}
            colSpan={{ base: 12, xl: hasPriceGraph ? 5 : 7 }}
          >
            <LastRoundDetailsCard company={company} />
          </GridItem>
          {hasPriceGraph && (
            <GridItem order={{ base: 3, xl: 4 }} colSpan={{ base: 12, xl: 7 }}>
              <PricingChartWrapper company={company} />
            </GridItem>
          )}
        </SimpleGrid>
      </CardBody>
      <CardFooter bg="grey.50">
        <ButtonFooterWrapper>
          <HiiveButton
            variant="rounded-solid-salmon"
            size="xl"
            onClick={() => router.push(makeUrl(company))}
            observabilityLabel="[CompanyOverviewCard] Learn More"
          >
            Learn More
          </HiiveButton>
        </ButtonFooterWrapper>
      </CardFooter>
    </Card>
  );
};

export default CompanyOverviewCard;
