import { Trans } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";
import {
  findHighestAcceptedBidSharePrice,
  findLowestAcceptedBidSharePrice,
  hasMultipleAcceptedBidSharePrices,
  hasSingleAcceptedBidSharePrice,
} from "@/components/companies";
import { useCardUpdates } from "@/hooks/featureFlags";
import { formatPricePerShare, formatShares } from "@/utils";

const AcceptedBidSharePrices = ({
  acceptedBidPrices,
  numSharesSold,
}: {
  readonly acceptedBidPrices: readonly number[];
  readonly numSharesSold: number;
}) => {
  const cardUpdatesEnabled = useCardUpdates();

  switch (true) {
    case hasSingleAcceptedBidSharePrice(acceptedBidPrices):
      return (
        <SensitiveText textStyle="text-xs" color="grey.700">
          {cardUpdatesEnabled ? (
            <Trans
              ns="transactions"
              i18nKey="shares_sold_at_v2"
              values={{
                numShares: formatShares(numSharesSold),
                pps: formatPricePerShare(acceptedBidPrices[0]),
              }}
              components={{
                b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
              }}
            />
          ) : (
            <Trans
              ns="transactions"
              i18nKey="shares_sold_at"
              values={{
                numShares: formatShares(numSharesSold),
                pps: formatPricePerShare(acceptedBidPrices[0]),
              }}
              components={{
                b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
              }}
            />
          )}
        </SensitiveText>
      );
    case hasMultipleAcceptedBidSharePrices(acceptedBidPrices):
      return (
        <SensitiveText textStyle="text-xs" color="grey.700">
          {cardUpdatesEnabled ? (
            <Trans
              ns="transactions"
              i18nKey="shares_sold_from_v2"
              values={{
                numShares: formatShares(numSharesSold),
                minPps: formatPricePerShare(
                  findLowestAcceptedBidSharePrice(acceptedBidPrices),
                ),
                maxPps: formatPricePerShare(
                  findHighestAcceptedBidSharePrice(acceptedBidPrices),
                ),
              }}
              components={{
                b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
              }}
            />
          ) : (
            <Trans
              ns="transactions"
              i18nKey="shares_sold_from"
              values={{
                numShares: formatShares(numSharesSold),
                minPps: formatPricePerShare(
                  findLowestAcceptedBidSharePrice(acceptedBidPrices),
                ),
                maxPps: formatPricePerShare(
                  findHighestAcceptedBidSharePrice(acceptedBidPrices),
                ),
              }}
              components={{
                b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
              }}
            />
          )}
        </SensitiveText>
      );
    default:
      return null;
  }
};

export default AcceptedBidSharePrices;
