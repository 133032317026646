import { PostingCard } from "@/components/common";
import { InquiryEntityPostingCardFragment } from "@/gql";

import { InquiryEntityPostingCardFooter } from "./InquiryEntityPostingCardFooter";
import { InquiryEntityPostingCardHeader } from "./InquiryEntityPostingCardHeader";
import { InquiryPostingCardBody } from "./InquiryPostingCardBody";

type InquiryEntityPostingCardProps = {
  discussion: InquiryEntityPostingCardFragment;
};

function InquiryEntityPostingCardContent({
  discussion,
}: InquiryEntityPostingCardProps) {
  const { id } = discussion;
  const url = `/discussions/${id}`;

  return (
    <PostingCard href={url}>
      <InquiryEntityPostingCardHeader discussion={discussion} />
      <InquiryPostingCardBody discussion={discussion} />
      <InquiryEntityPostingCardFooter discussion={discussion} />
    </PostingCard>
  );
}

export function InquiryEntityPostingCard({
  discussion,
}: InquiryEntityPostingCardProps) {
  return <InquiryEntityPostingCardContent discussion={discussion} />;
}
