import { useField } from "formik";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Divider,
  Flex,
  GridItem,
  Link,
  SimpleGrid,
  Text,
  FormLabel,
  ModalBody,
  Show,
} from "@chakra-ui/react";

import {
  CompanyEligibilityCriteriaAlert,
  CompanyEligibilityCriteriaCheckbox,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import {
  FixedValueInput,
  FormListbox,
  FormNumberInput,
  StepPropsV2,
} from "@/components/form";
import { StandingBidFeeBreakdown } from "@/components/postings";
import {
  ShareSeries,
  UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
} from "@/gql";
import { useModal, useStepValidator } from "@/hooks";
import { formatCurrency, formatShares, getShareSeriesText } from "@/utils";

import { UnaccreditedSellerAcceptStandingBidSequenceModalContext } from "./UnaccreditedSellerAcceptStandingBidSequenceModalContext";
import { StepKeys, stepKeys } from "./steps";
import { UnaccreditedSellerAcceptStandingBidSequenceModalFormValues } from "./types";

interface UnaccreditedSellerAcceptStandingBidModalProps
  extends StepPropsV2<
    StepKeys,
    UnaccreditedSellerAcceptStandingBidSequenceModalFormValues
  > {
  readonly standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}

const useValidationSchema = (
  standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment,
  showConfirmEligibilityRequirementsCheckbox: boolean,
) => {
  const { t } = useTranslation();
  const requiredError = t(`required`);
  const minShares = standingBid.minPartialAcceptNumShares || 1;
  const maxShares = standingBid.numSharesAvailable || 1;
  const numSharesSchema = Yup.number()
    .nullable()
    .min(minShares, `Must be at least ${formatShares(minShares)}`)
    .max(maxShares, `Cannot be more than ${formatShares(maxShares)}`)
    .required(`Required`);

  return Yup.object().shape({
    ...(standingBid.allowPartialAccept && { numShares: numSharesSchema }),
    shareSeries: Yup.string().nullable().required(requiredError),
    confirmedEligibilityRequirements: Yup.boolean().when([], {
      is: () => showConfirmEligibilityRequirementsCheckbox,
      then: Yup.boolean().oneOf([true], requiredError),
    }),
  });
};

const filterShareSeriesOptions = (
  shareSeriesOptions: readonly ShareSeries[],
) => {
  const allowedShareSeries = [
    ShareSeries.Common,
    ShareSeries.Options,
    ShareSeries.RestrictedStockUnits,
  ];

  return shareSeriesOptions.filter((shareSeries) =>
    allowedShareSeries.includes(shareSeries),
  );
};

const SharesInput = ({
  standingBid,
}: {
  standingBid: UnaccreditedSellerAcceptStandingBidSequenceModalStandingBidFragment;
}) => {
  const { t } = useTranslation();
  const [_field, { error }, _] = useField(`numShares`);

  const { numSharesAvailable, allowPartialAccept, minPartialAcceptNumShares } =
    standingBid;

  if (allowPartialAccept)
    return (
      <>
        <FormNumberInput
          name="numShares"
          label={t(`number_of_shares_selling`)}
        />
        {!error && (
          <Text textStyle="text-sm" mt={1}>
            Enter between {formatShares(minPartialAcceptNumShares)} and
            {` `}
            {formatShares(numSharesAvailable)}
          </Text>
        )}
      </>
    );

  return <FormNumberInput name="numShares" label={t(`number_of_shares`)} />;
};

const UnaccreditedSellerAcceptStandingBidModal = ({
  values,
  stepRouter: { stepControls },
  standingBid,
}: UnaccreditedSellerAcceptStandingBidModalProps) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const { numSharesAvailable, allowPartialAccept, company } = standingBid;

  const { text: eligibilityCriterionText } =
    company.companyEligibilityCriterion || {};

  const lockedShares =
    !allowPartialAccept && !isNil(numSharesAvailable)
      ? formatShares(numSharesAvailable)
      : null;

  const showConfirmEligibilityRequirementsCheckbox =
    eligibilityCriterionText && values.shareSeries === ShareSeries.Common;

  const validationSchema = useValidationSchema(
    standingBid,
    !!showConfirmEligibilityRequirementsCheckbox,
  );

  const onSuccess = () => stepControls.nextStep();

  useStepValidator({
    Context: UnaccreditedSellerAcceptStandingBidSequenceModalContext,
    stepKey: stepKeys.acceptStandingBid,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  return (
    <>
      <HiiveModalHeader>
        Accept Standing Bid{` `}
        <Text as="span">{standingBid.displayId}</Text>
      </HiiveModalHeader>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          <GridItem colSpan={2}>
            <Text mb={2}>{t(`accept_standing_bid_description`)}</Text>
            <Text>
              {t(`at_sale_close_disclaimer`)}
              {` `}
              <Link
                href="/terms-and-conditions"
                textDecor="underline"
                target="_blank"
              >
                {t(`terms_and_conditions`)}
              </Link>
              .
            </Text>
            <Divider mt={4} />
            {eligibilityCriterionText && (
              <CompanyEligibilityCriteriaAlert
                markdown={eligibilityCriterionText}
                companyName={company.name}
              />
            )}
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            {lockedShares ? (
              <FixedValueInput
                label="Number of shares"
                fixedValue={lockedShares}
              />
            ) : (
              <SharesInput standingBid={standingBid} />
            )}
          </GridItem>

          <GridItem colSpan={{ base: 2, md: 1 }}>
            <Flex justify="space-between">
              <FormLabel>Price per share</FormLabel>
              <Link
                href="/terms-and-conditions#fees-and-commissions"
                target="_blank"
                fontSize="sm"
                color="h-salmon-pink"
              >
                Fees
              </Link>
            </Flex>
            <Flex alignItems="center" h={10}>
              <Text>{formatCurrency(values.pricePerShare)}</Text>
            </Flex>
          </GridItem>

          <GridItem colSpan={2}>
            <FormListbox
              name="shareSeries"
              label={t(`share_series`)}
              itemToString={(item) => getShareSeriesText(item)}
              getItemKey={(item) => item}
              items={filterShareSeriesOptions(standingBid.shareSeriesOptions)}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <StandingBidFeeBreakdown
              companyId={standingBid.company.id}
              numShares={values.numShares}
              pricePerShare={values.pricePerShare}
            />
          </GridItem>
          {showConfirmEligibilityRequirementsCheckbox && (
            <GridItem colSpan={2}>
              <CompanyEligibilityCriteriaCheckbox
                name="confirmedEligibilityRequirements"
                label={t(`agreed_to_eligibility_requirements`, {
                  companyName: company?.name,
                })}
                eligibilityCriterionText={eligibilityCriterionText}
              />
            </GridItem>
          )}
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[UnaccreditedSellerAcceptStandingBid/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveSubmitButton
          observabilityLabel="[UnaccreditedSellerAcceptStandingBid/Submit]"
          type="submit"
          submitText="Accept Standing Bid"
        />
      </HiiveModalFooter>
    </>
  );
};

export default UnaccreditedSellerAcceptStandingBidModal;
