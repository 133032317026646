import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivitySection,
  CTACard,
  MailtoLink,
  MessageCounterpartyCard,
  Skeleton,
  WithQuery,
  InquiryPostingCard,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  ListingPageBidCard,
  ListingPageInquiryCard,
} from "@/components/postings";
import {
  BidState,
  ListingPageBuyerActivityBidFragment,
  ListingPageBuyerActivityListingCompanyFragment,
  ListingPageBuyerActivityListingFragment,
  ListingPermission,
  MessageTopicType,
  MyDiscussionListDiscussionFragment,
  TransferMethod,
  useListingPageBuyerActivityQuery,
  useMyDiscussionListDiscussionsQuery,
  UserPermissionV2,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  iHaveEntityPermission,
  useCurrentActor,
  useIHavePermission,
} from "@/hooks";
import { useCardUpdates } from "@/hooks/featureFlags";
import {
  constants,
  getIsInstitutionUser,
  getSecuritySpecialistContact,
} from "@/utils";

import { BuyerActivitySkeleton } from "./BuyerActivitySkeleton";
import { PendingPurchaseCard } from "./PendingPurchaseCard";
import { MockPlaceBidCard, PlaceBidCard } from "./PlaceBidCard";

const OpenBids = ({
  bids,
}: {
  readonly bids: readonly ListingPageBuyerActivityBidFragment[];
}) => (
  <ActivityGroup title="Your Bid on this Listing">
    {bids.map((bid) => (
      <ListingPageBidCard key={bid.id} bid={bid} />
    ))}
  </ActivityGroup>
);

const PastBids = ({
  bids,
}: {
  readonly bids: readonly ListingPageBuyerActivityBidFragment[];
}) => (
  <ActivityGroup title="Your Past Bids on this Listing">
    {bids.map((bid) => (
      <ListingPageBidCard key={bid.id} bid={bid} />
    ))}
  </ActivityGroup>
);

const Discussions = ({
  discussions,
}: {
  readonly discussions: readonly MyDiscussionListDiscussionFragment[];
}) => {
  const cardUpdatesEnabled = useCardUpdates();
  const { t } = useTranslation(`listings`);

  return (
    <ActivityGroup title={t(`your_inquiry`)}>
      {discussions.map((discussion) =>
        cardUpdatesEnabled ? (
          <InquiryPostingCard key={discussion.id} discussion={discussion} />
        ) : (
          <ListingPageInquiryCard key={discussion.id} discussion={discussion} />
        ),
      )}
    </ActivityGroup>
  );
};

const PendingPurchasesSkeleton = () => (
  <VStack spacing={4} w="full" alignItems="flex-start">
    <Skeleton h="4" w="48" />
    <ActivityCardSkeleton includeBody />
  </VStack>
);

const PendingPurchases = withCurrentActor(
  ({
    actor,
    listing,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly listing: ListingPageBuyerActivityListingFragment;
  }) => {
    const { t } = useTranslation();

    const query = useListingPageBuyerActivityQuery({
      variables: { listingId: listing.id },
    });

    const isInstitutionUser = getIsInstitutionUser(actor);

    const title = isInstitutionUser
      ? t(`pending_purchases_on_behalf_of`, {
          institutionName: actor.institution?.legalName,
        })
      : t(`pending_purchases_on_listing`);

    return (
      <WithQuery query={query} fallback={<PendingPurchasesSkeleton />}>
        {({
          data: {
            buyerListingActivity: { pendingPurchases },
          },
        }) => (
          <ActivityGroup title={title}>
            {pendingPurchases.map((pendingPurchase) => (
              <PendingPurchaseCard
                key={pendingPurchase.id}
                pendingPurchase={pendingPurchase}
              />
            ))}
          </ActivityGroup>
        )}
      </WithQuery>
    );
  },
);

const BuyerNeedsCompanyApprovalCard = ({
  company,
}: {
  readonly company: ListingPageBuyerActivityListingCompanyFragment;
}) => {
  const { t } = useTranslation();

  const securitySpecialistContact = getSecuritySpecialistContact(company);

  return (
    <CTACard
      direction="column"
      heading={t(`make_bid`)}
      description={
        <Trans
          i18nKey="must_be_approved_by_company"
          t={t}
          components={[
            <MailtoLink
              key="contact_for_buyer_approval"
              fontWeight={700}
              email={securitySpecialistContact}
              subject={`Buy Approval on ${company.name}`}
            />,
          ]}
          values={{ companyName: company.name }}
        />
      }
      action={{ disabled: true }}
    />
  );
};

const MessageSellerCard = ({
  listing,
}: {
  readonly listing: ListingPageBuyerActivityListingFragment;
}) => <MessageCounterpartyCard topic={listing} />;

const OtherTransferMethodDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <Card w="full">
      <CardBody h="full">
        <VStack alignItems="flex-start">
          <Text
            textTransform="uppercase"
            textStyle="heading-sm"
          >{t`place_a_bid`}</Text>
          <Text>
            <Trans
              i18nKey="other_transfer_method_bid_not_allowed"
              values={{ supportEmail: constants.email_support }}
              components={{
                mailto: (
                  <MailtoLink
                    fontWeight={500}
                    email={constants.email_support}
                  />
                ),
              }}
            />
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

const BuyerActivity = ({
  listing,
}: {
  readonly listing: ListingPageBuyerActivityListingFragment;
}) => {
  const actor = useCurrentActor();

  const openBids = listing.bids.filter(
    ({ state }) => state === BidState.Active || state === BidState.Countered,
  );
  const pastBids = listing.bids.filter(
    ({ state }) => state === BidState.Expired || state === BidState.Withdrawn,
  );

  const canPlaceBid = iHaveEntityPermission(
    listing,
    ListingPermission.PlaceBid,
  );

  const isUserOtherEligible = iHaveEntityPermission(
    actor,
    UserPermissionV2.BidOther,
  );

  const canMessageSeller = iHaveEntityPermission(
    listing,
    ListingPermission.StartDiscussion,
  );

  const actorHasPlaceBidPermission = useIHavePermission(
    UserPermissionV2.PlaceBid,
  );

  const query = useMyDiscussionListDiscussionsQuery({
    variables: {
      topicFilter: { id: listing.id, type: MessageTopicType.Listing },
    },
  });

  const showBuyerNeedsCompanyApprovalCard =
    listing.company.requiresApprovedBuyers &&
    !canPlaceBid &&
    actorHasPlaceBidPermission;

  const canActorPlaceBid = () => {
    if (listing.transferMethod === TransferMethod.Other)
      return isUserOtherEligible && actorHasPlaceBidPermission && canPlaceBid;

    return actorHasPlaceBidPermission && canPlaceBid;
  };

  const shouldRenderOtherTransferMethodDisclaimer =
    listing.transferMethod === TransferMethod.Other && !isUserOtherEligible;

  return (
    <WithQuery query={query} fallback={<BuyerActivitySkeleton />}>
      {({ data: { myDiscussions } }) => {
        const discussions = myDiscussions.filter(
          (discussion) =>
            !listing.bids.some((bid) => bid.discussion?.id === discussion.id),
        );

        const showPlaceBidCard = canActorPlaceBid();
        const showMockPlaceBidCard =
          !actorHasPlaceBidPermission && !canPlaceBid;
        const showMessageSellerCard = canMessageSeller;
        const showCTAs =
          showPlaceBidCard ||
          showMockPlaceBidCard ||
          showMessageSellerCard ||
          showBuyerNeedsCompanyApprovalCard;

        return (
          <VStack alignItems="flex-start" spacing={8}>
            {showCTAs && !shouldRenderOtherTransferMethodDisclaimer && (
              <VStack spacing={4} w="full">
                {showBuyerNeedsCompanyApprovalCard && (
                  <BuyerNeedsCompanyApprovalCard company={listing.company} />
                )}
                {showPlaceBidCard && <PlaceBidCard listing={listing} />}
                {showMockPlaceBidCard && <MockPlaceBidCard />}
                {showMessageSellerCard && (
                  <MessageSellerCard listing={listing} />
                )}
              </VStack>
            )}
            {shouldRenderOtherTransferMethodDisclaimer && (
              <OtherTransferMethodDisclaimer />
            )}
            <ActivitySection>
              <PendingPurchases listing={listing} />
              <OpenBids bids={openBids} />
              <PastBids bids={pastBids} />
              <Discussions discussions={discussions} />
            </ActivitySection>
          </VStack>
        );
      }}
    </WithQuery>
  );
};

export default BuyerActivity;
