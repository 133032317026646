import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Card,
  CardBody,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";
import { useCurrentActor } from "@/hooks";

import { TraderIVRequiredPage } from "./TraderIVRequiredPage";

const LinkList = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <UnorderedList>
      <ListItem>
        <Link
          href="https://www.hiive.com/form-crs"
          textDecoration="underline"
          target="_blank"
        >
          {t(`form_crs_for_hiive_markets_limited`)}
        </Link>
      </ListItem>
      <ListItem>
        <Link
          href="https://www.hiive.com/reg-bi"
          textDecoration="underline"
          target="_blank"
        >
          {t(`regulation_best_interest_disclosure`)}
        </Link>
      </ListItem>
      <ListItem>
        <Link
          href="https://www.hiive.com/disclosures"
          textDecoration="underline"
          target="_blank"
        >
          {t(`risk_disclosures_and_disclaimers`)}
        </Link>
      </ListItem>
    </UnorderedList>
  );
};

const TraderAwaitingApprovalPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <OnboardingContainerV2 canGoBack={false} metaTitle={t(`welcome_to_hiive`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`thank_you_for_your_interest`)}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={7}>
                <Text>{t(`we_are_excited_to_welcome_you`)}</Text>

                <Text>
                  <Trans
                    i18nKey="awaiting_approval.if_you_have_any_questions"
                    ns="onboarding"
                    components={{
                      mailto: (
                        <Link
                          fontWeight="medium"
                          textDecoration="underline"
                          href="mailto:support@hiive.com"
                        />
                      ),
                    }}
                  />
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};

const UnsuitableTraderAwaitingApprovalPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  return (
    <OnboardingContainerV2
      canGoBack={false}
      metaTitle={t(`verify_your_identity`)}
    >
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t`welcome_to_hiive`}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={7}>
                <Text>
                  {t`thank_you_for_your_interest_in_hiive_unsuitable`}
                </Text>
                <Text>{t`before_we_do_that`}</Text>
                <Flex direction="column" gap={3}>
                  <Text>{t`ahead_of_that_call_we_encourage_you`}</Text>
                  <LinkList />

                  <Text>{t`we_have_sent_all_of_the_above_info`}</Text>
                  <Text>
                    <Trans
                      i18nKey="awaiting_approval.if_you_have_any_questions_in_the_meantime"
                      ns="onboarding"
                      components={{
                        mailto: (
                          <Link
                            fontWeight="medium"
                            textDecoration="underline"
                            href="mailto:support@hiive.com"
                          />
                        ),
                      }}
                    />
                  </Text>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};

export const USACanadaTraderAwaitingApprovalPage = () => {
  const actor = useCurrentActor();

  return match({
    isSuitable: actor.suitableInvestor,
    isAccredited: actor.accredited,
    isIVRequired: !actor.identityVerified,
  })
    .with({ isAccredited: false }, () => <TraderAwaitingApprovalPage />)
    .with({ isSuitable: false }, () => <UnsuitableTraderAwaitingApprovalPage />)
    .with({ isIVRequired: true }, () => <TraderIVRequiredPage />)
    .otherwise(() => <TraderAwaitingApprovalPage />);
};
