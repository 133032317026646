import { useTranslation } from "react-i18next";

import { CardFooter, Flex, Stack, Spacer, Text } from "@chakra-ui/react";

import {
  calculateTotalOpenBids,
  ExpiryDate,
  ConditionallySoldOutBadge,
  PartiallySoldBadge,
  OpenBidsBadge,
  InquiriesBadge,
} from "@/components/companies";
import {
  CompanyYourActivityListingCardListingFragment,
  ListingState,
} from "@/gql";
import { toTimestamp } from "@/utils";
import { countTotalUnreadBidDiscussionMessages } from "@/utils/bid";

const InReviewState = ({
  listing,
}: {
  readonly listing: CompanyYourActivityListingCardListingFragment;
}) => {
  const { t } = useTranslation();

  return (
    <Text color="grey.700" textStyle="text-sm">
      {t(`standing_bid_in_review_status`, {
        date: toTimestamp(listing.insertedAt),
      })}
    </Text>
  );
};

const ExpireAtState = ({
  listing,
}: {
  readonly listing: CompanyYourActivityListingCardListingFragment;
}) =>
  !!listing.expireAt ? <ExpiryDate expireAt={listing.expireAt} /> : <Spacer />;

const CompanyListingCardFooter = ({
  listing,
}: {
  readonly listing: CompanyYourActivityListingCardListingFragment;
}) => {
  const {
    acceptedBidPrices,
    bids,
    expireAt,
    transferMethod,
    state,
    numActiveBids,
    numCounteredBids,
    numInquiries,
    numUnreadMessages,
  } = listing;

  const hasExpirationDate = !!expireAt;

  const isConditionallySold = state === ListingState.ConditionallySold;

  const isPartiallySold =
    state === ListingState.Open && acceptedBidPrices.length >= 1;

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const hasOpenBids = totalOpenBids >= 1;

  const totalUnreadBidDiscussionMessages =
    countTotalUnreadBidDiscussionMessages(bids);

  const numUnreadMessagesAsideFromBids =
    numUnreadMessages - totalUnreadBidDiscussionMessages;

  const hasInquiries = numInquiries > 0;

  const isInReview = state === ListingState.InReview;

  const hasFooter =
    hasExpirationDate ||
    isPartiallySold ||
    isConditionallySold ||
    hasOpenBids ||
    hasInquiries ||
    isInReview;

  if (!hasFooter) return null;

  return (
    <CardFooter>
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        py={1}
        w="full"
      >
        {isInReview ? (
          <InReviewState listing={listing} />
        ) : (
          <ExpireAtState listing={listing} />
        )}
        <Stack
          direction={{ base: `column`, md: `row` }}
          wrap="wrap"
          align="flex-end"
          spacing={{ base: 2, md: 3 }}
        >
          {isConditionallySold && (
            <ConditionallySoldOutBadge transferMethod={transferMethod} />
          )}
          {isPartiallySold && (
            <PartiallySoldBadge transferMethod={transferMethod} />
          )}
          {hasInquiries && (
            <InquiriesBadge
              numInquiries={numInquiries}
              numUnreadMessages={numUnreadMessagesAsideFromBids}
            />
          )}
          {hasOpenBids && (
            <OpenBidsBadge
              totalOpenBids={totalOpenBids}
              bids={bids}
              transferMethod={transferMethod}
            />
          )}
        </Stack>
      </Flex>
    </CardFooter>
  );
};

export default CompanyListingCardFooter;
