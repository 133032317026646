import { ChartAnnouncement } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import { useSlugsAsCompanyUrl } from "@/hooks/featureFlags";
import { getIsUnaccreditedSeller } from "@/utils";

import { CompanyPageContent } from "./CompanyPageContent";
import { CompanyPageContent as CompanyPageContentV2 } from "./CompanyPageV2Content";

interface CompanyPageProps {
  readonly slugOrId: string;
  readonly actor: UserWithInstitutionFragment;
}

const CompanyPage = withCurrentActor(
  ({ slugOrId, actor }: CompanyPageProps) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const slugAsCompanyUrlEnabled = useSlugsAsCompanyUrl();

    return (
      <>
        {!isUnaccreditedSeller && <ChartAnnouncement />}
        {slugAsCompanyUrlEnabled ? (
          <CompanyPageContentV2 slugOrId={slugOrId} />
        ) : (
          // The original code path was meant to process companyIds instead of nameSlugs
          // since the file was renamed here, this name is misleading.
          <CompanyPageContent companyId={slugOrId} />
        )}
      </>
    );
  },
);

export default CompanyPage;
