/* eslint-disable functional/immutable-data */
import { onError } from "@apollo/client/link/error";
import { datadogRum } from "@datadog/browser-rum";
import { GraphQLError } from "graphql";

import toastr, { NotificationDuration } from "@/libs/toastr";

interface GqlError extends GraphQLError {
  readonly code: string;
  readonly metadata: readonly string[];
}

const notFoundCodes = [`NOT_FOUND`, `UNAUTHORIZED`, `EXPIRED`, `FORBIDDEN`];
const somethingWentWrongCodes = [`INVALID_STATE`, `INTERNAL_SERVER_ERROR`];
const invitationExpiredCode = `INVITATION_EXPIRED`;

const filteredErrorCodes = [
  `UNAUTHORIZED`,
  `FORBIDDEN`,
  `INVALID_STATE`,
  `INTERNAL_SERVER_ERROR`,
];

const captureErrors = async (gqlErrors: readonly GqlError[]) => {
  gqlErrors
    .filter((error) => filteredErrorCodes.includes(error.code))
    .forEach((error) => {
      datadogRum.addError(error);
    });
};

const maybeRedirect = (gqlErrors: readonly GqlError[]) => {
  const apiUrl = localStorage.getItem(`api`);
  switch (true) {
    case gqlErrors.some((err) => err.code === `UNAUTHENTICATED`):
      window.localStorage.clear();

      if (apiUrl) localStorage.setItem(`api`, apiUrl);

      window.sessionStorage.clear();
      return;
    case gqlErrors.some((err) => err.code === invitationExpiredCode):
      window.location.href = `/invitation-expired`;
      return;
    case gqlErrors.some((err) => notFoundCodes.includes(err.code)):
      window.location.href = `/page-not-found`;
      return;
    case gqlErrors.some((err) => somethingWentWrongCodes.includes(err.code)):
      window.location.href = `/something-went-wrong`;
      return;
    case gqlErrors.some((err) => err.code === `INPUT_ERROR`):
      gqlErrors.forEach((err) => {
        toastr().error(
          err.message,
          err.metadata[0],
          NotificationDuration.NEVER,
        );
      });
      return;
    default:
      throw new Error(
        `Unhandled GraphQl Error Code: ${JSON.stringify(gqlErrors)}`,
      );
  }
};

const errorLink = onError((params) => {
  const gqlErrors = params.graphQLErrors as readonly GqlError[];
  const { networkError } = params;

  if (
    window.location.pathname === `/something-went-wrong` ||
    window.location.pathname === `/page-not-found`
  )
    return;

  // We get a high volume of `/something-went-wrong` views and should
  // log the errors to Datadog to help diagnose any issues.
  // eslint-disable-next-line no-console
  console.error(`Apollo error: ${JSON.stringify(params)}`);

  if (networkError || !gqlErrors) {
    window.location.href = `/something-went-wrong`;
    return;
  }

  captureErrors(gqlErrors).then(() => maybeRedirect(gqlErrors));
});

export default errorLink;
