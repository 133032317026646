import currency from "currency.js";
import dayjs from "dayjs";
import { Form } from "formik";

import {
  HiiveModalContentWrapper,
  HiiveModalHeader,
} from "@/components/common";
import {
  StepFormikQL,
  StepFormikQLChildrenProps,
  StepRouter,
  StepsHeader,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { ShareSeriesMakeupElement } from "@/components/postings";
import {
  BidPageBidByIdDocument,
  BidPageMyActivityDocument,
  ListingPageListingByIdDocument,
  MarketActivityDocument,
  ModifyListingModalListingFragment,
  SigningProcedure,
  useModifyListingMutation,
  UserActivityMyActivityDocument,
  UserWithInstitutionFragment,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { constants } from "@/utils";
import * as datetime from "@/utils/datetime";

import { getSigningProcedure } from "./getSigningProcedure";
import {
  AdditionalDetails,
  additionalDetailsValidationSchema,
  ModifyListingModalFormValues,
  ModifyListingModalStepFormContext,
  ShareDetails,
  stepKeys,
  StepKeys,
  useShareDetailsValidationSchema,
} from "./steps";

const getInitialShareSeriesMakeupValue = (
  listing: ModifyListingModalListingFragment,
) => {
  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  if (!isPartiallySold) {
    return listing.shareSeriesMakeup.map((shareSeries) => ({
      ...shareSeries,
      key: shareSeries.id,
    }));
  }

  const initialState = {
    initialShareSeries: [],
    allocatableShares: listing.numSharesAvailable || 0,
  };

  const state = listing.shareSeriesMakeup.reduce((acc, shareSeries) => {
    if (acc.allocatableShares > 0) {
      const nextNumShares = Math.round(
        listing.numSharesAvailable / listing.shareSeriesMakeup.length,
      );

      const nextShareSeries = {
        ...shareSeries,
        key: shareSeries.id,
        numShares: nextNumShares,
      };

      return {
        initialShareSeries: [nextShareSeries, ...acc.initialShareSeries],
        allocatableShares: acc.allocatableShares - nextNumShares,
      };
    }

    return acc;
  }, initialState);

  return state.initialShareSeries;
};

const mapShareSeriesMakeupValue = (
  previousListing: ModifyListingModalListingFragment,
  shareSeriesMakeupFormValue: readonly ShareSeriesMakeupElement[],
) => {
  const isPartiallySold =
    previousListing.numSharesAvailable < previousListing.numSharesOriginal;

  if (!isPartiallySold || shareSeriesMakeupFormValue.length > 1) {
    return shareSeriesMakeupFormValue.map((shareSeries) => ({
      id: shareSeries.key,
      shareSeries: shareSeries.shareSeries,
      numShares: shareSeries.numShares,
    }));
  }

  // this is an array with just one share series
  return shareSeriesMakeupFormValue.map((shareSeries) => {
    // we don't allow the user to add new share series if the listing is partially sold
    // so we can safely do a non-null assertion since we will always have a one-to-one
    // with the previous share series and share series form value
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const previousShareSeries = previousListing.shareSeriesMakeup.find(
      (_shareSeries) => _shareSeries.id === shareSeries.key,
    )!;

    const nextNumShares =
      shareSeries.numShares >= previousListing.numSharesAvailable
        ? previousShareSeries.numShares +
          (shareSeries.numShares - previousListing.numSharesAvailable)
        : previousShareSeries.numShares -
          (previousListing.numSharesAvailable - shareSeries.numShares);

    return {
      id: shareSeries.key,
      shareSeries: shareSeries.shareSeries,
      numShares: nextNumShares,
    };
  });
};

const createInitialValues = (
  listing: ModifyListingModalListingFragment,
): ModifyListingModalFormValues => ({
  listingId: listing.id,
  otherDetails: listing.otherDetails || ``,
  transferMethod: listing.transferMethod,
  hasExpirationDate: !!listing.expireAt,
  expireAt: !!listing.expireAt
    ? new Date(listing.expireAt)
    : datetime
        .add(`week`, constants.default_listing_expire_after_weeks, dayjs())
        .toDate(),
  shareSeriesMakeup: getInitialShareSeriesMakeupValue(listing),
  muteNotifications: false,
  manualSigningProcedure: listing.signingProcedure === SigningProcedure.Manual,
  pricePerShare: !!listing.listingPricePerShare
    ? listing.listingPricePerShare / 100
    : null,
});

const mapVariables =
  (
    actor: UserWithInstitutionFragment,
    previousListing: ModifyListingModalListingFragment,
  ) =>
  (formValues: ModifyListingModalFormValues) => {
    const {
      listingId,
      pricePerShare: _pricePerShare,
      hasExpirationDate,
      manualSigningProcedure,
      expireAt: _expireAt,
      transferMethod,
      shareSeriesMakeup,
      muteNotifications,
      ...values
    } = formValues;

    const pricePerShare = !!_pricePerShare
      ? currency(_pricePerShare).intValue
      : null;

    const expireAt =
      hasExpirationDate && !!_expireAt
        ? datetime.endOf(`day`, _expireAt).format()
        : null;

    const signingProcedure = getSigningProcedure(
      transferMethod,
      manualSigningProcedure,
    );

    return {
      listingId,
      input: {
        ...(actor.isHiiveUser ? { muteNotifications } : {}),
        signingProcedure,
        pricePerShare,
        expireAt,
        shareSeriesMakeup: mapShareSeriesMakeupValue(
          previousListing,
          shareSeriesMakeup,
        ),
        transferMethod,
        ...values,
      },
    };
  };

interface ModifyListingModalContentProps
  extends StepFormikQLChildrenProps<ModifyListingModalFormValues> {
  readonly stepRouter: StepRouter<StepKeys>;
  readonly listing: ModifyListingModalListingFragment;
}

const ModifyListingModalContent = ({
  listing,
  stepRouter,
  formikProps,
}: ModifyListingModalContentProps) => {
  const { values } = formikProps;
  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;
  const shareDetailsValidationSchema = useShareDetailsValidationSchema(listing);

  const isShareDetailsStepValid =
    shareDetailsValidationSchema.isValidSync(values);
  const isAdditionalDetailsStepValid =
    additionalDetailsValidationSchema.isValidSync(values);

  return (
    <div data-testid="modify-listing-modal">
      <HiiveModalHeader>Modify Listing</HiiveModalHeader>
      <StepsHeader
        stepRouter={stepRouter}
        steps={[
          {
            stepKey: stepKeys.modifyListingShareDetails,
            stepTitle: `Share Details`,
            isStepValid: isShareDetailsStepValid,
          },
          {
            stepKey: stepKeys.modifyListingAdditionalDetails,
            stepTitle: `Additional Details`,
            isStepValid: isAdditionalDetailsStepValid,
          },
        ]}
      />
      {stepKeys.modifyListingShareDetails === currentStepKey && (
        <ShareDetails
          stepRouter={stepRouter}
          listing={listing}
          {...formikProps}
        />
      )}
      {stepKeys.modifyListingAdditionalDetails === currentStepKey && (
        <AdditionalDetails
          stepRouter={stepRouter}
          listing={listing}
          {...formikProps}
        />
      )}
    </div>
  );
};

interface ModifyListingModalProps {
  readonly listing: ModifyListingModalListingFragment;
  readonly actor: UserWithInstitutionFragment;
}

const ModifyListingModal = ({ listing, actor }: ModifyListingModalProps) => {
  const mutation = useModifyListingMutation({
    refetchQueries: [
      MarketActivityDocument,
      UserActivityMyActivityDocument,
      BidPageMyActivityDocument,
      BidPageBidByIdDocument,
      ListingPageListingByIdDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.modifyListingShareDetails,
      stepKeys.modifyListingAdditionalDetails,
    ],
  });

  const _initialValues = createInitialValues(listing);

  return (
    <HiiveModalContentWrapper>
      <StepFormikQL
        stepRouter={stepRouter}
        mutation={mutation}
        mutationNames={[`modifyListing`]}
        initialValues={_initialValues}
        mapVariables={mapVariables(actor, listing)}
        context={ModifyListingModalStepFormContext}
      >
        {(props) => (
          <Form>
            <ModifyListingModalContent
              listing={listing}
              stepRouter={stepRouter}
              {...props}
            />
          </Form>
        )}
      </StepFormikQL>
    </HiiveModalContentWrapper>
  );
};

export default withCurrentActor(ModifyListingModal);
