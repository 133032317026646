import { useTranslation } from "react-i18next";

import { Box } from "@chakra-ui/react";

import { HiiveModalHeader } from "@/components/common";
import { StepRouter, StepsHeader } from "@/components/form";
import {
  PlaceStandingBidSequenceModalFormValues,
  getBidDetailsValidationSchema,
  getAdditionalDetailsValidationSchema,
} from "@/components/modals";
import { PlaceStandingBidSelectCompanyInputCompanyFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { getIsBroker } from "@/utils";

import { AdditionalDetails } from "./AdditionalDetails";
import { BidDetails } from "./BidDetails";
import { stepKeys, StepKeys } from "./steps";

export type PlaceStandingBidModalProps = {
  readonly stepRouter: StepRouter<StepKeys>;
  readonly initialCompany?: PlaceStandingBidSelectCompanyInputCompanyFragment;
  readonly formProps: Pick<
    ReturnType<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof useFormQL<PlaceStandingBidSequenceModalFormValues, any, any>
    >,
    `handleSubmit` | `control` | `trigger` | `watch` | `formState`
  >;
};

const PlaceStandingBidModal = ({
  stepRouter,
  initialCompany,
  formProps,
}: PlaceStandingBidModalProps) => {
  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;
  const actor = useCurrentActor();
  const actorIsBroker = getIsBroker(actor);
  const { t } = useTranslation(`standingBids`);
  const values = formProps?.watch();

  const bidDetailsValidationSchema = getBidDetailsValidationSchema(t);
  const isBidDetailsValid = bidDetailsValidationSchema.isValidSync(values);

  const additionalDetailsValidationSchema =
    getAdditionalDetailsValidationSchema(t, actor);
  const isAdditionalDetailsValid =
    additionalDetailsValidationSchema.isValidSync(values);

  return (
    <Box data-testid="create-standing-bid-modal">
      <HiiveModalHeader>
        {actorIsBroker ? t`place_standing_bid` : t`submit_standing_bid`}
      </HiiveModalHeader>
      <StepsHeader
        stepRouter={stepRouter}
        steps={[
          {
            stepKey: stepKeys.placeStandingBidBidDetails,
            stepTitle: `Bid information`,
            isStepValid: isBidDetailsValid,
          },
          {
            stepKey: stepKeys.placeStandingBidAdditionalDetails,
            stepTitle: `Additional details`,
            isStepValid: isAdditionalDetailsValid,
          },
        ]}
      />
      {stepKeys.placeStandingBidBidDetails === currentStepKey && (
        <BidDetails
          stepRouter={stepRouter}
          initialCompany={initialCompany}
          formProps={formProps}
        />
      )}
      {stepKeys.placeStandingBidAdditionalDetails === currentStepKey && (
        <AdditionalDetails stepRouter={stepRouter} formProps={formProps} />
      )}
    </Box>
  );
};

export default PlaceStandingBidModal;
