import { Flex, HStack, OtherProps, VStack, Stack } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";
import { AcceptedBidSharePrices } from "@/components/companies";
import { ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragment } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  lot,
  getIsPartiallyAcceptedStandingBid,
  getIsConditionallyCompletedStandingBid,
} from "@/utils";

const ActivityCardStandingBidNumShareAndPriceDetails = ({
  standingBid,
  textStyle = `activity-card-heading`,
}: {
  readonly standingBid: ActivityCardStandingBidNumSharesAndPriceDetailsStandingBidFragment;
  readonly textStyle?: OtherProps["textStyle"];
}) => {
  const {
    pricePerShare,
    numSharesOriginal,
    numSharesAvailable,
    acceptedBidPrices,
    numSharesAccepted,
  } = standingBid;

  // These fields are required for v2 StandingBids
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const sharesOriginal = numSharesOriginal!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const sharesAvailable = numSharesAvailable!!;

  if (getIsPartiallyAcceptedStandingBid(standingBid)) {
    return (
      <VStack align="flex-start">
        <Stack
          alignItems={{ base: `flex-start`, sm: `flex-end` }}
          flexDirection={{ base: `column`, sm: `row` }}
          gap={{ base: 2, sm: 6 }}
          textStyle={textStyle}
        >
          <Flex wrap="wrap" textStyle={textStyle} columnGap={1.5}>
            <SensitiveText as="del" color="grey.200">
              {formatShares(sharesOriginal)}
            </SensitiveText>
            <SensitiveText>{formatShares(sharesAvailable)}</SensitiveText>
            <HStack spacing={6}>
              <SensitiveText>
                @ {formatPricePerShare(pricePerShare)}/sh
              </SensitiveText>
            </HStack>
          </Flex>
          <SensitiveText>{lot(sharesAvailable, pricePerShare)}</SensitiveText>
        </Stack>
        <AcceptedBidSharePrices
          acceptedBidPrices={acceptedBidPrices}
          numSharesSold={numSharesAccepted}
        />
      </VStack>
    );
  }

  if (getIsConditionallyCompletedStandingBid(standingBid)) {
    return (
      <VStack align="flex-start">
        <Stack
          alignItems={{ base: `flex-start`, sm: `flex-end` }}
          flexDirection={{ base: `column`, sm: `row` }}
          gap={{ base: 2, sm: 6 }}
          textStyle={textStyle}
        >
          <Flex wrap="wrap" columnGap={1.5} as="del" color="grey.200">
            <SensitiveText>{formatShares(sharesOriginal)}</SensitiveText>
            <HStack spacing={6}>
              <SensitiveText>
                @ {formatPricePerShare(pricePerShare)}/sh
              </SensitiveText>
            </HStack>
          </Flex>
          <SensitiveText as="del" color="grey.200">
            {lot(sharesOriginal, pricePerShare)}
          </SensitiveText>
        </Stack>
        <AcceptedBidSharePrices
          acceptedBidPrices={acceptedBidPrices}
          numSharesSold={numSharesAccepted}
        />
      </VStack>
    );
  }

  return (
    <Stack
      alignItems={{ base: `flex-start`, sm: `flex-end` }}
      flexDirection={{ base: `column`, sm: `row` }}
      gap={{ base: 2, sm: 6 }}
      textStyle={textStyle}
    >
      <Flex wrap="wrap" columnGap={1.5}>
        <SensitiveText>{formatShares(sharesOriginal)}</SensitiveText>
        <HStack spacing={6}>
          <SensitiveText>
            @ {formatPricePerShare(pricePerShare)}/sh
          </SensitiveText>
        </HStack>
      </Flex>
      <SensitiveText>{lot(sharesOriginal, pricePerShare)}</SensitiveText>
    </Stack>
  );
};

export default ActivityCardStandingBidNumShareAndPriceDetails;
