import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Text,
  HStack,
  TextProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { ActivitySection, BackToTop } from "@/components/common";
import {
  CompanyMarketActivityListingCard,
  CompanyMarketActivityStandingBidCard,
} from "@/components/companies";
import {
  CompanyPageMarketActivityCompanyFragment,
  CompanyPageMarketActivityListingV2Fragment,
  CompanyPageMarketActivityListingsFragment,
  CompanyPageMarketActivityStandingBidV2Fragment,
  CompanyPageMarketActivityStandingBidsFragment,
} from "@/gql";
import { formatPricePerShare } from "@/utils";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";
import {
  getLastTruncatedListingPricePerShare,
  getLastTruncatedStandingBidPricePerShare,
  getTotalPostingsCount,
  useTrackViewMorePostings,
} from "./utils";

type MarketActivityContentV2TotalCountProps = {
  readonly count: number;
};

type MarketActivityContentV2ListingCardsProps = {
  listings: CompanyPageMarketActivityListingV2Fragment[];
  company: CompanyPageMarketActivityCompanyFragment;
};

type MarketActivityContentV2ListingsProps = {
  listings: CompanyPageMarketActivityListingsFragment;
  company: CompanyPageMarketActivityCompanyFragment;
  listingsCount: number;
  standingBidsCount: number;
};

type MarketActivityContentV2StandingBidCardsProps = {
  standingBids: CompanyPageMarketActivityStandingBidV2Fragment[];
  company: CompanyPageMarketActivityCompanyFragment;
};

type MarketActivityContentV2StandingBidsProps = {
  standingBids: CompanyPageMarketActivityStandingBidsFragment;
  company: CompanyPageMarketActivityCompanyFragment;
  listingsCount: number;
  standingBidsCount: number;
};

type MarketActivityContentV2Props = {
  readonly company: CompanyPageMarketActivityCompanyFragment;
};

function MarketActivityContentV2ViewMorePostingsText(props: TextProps) {
  return (
    <Text
      role="button"
      textStyle="heading-xs"
      textDecoration="underline"
      cursor="pointer"
      pt={6}
      {...props}
    />
  );
}

function MarketActivityContentV2ListingCards({
  company,
  listings,
}: MarketActivityContentV2ListingCardsProps) {
  return listings.map((listing) => {
    const { id } = listing;

    return (
      <CompanyMarketActivityListingCard
        key={id}
        listing={listing}
        company={company}
      />
    );
  });
}

function MarketActivityContentV2Listings({
  listings,
  listingsCount,
  standingBidsCount,
  company,
}: MarketActivityContentV2ListingsProps) {
  const { t } = useTranslation(`company`);

  const trackMorePostings = useTrackViewMorePostings();

  const { isOpen: showRemainingListings, onOpen: setShowRemainingListings } =
    useDisclosure();

  const { truncated: truncatedListings, remaining: remainingListings } =
    listings;

  const { length: remainingListingsLength } = remainingListings;

  const lastTruncatedPricePerShare =
    getLastTruncatedListingPricePerShare(truncatedListings);

  const hasListings = listingsCount > 0;
  const hasRemainingListings =
    !showRemainingListings && remainingListingsLength > 0;

  const handleViewMorePostings = useCallback(() => {
    trackMorePostings(`View more listings`, company);

    return setShowRemainingListings();
  }, [trackMorePostings, setShowRemainingListings]);

  return hasListings ? (
    <VStack spacing={4}>
      <MarketActivityContentV2ListingCards
        listings={truncatedListings}
        company={company}
      />

      {showRemainingListings && (
        <>
          <MarketActivityContentV2ListingCards
            listings={remainingListings}
            company={company}
          />
          <BackToTop
            variant={{
              base: `mobile`,
              lg: `desktop`,
            }}
          />
        </>
      )}

      {hasRemainingListings && (
        <MarketActivityContentV2ViewMorePostingsText
          onClick={handleViewMorePostings}
        >
          {t(`view_listings_over`, {
            pricePerShare: formatPricePerShare(lastTruncatedPricePerShare ?? 0),
          })}
        </MarketActivityContentV2ViewMorePostingsText>
      )}
    </VStack>
  ) : (
    <ListingsEmptyState
      company={company}
      numberOfStandingBids={standingBidsCount}
    />
  );
}

function MarketActivityContentV2StandingBidCards({
  company,
  standingBids,
}: MarketActivityContentV2StandingBidCardsProps) {
  return standingBids.map((standingBid) => {
    const { id } = standingBid;

    return (
      <CompanyMarketActivityStandingBidCard
        key={id}
        standingBid={standingBid}
        company={company}
      />
    );
  });
}

function MarketActivityContentV2StandingBids({
  standingBids,
  listingsCount,
  standingBidsCount,
  company,
}: MarketActivityContentV2StandingBidsProps) {
  const { t } = useTranslation(`company`);
  const trackMorePostings = useTrackViewMorePostings();

  const {
    isOpen: showRemainingStandingBids,
    onOpen: setShowRemainingStandingBids,
  } = useDisclosure();
  const { truncated: truncatedStandingBids, remaining: remainingStandingBids } =
    standingBids;

  const { length: remainingStandingBidsLength } = remainingStandingBids;

  const lastTruncatedPricePerShare = getLastTruncatedStandingBidPricePerShare(
    truncatedStandingBids,
  );

  const hasStandingBids = standingBidsCount > 0;
  const hasRemainingStandingBids =
    !showRemainingStandingBids && remainingStandingBidsLength > 0;

  const handleViewMorePostings = useCallback(() => {
    trackMorePostings(`View more standing bids`, company);

    return setShowRemainingStandingBids();
  }, [trackMorePostings, setShowRemainingStandingBids]);

  return hasStandingBids ? (
    <VStack spacing={4}>
      <MarketActivityContentV2StandingBidCards
        standingBids={truncatedStandingBids}
        company={company}
      />

      {showRemainingStandingBids && (
        <>
          <MarketActivityContentV2StandingBidCards
            standingBids={remainingStandingBids}
            company={company}
          />
          <BackToTop
            variant={{
              base: `mobile`,
              lg: `desktop`,
            }}
          />
        </>
      )}

      {hasRemainingStandingBids && (
        <MarketActivityContentV2ViewMorePostingsText
          onClick={handleViewMorePostings}
        >
          {t(`view_standing_bids_under`, {
            pricePerShare: formatPricePerShare(lastTruncatedPricePerShare ?? 0),
          })}
        </MarketActivityContentV2ViewMorePostingsText>
      )}
    </VStack>
  ) : (
    <StandingBidsEmptyState
      company={company}
      numberOfListings={listingsCount}
    />
  );
}

function MarketActivityContentV2TotalCount({
  count,
}: MarketActivityContentV2TotalCountProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      px="7px"
      py="2px"
      bg="grey.50"
      borderWidth={0.5}
      borderStyle="solid"
      borderColor="grey.200"
      borderRadius="full"
    >
      <Text textStyle="heading-4xs" color="grey.900" fontWeight={500}>
        {count}
      </Text>
    </Flex>
  );
}

export function MarketActivityContentV2({
  company,
}: MarketActivityContentV2Props) {
  const { t } = useTranslation(`company`);

  const {
    activity: { othersListingsV2, othersStandingBidsV2 },
  } = company;

  // If the feature flag is off, this clause should never be reached.
  // Once the orderbook redesign is released, this clause would be redundant since the
  // server would always return these values
  if (!othersListingsV2 || !othersStandingBidsV2) {
    return null;
  }

  const listingsCount = getTotalPostingsCount(othersListingsV2);
  const standingBidsCount = getTotalPostingsCount(othersStandingBidsV2);

  return (
    <ActivitySection title={t(`market_activity`)}>
      <Tabs variant="underline" mt={{ base: 4, md: 0 }}>
        <TabList pb={4}>
          <Tab>
            <HStack gap={2}>
              <Text>{t(`listings`)}</Text>
              <MarketActivityContentV2TotalCount count={listingsCount} />
            </HStack>
          </Tab>
          <Tab>
            <HStack gap={2}>
              <Text>{t(`standing_bids`)}</Text>
              <MarketActivityContentV2TotalCount count={standingBidsCount} />
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MarketActivityContentV2Listings
              company={company}
              listings={othersListingsV2}
              standingBidsCount={standingBidsCount}
              listingsCount={listingsCount}
            />
          </TabPanel>
          <TabPanel>
            <MarketActivityContentV2StandingBids
              company={company}
              standingBids={othersStandingBidsV2}
              standingBidsCount={standingBidsCount}
              listingsCount={listingsCount}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ActivitySection>
  );
}
