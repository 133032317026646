import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Link, Text, VStack } from "@chakra-ui/react";

import {
  OnboardingContainerV2,
  SlideAnimation,
} from "@/components/onboarding-v2";
import { SubmissionStatus } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { TraderIVRequiredPage } from "./TraderIVRequiredPage";

export const SupportedCountryTraderAwaitingApprovalPage = () => {
  const { t } = useTranslation(`onboarding`, {
    keyPrefix: `awaiting_approval`,
  });

  const actor = useCurrentActor();
  const shouldShowIVRequiredScreen =
    !actor.identityVerified &&
    actor.onboarding?.identityVerificationSubmissionStatus ===
      SubmissionStatus.Submitted;

  if (shouldShowIVRequiredScreen) return <TraderIVRequiredPage />;

  return (
    <OnboardingContainerV2 canGoBack={false} metaTitle={t(`welcome_to_hiive`)}>
      <Flex direction="column" w="full" maxW="45rem">
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`welcome_to_hiive`)}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={7}>
                <Text>
                  {t(
                    `thank_you_for_your_interest_in_the_hiive_platform_other_supported_individual`,
                  )}
                </Text>

                <Text>
                  <Trans
                    i18nKey="awaiting_approval.if_you_have_any_questions_in_the_meantime"
                    ns="onboarding"
                    components={{
                      mailto: (
                        <Link
                          fontWeight="medium"
                          textDecoration="underline"
                          href="mailto:support@hiive.com"
                        />
                      ),
                    }}
                  />
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </OnboardingContainerV2>
  );
};
