import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  InquiryPostingCard,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  MessageTopicType,
  PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussionFragment,
  usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

import { InquiryCard } from "./InquiryCard";

type MyDiscussionsProps = {
  myDiscussions: PostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageDiscussionFragment[];
};

const InquiriesEmptyState = () => {
  const { t } = useTranslation();

  return <ActivityGroupEmptyState message={t(`no_active_discussions`)} />;
};

const InquiriesPageSkeleton = () => (
  <Flex direction="column" gap={4} w="full" pt={{ base: 4, lg: 8 }}>
    <Skeleton h="20px" maxW="20%" />
    <Skeleton h="16px" maxW="40%" />
    <ActivityCardSkeleton />
    <ActivityCardSkeleton />
  </Flex>
);

function MyDiscussions({ myDiscussions }: MyDiscussionsProps) {
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivityGroup emptyState={<InquiriesEmptyState />}>
      {myDiscussions.map((discussion) =>
        cardUpdatesEnabled ? (
          <InquiryPostingCard key={discussion.id} discussion={discussion} />
        ) : (
          <InquiryCard key={discussion.id} discussion={discussion} />
        ),
      )}
    </ActivityGroup>
  );
}

const Inquiries = ({
  companyId,
  listingId,
}: {
  readonly companyId: string;
  readonly listingId: string;
}) => {
  const { t } = useTranslation();

  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery({
      variables: {
        id: companyId,
        topicFilter: { id: listingId, type: MessageTopicType.Listing },
      },
    });

  return (
    <WithQuery query={query} fallback={<InquiriesPageSkeleton />}>
      {({ data: { myDiscussions } }) => (
        <VStack alignItems="flex-start" spacing={4}>
          <Text textStyle="heading-sm" textTransform="uppercase">
            {t(`inquiries`)}
          </Text>
          <Text textStyle="text-md">{t(`click_on_inquiry_to_respond`)}</Text>
          <MyDiscussions myDiscussions={myDiscussions} />
        </VStack>
      )}
    </WithQuery>
  );
};

const InquiriesPageV2 = ({ id }: { id: string }) => {
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageInquiriesPageQuery({
      variables: { id },
    });

  const router = useRouter();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <WithQuery query={query} fallback={<></>}>
      {({ data }) => {
        const { companyById: company } = data;
        const listing = company.activity.myListings[0];

        if (!listing) {
          router.replace(`/dashboard/${id}`);
          return null;
        }

        return (
          <ActivitySection pt={{ base: 4, lg: 8 }}>
            <Inquiries companyId={id} listingId={listing.id} />
          </ActivitySection>
        );
      }}
    </WithQuery>
  );
};

export default InquiriesPageV2;
