import assign from "lodash/assign";
import { ReactNode } from "react";

import NextLink from "next/link";

import {
  Box,
  BoxProps,
  Card,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Link,
  ThemingProps,
  useStyleConfig,
  Text,
  TextProps,
  StackProps,
  VStack,
  CardBody,
  CardBodyProps,
  CardFooter,
  CardFooterProps,
} from "@chakra-ui/react";

type PostingCardProps = CardProps & {
  children: ReactNode;
  href: string;
};

type PostingCardHeaderProps = Omit<CardHeaderProps, `title`> & {
  title: ReactNode;
  children?: ReactNode;
};

type PostingCardLabelProps = BoxProps &
  ThemingProps<`PostingCardLabel`> & {
    children: ReactNode;
  };

type PostingCardHeaderTitleProps = TextProps;

type PostingCardTileProps = StackProps & {
  title: ReactNode;
  children: ReactNode;
};

type PostingCardTileIconProps = StackProps & {
  children: ReactNode;
};

type PostingCardBodyProps = CardBodyProps & {
  children: ReactNode;
};

type PostingCardBodyTitleProps = BoxProps & {
  children: ReactNode;
};

type PostingCardBodyDescriptionProps = TextProps & {
  children: ReactNode;
};

type PostingCardFooterProps = CardFooterProps & {
  children: ReactNode;
};

function PostingCardRoot({ children, href, ...restProps }: PostingCardProps) {
  return (
    <Link
      as={NextLink}
      href={href}
      w="full"
      _hover={{
        textDecoration: `none`,
      }}
    >
      <Card variant="posting" {...restProps}>
        {children}
      </Card>
    </Link>
  );
}

function PostingCardHeader({
  title,
  children,
  ...restProps
}: PostingCardHeaderProps) {
  return (
    <CardHeader {...restProps}>
      {title}
      <Box flexGrow={1} />
      {children}
    </CardHeader>
  );
}

function PostingCardLabel({
  children,
  variant,
  ...restProps
}: PostingCardLabelProps) {
  const styles = useStyleConfig(`PostingCardLabel`, { variant });

  return (
    <Box __css={styles} {...restProps}>
      {children}
    </Box>
  );
}

function PostingCardHeaderTitle(props: PostingCardHeaderTitleProps) {
  return <Text textStyle="text-md" color="grey.900" {...props} />;
}

function PostingCardTile({
  title,
  children,
  ...restProps
}: PostingCardTileProps) {
  return (
    <VStack
      borderWidth=".5px"
      borderColor="grey.100"
      borderRadius="md"
      alignItems="left"
      gap={0}
      px={2}
      py={1}
      w={{ base: 100, md: 32 }}
      {...restProps}
    >
      <Text
        textStyle="text-xs"
        color="grey.700"
        textAlign="left"
        verticalAlign="center"
        lineHeight="1rem"
        display={{
          base: `none`,
          md: `initial`,
        }}
      >
        {title}
      </Text>

      <Text textStyle="heading-4xs" color="grey.700" textAlign="left">
        {children}
      </Text>
    </VStack>
  );
}

function PostingCardTileIcon(props: PostingCardTileIconProps) {
  return (
    <VStack
      justifyContent="center"
      borderWidth={0.5}
      borderColor="grey.100"
      borderRadius="md"
      px={{ base: 2, md: 3 }}
      py={{ base: `2px`, md: 2 }}
      {...props}
    />
  );
}

function PostingCardBody(props: PostingCardBodyProps) {
  return <CardBody {...props} />;
}

function PostingCardBodyTitle({
  children,
  ...props
}: PostingCardBodyTitleProps) {
  return (
    <Box textStyle="activity-card-heading" {...props}>
      {children}
    </Box>
  );
}

function PostingCardBodyDescription(props: PostingCardBodyDescriptionProps) {
  return <Text textStyle="text-xs" color="grey.700" {...props} />;
}

function PostingCardFooter(props: PostingCardFooterProps) {
  return <CardFooter {...props} />;
}

export const PostingCard = assign(PostingCardRoot, {
  Header: PostingCardHeader,
  HeaderTitle: PostingCardHeaderTitle,

  Body: PostingCardBody,
  BodyTitle: PostingCardBodyTitle,
  BodyDescription: PostingCardBodyDescription,

  Footer: PostingCardFooter,

  Label: PostingCardLabel,
  Tile: PostingCardTile,
  TileIcon: PostingCardTileIcon,
});
