import { Namespace, TFunction } from "i18next";
import isNil from "lodash/isNil";
import * as Yup from "yup";

import {
  representedEmailSchema,
  representedNameSchema,
} from "@/components/postings";
import { UserWithInstitutionFragment } from "@/gql";
import { constants, getIsBroker } from "@/utils";

export const getBidDetailsValidationSchema = (t: TFunction<Namespace>) =>
  Yup.object({
    company: Yup.object({
      id: Yup.string().required(t`required`),
      name: Yup.string().required(t`required`),
    })
      .nullable()
      .required(t`required`),
    numShares: Yup.number()
      .min(1)
      .required(t`required`),
    acceptsShares: Yup.string()
      .nullable()
      .required(t`required`),
    minPartialAcceptNumShares: Yup.number()
      .min(1, t`must_be_at_least_one`)
      .nullable()
      .test({
        name: `checkRequired`,
        message: t`required_when_partial_acceptance_is_allowed`,
        // eslint-disable-next-line object-shorthand, func-names
        test: function (minPartialAcceptNumShares: number | null) {
          return (
            // eslint-disable-next-line react/no-this-in-sfc
            !this.parent.allowPartialAccept || !isNil(minPartialAcceptNumShares)
          );
        },
      })
      .test({
        name: `checkPartialAcceptMeetsMinValue`,
        message: t(`partial_accept_amount_for_a_bid_must_have_minimum`, {
          minStandingBidLot: constants.min_standing_bid_lot.text,
        }),
        // eslint-disable-next-line object-shorthand, func-names
        test: function (minPartialAcceptNumShares: number) {
          return (
            // eslint-disable-next-line react/no-this-in-sfc
            !this.parent.allowPartialAccept ||
            // eslint-disable-next-line react/no-this-in-sfc
            this.parent.pricePerShare * minPartialAcceptNumShares >=
              constants.min_standing_bid_lot.number
          );
        },
      })
      .test({
        name: `checkLessThanNumShares`,
        message: t`must_be_less_than`,
        // eslint-disable-next-line object-shorthand, func-names
        test: function (minPartialAcceptNumShares: number) {
          return (
            // eslint-disable-next-line react/no-this-in-sfc
            !this.parent.allowPartialAccept ||
            // eslint-disable-next-line react/no-this-in-sfc
            minPartialAcceptNumShares <= this.parent.numShares
          );
        },
      }),
    allowPartialAccept: Yup.boolean()
      .nullable()
      .required(t`required`),
    acceptsTransferMethods: Yup.array()
      .of(Yup.string())
      .min(1, t`must_select_at_least_one`)
      .nullable()
      .required(t`required`),
    pricePerShare: Yup.number()
      .typeError(t`required`)
      .nullable()
      .moreThan(0, t`must_be_a_positive_number`)
      .required(t`required`)
      .test({
        name: `checkBidMeetsMinValue`,
        params: {},
        message: t(`bid_must_be_a_minimum_value_of_try_adjusting`, {
          minStandingBidLot: constants.min_standing_bid_lot.text,
        }),
        // eslint-disable-next-line object-shorthand, func-names
        test: function (pricePerShare: number) {
          return (
            // eslint-disable-next-line react/no-this-in-sfc
            pricePerShare * this.parent.numShares >=
            constants.min_standing_bid_lot.number
          );
        },
      }),
  });

export const getAdditionalDetailsValidationSchema = (
  t: TFunction<Namespace>,
  actor: UserWithInstitutionFragment,
) =>
  Yup.object().shape({
    affiliate: getIsBroker(actor)
      ? Yup.boolean()
          .nullable()
          .required(t`required`)
      : Yup.boolean().nullable(),
    confirmed: Yup.boolean().oneOf([true], t`required`),
    expireAt: Yup.string().required(t`required`),
    otherDetails: Yup.string().nullable(),
    muteNotifyWatchers: Yup.boolean().nullable(),
    representedEmail: representedEmailSchema(actor),
    representedFirstName: representedNameSchema(actor),
    representedLastName: representedNameSchema(actor),
    notifyRepresentingEmail: Yup.boolean().required(t`required`),
    solicited: getIsBroker(actor)
      ? Yup.boolean()
          .nullable()
          .required(t`required`)
      : Yup.boolean().nullable(),
  });
