import { match } from "ts-pattern";

import { TransferMethod } from "@/gql";

import { transferTypeChoices, TransferTypeChoices } from "./types";

// TODO: can remove this and revert back to UnaccreditedSellerTransferMethodInput after server update
enum TempUnaccreditedSellerTransferMethodInput {
  Direct = `DIRECT`,
  Other = `OTHER`,
  Unknown = `UNKNOWN`,
}

export const transferTypeChoiceToTransferMethod = (
  transferTypeChoice: TransferTypeChoices,
) =>
  match(transferTypeChoice)
    .with(`Yes`, () => TempUnaccreditedSellerTransferMethodInput.Direct)
    .with(`No`, () => TempUnaccreditedSellerTransferMethodInput.Other)
    .with(`DontKnow`, () => TempUnaccreditedSellerTransferMethodInput.Unknown)
    .with(`Other`, () => TempUnaccreditedSellerTransferMethodInput.Other)
    .exhaustive();

export const transferMethodToTransferTypeChoice = (
  transferMethod: TransferMethod,
) =>
  match(transferMethod)
    .with(TransferMethod.Direct, () => transferTypeChoices.Yes)
    .with(TransferMethod.Unknown, () => transferTypeChoices.DontKnow)
    .with(TransferMethod.Other, () => transferTypeChoices.Other)
    .otherwise(() => {
      throw new Error(
        `Invalid transferMethod in transferMethodToTransferTypeChoice`,
      );
    });

export const transferTypeChoiceToString = (
  transferTypeChoice: TransferTypeChoices,
) =>
  match(transferTypeChoice)
    .with(`Yes`, () => `I can transfer the shares directly`)
    .with(`No`, () => `I can't transfer the shares directly`)
    .with(`Other`, () => `I can't transfer the shares directly`)
    .with(`DontKnow`, () => `I am not sure if I transfer the shares directly`)
    .exhaustive();
