import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, CardBody, Fade, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  DocumentType,
  SignDocumentAlertMessagesTransactionFragment,
  SigningProcedure,
  TransactionPermission,
  UserWithInstitutionFragment,
  useSignDocumentAlertMessagesMyActivityQuery,
} from "@/gql";
import { iHaveEntityPermission, useCurrentActor } from "@/hooks";
import { useTEXASEnabled } from "@/hooks/featureFlags";
import {
  getLongDocumentTitleByDocumentType,
  getIsBroker,
  getIsUnaccreditedSeller,
  appendSellerCompanyIdToUrl,
} from "@/utils";
import * as datetime from "@/utils/datetime";
import { getTransactionIsPurchase, makeUrl } from "@/utils/schema";

const DashboardInviteAlternateSignerAlert = ({
  transaction,
}: {
  readonly transaction: SignDocumentAlertMessagesTransactionFragment;
}) => {
  const actor = useCurrentActor();
  const router = useRouter();
  const { t } = useTranslation();

  if (!transaction.document) return null;

  const isPurchase = getTransactionIsPurchase(actor, transaction);
  const documentTitle = getLongDocumentTitleByDocumentType(
    transaction.document.type,
  );
  const companyName = transaction.bid.company.name;
  const expirationDate = !!transaction.expireAt
    ? datetime.format(`h:mm A z on MMM D, YYYY`, transaction.expireAt)
    : null;
  const onClick = () => {
    router.push(makeUrl(transaction));
  };

  const action = isPurchase ? `purchase` : `sale`;

  const description = t(
    transaction.document.type === DocumentType.Loi
      ? `invite_customer_and_begin_transfer_loi`
      : `invite_customer_and_begin_transfer_stn`,
    {
      companyName,
      documentTitle,
      expirationDate,
      action,
    },
  );

  return (
    <Card variant="teal" w="full">
      <CardBody w="full">
        <VStack alignItems="flex-start">
          <Text textStyle="deprecated-heading-xl">
            {t(`awaiting_signatures`, {
              ns: `dashboard`,
              documentTitle,
            })}
          </Text>
          <Text textStyle="deprecated-text-sm">{description}</Text>
          <HiiveButton
            onClick={onClick}
            variant="text-salmon"
            px={0}
            observabilityLabel="[DashboardInviteAlternateSignerAlert] Send to customer"
          >
            {t(`send_to_customer`, {
              ns: `dashboard`,
              type: transaction.document.type,
            })}
          </HiiveButton>
        </VStack>
      </CardBody>
    </Card>
  );
};

const DashboardSignDocumentAlert = ({
  transaction,
}: {
  readonly transaction: SignDocumentAlertMessagesTransactionFragment;
}) => {
  const actor = useCurrentActor();
  const router = useRouter();
  const { t } = useTranslation();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  if (!transaction.document) return null;

  const isPurchase = getTransactionIsPurchase(actor, transaction);
  const documentTitle = getLongDocumentTitleByDocumentType(
    transaction.document.type,
  );
  const companyName = transaction.bid.company.name;
  const expirationDate = !!transaction.expireAt
    ? datetime.format(`h:mm A z on MMM D, YYYY`, transaction.expireAt)
    : null;
  const onClick = () => {
    const url = `/listings/bids/${transaction.bid.id}/document/${transaction.id}`;
    router.push(
      isUnaccreditedSeller
        ? appendSellerCompanyIdToUrl(url, transaction.company.id)
        : url,
    );
  };

  const action = isPurchase ? `purchase` : `sale`;

  const description = t(
    transaction.document.type === DocumentType.Loi
      ? `sign_to_begin_transfer_loi`
      : `sign_to_begin_transfer_stn`,
    {
      companyName,
      documentTitle,
      expirationDate,
      action,
    },
  );

  return (
    <Card variant="teal" w="full" data-testId="dashboard-document-sign-cta">
      <CardBody w="full">
        <VStack alignItems="flex-start">
          <Text textStyle="deprecated-heading-xl">
            {t(`awaiting_your_signature`, {
              ns: `dashboard`,
              documentTitle,
            })}
          </Text>
          <Text textStyle="deprecated-text-sm">{description}</Text>
          <HiiveButton
            onClick={onClick}
            variant="text-salmon"
            px={0}
            observabilityLabel="[DashboardSignDocumentAlert] Review and Sign"
          >
            {t(`review_and_sign`, {
              ns: `dashboard`,
            })}
          </HiiveButton>
        </VStack>
      </CardBody>
    </Card>
  );
};

const BrokerDashboardSignDocumentAlertsContent = ({
  transactions,
}: {
  readonly transactions: readonly SignDocumentAlertMessagesTransactionFragment[];
}) => {
  const transactionsPendingDocumentSignature = transactions.filter(
    (transaction) =>
      iHaveEntityPermission(
        transaction,
        TransactionPermission.InviteAlternateSigner,
      ) && transaction.signingProcedure !== SigningProcedure.Manual,
  );

  if (transactionsPendingDocumentSignature.length === 0) return null;

  return (
    <Fade in>
      <VStack spacing={4} w="full">
        {transactionsPendingDocumentSignature.map(
          (transaction: SignDocumentAlertMessagesTransactionFragment) => (
            <DashboardInviteAlternateSignerAlert
              key={transaction.id}
              transaction={transaction}
            />
          ),
        )}
      </VStack>
    </Fade>
  );
};

const DashboardSignDocumentAlertsContent = ({
  transactions,
}: {
  readonly transactions: readonly SignDocumentAlertMessagesTransactionFragment[];
}) => {
  const isTEXASEnabled = useTEXASEnabled();
  const transactionsPendingDocumentSignature = transactions
    .filter(
      (transaction) =>
        iHaveEntityPermission(
          transaction,
          TransactionPermission.SignTransactionDocument,
        ) && transaction.signingProcedure !== SigningProcedure.Manual,
    )
    .filter((transaction) => (isTEXASEnabled ? !transaction.workflow : true));

  if (transactionsPendingDocumentSignature.length === 0) return null;

  return (
    <Fade in>
      <VStack spacing={4} w="full">
        {transactionsPendingDocumentSignature.map(
          (transaction: SignDocumentAlertMessagesTransactionFragment) => (
            <DashboardSignDocumentAlert
              key={transaction.id}
              transaction={transaction}
            />
          ),
        )}
      </VStack>
    </Fade>
  );
};
export const DashboardSignDocumentAlerts = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const query = useSignDocumentAlertMessagesMyActivityQuery();

    return (
      <WithQuery query={query} hideLoadingSpinner>
        {({
          data: {
            myActivity: { myTransactions },
          },
        }) =>
          getIsBroker(actor) ? (
            <BrokerDashboardSignDocumentAlertsContent
              transactions={myTransactions}
            />
          ) : (
            <DashboardSignDocumentAlertsContent transactions={myTransactions} />
          )
        }
      </WithQuery>
    );
  },
);
