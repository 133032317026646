import { Feed, FeedItem } from "@knocklabs/client";
import { GenericData } from "@knocklabs/types";
import groupBy from "lodash/groupBy";

const page_size = 1000000;
const fetchAllUnreadNotifications = async ({
  notificationFeed,
  key,
  lastItem,
  data,
}: {
  readonly notificationFeed: Feed;
  readonly key?: string;
  readonly lastItem?: FeedItem;
  readonly data?: GenericData;
}): Promise<readonly FeedItem[]> => {
  const response = await notificationFeed.fetch({
    status: `unread`,
    source: key,
    page_size,
    after: lastItem?.__cursor,
    trigger_data: data,
  });

  if (!response?.data.entries) return [];

  return response.data.entries;
};

export const fetchAllUnreadNotificationsMap = async ({
  notificationFeed,
}: {
  readonly notificationFeed: Feed;
}) => {
  const entries = await fetchAllUnreadNotifications({
    notificationFeed,
  });

  return groupBy(entries, `source.key`);
};

const viewNotifications = ({
  notificationFeed,
  notifications,
}: {
  readonly notificationFeed: Feed;

  readonly notifications: readonly FeedItem[];
}) => {
  // eslint-disable-next-line functional/prefer-readonly-type
  notificationFeed.markAsRead(notifications as FeedItem[]);
  // eslint-disable-next-line functional/prefer-readonly-type
  notificationFeed.markAsSeen(notifications as FeedItem[]);
};

const maybeViewNotifications = ({
  notificationFeed,
  notifications,
}: {
  readonly notificationFeed: Feed;
  readonly notifications: readonly FeedItem[];
}) => {
  if (notifications.length === 0) return;
  viewNotifications({ notificationFeed, notifications });
};

const maybeViewNotification = ({
  notificationFeed,
  notification,
}: {
  readonly notificationFeed: Feed;
  readonly notification: FeedItem | undefined;
}) => {
  if (!notification) return;
  viewNotifications({ notificationFeed, notifications: [notification] });
};

const getNewInquiryNotification = async ({
  notificationFeed,
  inquiryId,
}: {
  readonly notificationFeed: Feed;
  readonly inquiryId: string;
}) => {
  const unreadNotifications = await fetchAllUnreadNotifications({
    notificationFeed,
    key: `discussion-request-to-recipient`,
    data: { discussion_id: inquiryId },
  });
  return unreadNotifications[0];
};

const getInquiryNewMessageNotifications = async ({
  notificationFeed,
  inquiryId,
}: {
  readonly notificationFeed: Feed;
  readonly inquiryId: string;
}) =>
  fetchAllUnreadNotifications({
    notificationFeed,
    key: `discussion-message-to-recipient`,
    data: { discussion_id: inquiryId },
  });

export const maybeViewNewInquiryNotification = async ({
  notificationFeed,
  inquiryId,
}: {
  readonly notificationFeed: Feed;
  readonly inquiryId: string;
}) => {
  const notification = await getNewInquiryNotification({
    notificationFeed,
    inquiryId,
  });

  maybeViewNotification({ notificationFeed, notification });
};

export const viewInquiryNewMessageNotifications = async ({
  notificationFeed,
  inquiryId,
}: {
  readonly notificationFeed: Feed;
  readonly inquiryId: string;
}) => {
  const notifications = await getInquiryNewMessageNotifications({
    notificationFeed,
    inquiryId,
  });

  maybeViewNotifications({ notificationFeed, notifications });
};

const getNewBidNotification = async ({
  notificationFeed,
  bidId,
}: {
  readonly notificationFeed: Feed;
  readonly bidId: string;
}) => {
  const unreadNotifications = await fetchAllUnreadNotifications({
    notificationFeed,
    key: `bid-to-lister`,
    data: { bid_id: bidId },
  });
  return unreadNotifications[0];
};

const getNewStandingBidNotification = async ({
  notificationFeed,
  standingBidId,
}: {
  readonly notificationFeed: Feed;
  readonly standingBidId: string;
}) => {
  const unreadNotifications = await fetchAllUnreadNotifications({
    notificationFeed,
    key: `new-standing-bid-to-holders`,
    data: { standing_bid_id: standingBidId },
  });
  return unreadNotifications[0];
};

const getNewAcceptedBidNotification = async ({
  notificationFeed,
  bidId,
}: {
  readonly notificationFeed: Feed;
  readonly bidId: string;
}) => {
  const unreadAcceptedBidNotifications = await fetchAllUnreadNotifications({
    notificationFeed,
    key: `acceptance-confirmation-to-lister`,
    data: { bid_id: bidId },
  });
  const unreadAcceptedCounteredBidNotifications =
    await fetchAllUnreadNotifications({
      notificationFeed,
      key: `countered-bid-acceptance-to-lister`,
      data: { bid_id: bidId },
    });

  return (
    unreadAcceptedBidNotifications[0] ||
    unreadAcceptedCounteredBidNotifications[0]
  );
};

export const maybeViewNewBidNotification = async ({
  notificationFeed,
  bidId,
}: {
  readonly notificationFeed: Feed;
  readonly bidId: string;
}) => {
  const notification = await getNewBidNotification({
    notificationFeed,
    bidId,
  });

  maybeViewNotification({ notificationFeed, notification });
};

export const maybeViewNewStandingBidNotification = async ({
  notificationFeed,
  standingBidId,
}: {
  readonly notificationFeed: Feed;
  readonly standingBidId: string;
}) => {
  const notification = await getNewStandingBidNotification({
    notificationFeed,
    standingBidId,
  });

  maybeViewNotification({ notificationFeed, notification });
};

export const maybeViewNewAcceptedBidNotification = async ({
  notificationFeed,
  bidId,
}: {
  readonly notificationFeed: Feed;
  readonly bidId: string;
}) => {
  const notification = await getNewAcceptedBidNotification({
    notificationFeed,
    bidId,
  });

  maybeViewNotification({ notificationFeed, notification });
};
