import { ParsedUrlQuery } from "node:querystring";
import { useCallback, useMemo } from "react";
import { match, P } from "ts-pattern";

import { useRouter } from "next/router";

import { Flex, StackProps, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { FilterTypes } from "@/components/companies";
import {
  Industry,
  Investor,
  useAllIndustriesQuery,
  useAllInvestorsQuery,
  useBrowseCompaniesPageCountriesQuery,
} from "@/gql";
import { useCountryList } from "@/hooks";

import { FilterTag } from "./FilterTag";

interface SelectedFiltersProps extends StackProps {
  readonly onFilterChange: (
    field: keyof FilterTypes,
    value: string | readonly string[],
    forceApply?: boolean,
  ) => void;
  readonly onResetFilters: (
    action: "ALL" | "INDUSTRIES" | "INVESTORS" | "LAST_ROUND_VALUATION",
  ) => void;
}

type CountriesById = Record<
  string,
  { readonly code: string; readonly name: string }
>;

const useCountriesById = () => {
  const countryList = useCountryList();
  const { data } = useBrowseCompaniesPageCountriesQuery();

  const countriesById: CountriesById = useMemo(
    () =>
      data?.allCompanyCountries?.reduce(
        (soFar, country) => ({
          ...soFar,
          [country.id]: {
            code: country.name,
            name: countryList.getName(country.name),
          },
        }),
        {},
      ) || {},
    [data?.allCompanyCountries, countryList],
  );

  return countriesById;
};

const formatLastRoundPostValuationRange = (query: ParsedUrlQuery) => {
  const { valuationMinUnit, valuationMaxUnit } = query;
  const valuationMin = Number(query.valuationMin) ?? null;
  const valuationMax = Number(query.valuationMax) ?? null;

  const minimumText =
    valuationMin && valuationMinUnit
      ? `$${valuationMin} ${valuationMinUnit}`
      : null;
  const maximumText =
    valuationMax && valuationMaxUnit
      ? `$${valuationMax} ${valuationMaxUnit}`
      : null;

  return match([minimumText, maximumText])
    .with([P.string, P.string], ([min, max]) => `${min} - ${max}`)
    .with([P.string, null], ([min, _]) => `> ${min}`)
    .with([null, P.string], ([_, max]) => `< ${max}`)
    .otherwise(() => ``);
};

export const SelectedFilters = ({
  onFilterChange,
  onResetFilters,
  ...stackProps
}: SelectedFiltersProps) => {
  const router = useRouter();
  const { data: industriesData } = useAllIndustriesQuery();
  const { data: investorsData } = useAllInvestorsQuery();
  const countriesById = useCountriesById();

  const selectedInvestors =
    (typeof router.query.investors === `string`
      ? [router.query.investors]
      : router.query.investors) || [];

  const selectedIndustries =
    (typeof router.query.industries === `string`
      ? [router.query.industries]
      : router.query.industries) || [];

  const selectedCountries =
    (typeof router.query.countries === `string`
      ? [router.query.countries]
      : router.query.countries) || [];

  const removeIndustry = (name: string) => {
    const nextIndustries = selectedIndustries.filter(
      (industry) => industry !== name,
    );
    onFilterChange(`industries`, nextIndustries, true);
  };

  const removeInvestor = (name: string) => {
    const nextInvestors = selectedInvestors.filter(
      (investor) => investor !== name,
    );
    onFilterChange(`investors`, nextInvestors, true);
  };

  const removeCountry = (name: string) => {
    const nextCountries = selectedCountries.filter(
      (country) => country !== name,
    );
    onFilterChange(`countries`, nextCountries, true);
  };

  const getInvestorName = useCallback(
    (investorId: string) => {
      const investors = investorsData?.allInvestors || [];
      const matchingInvestor = investors.find(
        (investor: Investor) => investor.id === investorId,
      );

      return matchingInvestor?.name || `-`;
    },
    [investorsData?.allInvestors],
  );

  const getIndustryName = useCallback(
    (industryId: string) => {
      const industries = industriesData?.allIndustries || [];
      const matchingIndustry = industries.find(
        (industry: Industry) => industry.id === industryId,
      );
      return matchingIndustry?.name || `-`;
    },
    [industriesData?.allIndustries],
  );

  const removeLastRoundPostValuation = () => {
    onResetFilters(`LAST_ROUND_VALUATION`);
  };

  const lastRoundPostValuationText = formatLastRoundPostValuationRange(
    router.query,
  );

  if (
    !selectedIndustries.length &&
    !selectedInvestors.length &&
    !selectedCountries.length &&
    !lastRoundPostValuationText
  )
    return null;

  return (
    <VStack w="full" spacing={2} align="flex-start" {...stackProps}>
      <Flex flexWrap="wrap" gap={1}>
        {selectedInvestors.map((investorId) => (
          <FilterTag
            category="Investor"
            selected
            showCloseIcon
            key={investorId}
            id={investorId}
            name={getInvestorName(investorId)}
            onClick={removeInvestor}
          />
        ))}
        {selectedIndustries.map((industryId) => (
          <FilterTag
            category="Industry"
            selected
            showCloseIcon
            key={industryId}
            id={industryId}
            name={getIndustryName(industryId)}
            onClick={removeIndustry}
          />
        ))}
        {lastRoundPostValuationText && (
          <FilterTag
            id="lastRoundPostValuation"
            category="Last Round"
            selected
            showCloseIcon
            name={lastRoundPostValuationText}
            onClick={removeLastRoundPostValuation}
          />
        )}
        {selectedCountries.map((countryId) => (
          <FilterTag
            category="Country"
            selected
            showCloseIcon
            key={countryId}
            id={countryId}
            name={countriesById[countryId]?.name || `-`}
            onClick={removeCountry}
          />
        ))}
      </Flex>
      <HiiveButton
        variant="text-salmon"
        h="auto"
        px={0}
        py={1}
        onClick={() => onResetFilters(`ALL`)}
        observabilityLabel="[SelectedFilters] Clear"
      >
        Clear all
      </HiiveButton>
    </VStack>
  );
};
