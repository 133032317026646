import { Card, CardBody, CardHeader } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";
import { CompanyPriceGraphSkeleton } from "@/components/companies";

const CompanyHistoricalPricingCardSkeleton = () => (
  <Card>
    <CardHeader>
      <Skeleton h="20px" w="50%" />
    </CardHeader>
    <CardBody>
      <CompanyPriceGraphSkeleton />
    </CardBody>
  </Card>
);

export default CompanyHistoricalPricingCardSkeleton;
