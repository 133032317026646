import { ActivityCardPropertyPill } from "@/components/common";
import { useIsDesktop } from "@/hooks";

export const CompanyCardHeaderStringPill = ({
  value,
  title,
}: {
  readonly value: string;
  readonly title: string;
}) => {
  const isDesktop = useIsDesktop();

  const props = isDesktop
    ? {
        title,
        value,
      }
    : {
        value,
        py: `6px`,
        w: 90,
      };

  return <ActivityCardPropertyPill {...props} />;
};
