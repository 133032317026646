import {
  ActionsTileButtonWrapper,
  HiiveButton,
  InternalLink,
  Tile,
  TileHeader,
} from "@/components/common";
import { PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment } from "@/gql";
import { useModal } from "@/hooks";
import { getAvailableListingActions } from "@/utils";

interface ListingActionsTileProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardPageActiveBidsPageListingFragment;
}

export const ListingActionsTile = ({ listing }: ListingActionsTileProps) => {
  const { modals, onOpenModal } = useModal();

  const { canWithdrawListing, canModifyListing } =
    getAvailableListingActions(listing);

  return (
    <Tile bg="white" justifyContent="space-between" w="full">
      <TileHeader whiteSpace="nowrap">Adjust your listing</TileHeader>
      <ActionsTileButtonWrapper>
        {canModifyListing && (
          <InternalLink href="/modify-listing">
            <HiiveButton
              size="md"
              maxW="unset"
              variant="rounded-outline-grey"
              observabilityLabel="[UnaccreditedSellerListingActions] Modify Listing"
            >
              Modify
            </HiiveButton>
          </InternalLink>
        )}

        {canWithdrawListing && (
          <HiiveButton
            size="md"
            maxW="unset"
            variant="rounded-outline-grey"
            onClick={onOpenModal(
              modals.unaccreditedSellerWithdrawListing(listing),
            )}
            observabilityLabel="[UnaccreditedSellerListingActions] Withdraw Listing"
          >
            Withdraw
          </HiiveButton>
        )}
      </ActionsTileButtonWrapper>
    </Tile>
  );
};
