import * as React from "react";
import { useTranslation } from "react-i18next";

import { ModalBody, VStack, Text } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";

type TransactionModificationModalProps = {
  readonly name: string;
  readonly title: string;
  readonly description: string | React.ReactElement;
  readonly proposedUpdate: string;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

const TransactionModificationModal = ({
  name,
  title,
  description,
  proposedUpdate,
  onCancel,
  onConfirm,
}: TransactionModificationModalProps) => {
  const { t } = useTranslation();
  return (
    <HiiveModalContentWrapper>
      <HiiveModalHeader>{name}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="start">
          <Text textStyle="heading-lg" mb={2}>
            {title}
          </Text>
          <Text>{description}</Text>
          <Text>{proposedUpdate}</Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveCancelButton
          observabilityLabel="[TransactionModificationModal/Cancel]"
          onCancel={onCancel}
          title={t`cancel`}
        />
        <HiiveSubmitButton
          observabilityLabel="[TransactionModificationModal/Submit]"
          submitText={t`confirm`}
          onClick={onConfirm}
        />
      </HiiveModalFooter>
    </HiiveModalContentWrapper>
  );
};

export default TransactionModificationModal;
