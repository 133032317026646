import { useTranslation } from "react-i18next";

import { Box, SimpleGrid, VStack } from "@chakra-ui/react";

import {
  FeeDiscountAlert,
  FullContentWrapper,
  MobileTab,
  MobileTabList,
  MobileTabPanel,
  MobileTabPanels,
  MobileTabs,
  WithQuery,
} from "@/components/common";
import { CompanyPageContentCompanyV2Fragment } from "@/gql";
import { useCompanySlugOrRedirect, useIsDesktop } from "@/hooks";

import { CompanyActionsV2 as CompanyActions } from "./CompanyActions";
import { CompanyActivity } from "./CompanyActivityV2";
import { CompanyInfo } from "./CompanyInfoV2";
import { MarketActivityV2 as MarketActivity } from "./MarketActivity";
import { YourActivity } from "./YourActivityV2";
import useFeeDiscountAlert from "./useFeeDiscountAlert";

type MobileCompanyPageProps = {
  readonly slugOrId: string;
};

type MobileCompanyPageContentProps = {
  readonly company: CompanyPageContentCompanyV2Fragment;
};

type DesktopCompanyPageContentProps = {
  readonly company: CompanyPageContentCompanyV2Fragment;
};

function DesktopCompanyPageContent({
  company: { id: companyId },
}: DesktopCompanyPageContentProps) {
  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
      <CompanyInfo companyId={companyId} />
      <CompanyActivity companyId={companyId} />
    </SimpleGrid>
  );
}

function DesktopCompanyPage({ slugOrId }: { readonly slugOrId: string }) {
  const query = useCompanySlugOrRedirect(slugOrId);

  return (
    <WithQuery query={query}>
      {({ data: { company } }) => (
        <DesktopCompanyPageContent company={company} />
      )}
    </WithQuery>
  );
}

function MobileCompanyPageContent({ company }: MobileCompanyPageContentProps) {
  const { id: companyId, name: companyName } = company;

  const { t } = useTranslation(`company`);

  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <>
      {feeDiscountAlertType && (
        <Box mb={4}>
          <FeeDiscountAlert
            companyName={companyName}
            feeDiscount={feeDiscount}
            type={feeDiscountAlertType}
          />
        </Box>
      )}
      <MobileTabs>
        <MobileTabList>
          <MobileTab>{t(`overview`)}</MobileTab>
          <MobileTab>{t(`your_activity`)}</MobileTab>
          <MobileTab>{t(`market_activity`)}</MobileTab>
        </MobileTabList>
        <MobileTabPanels>
          <MobileTabPanel>
            <CompanyInfo companyId={companyId} />
          </MobileTabPanel>
          <MobileTabPanel>
            <VStack spacing={8}>
              <CompanyActions companyId={companyId} />
              <YourActivity companyId={companyId} />
            </VStack>
          </MobileTabPanel>
          <MobileTabPanel>
            <MarketActivity companyId={companyId} />
          </MobileTabPanel>
        </MobileTabPanels>
      </MobileTabs>
    </>
  );
}

function MobileCompanyPage({ slugOrId }: MobileCompanyPageProps) {
  const query = useCompanySlugOrRedirect(slugOrId);

  return (
    <WithQuery query={query}>
      {({ data: { company } }) => (
        <MobileCompanyPageContent company={company} />
      )}
    </WithQuery>
  );
}

export const CompanyPageContent = ({
  slugOrId,
}: {
  readonly slugOrId: string;
}) => {
  const isDesktopView = useIsDesktop();

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <Box w="full" maxW="max-width-md">
        {isDesktopView ? (
          <DesktopCompanyPage slugOrId={slugOrId} />
        ) : (
          <MobileCompanyPage slugOrId={slugOrId} />
        )}
      </Box>
    </FullContentWrapper>
  );
};
