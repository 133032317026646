import currency from "currency.js";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  GridItem,
  HStack,
  Radio,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import { HiiveSubmitButton } from "@/components/common";
import {
  CheckboxInput,
  FormNumberInput,
  MoneyInput,
  StepPropsV2,
  FormRadioInput,
} from "@/components/form";
import { bidTimeLimitSchema } from "@/components/postings";
import { ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragment } from "@/gql";
import { useStepValidator } from "@/hooks";
import {
  bidLabel,
  constants,
  fromBool,
  getNumSharesAvailableRounded,
  toBool,
  getMinOrMaxPPSValidationErrorMessage,
} from "@/utils";

import { SubmitBidCardStepFormContext } from "./SubmitBidCardStepFormContext";
import { stepKeys } from "./steps";
import { SubmitBidCardFormValues, StepKeys } from "./types";

const createValidationSchema = ({
  listing,
}: {
  readonly listing?: ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragment;
}) => {
  const minPostPps = (listing?.company.minPostPps || 0) / 100;
  const maxPostPps = (listing?.company.maxPostPps || 0) / 100;

  return Yup.object().shape({
    pricePerShare: Yup.number()
      .nullable()
      .when([], {
        is: () => !!listing?.company.minPostPps,
        then: Yup.number().min(
          minPostPps,
          getMinOrMaxPPSValidationErrorMessage(
            listing?.company.minPostPps,
            listing?.company.maxPostPps,
          ),
        ),
      })
      .when([], {
        is: () => !!listing?.company.maxPostPps,
        then: Yup.number().max(
          maxPostPps,
          getMinOrMaxPPSValidationErrorMessage(
            listing?.company.minPostPps,
            listing?.company.maxPostPps,
          ),
        ),
      })
      .required(`Price per share is required`),
    numShares: Yup.number()
      .test(
        `min-bid-size`,
        `The bid size can't be less than ${currency(
          constants.min_bid_lot.number,
        ).format()}`,
        function test(value: number) {
          const { pricePerShare } = this.parent;

          return value * pricePerShare >= constants.min_bid_lot.number;
        },
      )
      .max(
        listing ? getNumSharesAvailableRounded(listing) : 0,
        `Can't request more shares than a listing has`,
      )
      .required(`Required`),
    timeLimit: bidTimeLimitSchema,
    unsolicited: Yup.boolean().nullable().required(`Required`),
    affiliate: Yup.boolean().nullable().required(`Required`),
    confirmed: Yup.boolean().oneOf([true], `Required`),
  });
};

interface SubmitBidProps
  extends StepPropsV2<StepKeys, SubmitBidCardFormValues> {
  readonly listing: ListingPageBrokerCounterpartyActivitySubmitBidCardSubmitBidListingFragment;
}

const SubmitBid = ({
  listing,
  stepRouter,
  values,
  isSubmitting,
}: SubmitBidProps) => {
  const { t } = useTranslation();

  const validationSchema = createValidationSchema({
    listing,
  });

  useStepValidator({
    Context: SubmitBidCardStepFormContext,
    stepKey: stepKeys.submitBidFormCard,
    validator: {
      validationSchema,
      onSuccess: stepRouter.stepControls.nextStep,
    },
    values,
  });

  return (
    <Card variant="darkened-footer" data-testid="submit-bid-card">
      <CardHeader py={4}>
        <Text textStyle="heading-2xl">{t(`submit_a_bid`)}</Text>
      </CardHeader>
      <CardBody>
        <Text mb={4}>{t(`broker_submit_bid_body`)}</Text>
        <SimpleGrid columns={2} columnGap={9} rowGap={6}>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormNumberInput
              name="numShares"
              label={t(`number_of_shares`) as string}
              readOnly={!listing.acceptPartialBid}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <MoneyInput name="pricePerShare" label={t(`price_per_share`)} />
          </GridItem>
          <GridItem colSpan={2}>
            <FormNumberInput
              name="timeLimit"
              label={
                t(`bid_valid_for_days`, {
                  bidLabel: bidLabel(listing),
                }) as string
              }
            />
          </GridItem>
          <GridItem colSpan={2}>
            <FormRadioInput
              label={t(`broker_submit_bid_affiliate_question`, {
                companyName: listing.company.name,
              })}
              name="affiliate"
              mapper={{ from: fromBool, to: toBool }}
              data-testid="broker-affiliate-bid-radio-button"
            >
              <HStack spacing={12}>
                <Radio variant="base" value="true">
                  Yes
                </Radio>
                <Radio variant="base" value="false">
                  No
                </Radio>
              </HStack>
            </FormRadioInput>
          </GridItem>
          <GridItem colSpan={2}>
            <FormRadioInput
              label={t(`broker_submit_bid_unsolicited_question`)}
              name="unsolicited"
              mapper={{ from: fromBool, to: toBool }}
              data-testid="broker-solicited-bid-radio-button"
            >
              <HStack spacing={12}>
                <Radio variant="base" value="true">
                  Yes
                </Radio>
                <Radio variant="base" value="false">
                  No
                </Radio>
              </HStack>
            </FormRadioInput>
          </GridItem>
        </SimpleGrid>
      </CardBody>
      <CardBody>
        <GridItem colSpan={2}>
          <CheckboxInput
            name="confirmed"
            label={t(`broker_submit_bid_acknowledgement`)}
          />
        </GridItem>
      </CardBody>
      <CardFooter py={6}>
        <HStack justifyContent="flex-end" w="full">
          <HiiveSubmitButton
            observabilityLabel="[SubmitBid/Submit]"
            isLoading={isSubmitting}
            submitText={t(`continue`)}
            type="submit"
          />
        </HStack>
      </CardFooter>
    </Card>
  );
};

export default SubmitBid;
