import { ReactElement } from "react";

import { Text } from "@chakra-ui/react";

import { MailtoLink, PleaseNote } from "@/components/common";
import { UnauthorizedModalBodyWrapper } from "@/components/modals";
import {
  CompanyPermission,
  CreateListingSelectCompanyInputCompanyFragment,
} from "@/gql";
import { iHaveEntityPermission } from "@/hooks";
import { getSecuritySpecialistContact } from "@/utils";

const CreateListingNotTradable = ({
  company,
}: {
  readonly company: CreateListingSelectCompanyInputCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <PleaseNote>
      <Text textStyle="text-lg">
        Automated listing for{` `}
        {company.name}
        {` `}is not currently enabled. However, you may still create a listing
        {` `}
        for {company.name}
        {` `}by contacting our{` `}
        <Text textStyle="heading-lg" as="span">
          {company.name} Security Specialist at{` `}
          <MailtoLink email={securitySpecialistEmail} textStyle="heading-lg">
            {securitySpecialistEmail}
          </MailtoLink>
        </Text>
      </Text>
    </PleaseNote>
  );
};
interface CreateListingSequenceModalProps {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
  readonly children: ReactElement;
}

export const CreateListingSequenceModalAuthorization = ({
  initialCompany,
  children,
}: CreateListingSequenceModalProps): ReactElement => {
  if (
    !!initialCompany &&
    !iHaveEntityPermission(initialCompany, CompanyPermission.CreateListing)
  )
    return (
      <UnauthorizedModalBodyWrapper
        title="Make a Listing"
        observabilityLabelPrefix="CreateListing/NotTradable"
      >
        <CreateListingNotTradable company={initialCompany} />
      </UnauthorizedModalBodyWrapper>
    );

  return children;
};
