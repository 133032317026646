import { useTranslation } from "react-i18next";

import { CardHeader, HStack, Text, Box } from "@chakra-ui/react";

import { ActivityCardBadge } from "@/components/common";
import { CompanyCardHeaderStringPill } from "@/components/companies";
import { CompanyStandingBidCardHeaderStandingBidFragment } from "@/gql";
import { useIsDesktop } from "@/hooks";
import {
  acceptsSharesToString,
  gqlTransferMethodOptionsToTemp,
  standingBidAcceptsTransferMethodOptionsToString,
  TempStandingBidAcceptsTransferMethodsOptions,
} from "@/utils/translate";

const CompanyStandingBidCardHeaderV2 = ({
  standingBid,
}: {
  readonly standingBid: CompanyStandingBidCardHeaderStandingBidFragment;
}) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation(`company`);
  const { displayId, acceptsShares, acceptsTransferMethods } = standingBid;

  const acceptsTransferMethodsToString = (
    acceptsShares: readonly TempStandingBidAcceptsTransferMethodsOptions[],
  ): string =>
    acceptsShares?.length > 2
      ? t`any`
      : acceptsShares
          .reduce((acc: readonly string[], tm) => {
            const str = standingBidAcceptsTransferMethodOptionsToString(tm);
            return acc.includes(str) ? acc : [str, ...acc];
          }, [])
          .join(`, `);

  return (
    <CardHeader py={2} px={3}>
      <HStack justifyContent="space-between">
        <HStack w="full">
          <ActivityCardBadge variant="bid" title={t`standing_bid`} minW={126} />
          {isDesktop && (
            <Text textStyle="text-lg" color="grey.900">
              {displayId}
            </Text>
          )}
          <Box flexGrow={1} />
          <CompanyCardHeaderStringPill
            title={t(`transfer_method`)}
            value={acceptsTransferMethodsToString(
              gqlTransferMethodOptionsToTemp(acceptsTransferMethods),
            )}
          />
          <CompanyCardHeaderStringPill
            title={t(`share_type`)}
            value={acceptsSharesToString(acceptsShares)}
          />
        </HStack>
      </HStack>
    </CardHeader>
  );
};

export default CompanyStandingBidCardHeaderV2;
