import { Box, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { InquiryEntityPostingCardFragment } from "@/gql";

import { useDiscussionStatusText } from "./useDiscussionStatusText";

type InquiryEntityPostingCardFooterProps = {
  discussion: InquiryEntityPostingCardFragment;
};

export function InquiryEntityPostingCardFooter({
  discussion: { state, numUnreadMessages, totalNumMessages },
}: InquiryEntityPostingCardFooterProps) {
  const discussionStatusText = useDiscussionStatusText(state);

  const hasMessages = totalNumMessages > 0;

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm">{discussionStatusText}</Text>
      <Box flexGrow={1} />
      {hasMessages && (
        <MessagesBadge
          numUnreadMessages={numUnreadMessages}
          totalNumMessages={totalNumMessages}
        />
      )}
    </PostingCard.Footer>
  );
}
