import { motion, AnimatePresence } from "framer-motion";
import { ReactElement, useState } from "react";

import { Box, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

interface CollapsibleInlineTextProps {
  readonly collapsedText: string | ReactElement;
  readonly expandedText: ReactElement;
  readonly label: { readonly collapsed: string; readonly expanded: string };
}

const appendDots = (text: string | ReactElement) => {
  if (typeof text !== `string`) {
    return `..`;
  }
  const hasPeriod = text.charAt(text.length - 1) === `.`;
  return hasPeriod ? `..` : `...`;
};

const Ellipsis = ({
  collapsedText,
}: {
  readonly collapsedText: string | ReactElement;
}) => {
  const dots = appendDots(collapsedText);

  return (
    <Text align="left" as="span" lineHeight="inherit" textStyle="text-xs">
      {dots}
    </Text>
  );
};

const CollapsibleInlineText = ({
  collapsedText,
  expandedText,
  label,
}: CollapsibleInlineTextProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const collapseAnimation = {
    collapsed: { opacity: 0, width: 0 },
    expanded: { opacity: 1, width: `auto` },
  };

  return (
    <Box lineHeight={1}>
      <Text align="left" as="span" lineHeight="inherit" textStyle="text-xs">
        {collapsedText}
      </Text>

      {!isExpanded && <Ellipsis collapsedText={collapsedText} />}

      {` `}
      <AnimatePresence>
        {isExpanded && (
          <motion.span
            animate="expanded"
            exit="collapsed"
            initial="collapsed"
            transition={{ duration: 0.15 }}
            variants={collapseAnimation}
          >
            {expandedText}
          </motion.span>
        )}
      </AnimatePresence>
      {` `}
      <HiiveButton
        alignSelf="left"
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        variant="text-ghost"
        observabilityLabel="[CollapsibleInlineText] Toggle"
      >
        <Text as="span" fontWeight="medium" textStyle="text-xs">
          {isExpanded ? label.expanded : label.collapsed}
        </Text>
      </HiiveButton>
    </Box>
  );
};

export default CollapsibleInlineText;
