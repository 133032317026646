import { useMemo } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { useNumericFormat } from "react-number-format";

import {
  InputProps,
  NumberInput as ChakraNumberInput,
  NumberInputField,
} from "@chakra-ui/react";

const validationRegex = /^[0-9+\-.]$/;
const nonNegativeValidationRegex = /^[0-9+.]$/;

type NumberInputProps<TFieldValues extends FieldValues> = InputProps &
  ControllerRenderProps<TFieldValues, Path<TFieldValues>> & {
    readonly defaultValue?: string | number;
    readonly step?: number;
    readonly min?: number;
    readonly max?: number;
    readonly allowNegativeNumbers?: boolean;
  };

function normalizeNumberInput(value: string | number | undefined) {
  if (!value) {
    return undefined;
  }

  if (typeof value === `number`) {
    return value.toString();
  }

  return value;
}

export const NumberInput = <TFieldValues extends FieldValues>({
  allowNegativeNumbers,
  value: inputValue,
  onChange,
  ...numberInputProps
}: NumberInputProps<TFieldValues>) => {
  const { format, removeFormatting } = useNumericFormat({
    thousandSeparator: `,`,
  });

  const value = useMemo(() => {
    const normalizedValue = normalizeNumberInput(inputValue);

    if (!format) {
      return normalizedValue;
    }

    return normalizedValue ? format(normalizedValue) : normalizedValue;
  }, [inputValue, format]);

  return (
    <ChakraNumberInput
      {...numberInputProps}
      value={value}
      onChange={(value) => onChange(removeFormatting?.(value) || value)}
      onInvalid={undefined}
      inputMode="numeric"
      isValidCharacter={(value: string) =>
        allowNegativeNumbers
          ? validationRegex.test(value)
          : nonNegativeValidationRegex.test(value)
      }
    >
      <NumberInputField placeholder={numberInputProps.placeholder} />
    </ChakraNumberInput>
  );
};
