import { tableAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  Table as TableComponent,
} from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
);

const Table = defineMultiStyleConfig(
  defineMultiStyleConfig({
    defaultProps: {
      ...TableComponent.defaultProps,
      variant: `base`,
    },
    variants: {
      base: {
        thead: {
          tr: {
            borderBottomWidth: `1px`,
            borderColor: `grey.200`,
            borderStyle: `solid`,
            th: {
              bg: `grey.25`,
              color: `grey.800`,
              textTransform: `none`,
              fontWeight: 500,
              fontSize: 12,
              py: `20px`,
            },
          },
        },
        tbody: {
          bg: `white`,
          tr: {
            borderBottomWidth: `1px`,
            borderColor: `grey.200`,
            borderStyle: `solid`,
            td: {
              py: `20px`,
            },
            ":last-of-type": {
              border: `none`,
            },
          },
        },
      },
    },
  }),
);

export default Table;
