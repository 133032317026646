/* eslint-disable i18next/no-literal-string */
import { useTranslation } from "react-i18next";

import { NextRouter, useRouter } from "next/router";

import { Button, Flex, Text } from "@chakra-ui/react";

import { Loader } from "@/components/common";
import { NotFoundError } from "@/components/error";
import { withCurrentActor } from "@/components/hoc";
import {
  AcknowledgmentAndConsentPage,
  AwaitingApprovalPage,
  BrokerInfoPage,
  BrokerIntermediaryInfoPage,
  ClickwrapAgreementPage,
  CustomerAgreementPage,
  HoldingDetailsPage,
  InstitutionInfoPage,
  InstitutionIsBrokerPage,
  InvestingGoalsPage,
  InvestorAccreditationPageV2,
  InvestorStatusPageV2,
  InvestorSuitabilityPageV2,
  InvestorTypePage,
  RequestEmailVerificationPage,
  ResidencePage,
  SellerInfoGuidePage,
  VerifyIdentityPage,
  WatchlistPage,
  UserIsHeldFromPlatformPage,
  OnboardingInfoMessageProvider,
  TrustedContactPage,
} from "@/components/onboarding-v2";
import {
  OnboardingStep,
  UserRole,
  UserWithInstitutionFragment,
  useTransitionBackToPreviousStepMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError, useSignOut } from "@/hooks";
import { useSuitabilityUpfront } from "@/hooks/featureFlags";

const TemporaryLogoutPage = () => {
  const actor = useCurrentActor();
  const signOut = useSignOut();

  const [transitionBackToPreviousStepMutation] = useMutationWithError(
    useTransitionBackToPreviousStepMutation(),
    `transitionBackToPreviousStep`,
  );

  const onBack = () => transitionBackToPreviousStepMutation();

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      h="100vh"
      gap={6}
      pb={48}
      px={8}
    >
      <Text textStyle="heading-lg">
        Current Step: <strong>{actor.onboarding?.currentStep}</strong>
      </Text>
      <Text>V2 onboarding page not yet implemented.</Text>
      <Flex
        justifyContent="center"
        w="full"
        gap={4}
        direction={{ base: `column-reverse`, md: `row` }}
      >
        <Button
          w={{ base: `full`, md: `auto` }}
          maxW="auto"
          variant="rounded-outline-grey"
          size="xl"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          w={{ base: `full`, md: `auto` }}
          maxW="auto"
          variant="rounded-solid-salmon"
          size="xl"
          onClick={() => signOut()}
        >
          Sign Out
        </Button>
      </Flex>
    </Flex>
  );
};

const getCurrentOnboardingComponent = (
  currentOnboardingStep: OnboardingStep | null | undefined,
  router: NextRouter,
) => {
  switch (currentOnboardingStep) {
    case OnboardingStep.InvestorStatus:
      return <InvestorStatusPageV2 />;
    case OnboardingStep.Residence:
      return <ResidencePage />;
    case OnboardingStep.InvestorType:
      return <InvestorTypePage />;
    case OnboardingStep.SellerInfoGuide:
      return <SellerInfoGuidePage />;
    case OnboardingStep.Accreditation:
      return <InvestorAccreditationPageV2 />;
    case OnboardingStep.Watchlist:
      return <WatchlistPage />;
    case OnboardingStep.ClickwrapAgreement:
      return <ClickwrapAgreementPage />;
    case OnboardingStep.HoldingDetails:
      return <HoldingDetailsPage data-dd-privacy="mask" />;
    case OnboardingStep.Suitability:
      return <InvestorSuitabilityPageV2 />;
    case OnboardingStep.InstitutionInfo:
      return <InstitutionInfoPage />;
    case OnboardingStep.InstitutionIsBroker:
      return <InstitutionIsBrokerPage />;
    case OnboardingStep.VerifyEmail:
      return <RequestEmailVerificationPage />;
    case OnboardingStep.BrokerInfo:
      return <BrokerInfoPage />;
    case OnboardingStep.BrokerIntermediaryInfo:
      return <BrokerIntermediaryInfoPage />;
    case OnboardingStep.VerifyIdentity:
      return <VerifyIdentityPage />;
    case OnboardingStep.InvestingGoals:
      return <InvestingGoalsPage />;
    case OnboardingStep.TrustedContactPerson:
      return <TrustedContactPage />;
    case OnboardingStep.CustomerAgreement:
      return <CustomerAgreementPage />;
    case OnboardingStep.Acknowledgement:
      return <AcknowledgmentAndConsentPage />;
    // TODO: what's the difference?
    case OnboardingStep.AwaitingApproval:
    case OnboardingStep.NeedsFurtherVerification:
      return <AwaitingApprovalPage />;
    case null:
      // user has completed onboarding
      router.push(`/dashboard`);
      return <Loader />;
    default:
      // TODO: replace with <NotFoundError /> on SUP completion
      return <TemporaryLogoutPage />;
  }
};

const WelcomePage = withCurrentActor(
  ({ actor }: { actor: UserWithInstitutionFragment }) => {
    const isSUPEnabled = useSuitabilityUpfront();
    const router = useRouter();
    const { t } = useTranslation(`onboarding`, {
      keyPrefix: `awaiting_approval`,
    });
    const currentOnboardingStep = actor.onboarding?.currentStep;

    const isAdmin = actor.roles.includes(UserRole.Admin);

    const institutionMissingInformation =
      actor?.institution?.onboarding.reonboardingStartedAt;

    const adminWorkRequired =
      institutionMissingInformation && !isAdmin && !currentOnboardingStep;

    const currentOnboardingComponent = getCurrentOnboardingComponent(
      currentOnboardingStep,
      router,
    );

    if (!isSUPEnabled) return <NotFoundError />;

    if (adminWorkRequired) {
      return (
        <UserIsHeldFromPlatformPage
          metaTitle={t(`missing_profile_info`)}
          header={t(`firm_missing_information`)}
          body={t(`seek_admin_complete_information`)}
        />
      );
    }

    return (
      <OnboardingInfoMessageProvider actor={actor}>
        {currentOnboardingComponent}
      </OnboardingInfoMessageProvider>
    );
  },
);

export default WelcomePage;
