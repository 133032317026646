import { MagnifyingGlass, X } from "@phosphor-icons/react";
import { useEffect, useMemo, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  FormLabelProps,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import { CompanyCombobox } from "@/components/companies";
import { Combobox } from "@/components/form";
import {
  ListCompaniesOrderBy,
  FormCompanyComboboxListCompaniesCompanyFragment,
  useFormCompanyComboboxListCompaniesLazyQuery,
  CompanyPermission,
} from "@/gql";
import {
  iHaveEntityPermission,
  useColors,
  useCombobox,
  useDebounce,
} from "@/hooks";

type FormCompaniesComboboxProps<TFieldValues extends FieldValues> = {
  readonly name: Path<TFieldValues>;
  readonly placeholder?: string;
  readonly label: string;
  readonly labelSrOnly?: FormLabelProps[`srOnly`];
  readonly description?: string;
  readonly isDisabled?: boolean;
  readonly control: Control<TFieldValues>;
  readonly showSearchIcon?: boolean;
  readonly showClearIcon?: boolean;
};

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

const itemToString = (
  company: FormCompanyComboboxListCompaniesCompanyFragment,
) => company.name;

const FormCompaniesCombobox = <TFieldValues extends FieldValues>({
  control,
  description,
  placeholder,
  isDisabled,
  label,
  name,
  labelSrOnly,
  showSearchIcon,
  showClearIcon,
}: FormCompaniesComboboxProps<TFieldValues>) => {
  const { t } = useTranslation();
  const { debounce, isDebouncing } = useDebounce();

  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control, disabled: isDisabled });
  const [search, setSearch] = useState<string>(``);

  const [grey500] = useColors([`grey.500`]);

  const [loadCompanies, { data, loading }] =
    useFormCompanyComboboxListCompaniesLazyQuery({
      fetchPolicy: `no-cache`,
    });

  useEffect(() => {
    loadCompanies({
      variables: getVariables(``),
    });
  }, []);

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    debounce(() =>
      loadCompanies({
        variables: getVariables(search),
      }),
    );
  };

  const isLoading = loading || isDebouncing;

  const companyEdges = data?.listCompanies?.edges || [];

  const items = useMemo(
    () =>
      companyEdges.flatMap((edge) => {
        if (
          !edge ||
          !edge.node ||
          !iHaveEntityPermission(edge.node, CompanyPermission.PlaceStandingBid)
        )
          return [];

        return [edge.node];
      }),
    [companyEdges],
  );

  const { inputProps, menuProps, labelProps, itemProps, actions } = useCombobox(
    {
      items,
      itemToString: (item: FormCompanyComboboxListCompaniesCompanyFragment) =>
        item.name,
      getItemKey: (item: FormCompanyComboboxListCompaniesCompanyFragment) =>
        item.id,
      onSelectItem: (item) => {
        if (!item) return;
        actions.blur();
        onChange(item);
        handleChangeSearch(itemToString(item));
      },
      onChangeInputValue: handleChangeSearch,
      selectedItem: value,
      inputValue: search,
      isLoading,
    },
  );
  const selectedCompany = value;

  return (
    <FormControl id={name} isInvalid={invalid}>
      <Combobox.Label srOnly={labelSrOnly} {...labelProps}>
        {label}
      </Combobox.Label>
      {description && <Combobox.Description description={description} />}
      <Combobox.Container>
        <InputGroup>
          {selectedCompany && selectedCompany.logoUrl && (
            <InputLeftElement pointerEvents="none">
              <CompanyCombobox.SelectedIcon company={selectedCompany} />
            </InputLeftElement>
          )}
          {showSearchIcon && !value && (
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlass
                size={20}
                color={grey500}
                opacity={isDisabled ? `.5` : ``}
              />
            </InputLeftElement>
          )}
          <Combobox.Input
            isDisabled={isDisabled}
            placeholder={placeholder || t`search_company_name`}
            onBlur={onBlur}
            name={name}
            ref={ref}
            userSelect={isDisabled ? `none` : `auto`}
            {...inputProps}
          />
          {showClearIcon && !!search && (
            <InputRightElement>
              <IconButton
                /* eslint-disable-next-line i18next/no-literal-string */
                aria-label="Clear-Input"
                onClick={() => {
                  onChange(null);
                  setSearch(``);
                }}
                variant="icon-close"
                icon={
                  <X
                    size={20}
                    color={grey500}
                    opacity={isDisabled ? `.5` : ``}
                  />
                }
              />
            </InputRightElement>
          )}
        </InputGroup>
        <Combobox.Menu
          isLoading={isLoading}
          fallback={<CompanyCombobox.Skeleton />}
          maxH={242}
          isLazy={false}
          {...menuProps}
        >
          {items.map((item, index) => (
            <CompanyCombobox.Item
              key={item?.id}
              item={item!}
              index={index}
              role="option"
              {...itemProps}
            >
              <CompanyCombobox.ItemIcon company={item!} />
              <Text as="span">{item?.name}</Text>
            </CompanyCombobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormCompaniesCombobox;
