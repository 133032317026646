import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  FormLabel,
  GridItem,
  ModalBody,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  FullDivider,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { CheckboxInput, EmailInput, FormikQL } from "@/components/form";
import {
  InstitutionInvitationsDocument,
  InvitationType,
  RoleType,
  SendInvitationInput,
  useSendInvitationMutation,
} from "@/gql";
import {
  CustomEvents,
  useCustomEvent,
  useCustomToast,
  useModal,
} from "@/hooks";
import { Nullable, constants } from "@/utils";

import { RoleInput } from "./RoleInput";

const { EMAIL_REGEX, EMAIL_MAX_LEN } = constants;
interface InviteUserFormValues {
  readonly email: string;
  readonly role: RoleType;
  readonly signer: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .required(`Required`)
    .matches(EMAIL_REGEX, `Invalid email address`)
    .max(EMAIL_MAX_LEN, `Must be at most ${EMAIL_MAX_LEN} characters`),
  role: Yup.string().nullable().required(`Required`),
  signer: Yup.boolean().nullable(),
});

const mapVariables = ({
  signer,
  role,
  email,
}: InviteUserFormValues): { input: SendInvitationInput } => {
  const roles = signer ? [role, RoleType.Signer] : [role];
  return {
    input: {
      email,
      roles,
      invitationType: InvitationType.Institution,
    },
  };
};

const initialValues: Nullable<InviteUserFormValues> = {
  email: ``,
  role: null,
  signer: false,
};

const InviteInstitutionUserModal = () => {
  const { dispatch } = useCustomEvent();
  const { t } = useTranslation();

  const mutation = useSendInvitationMutation({
    refetchQueries: [InstitutionInvitationsDocument],
  });

  const { closeModal } = useModal();

  const { successToast } = useCustomToast();
  const onSuccess = () => {
    closeModal();
    successToast(`Invitation sent!`);
    dispatch(CustomEvents.UserInvitationSent);
  };

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`sendInvitation`]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <HiiveModalHeader>{t(`invite_user`)}</HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
                <GridItem colSpan={2}>
                  <EmailInput name="email" label={t(`email_to`)} type="email" />
                  <Text mt={4}>{t(`invite_user_description`)}</Text>
                  <Text mt={4}>{t(`invite_user_existing_account`)}</Text>
                </GridItem>

                <GridItem colSpan={2}>
                  <FullDivider />
                </GridItem>

                <GridItem colSpan={2}>
                  <RoleInput />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormLabel mb={2.5}>{t(`make_user_signer`)}</FormLabel>
                  <CheckboxInput
                    name="signer"
                    align="flex-start"
                    label={
                      <Text textStyle="text-md">
                        {t(`make_user_signer_description`)}
                      </Text>
                    }
                  />
                </GridItem>
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <ButtonFooterWrapper>
                <HiiveCancelButton
                  onCancel={closeModal}
                  observabilityLabel="[InviteUser/Cancel]"
                />
                <HiiveSubmitButton
                  submitText="Invite"
                  type="submit"
                  observabilityLabel="[InviteUser/Submit]"
                  isLoading={isSubmitting}
                />
              </ButtonFooterWrapper>
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default InviteInstitutionUserModal;
