/* eslint-disable consistent-return */
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { FlowKind, ModalKind } from "@/components/providers";
import {
  PlaceBidSequenceModalPlaceBidSuccessBidFragment,
  TransferMethod,
} from "@/gql";
import { useFlowLauncher, useModal } from "@/hooks";
import { constants, getAvailableCompanyActions, isHiiveFund } from "@/utils";

import { usePlaceBidSequenceModalStepFormContext } from "./PlaceBidSequenceModalStepFormContext";

const PlaceBidHiiveFundTranslations = {
  title: `place_bid_hiive_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_hiive_success_body`,
  contactUs: `place_bid_hiive_success_contact_us`,
} as const;

const PlaceBidFundTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_success_body`,
  contactUs: `place_bid_hiive_success_contact_us`,
} as const;

const PlaceBidTranslations = {
  title: `place_bid_success_title`,
  heading: `place_bid_success_heading`,
  body: `place_bid_success_body`,
  contactUs: `place_bid_success_contact_us`,
} as const;

const getPlaceBidSuccessModalTranslationKeys = (
  listing: PlaceBidSequenceModalPlaceBidSuccessBidFragment["listing"],
) =>
  match({ method: listing.transferMethod })
    .with(
      { method: TransferMethod.HiiveFund },
      () => PlaceBidHiiveFundTranslations,
    )
    .with({ method: TransferMethod.Fund }, () => PlaceBidFundTranslations)
    .otherwise(() => PlaceBidTranslations);

const getContactUsComponent = (
  listing: PlaceBidSequenceModalPlaceBidSuccessBidFragment["listing"],
) => {
  if (isHiiveFund(listing)) {
    return <MailtoLink fontWeight="medium" email={constants.email_funds} />;
  }
  return (
    <SecuritySpecialistLink key={listing.displayId} company={listing.company} />
  );
};

const PlaceBidSuccess = () => {
  const { mutationData } = usePlaceBidSequenceModalStepFormContext();
  const { closeModal, addModalCloseHandler, removeModalCloseHandler } =
    useModal();
  const { t } = useTranslation();

  const { showFlow } = useFlowLauncher();

  const bid = mutationData?.placeBid.bid;
  const aHiiveFund = bid && isHiiveFund(bid.listing);

  useEffect(() => {
    if (!bid) return;

    const { canPlaceStandingBid } = getAvailableCompanyActions(
      bid.listing.company,
    );

    addModalCloseHandler(ModalKind.PlaceBid, () => {
      // skip standing bid CTA for Hiive Funds
      if (canPlaceStandingBid && !aHiiveFund) {
        showFlow(FlowKind.BuyerPlaceBidSuccessStandingBidCTA);
      }
    });

    return () => {
      removeModalCloseHandler(ModalKind.PlaceBid);
    };
  }, [bid]);

  if (!bid) return null;

  const contactUsComponent = getContactUsComponent(bid.listing);
  const translationKeys = getPlaceBidSuccessModalTranslationKeys(bid.listing);

  return (
    <>
      <HiiveModalHeader>{t(translationKeys.title)}</HiiveModalHeader>
      <ModalBody>
        <VStack alignItems="start">
          <Text textStyle="heading-lg">
            {t(translationKeys.heading, {
              companyName: bid.listing.company.name,
              displayId: bid.listing.displayId,
            })}
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.body}
              values={{
                companyName: bid.listing.company.name,
                displayId: bid.listing.displayId,
              }}
            />
          </Text>
          <Text>
            <Trans
              i18nKey={translationKeys.contactUs}
              t={t}
              components={[contactUsComponent]}
            />
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          data-testid="place-bid-success-modal"
          observabilityLabel="[PlaceBidSuccess/Submit]"
          submitText={t(`okay`)}
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};
export default PlaceBidSuccess;
