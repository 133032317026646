import { useTranslation } from "react-i18next";

import { Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import {
  DEFAULT_TRUSTED_CONTACT,
  getTrustedContactFormSchema,
  mapTrustedContactVariables,
  TrustedContactForm,
} from "@/components/trusted-contact-form";
import {
  CurrentContextDocument,
  useTransitionCurrentStepMutation,
  useUpsertTrustedContactPersonMutation,
} from "@/gql";
import { useCurrentActor, useCustomToast, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

const TrustedContactPage = () => {
  const { t } = useTranslation();
  const { successToast } = useCustomToast();
  const upsertTrustedContact = useUpsertTrustedContactPersonMutation({
    refetchQueries: [CurrentContextDocument],
  });

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const actor = useCurrentActor();
  const { trustedContactPerson } = actor;
  const isUpdating = actor.trustedContactPerson?.isContactProvided;
  const onSuccess = async () => {
    if (isUpdating) {
      successToast(t`updated_trusted_contact_person`);
      return;
    }
    successToast(t`saved_trusted_contact_person`);
    await transitionCurrentStepMutation();
  };
  const trustedContactForm = useFormQL({
    mutation: upsertTrustedContact,
    initialValues: {
      ...(trustedContactPerson
        ? { ...trustedContactPerson.contactInfo }
        : { ...DEFAULT_TRUSTED_CONTACT }),
      collectTrustedContactPerson: trustedContactPerson?.isContactProvided,
    },

    mapVariables: mapTrustedContactVariables,
    validationSchema: getTrustedContactFormSchema(t),
    mode: `onBlur`,
    onSuccess,
  });

  return (
    <OnboardingContainerV2
      canGoBack
      metaTitle={t(`add_trusted_contact_person_onboarding`)}
    >
      <Flex direction="column">
        <VStack spacing={2} alignItems="flex-start" mb={9}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`add_trusted_contact_person_onboarding`)}
          </Text>
          <Text color="grey.900">{t(`can_complete_later`)}</Text>
        </VStack>
        <form
          autoComplete="off"
          onSubmit={trustedContactForm.handleSubmit}
          data-dd-privacy="mask"
        >
          <TrustedContactForm
            trustedContactForm={trustedContactForm}
            width={{ base: `full`, lg: `178` }}
          />
          <HStack p={2} mt={6} justifyContent="flex-end" w="full">
            <HiiveButton
              isLoading={isTransitioningCurrentStep}
              isDisabled={!trustedContactForm.formState?.isValid}
              maxW="auto"
              observabilityLabel="[TrustedContactForm] Next"
              size="xl"
              type="submit"
              variant="rounded-solid-salmon"
              w={{ base: `full`, sm: `auto` }}
            >
              {t(`next`)}
            </HiiveButton>
          </HStack>
        </form>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default TrustedContactPage;
