import { useTranslation } from "react-i18next";

import NextImage from "next/image";

import {
  Center,
  Divider,
  Flex,
  HStack,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";

import { WithQuery } from "@/components/common";
import { InitialsBadge } from "@/components/nav";
import { useUnaccreditedSellerNavbarMyActivityQuery } from "@/gql";
import { useCurrentActor, useRedirectCompanyLink, useSignOut } from "@/hooks";

import { NavMenuLink } from "./NavMenuLink";

export const UnaccreditedSellerNavFooter = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => {
  const redirectCompanyLink = useRedirectCompanyLink();
  const actor = useCurrentActor();
  const signOut = useSignOut();
  const handleSignOut = () => signOut();

  const query = useUnaccreditedSellerNavbarMyActivityQuery();
  const { t } = useTranslation();

  return (
    <WithQuery query={query}>
      {({ data: { unaccreditedSellerMyActivity } }) => {
        const { myCompany } = unaccreditedSellerMyActivity;

        return (
          <Flex direction="column" gap={5} data-dd-privacy="mask">
            <VStack gap={4}>
              <NavMenuLink
                href={redirectCompanyLink(myCompany)}
                onClick={onClick}
                textStyle="heading-lg"
              >
                <HStack spacing={3}>
                  {myCompany.logoUrl && (
                    <Center flex="none">
                      <NextImage
                        alt={myCompany.name}
                        style={{
                          maxHeight: 24,
                          maxWidth: 24,
                          objectFit: `contain`,
                        }}
                        height={24}
                        src={myCompany.logoUrl}
                        width={24}
                      />
                    </Center>
                  )}
                  <Text>{myCompany.name}</Text>
                </HStack>
              </NavMenuLink>
              <NavMenuLink
                href="/dashboard"
                onClick={onClick}
                textStyle="heading-lg"
              >
                {t(`dashboard`)}
              </NavMenuLink>
            </VStack>
            <Divider mx={4} w="full" />
            <VStack spacing={0} w="full">
              <HStack w="full" px={5} py={2.5}>
                <InitialsBadge />
                <VStack spacing={0} alignItems="flex-start">
                  <Text textStyle="heading-md">{actor.name}</Text>
                  <Text textStyle="text-xs">{actor.email}</Text>
                </VStack>
              </HStack>
              <NavMenuLink onClick={onClick} href="/account">
                {t(`account`)}
              </NavMenuLink>
              <ChakraLink
                onClick={handleSignOut}
                w="full"
                px={5}
                py={2.5}
                textStyle="heading-md"
              >
                {t(`sign_out`)}
              </ChakraLink>
            </VStack>
          </Flex>
        );
      }}
    </WithQuery>
  );
};
