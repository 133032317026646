import { Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  GridItem,
  ModalBody,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { EmailInput, FormikQL, TextInput } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  BasicUserFragment,
  InviteAlternateSignerModalBidFragment,
  InviteAlternateSignerMutationVariables,
  StandingBidPageStandingBidByIdDocument,
  TransactionPageTransactionByIdDocument,
  useInviteAlternateSignerMutation,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { getShortDocumentTitleByDocumentType, getIsBroker } from "@/utils";

interface InviteAlternateSignerFormValues {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
}

const initialValues: InviteAlternateSignerFormValues = {
  firstName: ``,
  lastName: ``,
  email: ``,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string()
    .email(`Invalid email address`)
    .required(`Email is required`),
});

const createMapVariables =
  (bid: InviteAlternateSignerModalBidFragment, actor: BasicUserFragment) =>
  (
    values: InviteAlternateSignerFormValues,
  ): InviteAlternateSignerMutationVariables => ({
    input: {
      ...values,
      documentId: bid.transaction?.document?.id || ``,
      institutionId: actor.institutionId,
    },
  });

interface InviteAlternateSignerModalProps {
  readonly bid: InviteAlternateSignerModalBidFragment;
  readonly actor: UserWithInstitutionFragment;
}

const InviteAlternateSignerModal = ({
  bid,
  actor,
}: InviteAlternateSignerModalProps) => {
  const { successToast } = useCustomToast();
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const mutation = useInviteAlternateSignerMutation({
    refetchQueries: [
      StandingBidPageStandingBidByIdDocument,
      TransactionPageTransactionByIdDocument,
    ],
  });

  const onSuccess = () => {
    const message = getIsBroker(actor)
      ? `Sent to your customer.`
      : `Alternate signer added.`;

    successToast(message);

    closeModal();
  };

  const mapVariables = createMapVariables(bid, actor);

  const title = getIsBroker(actor)
    ? `Send to Your Customer`
    : `Send to Alternate Signer`;

  const shortDocumentTitle = bid.transaction?.document?.type
    ? getShortDocumentTitleByDocumentType(bid.transaction.document.type)
    : null;

  const modalDescriptionText = getIsBroker(actor)
    ? t(`broker_invite_alternate_signer_prompt`)
    : t(`invite_alternate_signer_prompt`, {
        documentTitle: shortDocumentTitle,
      });

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        mutation={mutation}
        mutationNames={[`inviteAlternateSigner`]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form>
            <HiiveModalHeader>{title}</HiiveModalHeader>
            <ModalBody>
              <VStack alignItems="flex-start" spacing={6}>
                <Text>{modalDescriptionText}</Text>
                <SimpleGrid columns={2} rowGap={7} columnGap={9} w="full">
                  <GridItem colSpan={1}>
                    <TextInput label="First Name" name="firstName" />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <TextInput label="Last Name" name="lastName" />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <EmailInput label="Email" name="email" />
                  </GridItem>
                </SimpleGrid>
              </VStack>
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  observabilityLabel="[InviteAlternateSigner/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                observabilityLabel="[InviteAlternateSigner/Submit]"
                submitText="Send"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default withCurrentActor(InviteAlternateSignerModal);
