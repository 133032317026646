import currency from "currency.js";
import i18next from "i18next";

import { Maybe } from "@/types";
import { constants } from "@/utils";

export const getAreFeesHighForBid = ({
  pricePerShare,
  numberOfShares,
}: {
  readonly pricePerShare?: Maybe<number>;
  readonly numberOfShares: number;
}) => {
  const pricePerShareInDollars = pricePerShare
    ? Math.round(pricePerShare / 100)
    : null;

  const isWithinWarningThreshold = pricePerShareInDollars
    ? pricePerShareInDollars * numberOfShares <
      constants.high_fees_warning_threshold
    : false;

  return isWithinWarningThreshold;
};

export const getMinOrMaxPPSValidationErrorMessage = (
  min?: number | null,
  max?: number | null,
): string => {
  if (!!min && !!max) {
    return i18next.t(`pps_range_bid_constraint_error`, {
      minPps: currency(min / 100).format(),
      maxPps: currency(max / 100).format(),
    });
  }

  if (min) {
    return i18next.t(`min_pps_bid_constraint_error`, {
      pps: currency(min / 100).format(),
    });
  }

  if (max) {
    return i18next.t(`max_pps_bid_constraint_error`, {
      pps: currency(max / 100).format(),
    });
  }

  return ``;
};
