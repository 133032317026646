import { Bank, User } from "@phosphor-icons/react";
import { ReactNode, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Flex, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  SlideAnimation,
  OnboardingInfoMessageContext,
} from "@/components/onboarding-v2";
import { FormRadioTile } from "@/components/react-hook-form";
import {
  InvestorStatus,
  UserWithInstitutionFragment,
  useTransitionCurrentStepMutation,
  useUpdateUserMutation,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

interface InvestorStatusFormValues {
  readonly investorStatus?: InvestorStatus | null;
}

const initialValues = (actor: UserWithInstitutionFragment) => ({
  investorStatus: actor.investorStatus,
});

const validationSchema = Yup.object().shape({
  investorStatus: Yup.string()
    .nullable()
    .required(`Investor status is required`),
});

const mapVariables = ({ investorStatus }: InvestorStatusFormValues) => ({
  input: { investorStatus },
});

export const InnerTileContainer = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <VStack spacing={2} alignItems="center" pr={4} py={1.5} w="full">
    {children}
  </VStack>
);

export const InvestorStatusForm = () => {
  const actor = useCurrentActor();
  const mutation = useUpdateUserMutation();
  const { resetReonboardingMessage } = useContext(OnboardingInfoMessageContext);
  const { t } = useTranslation();
  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
    resetReonboardingMessage();
  };

  const { handleSubmit, isLoading, control, formState } = useFormQL({
    mutation,
    mapVariables,
    initialValues: initialValues(actor),
    validationSchema,
    onSuccess,
  });

  const [grey700] = useColors([`grey.700`]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <SlideAnimation>
        <FormRadioTile.Group
          size="lg"
          gridTemplateColumns={{ base: `1fr`, md: `1fr 1fr` }}
          bg="white"
          p={{ base: 4, lg: 7 }}
          borderRadius="md"
          borderColor="grey.200"
          borderWidth={0.5}
          boxShadow="card"
        >
          <FormRadioTile.Tile
            name="investorStatus"
            control={control}
            value={InvestorStatus.Individual}
          >
            <InnerTileContainer>
              <User size={40} color={grey700} />
              <Text textStyle="heading-lg">{t(`individual`)}</Text>
              <UnorderedList>
                <ListItem>
                  <Trans
                    i18nKey="individual_sell_or_buy_private_stock"
                    components={{ bold: <strong /> }}
                  />
                </ListItem>
                <ListItem>{t(`individual_monitor_market_data`)}</ListItem>
              </UnorderedList>
            </InnerTileContainer>
          </FormRadioTile.Tile>
          <FormRadioTile.Tile
            name="investorStatus"
            value={InvestorStatus.Institutional}
            control={control}
          >
            <InnerTileContainer>
              <Bank size={40} color={grey700} />
              <Text textStyle="heading-lg">{t(`institution`)}</Text>
              <UnorderedList>
                <ListItem>
                  <Trans
                    i18nKey="institutional_sell_or_buy_private_stock"
                    components={{ bold: <strong /> }}
                  />
                </ListItem>
                <ListItem>{t(`institutional_monitor_market_data`)}</ListItem>
              </UnorderedList>
            </InnerTileContainer>
          </FormRadioTile.Tile>
        </FormRadioTile.Group>
      </SlideAnimation>

      <Flex w="full" justify="flex-end" mt={8}>
        <HiiveButton
          observabilityLabel="[InvestorStatusPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          w={{ base: `full`, sm: `unset` }}
          isLoading={isLoading || isTransitioningCurrentStep}
          isDisabled={!formState.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </Flex>
    </form>
  );
};
