import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import { FormTextarea } from "@/components/form";
import { ListingNotesExplainer } from "@/components/listings";

export const ListingNotesCard = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation();

  return (
    <Card w="full" ref={ref}>
      <CardHeader>
        <Text textStyle="heading-sm">4. {t(`listing_notes_optional`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack spacing={5} alignItems="flex-start">
          <ListingNotesExplainer />
          <FormTextarea
            name="otherDetails"
            label="Additional listing notes"
            placeholder="Add optional details"
            labelSrOnly
          />
        </VStack>
      </CardBody>
    </Card>
  );
});
