export default {
  order: `Order`,
  bid: `Bid`,
  sh: `/sh`,
  placed: `Placed {{timestamp}}`,
  withdrawn: `Withdrawn`,
  submitted_to_hiive_for_review: `Submitted to Hiive for review {{timestamp}}`,
  placed_by_hiive: `Placed by Hiive {{timestamp}}`,
  expires: `Expires {{timestamp}}`,
};
