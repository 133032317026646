import { Box, Flex, HStack } from "@chakra-ui/react";

import { Skeleton } from "@/components/common";

const CompanyPriceGraphSkeleton = () => (
  <Flex gap={5} direction="column" borderColor="grey.100" borderRadius="md">
    <Skeleton h="12px" borderRadius="md" w={16} />
    <Skeleton h="60px" borderRadius="md" w={20} />
    <Skeleton h="12px" borderRadius="md" w={40} />

    <Flex
      gap={1}
      flexDirection={{ base: `column`, md: `row` }}
      alignItems={{ base: `flex-end`, md: `flex-start` }}
    >
      <HStack maxW="50%" w="full" gap={2}>
        <Skeleton h="36px" borderRadius="md" />
      </HStack>
      <Box flexGrow={1} />
      <HStack maxW="40%" w="full" gap={2}>
        <Skeleton h="36px" borderRadius="md" />
      </HStack>
    </Flex>

    <Skeleton h="445px" />

    <Flex direction="column" gap={2} justify="start" w="full">
      <Skeleton h="16px" w={20} />
      <Flex gap={2} maxW="40%">
        <Skeleton h="12px" maxW="50%" />
        <Skeleton h="12px" maxW="50%" />
      </Flex>
    </Flex>
    <Flex gap={2} flexDirection="column">
      <Skeleton h="12px" />
      <Skeleton h="12px" maxW="80%" />
    </Flex>
  </Flex>
);

export default CompanyPriceGraphSkeleton;
