/* eslint-disable functional/immutable-data */

/* eslint-disable no-return-assign */
import { useApolloClient } from "@apollo/client";
import { useOrganization } from "@frigade/react";
import isEmpty from "lodash/isEmpty";
import { ReactElement, useEffect } from "react";

import { useRouter } from "next/router";

import { Box, Flex } from "@chakra-ui/react";

import { CompleteProfileBanner, Loader } from "@/components/common";
import { FeatureFlagsHelper } from "@/components/helpers";
import { NavBar, NavSpacer } from "@/components/nav";
import {
  FrontChatProvider,
  KnockProvider,
  ModalProvider,
  CurrentActorProvider,
} from "@/components/providers";
import {
  CurrentContextDocument,
  useCurrentContextQuery,
  useOnIdentityInquiryUpdatedSubscription,
  InvestorStatus,
} from "@/gql";
import {
  useCheckAuthentication,
  canAccessPlatform,
  useSession,
  useSignOut,
  useToken,
  useCorrectPortalRedirect,
  useIsOnCorrectPortal,
} from "@/hooks";
import {
  useMarketplaceCompleteProfileBanner,
  useSuitabilityUpfront,
} from "@/hooks/featureFlags";
import { useIdentifyUser } from "@/hooks/useIdentifyUser";
import { ROUTES } from "@/utils";
import { getIsProfileIncomplete } from "@/utils/user";

import Layout from "./Layout";

interface AuthenticatedLayoutProps {
  readonly children: JSX.Element | readonly JSX.Element[];
  readonly title?: string;
  readonly useCustomLayout?: boolean;
}

const useIdentifySentryUser = () => {
  const token = useToken();
  const user = useSession();
  const identifyUser = useIdentifyUser();

  useEffect(() => {
    if (!user) {
      return;
    }

    identifyUser(user);
  }, [token, user]);
};

const useIsOnboarding = () => {
  const router = useRouter();

  const exemptRoutes = [`/terms-and-conditions`];

  const onboardingAccessibleRoutes = [
    `/welcome`,
    `/terms-and-conditions`,
    `/verify-email`,
    `/something-went-wrong`,
    `/page-not-found`,
  ];

  const isOnboarding =
    onboardingAccessibleRoutes.includes(router.asPath) ||
    exemptRoutes.includes(router.asPath);

  return isOnboarding;
};

const AuthenticatedProviders = ({
  children,
}: {
  readonly children: ReactElement;
}) => (
  <CurrentActorProvider>
    <ModalProvider>
      <FeatureFlagsHelper />
      <KnockProvider>
        <FrontChatProvider>{children}</FrontChatProvider>
      </KnockProvider>
    </ModalProvider>
  </CurrentActorProvider>
);

const AuthenticatedLayout = ({
  children,
  title,
  useCustomLayout = false,
}: AuthenticatedLayoutProps) => {
  const { data, loading } = useCurrentContextQuery();
  const signOut = useSignOut();
  const client = useApolloClient();
  const { replace, push } = useRouter();

  const { setOrganizationIdWithProperties } = useOrganization();
  const isSUPEnabled = useSuitabilityUpfront();
  const redirectToCorrectPortal = useCorrectPortalRedirect();
  const isOnCorrectPortal = useIsOnCorrectPortal();

  const isOnboarding = useIsOnboarding();
  const isBannerFlagOn = useMarketplaceCompleteProfileBanner();
  const actor = data?.currentContext?.currentActor;
  useCheckAuthentication({ actor, loading });
  useIdentifySentryUser();

  const hasActor = !isEmpty(actor);

  const isOnboardingComplete = actor?.onboardingComplete;
  const isReOnboarding =
    hasActor &&
    !isOnboarding &&
    actor.institution?.onboarding.reonboardingStartedAt;

  const shouldRedirectToOnboarding =
    (hasActor && !isOnboarding && !canAccessPlatform(actor)) || isReOnboarding;

  const shouldRenderCompleteProfileBanner = !!(
    isBannerFlagOn &&
    isOnboardingComplete &&
    getIsProfileIncomplete(actor)
  );

  const shouldSkipVerifiedIdentitySubscription =
    actor?.investorStatus !== InvestorStatus.Individual ||
    actor?.identityVerified;

  const verifiedIdentityResult = useOnIdentityInquiryUpdatedSubscription({
    skip: shouldSkipVerifiedIdentitySubscription,
  });

  useEffect(() => {
    if (!hasActor || isOnCorrectPortal(actor)) {
      return;
    }

    redirectToCorrectPortal(actor);
  }, [actor]);

  useEffect(() => {
    if (verifiedIdentityResult.data) {
      client.refetchQueries({
        include: [CurrentContextDocument],
      });
    }
  }, [verifiedIdentityResult]);

  useEffect(() => {
    if (actor?.institution) {
      setOrganizationIdWithProperties(actor.institution.id, {
        name: actor.institution.legalName,
        membershipAgreementSigned: actor.institution.membershipAgreementSigned,
      });
    }
  }, [
    actor?.institution?.membershipAgreementSigned,
    actor?.institution?.legalName,
  ]);

  useEffect(() => {
    if (shouldRedirectToOnboarding) {
      push(ROUTES.WELCOME);
    }
  }, [shouldRedirectToOnboarding, isSUPEnabled, signOut, push, replace]);

  if (loading || shouldRedirectToOnboarding) {
    return <Loader minHeight="100vh" />;
  }

  if (useCustomLayout)
    return (
      <AuthenticatedProviders>
        <Layout title={title}>
          <Flex
            as="main"
            id="main-element"
            direction="column"
            minH="100vh"
            flex="1 1 auto"
            position="relative"
          >
            {children}
          </Flex>
        </Layout>
      </AuthenticatedProviders>
    );
  return (
    <AuthenticatedProviders>
      <Layout title={title}>
        <Flex
          direction="column"
          minH="100vh"
          flex="1 1 auto"
          position="relative"
        >
          <Box position="fixed" top={0} left={0} right={0} zIndex="modal">
            {shouldRenderCompleteProfileBanner && <CompleteProfileBanner />}
            <NavBar />
          </Box>
          <NavSpacer
            shouldRenderExtraSpace={shouldRenderCompleteProfileBanner}
          />
          <Flex
            as="main"
            id="main-element"
            flex="1 1 auto"
            direction="column"
            align="center"
          >
            {children}
          </Flex>
        </Flex>
      </Layout>
    </AuthenticatedProviders>
  );
};

export default AuthenticatedLayout;
