import { UserWithInstitutionFragment } from "@/gql";

export const mockUser: UserWithInstitutionFragment = {
  __typename: `User`,
  onboardingComplete: false,
  chatIdHash: `chat-id-hash`,
  id: `user-id`,
  name: `John Doe`,
  firstName: `John`,
  lastName: `Doe`,
  email: `johndoe@example.com`,
  emailVerified: false,
  roles: [],
  isSuperadmin: false,
  isActive: true,
  identityVerified: false,
  accredited: false,
  membershipAgreementSigned: false,
  isHiiveUser: false,
  phoneNumber: `123-456-7890`,
  updatedAt: `2025-01-23T00:00:00Z`,
  insertedAt: `2025-01-23T00:00:00Z`,
  myPermissionsV2: [],
  institution: null,
  entities: [],
  accreditationQuestions: [],
  accreditationAnswers: [],
  feeDiscountApplications: [],
  suitabilityAnswers: [],
};
