import { match } from "ts-pattern";

import {
  TransferMethod,
  UnaccreditedSellerTransferMethodInput,
  UnaccreditedSellerTransferMethodInputV2,
} from "@/gql";

// TODO: This can be removed after Server is updated
enum TempUnaccreditedSellerTransferMethodInput {
  Direct = `DIRECT`,
  Other = `OTHER`,
  Unknown = `UNKNOWN`,
}

export const getTransferMethodByUnaccreditedSellerTransferMethodInput = (
  transferMethod:
    | UnaccreditedSellerTransferMethodInput
    | UnaccreditedSellerTransferMethodInputV2
    | TempUnaccreditedSellerTransferMethodInput,
) =>
  match(transferMethod)
    .with(
      UnaccreditedSellerTransferMethodInput.Direct,
      () => TransferMethod.Direct,
    )
    .with(
      UnaccreditedSellerTransferMethodInput.Unknown,
      () => TransferMethod.Unknown,
    )
    .with(
      UnaccreditedSellerTransferMethodInput.Other,
      () => TransferMethod.Other,
    )
    .otherwise(() => {
      throw new Error(
        `Unknown transferMethod in getTransferMethodByUnaccreditedSellerTransferMethodInputs: ${transferMethod}`,
      );
    });
