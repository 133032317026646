import { ArrowUp } from "@phosphor-icons/react";
import useEventListener from "@use-it/event-listener";
import { useState } from "react";

import {
  Flex,
  useMultiStyleConfig,
  Box,
  ResponsiveValue,
} from "@chakra-ui/react";

const BackToTop = ({
  variant = `desktop`,
}: {
  readonly variant?: ResponsiveValue<`desktop` | `mobile`>;
}) => {
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  const { pagePosition, iconContainer } = useMultiStyleConfig(`BackToTop`, {
    variant,
  });

  useEventListener(`scroll`, () => {
    if (window.scrollY >= 500) return setShowBackToTop(true);
    return setShowBackToTop(false);
  });

  const handleClick = () => window.scrollTo({ top: 0, behavior: `smooth` });

  return showBackToTop ? (
    <Box __css={pagePosition}>
      <Flex __css={iconContainer} onClick={handleClick}>
        <ArrowUp size={32} color="white" />
      </Flex>
    </Box>
  ) : null;
};

export default BackToTop;
