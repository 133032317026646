import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

const SomethingWentWrongError = ({
  isSignedIn,
}: {
  readonly isSignedIn?: boolean;
}) => {
  const router = useRouter();
  const { t } = useTranslation(`error`);

  return (
    <Flex direction="column" alignItems="center" pt={{ base: 10, md: 20 }}>
      <Text textStyle="deprecated-heading-7xl" textAlign="center">
        {t(`something_went_wrong`)}
      </Text>
      <VStack gap={5}>
        <Box textStyle="deprecated-text-4xl" textAlign="center">
          <Text>{t(`working_on_the_problem`)}</Text>
          <Text>{t(`please_try_again_later`)}</Text>
        </Box>
        {isSignedIn && (
          <HiiveButton
            onClick={() => router.push(`/dashboard`)}
            size="md"
            variant="rounded-solid-salmon"
            observabilityLabel="[SomethingWentWrongError] Back to Dashboard"
          >
            {t(`back_to_dashboard`)}
          </HiiveButton>
        )}
      </VStack>
    </Flex>
  );
};

export default SomethingWentWrongError;
