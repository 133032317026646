import HiiveButton, { HiiveButtonProps } from "./HiiveButton";

interface HiiveCancelButtonProps extends HiiveButtonProps {
  readonly onCancel: () => void;
  readonly cancelText?: string;
}

const HiiveCancelButton = ({
  cancelText = `Cancel`,
  onCancel,
  observabilityLabel,
  ...buttonProps
}: HiiveCancelButtonProps) => (
  <HiiveButton
    variant="rounded-outline-grey"
    size="xl"
    onClick={onCancel}
    w={{ base: `full`, sm: `unset` }}
    maxW="unset"
    observabilityLabel={observabilityLabel}
    {...buttonProps}
  >
    {cancelText}
  </HiiveButton>
);

export default HiiveCancelButton;
