import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Flex, FormLabel, Text } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  OnboardingInfoMessageContext,
  SlideAnimation,
} from "@/components/onboarding-v2";
import {
  FormCountryCombobox,
  FormRadioTile,
  FormTextAreaInput,
  FormTextInput,
} from "@/components/react-hook-form";
import {
  EntityType,
  InstitutionEntityType,
  InstitutionInfoPageMutationVariables,
  UserWithInstitutionFragment,
  useCountriesQuery,
  useInstitutionInfoPageMutation,
  useTransitionCurrentStepMutation,
} from "@/gql";
import { useCurrentActor, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

import { AddressFields } from "./AddressFields";
import { InstitutionEntityTypeField } from "./InstitutionEntityTypeField";

const getInitialValues = (
  actor: UserWithInstitutionFragment,
): InstitutionInfoFormValues => ({
  legalName: actor.institution?.legalName || ``,
  countryId: actor.institution?.country.id || ``,
  streetAddress: actor.institution?.streetAddress || ``,
  city: actor.institution?.city || ``,
  jobTitle: actor.jobTitle || ``,
  isBrokerDealer: actor.institution?.isBrokerDealer || false,
  entityType: actor.institution?.entityType || null,
  jurisdictionOfIncorporationId:
    actor.institution?.jurisdictionOfIncorporation?.id || ``,
  region: actor.institution?.region || ``,
  postalCode: actor.institution?.postalCode || ``,
});

export type InstitutionInfoFormValues = {
  readonly legalName: string;
  readonly countryId: string;
  readonly jobTitle: string;
  readonly isBrokerDealer: boolean;
  readonly streetAddress: string;
  readonly city: string;
  readonly entityType: InstitutionEntityType | null;
  readonly jurisdictionOfIncorporationId: string;
  readonly region: string;
  readonly postalCode: string;
};

const useCountriesById = () => {
  const { data } = useCountriesQuery();

  const countriesById: Record<string, { id: string; name: string }> = useMemo(
    () =>
      data?.countries?.reduce(
        (soFar, country) => ({
          ...soFar,
          [country.id]: {
            id: country.id,
            name: country.name,
          },
        }),
        {},
      ) || {},
    [data?.countries],
  );

  return countriesById;
};

const useValidationSchema = () => {
  const { t } = useTranslation();
  const countriesById = useCountriesById();

  const validationSchema = Yup.object().shape({
    legalName: Yup.string().required(
      t(`validation_required`, { field: t(`legal_name`) }),
    ),
    countryId: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`country`) })),
    jobTitle: Yup.string().required(
      t(`validation_required`, { field: t(`job_title`) }),
    ),
    isBrokerDealer: Yup.boolean().required(t(`required`)),
    entityType: Yup.string().when(`countryId`, {
      is: (countryId: string) => countriesById[countryId]?.name === `UK`,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .oneOf(
          Object.values(EntityType),
          t(`validation_required`, { field: t(`legal_structure`) }),
        )
        .nullable()
        .required(t(`validation_required`, { field: t(`legal_structure`) })),
    }),
    jurisdictionOfIncorporationId: Yup.string().required(
      t(`validation_required`, { field: t(`jurisdiction`) }),
    ),
    streetAddress: Yup.string()
      .nullable()
      .required(t(`validation_required`, { field: t(`street_address`) }))
      .matches(/[a-zA-Z]/, {
        message: t(`validation_invalid`, { field: t(`street_address`) }),
      }),
    city: Yup.string()
      .required(t(`validation_required`, { field: t(`city`) }))
      .matches(/[a-zA-Z]/, {
        message: t(`validation_invalid`, { field: t(`city`) }),
      }),
    region: Yup.string().when(`countryId`, {
      is: (countryId: string) =>
        [`CH`, `UK`].includes(countriesById[countryId]?.name),
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .required(t(`required`))
        .matches(/[a-zA-Z]/, t(`validation_invalid`, { field: t(`region`) })),
    }),
    postalCode: Yup.string().required(t(`required`)),
  });

  return validationSchema;
};

const mapVariables = ({
  jobTitle,
  entityType,
  ...institutionInput
}: InstitutionInfoFormValues): InstitutionInfoPageMutationVariables => ({
  upsertInstitutionInput: {
    entityType,
    ...institutionInput,
  },
  updateUserInput: {
    jobTitle,
  },
});

export const InstitutionInfoForm = () => {
  const actor = useCurrentActor();
  const mutation = useInstitutionInfoPageMutation();
  const { t } = useTranslation();
  const { resetReonboardingMessage } = useContext(OnboardingInfoMessageContext);
  const validationSchema = useValidationSchema();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onSuccess = async () => {
    await transitionCurrentStepMutation();
    resetReonboardingMessage();
  };

  const { control, handleSubmit, isLoading, watch, setValue } = useFormQL({
    initialValues: getInitialValues(actor),
    mapVariables,
    mutation,
    onSuccess,
    validationSchema,
    shouldFocusError: false,
  });

  const countryId = watch(`countryId`);
  const jurisdictionCountry_id = watch(`jurisdictionOfIncorporationId`);

  const countriesById = useCountriesById();
  const currentCountryName = countryId ? countriesById[countryId]?.name : `US`;
  const currentJurisdictionCountryName = jurisdictionCountry_id
    ? countriesById[jurisdictionCountry_id]?.name
    : `US`;

  const onSelectCountry = (selectedCountryId: string) => {
    if (selectedCountryId !== countryId) {
      setValue(`city`, ``);
      setValue(`region`, ``);
      setValue(`postalCode`, ``);
      setValue(`streetAddress`, ``);
    }
  };

  const onSelectJurisdictionCountry = (selectedCountryId: string) => {
    if (selectedCountryId !== jurisdictionCountry_id) {
      setValue(`entityType`, null);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Flex direction="column" gap={8}>
        <SlideAnimation>
          <Flex
            direction="column"
            gap={14}
            bgColor="white"
            borderRadius="md"
            borderColor="grey.200"
            borderWidth={0.5}
            boxShadow="card"
            px={6}
            py={8}
          >
            <Flex direction="column" gap={5}>
              <FormTextInput
                name="legalName"
                label={t(`what_is_your_firm_fund_name`)}
                placeholder={t(`firm_fund_name`)}
                control={control}
              />
              <FormCountryCombobox
                control={control}
                disabled={isLoading}
                label={t(`where_is_your_firm_fund`)}
                name="countryId"
                placeholder={t(`search_country`)}
                showSearchIcon
                onSelectCountry={onSelectCountry}
              />
              <FormTextAreaInput
                name="streetAddress"
                rows={2}
                label={t(`street_address_of_firm_fund`)}
                control={control}
              />
              <AddressFields country={currentCountryName} control={control} />
            </Flex>

            <FormCountryCombobox
              control={control}
              label={t(`jurisdiction_of_incorporation_registration`)}
              name="jurisdictionOfIncorporationId"
              placeholder={t(`search_country`)}
              onSelectCountry={onSelectJurisdictionCountry}
              showSearchIcon
            />
            <InstitutionEntityTypeField
              name="entityType"
              countryName={currentJurisdictionCountryName}
              control={control}
            />
            <FormTextInput
              name="jobTitle"
              label={t(`what_is_your_position_in_firm_fund`)}
              placeholder={t(`position_name`)}
              control={control}
            />

            <Flex direction="column" gap={4}>
              <FormLabel m={0} p={0}>
                {t(`are_you_a_broker_dealer`)}
              </FormLabel>
              <FormRadioTile.Group
                p={0}
                size="sm"
                gap={4}
                gridTemplateColumns={{ base: `1fr`, md: `repeat(3, 1fr)` }}
              >
                <FormRadioTile.Tile
                  name="isBrokerDealer"
                  value
                  control={control}
                >
                  <Text>{t(`yes`)}</Text>
                </FormRadioTile.Tile>
                <FormRadioTile.Tile
                  name="isBrokerDealer"
                  value={false}
                  control={control}
                >
                  <Text>{t(`no`)}</Text>
                </FormRadioTile.Tile>
              </FormRadioTile.Group>
            </Flex>
          </Flex>
        </SlideAnimation>
        <Flex w="full" justifyContent="flex-end">
          <HiiveButton
            isLoading={isLoading || isTransitioningCurrentStep}
            observabilityLabel="[InstitutionInfoPage] Next"
            size="xl"
            maxW="auto"
            w={{ base: `full`, sm: `auto` }}
            type="submit"
            variant="rounded-solid-salmon"
          >
            {t(`next`)}
          </HiiveButton>
        </Flex>
      </Flex>
    </form>
  );
};
