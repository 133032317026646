import { datadogRum } from "@datadog/browser-rum";
import {
  initialize,
  LDClient,
  LDEvaluationDetail,
} from "launchdarkly-js-client-sdk";
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  createContext,
  useMemo,
} from "react";

import { useCurrentContextQuery } from "@/gql";
import { FeatureFlags } from "@/hooks/featureFlags";

interface ProviderProps {
  clientSideId?: string;
  children?: ReactNode;
}

const LD_TIMEOUT = 5;

export enum LDStatus {
  Unfetched,
  Success,
  Failure,
}

const defaultFlags: FeatureFlags = {
  "hiive.trace_full_graphql": false,
  "hiive.enable_maintenance": false,
  "marketplace.sell_side_broker_portal": true,
  "issuer.fee_discount.new_proceeds_section": true,
  "execution.internal_multi_entity": false,
  "execution.transaction_execution_automation_system": false,
  "marketplace.suitability_upfront": true,
  "marketplace.suppress_notifications": false,
  "marketplace.suitability_v3": true,
  "marketplace.front_chat_u16r": false,
  "marketplace.front_chat_trader": false,
  "execution.buyer_account_information_transfer": false,
  "marketplace.complete_profile_banner": false,
  "marketplace.new_terms_and_conditions": false,
  "marketplace.pricing_chart_disclaimer_updates": false,
  "marketplace.rsu_restriction_and_eligible_buyers": false,
  "marketplace.slugs_as_company_url": false,
  "marketplace.order_book_truncation": false,
  "marketplace.card_updates": false,
};

export const LDStatusContext = createContext<{
  readonly status: LDStatus;
  readonly client?: LDClient;
  readonly flags: FeatureFlags;
}>({
  status: LDStatus.Unfetched,
  client: undefined,
  flags: defaultFlags,
});

const AsyncLDProvider: React.FC<ProviderProps> = ({
  children,
  clientSideId,
}) => {
  const LDClientRef = useRef<LDClient | undefined>();
  const [flags, setFlags] = useState<FeatureFlags>(defaultFlags);
  const [status, setStatus] = useState<LDStatus>(LDStatus.Unfetched);
  const { data, loading } = useCurrentContextQuery();

  const currentActor = data?.currentContext?.currentActor;

  useEffect(() => {
    if (!loading && clientSideId) {
      // eslint-disable-next-line functional/no-let
      let user;

      if (process.env.NEXT_PUBLIC_E2E) {
        user = {
          key: `e2e2e2e2-e2e2-e2e2-e2e2-e2e2e2e2e2ea`,
          anonymous: false,
        };
      } else {
        user = currentActor
          ? { key: currentActor.id, anonymous: false }
          : { anonymous: true };
      }

      const client = initialize(clientSideId, user, {
        streaming: true,
        bootstrap: defaultFlags,
        inspectors: [
          {
            type: `flag-used`,
            name: `dd-inspector`,
            method: (key: string, detail: LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value);
            },
          },
        ],
      });

      client.on(`change`, (items) => {
        const newFlags = Object.keys(items).reduce(
          (acc, key) => ({ ...acc, [key]: items[key].current }),
          {},
        );
        setFlags((currentFlags) => ({ ...currentFlags, ...newFlags }));
        setStatus(LDStatus.Success);
      });
      client.on(`failed`, () => {
        // eslint-disable-next-line no-console
        console.error(`LD Failed to load`);
        setStatus(LDStatus.Failure);
      });

      client.waitForInitialization(LD_TIMEOUT).catch(() => {
        // eslint-disable-next-line no-console
        console.error(`LD failed to initialize after ${LD_TIMEOUT} seconds`);
        setStatus(LDStatus.Failure);
      });

      LDClientRef.current = client;
    }

    return () => {
      LDClientRef.current?.close();
    };
  }, [clientSideId, currentActor, loading]);

  const context = useMemo(
    () => ({ status, client: LDClientRef.current, flags }),
    [status, LDClientRef.current, flags],
  );

  if (status === LDStatus.Unfetched) {
    return null;
  }

  return (
    <LDStatusContext.Provider value={context}>
      {children}
    </LDStatusContext.Provider>
  );
};

export default AsyncLDProvider;
