import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DiscussionState } from "@/gql";

export function useDiscussionStatusText(state: DiscussionState) {
  const { t } = useTranslation(`discussions`);

  const discussionStatusText = useMemo(() => {
    const statusText = {
      [DiscussionState.Pending]: t(`requested`),
      [DiscussionState.Active]: t(`in_progress`),
      [DiscussionState.Closed]: t(`closed`),
    };

    return statusText[state];
  }, [state, t]);

  return discussionStatusText;
}
