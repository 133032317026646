import i18next from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import { ModalBody, Text } from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormTextInput } from "@/components/react-hook-form";
import { useUpdateUserEmailMutation } from "@/gql";
import { useCustomToast, useModal } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { ToastDuration } from "@/types/toast";
import { ROUTES } from "@/utils";
import constants from "@/utils/constants";

const { EMAIL_REGEX } = constants;

const getValidationSchema = (t: typeof i18next.t) =>
  Yup.object().shape({
    email: Yup.string()
      .nullable()
      .required(t(`email_is_required`))
      .matches(EMAIL_REGEX, i18next.t(`email_is_invalid`)),
  });

interface ChangeEmailFormValues {
  readonly email: string;
}

const createInitialValues = (email: string) => ({
  email,
});

const mapVariables = (values: ChangeEmailFormValues) => ({
  email: values.email,
});

interface ChangeEmailModalProps {
  readonly oldEmail: string;
  readonly redirectUrl?: string;
}

const ChangeEmailModal = ({
  oldEmail,
  redirectUrl = ROUTES.ACCOUNT_REQUEST_UPDATED_EMAIL_VERIFICATION,
}: ChangeEmailModalProps) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useCustomToast();
  const router = useRouter();
  const mutation = useUpdateUserEmailMutation();
  const { closeModal } = useModal();

  const initialValues = createInitialValues(oldEmail);

  const validationSchema = getValidationSchema(t);

  const onSuccess = () => {
    closeModal();
    successToast(t(`email_change_success_message`), {
      duration: ToastDuration.Long,
    });
    router.replace(redirectUrl);
  };

  const onError = () => {
    errorToast(t(`email_change_error_message`), {
      duration: ToastDuration.Long,
    });
  };

  const { handleSubmit, isLoading, control, watch } = useFormQL({
    mutation,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
    onError,
  });

  const emailValue = watch(`email`);

  return (
    <HiiveModalContentWrapper>
      <form onSubmit={handleSubmit}>
        <HiiveModalHeader>
          <Text>{t(`update_email_address`)}</Text>
        </HiiveModalHeader>
        <ModalBody>
          <FormTextInput
            bg="h-white"
            control={control}
            label={t(`new_email_address`)}
            name="email"
            placeholder={t(`enter_email_address`)}
            type="email"
          />
        </ModalBody>
        <HiiveModalFooter>
          <ButtonFooterWrapper>
            <HiiveCancelButton
              onCancel={closeModal}
              observabilityLabel="[ChangeEmail/Cancel]"
            />
            <HiiveSubmitButton
              isDisabled={oldEmail === emailValue}
              isLoading={isLoading}
              observabilityLabel="[ChangeEmail/Submit]"
              submitText={t(`update`)}
              type="submit"
            />
          </ButtonFooterWrapper>
        </HiiveModalFooter>
      </form>
    </HiiveModalContentWrapper>
  );
};

export default ChangeEmailModal;
