import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Image,
  List,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton, WatchlistButton, WithQuery } from "@/components/common";
import {
  DashboardMostActiveCompaniesCompanyFragment,
  useMostActiveCompaniesQuery,
} from "@/gql";
import { useRedirectCompanyLink } from "@/hooks";

import { DashboardMostActiveCompaniesSkeleton } from "./DashboardMostActiveCompaniesSkeleton";

const PostingCount = ({
  title,
  count,
}: {
  readonly title: string;
  readonly count: number;
}) => (
  <HStack
    borderWidth={0.5}
    borderColor="grey.200"
    bg="grey.25"
    minW={20}
    px={2}
    py={1}
    borderRadius="sm"
  >
    <Text textStyle="heading-xs">{count}</Text>
    <Text textStyle="deprecated-text-sm">{title}</Text>
  </HStack>
);

const MostActiveCompany = ({
  company,
}: {
  readonly company: DashboardMostActiveCompaniesCompanyFragment;
}) => {
  const router = useRouter();
  const redirectCompanyLink = useRedirectCompanyLink();

  return (
    <Box position="relative">
      <Card
        variant="subcard"
        as="button"
        w="full"
        onClick={() => router.push(redirectCompanyLink(company))}
      >
        <CardBody w="full">
          <HStack justifyContent="space-between" alignItems="flex-start">
            <VStack alignItems="flex-start" spacing={3}>
              <HStack>
                {company.logoUrl && (
                  <VStack h="6" minW="6">
                    <Image h="full" src={company.logoUrl} alt={company.name} />
                  </VStack>
                )}
                <Text textStyle="heading-md" textAlign="left">
                  {company.name}
                </Text>
              </HStack>
              <HStack>
                <PostingCount
                  count={company.activity.numOpenListings}
                  title="Listings"
                />
                <PostingCount
                  count={company.activity.numOpenStandingBidsAndActiveBids}
                  title="Bids"
                />
              </HStack>
            </VStack>
            <Spacer w={10} h={10} />
          </HStack>
        </CardBody>
      </Card>
      <WatchlistButton
        position="absolute"
        top="10.5px"
        right="10.5px"
        isCollapsed
        companyId={company.id}
      />
    </Box>
  );
};

export const DashboardMostActiveCompanies = () => {
  const query = useMostActiveCompaniesQuery({
    variables: { limit: 5 },
    fetchPolicy: `network-only`,
  });

  const router = useRouter();

  return (
    <WithQuery
      query={query}
      fallback={<DashboardMostActiveCompaniesSkeleton />}
    >
      {({ data: { mostActiveCompanies } }) => (
        <Card data-testid="most-active-companies" w="full">
          <CardHeader>
            <Text textStyle="heading-sm">Most Active Companies</Text>
          </CardHeader>
          <CardBody textAlign="center">
            <List mb="6" spacing={4}>
              {mostActiveCompanies.map((company) => {
                if (!company) return null;
                return <MostActiveCompany key={company.id} company={company} />;
              })}
            </List>
            <HiiveButton
              variant="text-salmon"
              onClick={() =>
                router.push(
                  `/companies/browse?orderBy=MARKET_ACTIVITY&searchText=`,
                )
              }
              observabilityLabel="[DashboardMostActiveCompanies] View more"
            >
              View more active companies
            </HiiveButton>
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};
