import {
  ActivityCard,
  ActivityCardStandingBidCardFooter,
} from "@/components/common";
import {
  CompanyStandingBidCardBody,
  CompanyStandingBidCardBodyV2,
  CompanyActivityStandingBidCardFooter,
  CompanyStandingBidCardHeader,
  CompanyStandingBidCardHeaderV2,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyMarketActivityStandingBidCardStandingBidFragment,
  CompanyPageMarketActivityCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

const CompanyMarketActivityStandingBidCard = withCurrentActor(
  ({
    standingBid,
    company,
    actor,
  }: {
    readonly standingBid: CompanyMarketActivityStandingBidCardStandingBidFragment;
    readonly company?: CompanyPageMarketActivityCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const areCardUpdatesEnabled = useCardUpdates();

    const url = `/standing-bids/${standingBid.id}`;
    const href =
      isUnaccreditedSeller && company?.id
        ? appendSellerCompanyIdToUrl(url, company.id)
        : url;

    if (areCardUpdatesEnabled) {
      return (
        <ActivityCard href={href}>
          <CompanyStandingBidCardHeaderV2 standingBid={standingBid} />
          <CompanyStandingBidCardBodyV2 standingBid={standingBid} />
          <CompanyActivityStandingBidCardFooter standingBid={standingBid} />
        </ActivityCard>
      );
    }

    return (
      <ActivityCard href={href}>
        <CompanyStandingBidCardHeader standingBid={standingBid} />
        <CompanyStandingBidCardBody
          standingBid={standingBid}
          company={company}
        />
        <ActivityCardStandingBidCardFooter standingBid={standingBid} />
      </ActivityCard>
    );
  },
);

export default CompanyMarketActivityStandingBidCard;
