import { CardFooter, Spacer } from "@chakra-ui/react";

import {
  ConditionallyCompletedBadge,
  ExpiryDate,
  InquiriesBadge,
  PartiallyAcceptedBadge,
} from "@/components/companies";
import {
  ActivityCardStandingBidCardFooterStandingBidFragment,
  StandingBidState,
} from "@/gql";

import ActivityCardFooterWrapper from "./ActivityCardFooterWrapper";

const ActivityCardStandingBidCardFooter = ({
  standingBid,
}: {
  readonly standingBid: ActivityCardStandingBidCardFooterStandingBidFragment;
}) => {
  const {
    expireAt,
    state,
    acceptedBidPrices,
    numDiscussions,
    numUnreadMessages,
  } = standingBid;

  const hasExpirationDate = !!expireAt;
  const hasInquires = numDiscussions >= 1;

  const isPartiallyAccepted =
    state === StandingBidState.Open && acceptedBidPrices.length >= 1;

  const isConditionallyCompleted =
    state === StandingBidState.ConditionallyCompleted;

  const hasFooter =
    hasExpirationDate ||
    isPartiallyAccepted ||
    isConditionallyCompleted ||
    hasInquires;

  if (!hasFooter) {
    return null;
  }

  return (
    <CardFooter>
      <ActivityCardFooterWrapper>
        {hasExpirationDate ? <ExpiryDate expireAt={expireAt} /> : <Spacer />}
        {isPartiallyAccepted && <PartiallyAcceptedBadge />}
        {isConditionallyCompleted && <ConditionallyCompletedBadge />}
        {hasInquires && (
          <InquiriesBadge
            numInquiries={numDiscussions}
            numUnreadMessages={numUnreadMessages}
          />
        )}
      </ActivityCardFooterWrapper>
    </CardFooter>
  );
};

export default ActivityCardStandingBidCardFooter;
