import { useCallback } from "react";
import { match } from "ts-pattern";

import { useRouter } from "next/router";

import { BasicUserFragment, InvestorType } from "@/gql";
import { useIsHiiveConnect, useSignOut } from "@/hooks";

const getHasConfiguredEnv = () => {
  const env = process.env.NEXT_PUBLIC_INFRA_ENV;

  if (!env) {
    return false;
  }

  return [`production`, `development`].includes(env);
};

export function useCorrectPortalRedirect() {
  const signOut = useSignOut();
  const isHiiveConnect = useIsHiiveConnect();
  const { replace } = useRouter();

  return useCallback(
    async ({ investorType }: BasicUserFragment) => {
      const hasConfiguredEnv = getHasConfiguredEnv();
      const clientHost = process.env.NEXT_PUBLIC_CLIENT_HOST || ``;
      const connectHost = process.env.NEXT_PUBLIC_CLIENT_CONNECT_HOST || ``;

      return match(investorType)
        .with(InvestorType.Broker, async () => {
          // Redirect broker users to broker portal if in main app
          if (!isHiiveConnect && hasConfiguredEnv) {
            signOut();
            const redirect = `${connectHost}${window.location.pathname}`;
            await replace(redirect);
          }
        })
        .otherwise(async () => {
          // Redirect non-broker users to main app if in broker portal
          const shouldRedirectConnect = isHiiveConnect && hasConfiguredEnv;

          if (shouldRedirectConnect) {
            signOut();

            const redirect = `${clientHost}${window.location.pathname}`;
            await replace(redirect);
          }
        });
    },
    [isHiiveConnect],
  );
}
