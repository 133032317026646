export default {
  not_tradeable_sell_side: `To sell your {{company}} shares, contact <mailto>{{email}}</mailto>.`,
  company_standing_bid_not_enabled: `You can place bids on any current {{company}} listings.`,
  submit_a_listing: `Submit a Listing`,
  submit_listing_cta: `Submit a listing to sell your customer's shares. Your listing may be posted on the Hiive marketplace, enabling buyers to bid on it.`,
  list_for_sale: `List for Sale`,
  create_listing_cta: `Create a listing for shares you have the ability to sell, enabling buyers to bid directly on your listing.`,
  sell: `Sell`,
  listing_sold_at_range: `{{numShares}} {{listingFraction}} sold from <b>{{minPps}}/{{listingFractionAbbreviation}}<b/> to <b>{{maxPps}}/{{listingFractionAbbreviation}}</b>`,
  listing_sold_at: `{{numShares}} {{listingFraction}} sold at <b>{{pps}}/{{listingFractionAbbreviation}}</b>`,
  this_fund_is_organized_by_hiive: `This fund is organized by Hiive`,
  listing: `Listing`,
  your_inquiry: `Your inquiry on this listing`,
  transfer_method: `Transfer type`,
  share_type: `Share type`,
};
