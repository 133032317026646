import { WarningCircle } from "@phosphor-icons/react";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { useColors } from "@/hooks";

const ConditionallyCompletedBadge = () => {
  const [grey100] = useColors([`grey.100`]);

  return (
    <CustomTooltip
      placement="bottom"
      tooltipContent="This Standing Bid is completed pending execution of a transaction document. If a transaction document is not signed within 14 days, then this Standing Bid will be re-opened."
    >
      <Badge variant="rounded-grey">
        <HStack spacing={1.5}>
          <WarningCircle color={grey100} weight="bold" size={14} />
          <Text fontSize={{ base: 12, lg: `inherit` }}>
            Conditionally completed
          </Text>
        </HStack>
      </Badge>
    </CustomTooltip>
  );
};

export default ConditionallyCompletedBadge;
