import { CardFooter } from "@chakra-ui/react";

import {
  ConditionallyCompletedBadge,
  InquiriesBadge,
  PartiallyAcceptedBadge,
} from "@/components/companies";
import {
  ActivityCardStandingBidCardFooterStandingBidFragment,
  StandingBidState,
} from "@/gql";

const ActivityCardStandingBidCardFooterV2 = ({
  standingBid,
}: {
  readonly standingBid: ActivityCardStandingBidCardFooterStandingBidFragment;
}) => {
  const { state, acceptedBidPrices, numDiscussions, numUnreadMessages } =
    standingBid;

  const isPartiallyAccepted =
    state === StandingBidState.Open && acceptedBidPrices.length >= 1;

  const isConditionallyCompleted =
    state === StandingBidState.ConditionallyCompleted;

  const hasInquires = numDiscussions >= 1;

  const hasFooter =
    isPartiallyAccepted || isConditionallyCompleted || hasInquires;

  if (!hasFooter) {
    return null;
  }

  return (
    <CardFooter
      px={3}
      py={3}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      {isPartiallyAccepted && <PartiallyAcceptedBadge />}
      {isConditionallyCompleted && <ConditionallyCompletedBadge />}
      {hasInquires && (
        <InquiriesBadge
          numInquiries={numDiscussions}
          numUnreadMessages={numUnreadMessages}
        />
      )}
    </CardFooter>
  );
};

export default ActivityCardStandingBidCardFooterV2;
