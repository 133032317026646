import { Trans } from "react-i18next";
import { match } from "ts-pattern";

import { CardBody, Flex, Stack, Text } from "@chakra-ui/react";

import {
  ActivityCardListingSolicitedCardBody,
  SensitiveText,
} from "@/components/common";
import {
  findHighestAcceptedBidSharePrice,
  findLowestAcceptedBidSharePrice,
  hasMultipleAcceptedBidSharePrices,
  PostingNumSharesPriceDetails,
} from "@/components/companies";
import {
  CompanyListingCardBodyCompanyFragment,
  CompanyListingCardBodyListingFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  calculateListingNumSharesSoldConditionally,
  formatPricePerShare,
  formatShares,
  getIsConditionallySoldListing,
  getIsPartiallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
  getShowListingSolicitedState,
} from "@/utils";

type CompanyListingCardbodyV2 = {
  readonly listing: CompanyListingCardBodyListingFragment;
  readonly company: CompanyListingCardBodyCompanyFragment;
};

const ListingAcceptedShares = ({
  listing,
}: {
  readonly listing: CompanyListingCardBodyListingFragment;
}) => {
  const { acceptedBidPrices } = listing;
  const numSharesSoldConditionally =
    calculateListingNumSharesSoldConditionally(listing);

  const hasMultipleAcceptedBids =
    hasMultipleAcceptedBidSharePrices(acceptedBidPrices);

  if (numSharesSoldConditionally === 0) return null;

  return (
    <SensitiveText textStyle="text-xs" color="grey.700">
      {match({ hasMultipleAcceptedBids })
        .with({ hasMultipleAcceptedBids: true }, () => (
          <Trans
            ns="transactions"
            i18nKey="shares_sold_from_v2"
            values={{
              numShares: formatShares(numSharesSoldConditionally),
              minPps: formatPricePerShare(
                findLowestAcceptedBidSharePrice(acceptedBidPrices),
              ),
              maxPps: formatPricePerShare(
                findHighestAcceptedBidSharePrice(acceptedBidPrices),
              ),
            }}
            components={{
              b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
            }}
          />
        ))
        .otherwise(() => (
          <Trans
            ns="transactions"
            i18nKey="shares_sold_at_v2"
            values={{
              numShares: formatShares(numSharesSoldConditionally),
              pps: formatPricePerShare(acceptedBidPrices[0]),
            }}
            components={{
              b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
            }}
          />
        ))}
    </SensitiveText>
  );
};

export function CompanyListingCardBodyV2({
  listing,
  company,
}: CompanyListingCardbodyV2) {
  const actor = useCurrentActor();
  const showListingSolicited = getShowListingSolicitedState(listing, actor);
  const { listingPricePerShare: pricePerShare } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);

  return (
    <CardBody p={3}>
      {showListingSolicited ? (
        <ActivityCardListingSolicitedCardBody
          spacing={0}
          alignItems="flex-start"
          textAlign="left"
          company={company}
        />
      ) : (
        <Stack
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
          gap={0.5}
        >
          <Flex wrap="wrap" columnGap={1.5}>
            <SensitiveText>
              <PostingNumSharesPriceDetails
                isPartiallyAccepted={getIsPartiallySoldListing(listing)}
                isConditionallyCompleted={getIsConditionallySoldListing(
                  listing,
                )}
                numSharesOriginal={numSharesOriginal}
                numSharesAvailable={numSharesAvailable}
                pricePerShare={pricePerShare!}
              />
            </SensitiveText>
          </Flex>
          <ListingAcceptedShares listing={listing} />
        </Stack>
      )}
    </CardBody>
  );
}
