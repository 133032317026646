import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import { useModal } from "@/hooks";
import {
  abbrCountLabel,
  bidLabel,
  countLabel,
  formatPricePerShare,
  formatShares,
  getBidNumSharesActual,
  getLongDocumentTitleByTransferMethod,
  isEitherFund,
  isOtherMethod,
} from "@/utils";

import { useAcceptCounterBidSequenceModalStepFormContext } from "./AcceptCounterBidSequenceModalStepFormContext";

const AcceptCounterBidSuccessModal = () => {
  const { t } = useTranslation();
  const { mutationData } = useAcceptCounterBidSequenceModalStepFormContext();

  const { closeModal } = useModal();

  const bid = mutationData?.acceptCounteredBid.bid;
  const listing = bid?.listing;

  if (!bid || !listing) return null;
  const useLOITerms = !isEitherFund(bid.listing) && !isOtherMethod(bid.listing);
  const textKey = isOtherMethod(bid.listing)
    ? `bid_accepted_congrats`
    : `bid_accepted_you_can_message`;

  const bidOrOrder = bidLabel(bid.listing);
  const share = countLabel(bid.listing);
  const abbrShare = abbrCountLabel(bid.listing);
  const numShares = formatShares(getBidNumSharesActual(bid));
  const pricePerShare = formatPricePerShare(bid.pricePerShare);
  const documentTitle = getLongDocumentTitleByTransferMethod(
    bid.listing.transferMethod,
  );
  return (
    <>
      <HiiveModalHeader>{`Countered ${bidOrOrder} accepted!`}</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {`${bid.displayId}: ${numShares} ${share}s @ ${pricePerShare}/${abbrShare}`}
        </Text>
        <VStack mb={4} alignItems="start">
          {useLOITerms && (
            <Text>{t(`bid_accepted_next_steps`, { documentTitle })}</Text>
          )}
          <Text>{t(textKey)}</Text>
          <Text>
            {t(`for_support`)}
            {` `}
            <SecuritySpecialistLink company={listing.company} />.
          </Text>
        </VStack>
      </ModalBody>
      <HiiveModalFooter>
        <HiiveSubmitButton
          observabilityLabel="[AcceptCounterBidSuccess/Submit]"
          submitText="Done"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default AcceptCounterBidSuccessModal;
