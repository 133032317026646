import { CardBody, CardHeader, HStack, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardListingCardFooter,
  ActivityCardListingNumShareAndPriceDetails,
} from "@/components/common";
import { UserActivityListingFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

import { DashboardYourListingCardV2 } from "./DashboardYourListingCardV2";

export const DashboardYourListingCard = ({
  listing,
}: {
  readonly listing: UserActivityListingFragment;
}) => {
  const areCardUpdatesEnabled = useCardUpdates();

  if (areCardUpdatesEnabled) {
    return <DashboardYourListingCardV2 listing={listing} />;
  }
  return (
    <ActivityCard href={`/listings/${listing.id}`}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="listing" title="Listing" />
            <Text textStyle="text-lg" color="grey.900">
              {listing.displayId}
            </Text>
          </HStack>
          <ActivityCardCompany company={listing.company} />
        </HStack>
      </CardHeader>
      <CardBody>
        <ActivityCardListingNumShareAndPriceDetails listing={listing} />
      </CardBody>
      <ActivityCardListingCardFooter listing={listing} />
    </ActivityCard>
  );
};
