import { Trans, useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { SecuritySpecialistLink } from "@/components/companies";
import {
  PlaceStandingBidSuccessModalStandingBidFragment,
  StandingBid,
} from "@/gql";
import { useModal } from "@/hooks";
import { formatPricePerShare, formatShares } from "@/utils";

type StandingBidContentType = {
  readonly pricePerShare: number;
  readonly company: Pick<StandingBid[`company`], "id" | "name">;
};

const PlaceStandingBidSuccessModalBrokerContent = ({
  numShares,
  standingBid,
}: {
  readonly numShares: number;
  readonly standingBid: StandingBidContentType;
}) => {
  const { t } = useTranslation(`standingBids`);
  return (
    <>
      <HiiveModalHeader>Standing bid is in review</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {t(`your_standing_bid_is_in_review`, {
            companyName: standingBid.company.name,
            numShares: formatShares(numShares),
            pricePerShare: formatPricePerShare(standingBid.pricePerShare),
          })}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>{t`your_standing_bid_is_being_reviewed`}</Text>
          <Text>
            <Trans
              i18nKey="you_may_also_reach_out"
              ns="standingBids"
              components={{
                mailto: (
                  <SecuritySpecialistLink company={standingBid.company} />
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};

const PlaceStandingBidSuccessModalBuyerContent = ({
  numShares,
  standingBid,
}: {
  readonly numShares: number;
  readonly standingBid: StandingBidContentType;
}) => {
  const { t } = useTranslation(`standingBids`);
  return (
    <>
      <HiiveModalHeader>Standing bid placed!</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-xl" mb={4}>
          {t(`your_standing_bid_is_live`, {
            companyName: standingBid.company.name,
            numShares: formatShares(numShares),
            pricePerShare: formatPricePerShare(standingBid.pricePerShare),
          })}
        </Text>
        <VStack mb={4} spacing={6} alignItems="start">
          <Text>
            {t(`potential_sellers_will_now_be_notified`, {
              companyName: standingBid.company.name,
            })}
          </Text>
          <Text>
            <Trans
              i18nKey="you_may_also_reach_out_for_assistance"
              ns="standingBids"
              components={{
                mailto: (
                  <SecuritySpecialistLink company={standingBid.company} />
                ),
              }}
            />
          </Text>
        </VStack>
      </ModalBody>
    </>
  );
};

const PlaceStandingBidSuccessModal = ({
  standingBid,
}: {
  readonly standingBid: PlaceStandingBidSuccessModalStandingBidFragment | null;
}) => {
  const { closeModal } = useModal();

  const numShares = standingBid?.numSharesOriginal || 0;
  const brokerPlaced = !!standingBid?.brokerId;

  if (!standingBid) return null;

  return (
    <>
      {brokerPlaced ? (
        <PlaceStandingBidSuccessModalBrokerContent
          numShares={numShares}
          standingBid={standingBid}
        />
      ) : (
        <PlaceStandingBidSuccessModalBuyerContent
          numShares={numShares}
          standingBid={standingBid}
        />
      )}
      <HiiveModalFooter>
        <HiiveSubmitButton
          data-testId="placeStandingBidSuccessButton"
          observabilityLabel="[PlaceStandingBidSuccess/Submit]"
          submitText="Okay"
          onClick={closeModal}
        />
      </HiiveModalFooter>
    </>
  );
};

export default PlaceStandingBidSuccessModal;
