import { Box, HStack } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { BidPostingCardFragment } from "@/gql";

type BidPostingCardFooterProps = {
  bid: BidPostingCardFragment;
};

export function BidPostingCardFooter({
  bid: { discussion },
}: BidPostingCardFooterProps) {
  if (!discussion) {
    return null;
  }

  const { numUnreadMessages, totalNumMessages } = discussion;

  if (totalNumMessages < 1) {
    return null;
  }

  return (
    <PostingCard.Footer>
      <HStack w="full">
        <Box flexGrow={1} />
        <MessagesBadge
          numUnreadMessages={numUnreadMessages}
          totalNumMessages={totalNumMessages}
        />
      </HStack>
    </PostingCard.Footer>
  );
}
