import { WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Box, Card, CardBody, Stack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment } from "@/gql";
import {
  useColors,
  useCustomToast,
  useIdentityVerificationModal,
} from "@/hooks";

interface VerifyYourIdentityCardProps {
  readonly actor: UserWithInstitutionFragment;
}

export const VerifyYourIdentityCard = withCurrentActor(
  ({ actor }: VerifyYourIdentityCardProps) => {
    const { infoToast } = useCustomToast();
    const [red600] = useColors([`red.600`]);
    const { t } = useTranslation();

    const handleOnComplete = () => {
      infoToast(t(`iv_in_progress`), {
        description: t(`identity_verification_being_processed`),
        icon: <WarningCircle color={red600} size={26} />,
      });
    };

    const { isOpen, onOpen } = useIdentityVerificationModal({
      actor,
      onComplete: handleOnComplete,
    });

    return (
      <Card>
        <CardBody>
          <Stack
            direction={{ base: `column`, md: `row` }}
            alignItems={{ base: `left`, md: `center` }}
            justifyContent="space-between"
            spacing={5}
          >
            <VStack alignItems="flex-start" w="full">
              <Text textStyle="heading-sm">Verify Your Identity</Text>
              <Text textStyle="text-md">
                Before you can sell shares or accept a buyer&apos;s bid, you
                must verify your identity. This protects the security of your
                transactions and ensures regulatory compliance. We use a third
                party service to perform the verification. You will need a
                webcam or a camera on your device to verify your government
                issued ID.
              </Text>
            </VStack>
            <Box flex="1">
              <HiiveButton
                variant="rounded-solid-salmon"
                size="xl"
                isDisabled={isOpen}
                onClick={onOpen}
                data-testid="verify-identity-button"
                observabilityLabel="[VerifyYourIdentityCard] Verify"
              >
                Verify Your Identity
              </HiiveButton>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    );
  },
);
