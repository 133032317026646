import {
  ActivityCard,
  ActivityCardListingCardFooter,
} from "@/components/common";
import {
  CompanyListingCardBody,
  CompanyListingCardHeader,
  CompanyListingCardHeaderV2,
  CompanyListingCardBodyV2,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyMarketActivityListingCardCompanyFragment,
  CompanyMarketActivityListingCardListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

const CompanyMarketActivityListingCard = withCurrentActor(
  ({
    listing,
    company,
    actor,
  }: {
    readonly listing: CompanyMarketActivityListingCardListingFragment;
    readonly company: CompanyMarketActivityListingCardCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const cardUpdatesEnabled = useCardUpdates();
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const url = `/listings/${listing.id}`;
    return (
      <ActivityCard
        href={
          isUnaccreditedSeller
            ? appendSellerCompanyIdToUrl(url, company.id)
            : url
        }
      >
        {cardUpdatesEnabled ? (
          <CompanyListingCardHeaderV2 listing={listing} />
        ) : (
          <CompanyListingCardHeader listing={listing} />
        )}

        {cardUpdatesEnabled ? (
          <CompanyListingCardBodyV2 listing={listing} company={company} />
        ) : (
          <CompanyListingCardBody listing={listing} company={company} />
        )}

        <ActivityCardListingCardFooter listing={listing} />
      </ActivityCard>
    );
  },
);

export default CompanyMarketActivityListingCard;
