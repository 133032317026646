import { useTranslation } from "react-i18next";

import { CardFooter, Flex, Stack, Text } from "@chakra-ui/react";

import {
  ConditionallyCompletedBadge,
  PartiallyAcceptedBadge,
  InquiriesBadge,
} from "@/components/companies";
import {
  ActivityCardStandingBidCardFooterStandingBidFragment,
  CompanyYourActivityStandingBidCardStandingBidFragment,
  StandingBidState,
} from "@/gql";
import {
  getIsConditionallyCompletedStandingBid,
  getIsPartiallyAcceptedStandingBid,
  toTimestamp,
} from "@/utils";

const InReviewState = ({
  standingBid,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
}) => {
  const { t } = useTranslation();

  return (
    <Text color="grey.700" textStyle="text-sm">
      {t(`standing_bid_in_review_status`, {
        date: toTimestamp(standingBid.insertedAt),
      })}
    </Text>
  );
};

const CompanyStandingBidCardFooterV2 = ({
  standingBid,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
}) => {
  const { state, acceptedBidPrices, numDiscussions, numUnreadMessages } =
    standingBid;

  const isPartiallyAccepted =
    state === StandingBidState.Open && acceptedBidPrices.length >= 1;

  const isConditionallyCompleted =
    state === StandingBidState.ConditionallyCompleted;

  const hasDiscussions = numDiscussions >= 1;

  const isInReview = state === StandingBidState.InReview;

  const hasFooter =
    isPartiallyAccepted ||
    isConditionallyCompleted ||
    hasDiscussions ||
    isInReview;

  if (!hasFooter) return null;

  return (
    <CardFooter>
      <Flex direction="row" justify="flex-end" align="center" py={1} w="full">
        {isInReview && <InReviewState standingBid={standingBid} />}
        <Stack
          direction={{ base: `column`, md: `row` }}
          wrap="wrap"
          align="flex-end"
          spacing={{ base: 2, md: 3 }}
        >
          {isPartiallyAccepted && <PartiallyAcceptedBadge />}
          {isConditionallyCompleted && <ConditionallyCompletedBadge />}
          {hasDiscussions && (
            <InquiriesBadge
              numInquiries={numDiscussions}
              numUnreadMessages={numUnreadMessages}
            />
          )}
        </Stack>
      </Flex>
    </CardFooter>
  );
};

const CompanyActivityStandingBidCardFooter = ({
  standingBid,
}: {
  readonly standingBid: ActivityCardStandingBidCardFooterStandingBidFragment;
}) => {
  const isPartiallyAccepted = getIsPartiallyAcceptedStandingBid(standingBid);

  const isConditionallyCompleted =
    getIsConditionallyCompletedStandingBid(standingBid);

  const hasFooter = isPartiallyAccepted || isConditionallyCompleted;

  if (!hasFooter) {
    return null;
  }

  return (
    <CardFooter>
      <Flex direction="row" justify="flex-end" align="center" py={1} w="full">
        {isPartiallyAccepted && <PartiallyAcceptedBadge />}
        {isConditionallyCompleted && <ConditionallyCompletedBadge />}
      </Flex>
    </CardFooter>
  );
};

export { CompanyActivityStandingBidCardFooter, CompanyStandingBidCardFooterV2 };
