import {
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  SensitiveText,
} from "@/components/common";
import {
  MessagesBadge,
  PostingNumSharesPriceDetails,
} from "@/components/companies";
import { UserActivityBidFragment } from "@/gql";
import { makeUrl, bidLabel } from "@/utils";

export const DashboardYourBidCardV2 = ({
  bid,
}: {
  readonly bid: UserActivityBidFragment;
}) => {
  const title = bidLabel(bid.listing);
  const { pricePerShare, numShares } = bid;

  return (
    <ActivityCard href={makeUrl(bid)}>
      <CardHeader px={3} py={2}>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title={title} />
            <Text
              textStyle={{ base: `text-sm`, lg: `text-lg` }}
              color="grey.900"
            >
              {bid.displayId}
            </Text>
          </HStack>
          <ActivityCardCompany company={bid.company} />
        </HStack>
      </CardHeader>
      <CardBody p={3}>
        <Flex wrap="wrap" columnGap={1.5}>
          <SensitiveText>
            <PostingNumSharesPriceDetails
              isPartiallyAccepted={false}
              isConditionallyCompleted={false}
              numSharesOriginal={numShares}
              numSharesAvailable={numShares}
              pricePerShare={pricePerShare}
            />
          </SensitiveText>
        </Flex>
      </CardBody>
      {!!bid.discussion && bid.discussion.totalNumMessages > 0 && (
        <CardFooter
          px={3}
          py={3}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <MessagesBadge
            totalNumMessages={bid.discussion.totalNumMessages}
            numUnreadMessages={bid.discussion.numUnreadMessages}
          />
        </CardFooter>
      )}
    </ActivityCard>
  );
};
