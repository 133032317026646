import { useTranslation } from "react-i18next";

import { GridItem, ModalBody, Show, SimpleGrid } from "@chakra-ui/react";

import {
  FullDivider,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveNextButton,
} from "@/components/common";
import { StandingBidAcceptedTransferMethods2 } from "@/components/postings";
import {
  FormNumberInput,
  FormMoneyInput,
  FormListBox,
  FormConditionalInput,
  FormCompaniesCombobox,
} from "@/components/react-hook-form";
import {
  BrokerStandingBidAllowPartialAcceptTooltip,
  StandingBidAllowPartialAcceptTooltip,
} from "@/components/tooltip";
import { StandingBidAcceptsSharesOptions } from "@/gql";
import { useCurrentActor, useModal } from "@/hooks";
import { acceptsSharesToString, getIsBroker } from "@/utils";

import { PlaceStandingBidModalProps } from "./PlaceStandingBidModal";

export const BidDetails = ({
  stepRouter,
  initialCompany,
  formProps: { watch, control, trigger },
}: PlaceStandingBidModalProps) => {
  const actor = useCurrentActor();
  const actorIsBroker = getIsBroker(actor);
  const { t } = useTranslation(`standingBids`);
  const { closeModal } = useModal();
  const values = watch();
  const { stepControls } = stepRouter;

  const onNext = async () => {
    const isValid = await trigger([
      `company`,
      `numShares`,
      `pricePerShare`,
      `acceptsShares`,
      `allowPartialAccept`,
      `minPartialAcceptNumShares`,
      `acceptsTransferMethods`,
    ]);

    if (isValid) stepControls.nextStep();
  };

  const { allowPartialAccept } = values;

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {!initialCompany && (
            <>
              <GridItem colSpan={2}>
                <FormCompaniesCombobox
                  name="company"
                  label={t`which_company`}
                  control={control}
                  showSearchIcon
                  showClearIcon
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FullDivider />
              </GridItem>
            </>
          )}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormNumberInput
              control={control}
              name="numShares"
              label={t`number_of_shares`}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormMoneyInput
              control={control}
              name="pricePerShare"
              label={t`price_per_share`}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <FormListBox
              control={control}
              name="acceptsShares"
              label={t`share_type`}
              placeholder={t`share_type`}
              itemToString={(item: StandingBidAcceptsSharesOptions) =>
                acceptsSharesToString(item)
              }
              getItemKey={(item) => item}
              items={[
                StandingBidAcceptsSharesOptions.Both,
                StandingBidAcceptsSharesOptions.Common,
                StandingBidAcceptsSharesOptions.Pref,
              ]}
            />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormConditionalInput
              name="allowPartialAccept"
              label={t`allow_partial_acceptance`}
              tooltipContent={
                actorIsBroker ? (
                  <BrokerStandingBidAllowPartialAcceptTooltip />
                ) : (
                  <StandingBidAllowPartialAcceptTooltip />
                )
              }
              control={control}
            />
          </GridItem>
          {allowPartialAccept && (
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormNumberInput
                pt={3}
                control={control}
                name="minPartialAcceptNumShares"
                label={t`minimum_number_of_shares`}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <StandingBidAcceptedTransferMethods2
              control={control}
              label={
                actorIsBroker
                  ? t(`submit_standing_bid_accepted_transfer_methods_label`)
                  : t(`place_standing_bid_accepted_transfer_methods_label`)
              }
              name="acceptsTransferMethods"
            />
          </GridItem>
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[PlaceStandingBid/BidDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveNextButton
          onClick={onNext}
          type={undefined}
          observabilityLabel="[PlaceStandingBid/BidDetails/Next]"
        />
      </HiiveModalFooter>
    </>
  );
};
