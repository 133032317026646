/* eslint-disable object-shorthand */

/* eslint-disable func-names */
import { useField } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  GridItem,
  HStack,
  ModalBody,
  Radio,
  Show,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveAdvancedOptionsDropdown,
  HiiveBackButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveSubmitButton,
} from "@/components/common";
import {
  CheckboxInput,
  DateInput,
  FormRadioInput,
  OtherDetailsInput,
  StepPropsV2,
} from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  ShareSeriesMakeupElement,
  TransferMethodInput,
} from "@/components/postings";
import { ListingOtherDetailsTooltip } from "@/components/tooltip";
import {
  ModifyListingModalListingFragment,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCustomToast, useModal, useStepValidator } from "@/hooks";
import { useRsuRestrictionAndEligibleBuyers } from "@/hooks/featureFlags";
import {
  checkContainsRestrictedStockUnit,
  fromBool,
  toBool,
  checkTransferMethodManual,
} from "@/utils";
import { pipe } from "@/utils/pipe";

import {
  ModifyListingModalStepFormContext,
  useModifyListingModalStepFormContext,
} from "./ModifyListingModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { ModifyListingModalFormValues } from "./types";

export const validationSchema = Yup.object().shape({
  transferMethod: Yup.string().required(`Required`),
  hasExpirationDate: Yup.bool().required(`Required`),
  expireAt: Yup.string()
    .nullable()
    .test({
      name: `checkRequired`,
      message: `Required`,
      test: function () {
        if (this.parent.hasExpirationDate) {
          return !!this.parent.expireAt;
        }
        return true;
      },
    }),
  otherDetails: Yup.string().nullable(),
  muteNotifyWatchers: Yup.boolean().nullable(),
  manualSigningProcedure: Yup.boolean(),
});

interface AdditionalDetailsProps
  extends StepPropsV2<StepKeys, ModifyListingModalFormValues> {
  readonly listing: ModifyListingModalListingFragment;
  readonly actor: UserWithInstitutionFragment;
}

const AdditionalDetails = ({
  actor,
  values,
  listing,
  isSubmitting,
  stepRouter,
}: AdditionalDetailsProps) => {
  const rsuRestrictionAndEligibleBuyers = useRsuRestrictionAndEligibleBuyers();

  const { closeModal } = useModal();
  const { successToast } = useCustomToast();
  const { stepControls } = stepRouter;
  const { t } = useTranslation();

  const { transferMethod, shareSeriesMakeup } = values;

  const { submitMutation } = useModifyListingModalStepFormContext();

  const isPartiallySold =
    listing.numSharesAvailable < listing.numSharesOriginal;

  const onSuccess = () =>
    submitMutation().then(() => {
      successToast(`Listing modified.`);
      closeModal();
    });

  useStepValidator({
    Context: ModifyListingModalStepFormContext,
    stepKey: stepKeys.modifyListingAdditionalDetails,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const [_field, _, { setValue: setManualSigningProcedure }] = useField(
    `manualSigningProcedure`,
  );

  const isTransferMethodManual = checkTransferMethodManual(transferMethod);

  const hasRestrictedStockUnit = useMemo(() => {
    const containsRestrictedStockUnit = pipe(shareSeriesMakeup)
      .step((shareSeriesElements) =>
        shareSeriesElements.filter(
          (element): element is ShareSeriesMakeupElement => !!element,
        ),
      )
      .step(checkContainsRestrictedStockUnit)
      .end();

    return !!rsuRestrictionAndEligibleBuyers && containsRestrictedStockUnit;
  }, [rsuRestrictionAndEligibleBuyers, shareSeriesMakeup]);

  const handleChangeTransferMethod = (transferMethod: TransferMethod) => {
    if (checkTransferMethodManual(transferMethod)) {
      return setManualSigningProcedure(true);
    }

    return setManualSigningProcedure(false);
  };

  return (
    <>
      <ModalBody>
        <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
          {!isPartiallySold && (
            <GridItem colSpan={2} data-testid="transfer-method-input">
              <TransferMethodInput
                onChange={handleChangeTransferMethod}
                name="transferMethod"
                label="How will these be transferred?"
                isDisabled={hasRestrictedStockUnit}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <FormRadioInput
              label={t(`end_date_question`)}
              name="hasExpirationDate"
              mapper={{ from: fromBool, to: toBool }}
              data-testid="modify-listing-end-date-question"
              tooltipContent={t(
                `listing_additional_details_expiry_date_tooltip`,
              )}
            >
              <HStack spacing={12}>
                <Radio variant="base" value="true">
                  {t(`yes`)}
                </Radio>
                <Radio variant="base" value="false">
                  {t(`no`)}
                </Radio>
              </HStack>
            </FormRadioInput>
          </GridItem>
          {values.hasExpirationDate && (
            <GridItem colSpan={2}>
              <DateInput
                name="expireAt"
                label={t(`end_date_input_label`)}
                tooltipContent={t(`end_date_input_tooltip`)}
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <OtherDetailsInput
              name="otherDetails"
              label="Additional listing notes"
              placeholder="Add details"
              tooltipContent={<ListingOtherDetailsTooltip />}
            />
          </GridItem>
          {actor.isHiiveUser && (
            <GridItem colSpan={2}>
              <HiiveAdvancedOptionsDropdown
                validationSchema={validationSchema}
                fieldNames={[`manualSigningProcedure`, `muteNotifications`]}
              >
                <VStack spacing={4} alignItems="flex-start">
                  {!!values.transferMethod && (
                    <CheckboxInput
                      isDisabled={isTransferMethodManual}
                      name="manualSigningProcedure"
                      label={t(`manual_signing_procedure`)}
                    />
                  )}
                  <CheckboxInput
                    name="muteNotifications"
                    label="Do not send notifications about this change."
                  />
                </VStack>
              </HiiveAdvancedOptionsDropdown>
            </GridItem>
          )}
        </SimpleGrid>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[ModifyListing/AdditionalDetails/Cancel]"
            onCancel={closeModal}
          />
        </Show>
        <HiiveBackButton
          observabilityLabel="[ModifyListing/AdditionalDetails/Back]"
          onClick={stepControls.previousStep}
        />
        <HiiveSubmitButton
          observabilityLabel="[ModifyListing/AdditionalDetails/Submit]"
          isLoading={isSubmitting}
          submitText="Modify Listing"
          type="submit"
        />
      </HiiveModalFooter>
    </>
  );
};

export default withCurrentActor(AdditionalDetails);
