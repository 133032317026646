import { RefObject, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Card, VStack, GridItem, Flex, CardBody } from "@chakra-ui/react";

import {
  SectionNavBackButton,
  SectionNavButton,
  SectionNavWrapper,
} from "@/components/listings";
import { CurrentContextDocument } from "@/gql";
import { useApolloClient } from "@/hooks/useApolloClient";

import { sectionKeys } from "./ModernTreasuryConnectAccount";

const ModernTreasuryConnectAccountNavigation = ({
  getNavButtonProps,
  containerRef,
  isAccountDetailsAvailable,
  isVerifyAccountAvailable,
}: {
  readonly getNavButtonProps: (sectionKey: keyof typeof sectionKeys) => {
    readonly onClick?: () => void;
    readonly isActive: boolean;
  };
  readonly containerRef: RefObject<HTMLDivElement>;
  readonly isAccountDetailsAvailable: boolean;
  readonly isVerifyAccountAvailable: boolean;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const apolloClient = useApolloClient();

  const transactionId = router.query.id as string;
  const accountDetailsNavProps = getNavButtonProps(sectionKeys.AccountDetails);

  const verifyAccountNavProps = getNavButtonProps(sectionKeys.VerifyAccount);

  useEffect(() => {
    // auto nav to account details as soon as is available
    if (isAccountDetailsAvailable && accountDetailsNavProps.onClick) {
      accountDetailsNavProps.onClick();
    }
  }, [isAccountDetailsAvailable]);

  useEffect(() => {
    // auto nav to verify account as soon as is available
    if (isVerifyAccountAvailable && verifyAccountNavProps.onClick) {
      verifyAccountNavProps.onClick();
    }
  }, [isVerifyAccountAvailable]);

  return (
    <GridItem display={{ base: `none`, lg: `grid` }}>
      <SectionNavWrapper containerElement={containerRef.current}>
        <SectionNavBackButton
          onClick={async () => {
            await apolloClient.refetchQueries({
              include: [CurrentContextDocument],
            });
            router.push(`/transactions/${transactionId}`);
          }}
          observabilityLabel="[ModernTreasuryConnectAccountNavigation/Back]"
        />
        <Flex direction="column" gap={3}>
          <Card w="full">
            <CardBody>
              <VStack spacing={2}>
                <SectionNavButton
                  {...getNavButtonProps(sectionKeys.CountryOfAccount)}
                >
                  {`1. ${t`country_of_account`}`}
                </SectionNavButton>
                <SectionNavButton {...accountDetailsNavProps}>
                  {`2. ${t`account_details`}`}
                </SectionNavButton>
                <SectionNavButton {...verifyAccountNavProps}>
                  {`3. ${t`verify_account`}`}
                </SectionNavButton>
              </VStack>
            </CardBody>
          </Card>
        </Flex>
      </SectionNavWrapper>
    </GridItem>
  );
};

export default ModernTreasuryConnectAccountNavigation;
