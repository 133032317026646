import { ActivityCard } from "@/components/common";
import {
  CompanyStandingBidCardBody,
  CompanyStandingBidCardHeader,
  CompanyStandingBidCardFooter,
  CompanyStandingBidCardHeaderV2,
  CompanyStandingBidCardBodyV2,
  CompanyStandingBidCardFooterV2,
} from "@/components/companies";
import {
  CompanyYourActivityStandingBidCardCompanyFragment,
  CompanyYourActivityStandingBidCardStandingBidFragment,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

const CompanyYourActivityStandingBidCard = ({
  standingBid,
  company,
}: {
  readonly standingBid: CompanyYourActivityStandingBidCardStandingBidFragment;
  readonly company: CompanyYourActivityStandingBidCardCompanyFragment;
}) => {
  const areCardUpdatesEnabled = useCardUpdates();
  const href = `/standing-bids/${standingBid.id}`;

  if (areCardUpdatesEnabled) {
    return (
      <ActivityCard href={href}>
        <CompanyStandingBidCardHeaderV2 standingBid={standingBid} />
        <CompanyStandingBidCardBodyV2 standingBid={standingBid} />
        <CompanyStandingBidCardFooterV2 standingBid={standingBid} />
      </ActivityCard>
    );
  }
  return (
    <ActivityCard href={href}>
      <CompanyStandingBidCardHeader standingBid={standingBid} />
      <CompanyStandingBidCardBody standingBid={standingBid} company={company} />
      <CompanyStandingBidCardFooter standingBid={standingBid} />
    </ActivityCard>
  );
};

export default CompanyYourActivityStandingBidCard;
