import { ReactNode } from "react";

import {
  Box,
  Button,
  Card,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

interface ActionCardAction {
  readonly label: string;
  readonly onClick: () => void;
  readonly variant?:
    | "rounded-outline-grey"
    | "rounded-outline-red"
    | "rounded-solid-salmon";
}

interface ActionCardProps {
  readonly action: ActionCardAction;
  readonly badge?: ReactNode;
  readonly icon: ReactNode;
  readonly subText?: string;
  readonly text: string;
}

const ActionCard = ({
  action,
  badge,
  icon,
  subText,
  text,
}: ActionCardProps) => (
  <Card w="full" p={4} variant="flat">
    <Stack
      alignItems="center"
      direction={{ base: `column`, lg: `row` }}
      gap={4}
    >
      <Box
        alignItems="center"
        backgroundColor="grey.50"
        borderRadius="100%"
        display="flex"
        height="2.5rem"
        justifyContent="center"
        width="2.5rem"
        flexShrink={0}
      >
        {icon}
      </Box>

      <VStack alignItems={{ base: `center`, lg: `flex-start` }}>
        <HStack>
          <Text textStyle="heading-md">{text}</Text>
          {badge}
        </HStack>
        {subText && (
          <Text
            textStyle="text-sm"
            textAlign={{ base: `center`, lg: `initial` }}
          >
            {subText}
          </Text>
        )}
      </VStack>

      <Spacer display={{ base: `none`, lg: `block` }} />

      {action && (
        <Button
          onClick={action.onClick}
          size="md"
          variant={action.variant || `rounded-outline-grey`}
        >
          {action.label}
        </Button>
      )}
    </Stack>
  </Card>
);

export default ActionCard;
