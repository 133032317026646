import { useTranslation } from "react-i18next";

import { CardFooter, VStack, HStack, Radio, Text, Box } from "@chakra-ui/react";

import { FormRadioInput, DateInput } from "@/components/form";
import { fromBool, toBool } from "@/utils";

type ExpiryDateCardContentProps = {
  readonly hasExpirationDate: boolean | null;
};

export function ExpiryDateCardContent({
  hasExpirationDate,
}: ExpiryDateCardContentProps) {
  const { t } = useTranslation();

  return (
    <CardFooter>
      <VStack gap={6} alignItems="flex-start">
        <Text>{t(`listing_will_automatically_expire`)}</Text>
        <FormRadioInput
          label={t(`end_date_question`)}
          name="hasExpirationDate"
          mapper={{ from: fromBool, to: toBool }}
        >
          <HStack spacing={12}>
            <Radio variant="base" value={Boolean(true).toString()}>
              {t(`yes`)}
            </Radio>
            <Radio variant="base" value={Boolean(false).toString()}>
              {t(`no`)}
            </Radio>
          </HStack>
        </FormRadioInput>
        {hasExpirationDate && (
          <Box w="45%">
            <DateInput name="expireAt" label={t(`end_date_input_label`)} />
          </Box>
        )}
      </VStack>
    </CardFooter>
  );
}
