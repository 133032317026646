import { match } from "ts-pattern";

import { SigningProcedure, TransferMethod } from "@/gql";

export function getSigningProcedure(
  transferMethod: TransferMethod,
  manualSigningProcedure?: boolean,
) {
  if (manualSigningProcedure) {
    return SigningProcedure.Manual;
  }

  return match(transferMethod)
    .with(TransferMethod.Direct, () => SigningProcedure.Automated)
    .otherwise(() => SigningProcedure.Manual);
}
