import { motion } from "framer-motion";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Fade,
  Flex,
  HStack,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardPlacedByHiive,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  WithQuery,
  HiiveButton,
  Skeleton,
  ActivityCardSkeleton,
  ActivityCardFooterCountsWrapper,
  ActivityCardNewBadge,
} from "@/components/common";
import { CompanyStandingBidCardTransferDetails } from "@/components/companies";
import { UnaccreditedSellerListForSaleSvg } from "@/components/svg";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import {
  PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompanyFragment,
  PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListingFragment,
  PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment,
  usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery,
} from "@/gql";
import {
  getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard,
  getNumOfShares,
} from "@/utils";

const StandingBidCardDetails = ({
  standingBid,
}: {
  readonly standingBid: PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment;
}) => {
  const numShares = getNumOfShares(standingBid);

  return !isNil(numShares) ? (
    <HStack justifyContent="space-between" spacing={6}>
      <ActivityCardPricingWrapper>
        <ActivityCardPricingNumSharesAndPrice
          numShares={numShares}
          price={standingBid.pricePerShare}
        />
        <ActivityCardPricingTotal
          numShares={numShares}
          price={standingBid.pricePerShare}
        />
      </ActivityCardPricingWrapper>
      <CompanyStandingBidCardTransferDetails standingBid={standingBid} />
    </HStack>
  ) : null;
};

const StandingBidCard = ({
  standingBid,
}: {
  readonly standingBid: PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment;
}) => {
  const { getIsNewStandingBid } = useUnaccreditedSellerInlineNotifications();

  const isNewStandingBid = getIsNewStandingBid(standingBid);

  return (
    <ActivityCard href={`/standing-bids/${standingBid.id}`}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title="Standing Bid" />
            <Text textStyle="text-lg">{standingBid.displayId}</Text>
          </HStack>
          {standingBid.fromHiive && <ActivityCardPlacedByHiive />}
        </HStack>
      </CardHeader>
      <CardBody>
        <StandingBidCardDetails standingBid={standingBid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTimestamp title="Placed" date={standingBid.placedAt} />
            {!!standingBid.expireAt && (
              <ActivityCardTimestamp
                title="Expires"
                date={standingBid.expireAt}
              />
            )}
          </ActivityCardFooterStatusWrapper>
          {isNewStandingBid && (
            <ActivityCardFooterCountsWrapper>
              <Fade in>
                <ActivityCardNewBadge />
              </Fade>
            </ActivityCardFooterCountsWrapper>
          )}
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};

const ListYourSharesCard = () => {
  const router = useRouter();

  return (
    <Card w="full" overflow="hidden">
      <CardBody position="relative">
        <VStack spacing={8} w="full" alignItems="flex-start">
          <VStack alignItems="flex-start" h="full">
            <Text textStyle="heading-3xl">List Your Shares for Sale</Text>
            <Text textStyle="text-md" maxW={{ base: `unset`, xl: `50%` }}>
              Post your shares or unexercised options for sale. By creating a
              listing you can set your own asking price. A buyer may then make
              you an offer at or below your price. You will have the ability to
              accept, or make a counter-offer against, any bid that you receive.
              Buyers may also reach out to you via our messaging function, in
              order to discuss your listing.{` `}
            </Text>
          </VStack>
          <HiiveButton
            variant="rounded-solid-salmon"
            size="xl"
            boxShadow="card"
            px={16}
            onClick={() => router.push(`/create-listing`)}
            observabilityLabel="[UnaccreditedSellerDashboardPage/ListYourSharesCard] Create Listing"
          >
            Create Listing
          </HiiveButton>
        </VStack>
        <Show above="xl" ssr={false}>
          <Box
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Box
              position="absolute"
              zIndex="0"
              h="full"
              w="50%"
              right="0"
              top="0"
              preserveAspectRatio="xMinYMid slice"
              as={UnaccreditedSellerListForSaleSvg}
            />
          </Box>
        </Show>
      </CardBody>
    </Card>
  );
};

const StandingBidsWithDiscussionsEmptyState = () => (
  <ActivityGroupEmptyState message="You are not engaged in any inquiries on standing bids." />
);

const StandingBidsWithDiscussions = ({
  standingBidsWithDiscussions,
}: {
  readonly standingBidsWithDiscussions: readonly PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment[];
}) => (
  <ActivityGroup emptyState={<StandingBidsWithDiscussionsEmptyState />}>
    {standingBidsWithDiscussions.map((standingBidWithDiscussion) => (
      <StandingBidCard
        key={standingBidWithDiscussion.id}
        standingBid={standingBidWithDiscussion}
      />
    ))}
  </ActivityGroup>
);

const AvailableStandingBidsEmptyState = ({
  company,
}: {
  readonly company: PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompanyFragment;
}) => {
  const { t } = useTranslation();
  return (
    <ActivityGroupEmptyState
      message={t(`no_standing_bids`, {
        companyName: company.name,
      })}
    />
  );
};

const AvailableStandingBids = ({
  availableStandingBids,
  company,
}: {
  readonly availableStandingBids: readonly PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment[];
  readonly company: PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompanyFragment;
}) => (
  <ActivityGroup
    emptyState={<AvailableStandingBidsEmptyState company={company} />}
  >
    {availableStandingBids.map((availableStandingBid) => (
      <StandingBidCard
        key={availableStandingBid.id}
        standingBid={availableStandingBid}
      />
    ))}
  </ActivityGroup>
);

const StandingBidsPageContent = ({
  availableStandingBids,
  standingBidsWithDiscussions,
  company,
  listing,
}: {
  readonly availableStandingBids: readonly PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment[];
  readonly standingBidsWithDiscussions: readonly PostActivityUnaccreditedSellerDashboardPageStandingBidsPageStandingBidFragment[];
  readonly company: PostActivityUnaccreditedSellerDashboardPageStandingBidsPageCompanyFragment;
  readonly listing?: PostActivityUnaccreditedSellerDashboardPageStandingBidsPageListingFragment | null;
}) => {
  const hasListYourSharesCard = !listing;

  return (
    <VStack
      spacing={8}
      {...(hasListYourSharesCard && {
        transform: { base: `none`, lg: `translateY(-48px)` },
        pt: { base: 4, lg: 0 },
      })}
      {...(!hasListYourSharesCard && {
        pt: { base: 4, lg: 8 },
      })}
    >
      {hasListYourSharesCard && (
        <Fade in>
          <ListYourSharesCard />
        </Fade>
      )}
      <ActivitySection>
        <VStack alignItems="flext-start" spacing={4}>
          <Text textStyle="heading-sm" textTransform="uppercase">
            Inquiries on Standing Bids
          </Text>
          <Text textStyle="text-md">
            Click on a standing bid to view and continue a previously initiated
            conversation with a buyer.
          </Text>
          <StandingBidsWithDiscussions
            standingBidsWithDiscussions={standingBidsWithDiscussions}
          />
        </VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <Text textStyle="heading-sm" textTransform="uppercase">
            Available Standing Bids
          </Text>
          <Text textStyle="text-md">
            Browse standing bids, which are offers made by potential buyers to
            buy up to a specific quantity of stock at a certain price. You can
            message the buyer with any questions. You cannot counter-offer
            standing bids, but you can ask the buyer to modify their price.
          </Text>
          <AvailableStandingBids
            availableStandingBids={availableStandingBids}
            company={company}
          />
        </VStack>
      </ActivitySection>
    </VStack>
  );
};

const StandingBidsPageSkeleton = () => (
  <VStack spacing={8} w="full" pt={{ base: 4, lg: 8 }}>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="208px" />
      <Skeleton h="16px" maxW="75%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="196px" />
      <Skeleton h="16px" maxW="95%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </VStack>
);

const StandingBidsPage = () => {
  const query =
    usePostActivityUnaccreditedSellerDashboardPageStandingBidsPageQuery();
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<StandingBidsPageSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity,
          unaccreditedSellerMyCompanyActivity,
        },
      }) => {
        const { myListing, myCompany } = unaccreditedSellerMyActivity;

        if (
          !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboard(
            unaccreditedSellerMyActivity,
            unaccreditedSellerMyCompanyActivity,
          ) &&
          !myListing
        ) {
          router.replace(`/dashboard`);
          return null;
        }

        const { availableStandingBids, standingBidsWithDiscussions } =
          unaccreditedSellerMyCompanyActivity;

        return (
          <StandingBidsPageContent
            company={myCompany}
            listing={myListing}
            availableStandingBids={availableStandingBids}
            standingBidsWithDiscussions={standingBidsWithDiscussions}
          />
        );
      }}
    </WithQuery>
  );
};

export default StandingBidsPage;
