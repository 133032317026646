import { useRouter } from "next/router";

import { Card, CardBody, CardFooter, HStack } from "@chakra-ui/react";

import {
  ActivityCardFooterCountsWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTransactionStatus,
} from "@/components/common";
import { UserActivityTransactionFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { makeUrl } from "@/utils";

import DashboardTransactionCardHeader from "./DashboardTransactionCardHeader";
import { DashboardYourPendingSaleCardV2 } from "./DashboardYourPendingSaleCardV2";

const DashboardYourPendingSaleCardDetails = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const { numShares, pricePerShare } = transaction;

  return (
    <HStack justifyContent="space-between">
      <ActivityCardPricingWrapper>
        <ActivityCardPricingNumSharesAndPrice
          numShares={numShares}
          price={pricePerShare}
        />
        <ActivityCardPricingTotal numShares={numShares} price={pricePerShare} />
      </ActivityCardPricingWrapper>
    </HStack>
  );
};

export const DashboardYourPendingSaleCard = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const { company, bid } = transaction;
  const router = useRouter();
  const areCardUpdatesEnabled = useCardUpdates();

  if (areCardUpdatesEnabled) {
    return <DashboardYourPendingSaleCardV2 transaction={transaction} />;
  }

  return (
    <Card
      variant="activity"
      as="button"
      onClick={() => router.push(makeUrl(transaction))}
    >
      <DashboardTransactionCardHeader transaction={transaction} />
      <CardBody>
        <DashboardYourPendingSaleCardDetails transaction={transaction} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterCountsWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTransactionStatus
              bid={bid}
              transaction={transaction}
              company={company}
            />
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterCountsWrapper>
      </CardFooter>
    </Card>
  );
};
