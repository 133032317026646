export const stepKeys = {
  placeStandingBidBidDetails: `placeStandingBidBidDetails`,
  placeStandingBidAdditionalDetails: `placeStandingBidAdditionalDetails`,
  placeStandingBidSuccess: `placeStandingBidSuccess`,
} as const;

export const BidDetailsStepFields = [
  `company`,
  `numShares`,
  `pricePerShare`,
  `acceptsShares`,
  `allowPartialAccept`,
  `minPartialAcceptNumShares`,
  `acceptsTransferMethods`,
];

export type StepKeys = keyof typeof stepKeys;
