import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { ModalBody, Show, Text } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { StepPropsV2 } from "@/components/form";
import { BrokerSubmitBidSequenceModalBrokerSubmitBidMutation } from "@/gql";

import { useBrokerSubmitBidSequenceModalStepFormContext } from "./BrokerSubmitBidSequenceModalStepFormContext";
import { StepKeys } from "./steps";
import { BrokerSubmitBidSequenceModalFormValues } from "./types";

const BrokerSubmitBidConfirmationModal = ({
  isSubmitting,
  stepRouter: { stepControls },
}: StepPropsV2<StepKeys, BrokerSubmitBidSequenceModalFormValues>) => {
  const { submitMutation } = useBrokerSubmitBidSequenceModalStepFormContext();
  const { t } = useTranslation();
  const router = useRouter();

  const handleClickSubmit = () =>
    submitMutation().then(
      (response: BrokerSubmitBidSequenceModalBrokerSubmitBidMutation) => {
        const newBidId = response.brokerSubmitBid.bid?.id;
        if (!newBidId) return;

        stepControls.nextStep();
        router.push(`/listings/bids/${newBidId}`);
      },
    );

  return (
    <>
      <HiiveModalHeader>Confirm</HiiveModalHeader>
      <ModalBody>
        <Text textStyle="heading-lg">{t(`confirm_submit_bid`)}</Text>
      </ModalBody>
      <HiiveModalFooter>
        <Show above="md" ssr={false}>
          <HiiveCancelButton
            observabilityLabel="[SubmitBidConfirmation/Cancel]"
            onCancel={stepControls.previousStep}
          />
        </Show>
        <HiiveSubmitButton
          observabilityLabel="[SubmitBidConfirmation/Submit]"
          isLoading={isSubmitting}
          submitText={t(`yes_accept_bid`, { bidType: `bid` })}
          onClick={handleClickSubmit}
        />
      </HiiveModalFooter>
    </>
  );
};
export default BrokerSubmitBidConfirmationModal;
