import { ReactNode } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { FormControl, FormErrorMessage } from "@chakra-ui/react";

import { TooltipFormLabel } from "@/components/form";

import DateInput from "./DateInput";

type FormDateInputProps<TFieldValues extends FieldValues> = Pick<
  ReactDatePickerProps,
  `maxDate`
> & {
  readonly name: Path<TFieldValues>;
  readonly label: string;
  readonly control: Control<TFieldValues>;
  readonly isRequired?: boolean;
  readonly tooltipContent?: ReactNode;
  readonly tooltipSize?: string;
};

const FormDateInput = <TFieldValues extends FieldValues>({
  name,
  label,
  control,
  tooltipContent,
  tooltipSize,
  isRequired,
}: FormDateInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl id={name} isInvalid={invalid}>
      <TooltipFormLabel
        htmlFor={name}
        tooltipContent={tooltipContent}
        tooltipSize={tooltipSize}
      >
        {isRequired ? `${label} *` : label}
      </TooltipFormLabel>
      <DateInput {...field} />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormDateInput;
