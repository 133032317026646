import { ReactNode } from "react";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";

const CTACardDetails = ({
  heading,
  description,
}: {
  readonly heading: string;
  readonly description: ReactNode;
}) => (
  <Box>
    <Text textStyle="heading-sm" textTransform="uppercase" mb={3}>
      {heading}
    </Text>
    <Text>{description}</Text>
  </Box>
);

type Action =
  | {
      readonly ctaText: string;
      readonly disabled?: false;
      readonly onClick: () => void;
    }
  | { readonly disabled: true };

interface CTACardProps {
  readonly direction: StackProps[`direction`];
  readonly heading: string;
  readonly description: ReactNode;
  readonly action: Action;
  readonly footer?: ReactNode;
  readonly testId?: string;
}

const CTACard = ({
  direction,
  heading,
  description,
  action,
  footer,
  testId,
}: CTACardProps) => {
  const isColumn = direction === `column`;
  const actionEnabled = !action.disabled;

  return (
    <Card w="full" data-testId={testId}>
      <CardBody h="full">
        <Stack
          alignItems={isColumn ? `flex-start` : `center`}
          justifyContent="space-between"
          h="full"
          direction={direction}
          spacing={5}
        >
          <CTACardDetails heading={heading} description={description} />
          {actionEnabled && (
            <HiiveButton
              onClick={action.onClick}
              size="xl"
              variant="rounded-solid-salmon"
              flex="none"
              maxW="unset"
              minW={isColumn ? `full` : 40}
              observabilityLabel={`[CTACard] ${action.ctaText}`}
            >
              {action.ctaText}
            </HiiveButton>
          )}
        </Stack>
      </CardBody>
      {footer && <CardFooter bg="grey.25">{footer}</CardFooter>}
    </Card>
  );
};

export default CTACard;
