import { useTranslation } from "react-i18next";

import { ActivityCardPropertyPill } from "@/components/common";
import { standingBidAcceptsTransferMethodOptionsToString } from "@/utils/translate";

// TODO: This can be removed for StandingBidAcceptsTransferMethodsOptionsV2 after Server is updated
enum TempStandingBidAcceptsTransferMethodsOptions {
  Direct = `DIRECT`,
  Fund = `FUND`,
  Other = `OTHER`,
}

const acceptsTransferMethodsToString = (
  acceptsShares: readonly TempStandingBidAcceptsTransferMethodsOptions[],
): string =>
  acceptsShares
    .reduce((acc: readonly string[], tm) => {
      const str = standingBidAcceptsTransferMethodOptionsToString(tm);
      return acc.includes(str) ? acc : [str, ...acc];
    }, [])
    .join(`, `);

export const AcceptableTransferMethods = ({
  acceptsTransferMethods,
}: {
  readonly acceptsTransferMethods: readonly TempStandingBidAcceptsTransferMethodsOptions[];
}) => {
  const acceptableTransferMethods = acceptsTransferMethodsToString(
    acceptsTransferMethods,
  );
  const { t } = useTranslation();

  return (
    <ActivityCardPropertyPill
      title={t`transfer_type`}
      value={acceptableTransferMethods}
    />
  );
};
