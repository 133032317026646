import { HStack, VStack, Text, Image, StackProps } from "@chakra-ui/react";

import { ActivityCardCompanyFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

interface ActivityCardCompanyProps extends StackProps {
  readonly company: ActivityCardCompanyFragment;
}

const ActivityCardCompany = ({
  company,
  ...stackProps
}: ActivityCardCompanyProps) => {
  const areCardUpdatesEnabled = useCardUpdates();
  return (
    <HStack {...stackProps}>
      {company.logoUrl && (
        <VStack h="6" minW="6">
          <Image h="full" src={company.logoUrl} alt={company.name} />
        </VStack>
      )}
      <Text
        textStyle={{
          base: `heading-md`,
          sm: areCardUpdatesEnabled ? `heading-md` : `heading-lg`,
        }}
      >
        {company.name}
      </Text>
    </HStack>
  );
};

export default ActivityCardCompany;
