import { X } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  HStack,
  IconButton,
  ModalHeader,
  ModalHeaderProps,
} from "@chakra-ui/react";

import { useModal } from "@/hooks";

const ExecutionModalHeader = ({
  children,
  closeModal,
}: {
  readonly children: ReactNode;
  readonly closeModal?: () => void;
} & ModalHeaderProps) => {
  const { closeModal: modalProviderCloseModal, ...props } = useModal();
  const { t } = useTranslation();

  return (
    <ModalHeader
      as={HStack}
      p={0}
      py={4}
      px={0}
      justifyContent="space-between"
      alignItems="center"
      w="full"
      color="grey.900"
      border="none"
      borderBottom="1px solid"
      borderColor="grey.100"
      bg="white"
      gap={4}
      {...props}
    >
      <Box textStyle="heading-md" pl={6}>
        {children}
      </Box>
      <IconButton
        aria-label={t(`close_modal`)}
        border="1px solid"
        borderColor="grey.100"
        color="grey.800"
        onClick={closeModal || modalProviderCloseModal}
        p={1}
        mr={4}
        minH={1.5}
        minW={1.5}
        variant="icon-close"
        icon={<X size={18} />}
      />
    </ModalHeader>
  );
};

export default ExecutionModalHeader;
