import { EmbeddableFlowError } from "@modern-treasury/modern-treasury-js";
import { CheckCircle } from "@phosphor-icons/react";
import * as iso from "iso-3166-1";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  FullContentWrapper,
  HiiveButton,
  WithQuery,
} from "@/components/common";
import { SomethingWentWrongError } from "@/components/error";
import { SimpleListbox } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import { ScrollSection } from "@/components/listings";
import {
  BankAccountRequestStatus,
  CurrentContextDocument,
  useCountriesQuery,
  useTransactionByIdQuery,
  useUpdateTransactionSellerBankAccountRequestStatusMutation,
} from "@/gql";
import {
  useBasicSectionScrollTracking,
  useCountryList,
  useMemoizedCountries,
  useModernTreasuryEmbeddableFlow,
  useMutationWithError,
  UseSectionScrollTrackingGetSectionProps,
} from "@/hooks";
import { useApolloClient } from "@/hooks/useApolloClient";

import ModernTreasuryConnectAccountNavigation from "./ModernTreasuryConnectAccountNavigation";
import ModernTreasuryConnectAccountSkeleton from "./ModernTreasuryConnectAccountSkeleton";

export const sectionKeys = {
  CountryOfAccount: `CountryOfAccount`,
  AccountDetails: `AccountDetails`,
  VerifyAccount: `VerifyAccount`,
} as const;

const ENABLED_COUNTRIES = [
  `USA`,
  `CAN`,
  `IND`,
  `GBR`,
  `AUS`,
  `BEL`,
  `CHL`,
  `CHN`,
  `COL`,
  `DEU`,
  `ESP`,
  `FRA`,
  `HKG`,
  `IRL`,
  `ITA`,
  `PER`,
  `MEX`,
  `NLD`,
];

const CountryCodeForm = ({
  onCountryCodeChanged,
}: {
  readonly onCountryCodeChanged: (val: string) => void;
}) => {
  const [selectedCountry, setSelectedCountry] = useState<{
    readonly id: string;
    readonly name: string;
    readonly iso?: string;
  }>();
  const { t } = useTranslation(`transactions`);
  const { data } = useCountriesQuery();
  const countryList = useCountryList();
  const countriesMap: Record<string, string> = useMemoizedCountries({
    countries: data?.countries,
    countryList,
  });

  useEffect(() => {
    if (selectedCountry && selectedCountry.iso) {
      onCountryCodeChanged(selectedCountry.iso);
    }
  }, [selectedCountry]);

  const countryItems = Object.keys(countriesMap)
    .map((key) => ({
      id: key,
      name: countriesMap[key],
      iso: iso.whereCountry(
        // in useMemoizedCountries we rename the UK -- so for the ISO lookup we need to use the actual name
        countriesMap[key] === `United Kingdom`
          ? `United Kingdom of Great Britain and Northern Ireland`
          : countriesMap[key],
      )?.alpha3,
    }))
    .filter((country) => ENABLED_COUNTRIES.includes(country?.iso || ``))
    .sort((countryA, countryB) => {
      const countryAIndex = ENABLED_COUNTRIES.indexOf(countryA?.iso || ``);
      const countryBIndex = ENABLED_COUNTRIES.indexOf(countryB?.iso || ``);

      if (countryAIndex < countryBIndex) return -1;

      return 1;
    });

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-2xs">{`1. ${t`country_of_account`}`}</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          <VStack alignItems="flex-start" pb={4}>
            <div>
              <Text textStyle="heading-2xs">{t`new_bank_account_title`}</Text>
              <br />
              <Trans
                i18nKey="new_bank_account_description"
                t={t}
                components={{
                  bold: <strong />,
                  br: <Box h={3} />,
                }}
                values={{ email: `execution@hiive.com` }}
              />
            </div>
          </VStack>
          <Box w={{ lg: `50%`, sm: `100%` }}>
            <SimpleListbox
              placeholder={t`new_bank_account_form_placeholder`}
              items={countryItems}
              itemToString={(item) => item?.name || ``}
              getItemKey={(item) => item?.id || ``}
              onItemSelected={(item) => setSelectedCountry(item)}
              value={selectedCountry}
              label={t`country_of_account`}
            />
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};

const ModernTreasuryVerificationCard = ({
  countryCode,
}: {
  readonly countryCode?: string;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const apolloClient = useApolloClient();

  const onNextClick = async () => {
    await apolloClient.refetchQueries({
      include: [CurrentContextDocument],
    });
    await router.push(`/transactions/${router.query.id}`);
  };

  const isVerificationAutomatic = countryCode === `USA`;

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-2xs">{`3. ${t`verifying_account`}`}</Text>
      </CardHeader>
      <CardBody>
        <VStack gap={4} alignItems="flex-start" pb={4}>
          <VStack gap={2} alignItems="flex-start">
            <Box h={1} />
            {isVerificationAutomatic ? (
              <>
                <Text textStyle="heading-2xs">{t`mt_account_verification_title`}</Text>
                <Box h={1} />
                <Trans
                  i18nKey="mt_account_verification_description"
                  components={{ br: <Box h={1} /> }}
                />
              </>
            ) : (
              <>
                <Text textStyle="heading-2xs">{t`mt_account_verification_manual_title`}</Text>
                <Trans
                  i18nKey="mt_account_verification_description_manual"
                  values={{ email: `execution@hiive.com` }}
                />
              </>
            )}
          </VStack>
        </VStack>
      </CardBody>
      <CardFooter as={HStack} justifyContent="flex-end" py={4} bg="grey.25">
        <HiiveButton
          onClick={onNextClick}
          variant="rounded-solid-salmon"
          size="xl"
          observabilityLabel="[ModernTreasuryConnectAccount] next"
        >
          {t`next`}
        </HiiveButton>
      </CardFooter>
    </Card>
  );
};

const ModernTreasuryConnectAccountForm = ({
  countryCode,
  onError,
  onSuccess,
}: {
  readonly countryCode?: string;
  readonly onSuccess: () => void;
  readonly onError: (error: EmbeddableFlowError) => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const transactionId = router.query.id as string;
  const mountedCountryId = useRef(``);
  const mountedRef = useRef(false);
  const [mounted, setMounted] = useState(false);
  const mtContainerId = `#mt-div`;
  const { mountEmbeddableFlow, unmountEmbeddableFlow } =
    useModernTreasuryEmbeddableFlow({
      onFlowSuccess: () => {
        onSuccess();
        unmountEmbeddableFlow();
      },
      onFlowError: (error) => {
        onError(error);
        unmountEmbeddableFlow();
      },
    });

  useEffect(() => {
    if (countryCode && !mounted && !mountedRef.current) {
      const mountMT = async () => {
        // a bit of a hack here, we use the reference to prevent the frame from mounting twice
        mountedCountryId.current = countryCode;
        mountedRef.current = true;
        await mountEmbeddableFlow(countryCode, mtContainerId, transactionId!);
        setMounted(true);
      };

      mountMT();
    }
  }, [countryCode, mounted]);

  useEffect(() => {
    // if countryCode is updated we want to remove the previous embed
    if (mounted && mountedCountryId.current !== countryCode) {
      unmountEmbeddableFlow();
      mountedRef.current = false;
      // use the state Mounted to force a rerender once we unmount the frame and are ready to mount again
      setMounted(false);
    }
  }, [countryCode]);

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-2xs">{`2. ${t`account_details`}`}</Text>
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <Box width={{ md: `50%`, sm: `100%`, lg: `50%` }}>
          <div id="mt-div" />
        </Box>
      </CardBody>
    </Card>
  );
};

const ModernTreasurySuccessCard = () => {
  const { t } = useTranslation();

  return (
    <Card w="full">
      <CardBody as={VStack} spacing={4} alignItems="flex-start">
        <HStack gap={2}>
          <CheckCircle size={20} weight="fill" color="black" />
          <Text textStyle="heading-2xl"> {t`success`}</Text>
        </HStack>
        <Text textStyle="text-md">{t`mt_success_description`}</Text>;
      </CardBody>
    </Card>
  );
};

const ModernTreasuryConnectAccountPage = () => {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState<string>();
  const [hasCompletedFlow, setHasCompletedFlow] = useState<boolean>(false);
  const [mtFlowError, setMtFlowError] = useState<EmbeddableFlowError>();
  const containerRef = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const transactionId = router.query.id as string;

  const [updateTransactionSellerBankAccountIdRequestStatus] =
    useMutationWithError(
      useUpdateTransactionSellerBankAccountRequestStatusMutation(),
      `updateTransactionSellerBankAccountRequestStatus`,
    );

  const getDisabledSectionKeys = () => {
    if (!countryCode)
      return [sectionKeys.AccountDetails, sectionKeys.VerifyAccount];

    if (!hasCompletedFlow) return [sectionKeys.VerifyAccount];

    if (hasCompletedFlow)
      return [sectionKeys.CountryOfAccount, sectionKeys.AccountDetails];

    return [];
  };

  const {
    getSectionProps,
    getNavButtonProps,
  }: {
    readonly getSectionProps: UseSectionScrollTrackingGetSectionProps<
      keyof typeof sectionKeys,
      HTMLDivElement
    >;
    readonly getNavButtonProps: (sectionKey: keyof typeof sectionKeys) => {
      readonly onClick?: () => void;
      readonly isActive: boolean;
    };
  } = useBasicSectionScrollTracking({
    keys: [
      sectionKeys.CountryOfAccount,
      sectionKeys.AccountDetails,
      sectionKeys.VerifyAccount,
    ],
    disabledSectionKeys: getDisabledSectionKeys(),
    containerElement: containerRef.current,
  });

  const onConnectAccountSuccess = async () => {
    await updateTransactionSellerBankAccountIdRequestStatus({
      variables: {
        transactionId,
        sellerBankAccountRequestStatus: BankAccountRequestStatus.Collected,
      },
    });

    setHasCompletedFlow(true);
  };

  // TODO: better error handling
  if (mtFlowError) {
    return <SomethingWentWrongError />;
  }

  const fixedHeightProps = {
    __css: {
      minH: {
        base: `unset`,
        lg: 0,
      },
      height: 0,
    },
  };

  return (
    <FullContentWrapper px={{ base: 4, lg: 8 }}>
      <SimpleGrid
        columnGap={6}
        rowGap={4}
        maxW="max-width-lg"
        gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
        w="full"
      >
        <GridItem gridColumn={{ base: 1, lg: 2 }}>
          <Text textStyle="heading-3xl">{t`account_information`}</Text>
        </GridItem>
        <ModernTreasuryConnectAccountNavigation
          getNavButtonProps={getNavButtonProps}
          containerRef={containerRef}
          isAccountDetailsAvailable={!!countryCode}
          isVerifyAccountAvailable={hasCompletedFlow}
        />
        <GridItem ref={containerRef} gridColumn={{ base: 1, lg: 2 }}>
          <VStack
            justifyContent="flex-start"
            alignItems="flex-start"
            gap={hasCompletedFlow ? 0 : 4}
          >
            <ScrollSection
              {...getSectionProps(sectionKeys.CountryOfAccount)}
              {...(!countryCode ? fixedHeightProps : {})}
            >
              {!hasCompletedFlow && (
                <CountryCodeForm onCountryCodeChanged={setCountryCode} />
              )}
            </ScrollSection>
            <ScrollSection
              {...getSectionProps(sectionKeys.AccountDetails)}
              {...(!hasCompletedFlow ? fixedHeightProps : {})}
            >
              {!hasCompletedFlow && countryCode && (
                <ModernTreasuryConnectAccountForm
                  countryCode={countryCode}
                  onSuccess={onConnectAccountSuccess}
                  onError={(error) => {
                    setMtFlowError(error);
                  }}
                />
              )}
            </ScrollSection>
            <ScrollSection {...getSectionProps(sectionKeys.VerifyAccount)}>
              {hasCompletedFlow && (
                <VStack gap={2}>
                  <ModernTreasurySuccessCard />
                  <ModernTreasuryVerificationCard countryCode={countryCode} />
                </VStack>
              )}
            </ScrollSection>
          </VStack>
        </GridItem>
      </SimpleGrid>
    </FullContentWrapper>
  );
};

const ModernTreasuryConnectAccount = withCurrentActor(({ actor }) => {
  const router = useRouter();
  const transactionId = router.query.id as string;

  const query = useTransactionByIdQuery({
    variables: { id: transactionId },
  });

  return (
    <WithQuery
      query={query}
      fallback={<ModernTreasuryConnectAccountSkeleton />}
    >
      {({ data }) => {
        const doesTransactionHasSellerBankAccountId =
          !!data?.transactionById?.sellerBankAccountId;
        const isTransactionSeller =
          !!data?.transactionById?.sellerId &&
          data?.transactionById?.sellerId === actor.id;
        const hasBankAccountNotBeenRequested =
          data?.transactionById?.sellerBankAccountRequestStatus &&
          [
            BankAccountRequestStatus.NotRequested,
            BankAccountRequestStatus.Cancelled,
          ].includes(data?.transactionById?.sellerBankAccountRequestStatus);

        if (
          !isTransactionSeller ||
          hasBankAccountNotBeenRequested ||
          doesTransactionHasSellerBankAccountId
        ) {
          router.replace(`/transactions/${transactionId}`);
          return null;
        }

        return <ModernTreasuryConnectAccountPage />;
      }}
    </WithQuery>
  );
});

export default ModernTreasuryConnectAccount;
