import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  `pagePosition`,
  `iconContainer`,
]);

export const BackToTop = defineMultiStyleConfig({
  baseStyle: {
    pagePosition: {
      position: `fixed`,
      zIndex: `popover`,
      bottom: `155px`,
      right: 6,
    },
    iconContainer: {
      borderRadius: `full`,
      cursor: `pointer`,
      padding: 2.5,
      justify: `center`,
      align: `center`,
      backgroundColor: `grey.900`,
      opacity: 0.3,
      _hover: {
        opacity: 0.7,
      },
    },
  },
  variants: {
    mobile: {
      pagePosition: {
        bottom: `150px`,
      },
    },
    desktop: {
      pagePosition: {
        bottom: `90px`,
      },
    },
  },
  defaultProps: {
    variant: `desktop`,
  },
});
