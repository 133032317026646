import { CardHeader, HStack, Text } from "@chakra-ui/react";

import { ActivityCardBadge } from "@/components/common";
import { CompanyStandingBidCardHeaderStandingBidFragment } from "@/gql";

const CompanyStandingBidCardHeader = ({
  standingBid,
}: {
  readonly standingBid: CompanyStandingBidCardHeaderStandingBidFragment;
}) => {
  const { displayId } = standingBid;

  return (
    <CardHeader>
      <HStack justifyContent="space-between">
        <HStack>
          <ActivityCardBadge variant="bid" title="Standing Bid" minW={126} />
          <Text textStyle="text-lg" color="grey.900">
            {displayId}
          </Text>
        </HStack>
      </HStack>
    </CardHeader>
  );
};

export default CompanyStandingBidCardHeader;
