import { useTranslation } from "react-i18next";

import { CardBody, CardHeader, Flex, HStack, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardStandingBidCardFooterV2,
  SensitiveText,
} from "@/components/common";
import { PostingNumSharesPriceDetails } from "@/components/companies";
import { UserActivityStandingBidFragment } from "@/gql";
import {
  formatShares,
  getIsConditionallyCompletedStandingBid,
  getIsPartiallyAcceptedStandingBid,
} from "@/utils";

export const DashboardYourStandingBidCardV2 = ({
  standingBid,
}: {
  readonly standingBid: UserActivityStandingBidFragment;
}) => {
  const { t } = useTranslation(`standingBids`);
  const {
    pricePerShare,
    numSharesOriginal,
    numSharesAvailable,
    numSharesAccepted,
  } = standingBid;

  return (
    <ActivityCard href={`/standing-bids/${standingBid.id}`}>
      <CardHeader px={3} py={2}>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title={t`standing_bid`} />
            <Text
              textStyle={{ base: `text-sm`, lg: `text-lg` }}
              color="grey.900"
            >
              {standingBid.displayId}
            </Text>
          </HStack>
          <ActivityCardCompany company={standingBid.company} />
        </HStack>
      </CardHeader>
      <CardBody p={3}>
        <Flex wrap="wrap" columnGap={1.5}>
          <SensitiveText>
            <PostingNumSharesPriceDetails
              isPartiallyAccepted={getIsPartiallyAcceptedStandingBid(
                standingBid,
              )}
              isConditionallyCompleted={getIsConditionallyCompletedStandingBid(
                standingBid,
              )}
              numSharesAvailable={numSharesAvailable!}
              numSharesOriginal={numSharesOriginal!}
              pricePerShare={pricePerShare}
            />
          </SensitiveText>
        </Flex>
        {numSharesAccepted > 0 && (
          <SensitiveText textStyle="text-xs" color="grey.700">
            {t(`standing_bid_shares_purchased`, {
              numSharesAccepted: formatShares(numSharesAccepted),
            })}
          </SensitiveText>
        )}
      </CardBody>
      <ActivityCardStandingBidCardFooterV2 standingBid={standingBid} />
    </ActivityCard>
  );
};
