import { MagnifyingGlass } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Image,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";

import { HiiveButton, WithQuery } from "@/components/common";
import { CompanyCombobox } from "@/components/companies";
import { Combobox } from "@/components/form";
import {
  OnboardingInfoMessageContext,
  SlideAnimation,
} from "@/components/onboarding-v2";
import {
  CompanyWatchlistCompanyFragment,
  ListCompaniesOrderBy,
  useTransitionCurrentStepMutation,
  useWatchlistComboboxListCompaniesLazyQuery,
  useWatchlistPageActorWatchlistQuery,
  useWatchlistPageAddToWatchlistMutation,
  useWatchlistPageRemoveFromWatchlistMutation,
  WatchlistComboboxCompanyFragment,
  WatchlistPageActorWatchlistDocument,
} from "@/gql";
import {
  useColors,
  useCombobox,
  useDebounce,
  useMutationWithError,
} from "@/hooks";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: ListCompaniesOrderBy.MarketActivity,
  searchText,
});

const WatchlistCombobox = ({
  watchlist,
  onSelectCompany,
}: {
  readonly watchlist: readonly CompanyWatchlistCompanyFragment[];
  readonly onSelectCompany: (companyId: string) => void;
}) => {
  const { t } = useTranslation();
  const { debounce, isDebouncing } = useDebounce();

  const [search, setSearch] = useState<string>(``);

  const [loadCompanies, { data, loading }] =
    useWatchlistComboboxListCompaniesLazyQuery({
      fetchPolicy: `no-cache`,
    });

  useEffect(() => {
    loadCompanies({
      variables: getVariables(``),
    });
  }, []);

  const handleChangeSearch = (search: string) => {
    setSearch(search);
    debounce(() =>
      loadCompanies({
        variables: getVariables(search),
      }),
    );
  };

  const currentWatchlistCompanyIds = useMemo(
    () => new Set(watchlist.map(({ id }) => id)),
    [watchlist],
  );

  const companyEdges = data?.listCompanies?.edges || [];

  const companiesNotOnWatchlist = useMemo(
    () =>
      companyEdges.flatMap((edge) => {
        if (!edge?.node || currentWatchlistCompanyIds.has(edge.node.id))
          return [];
        return [edge.node];
      }),
    [companyEdges, currentWatchlistCompanyIds],
  );

  const isLoading = loading || isDebouncing;

  const { inputProps, menuProps, labelProps, itemProps, actions } =
    useCombobox<WatchlistComboboxCompanyFragment>({
      items: companiesNotOnWatchlist,
      itemToString: (item) => item.name,
      getItemKey: (item) => item.id,
      onSelectItem: (item) => {
        if (!item) return;
        actions.blur();
        onSelectCompany(item.id);
        handleChangeSearch(``);
      },
      onChangeInputValue: handleChangeSearch,
      inputValue: search,
      isLoading,
    });

  const [grey500] = useColors([`grey.500`]);

  return (
    <>
      <Combobox.Label srOnly {...labelProps}>
        {t(`search_company`)}
      </Combobox.Label>
      <Combobox.Container h="auto">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <HStack spacing={2}>
              <MagnifyingGlass size={20} color={grey500} />
            </HStack>
          </InputLeftElement>

          <Combobox.Input placeholder={t(`search_company`)} {...inputProps} />
        </InputGroup>
        <Combobox.Menu
          isLoading={isLoading}
          fallback={<CompanyCombobox.Skeleton />}
          maxH={242}
          isLazy={false}
          {...menuProps}
        >
          {companiesNotOnWatchlist.map((item, index) => (
            <CompanyCombobox.Item
              key={item.id}
              item={item}
              index={index}
              {...itemProps}
            >
              <CompanyCombobox.ItemIcon company={item} />
              <Text as="span">{item.name}</Text>
            </CompanyCombobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
    </>
  );
};

const WatchlistSelectedCompaniesList = ({
  watchlist,
  onRemoveCompany,
}: {
  readonly watchlist: readonly CompanyWatchlistCompanyFragment[];
  readonly onRemoveCompany: (companyId: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" w="full" gap={4} mt={watchlist.length > 0 ? 7 : 0}>
      <AnimatePresence>
        {watchlist.map(({ id, name, logoUrl }) => (
          <motion.div
            layout
            key={id}
            initial={{ translateY: -10, opacity: 0, height: 0 }}
            animate={{ translateY: 0, opacity: 1, scale: 1, height: `auto` }}
            transition={{
              type: `spring`,
              stiffness: 500,
              damping: 30,
            }}
            exit={{
              opacity: 0,
              height: 0,
              scale: 0.9,
              translateY: -30,
              transition: {
                opacity: { duration: 0.09, ease: `easeOut` },
                height: { duration: 0.2, ease: `easeIn` },
                scale: { duration: 0.09, ease: `easeOut` },
                translateY: { duration: 0.09, ease: `easeOut` },
              },
            }}
          >
            <Flex
              p={5}
              borderRadius="md"
              borderWidth={0.5}
              borderColor="grey.200"
              bg="white"
              align="center"
              gap={4}
            >
              <Box h={6} w={6}>
                {logoUrl && (
                  <Image
                    m="0 auto"
                    h={6}
                    maxW={6}
                    objectFit="contain"
                    src={logoUrl}
                    alt={name}
                  />
                )}
              </Box>

              <Text textStyle="heading-md">{name}</Text>

              <Button
                flex="none"
                ml="auto"
                onClick={() => onRemoveCompany(id)}
                variant="boxed-grey"
                px={4}
                py={2}
                minW={20}
              >
                {t(`remove`)}
              </Button>
            </Flex>
          </motion.div>
        ))}
      </AnimatePresence>
    </Flex>
  );
};

export const WatchlistCompaniesSearch = () => {
  const { t } = useTranslation();
  const { resetReonboardingMessage } = useContext(OnboardingInfoMessageContext);
  const query = useWatchlistPageActorWatchlistQuery();

  const [addToWatchlistMutation, isAddingToWatchlist] = useMutationWithError(
    useWatchlistPageAddToWatchlistMutation({
      refetchQueries: [WatchlistPageActorWatchlistDocument],
    }),
    `addToWatchlist`,
  );
  const [removeFromWatchlistMutation, isRemovingFromWatchlist] =
    useMutationWithError(
      useWatchlistPageRemoveFromWatchlistMutation({
        refetchQueries: [WatchlistPageActorWatchlistDocument],
      }),
      `removeFromWatchlist`,
    );
  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const onNext = async () => {
    if (isAddingToWatchlist || isRemovingFromWatchlist) return;

    await transitionCurrentStepMutation();
    resetReonboardingMessage();
  };

  const onSelectCompany = (companyId: string) => {
    addToWatchlistMutation({
      variables: {
        companyId,
      },
    });
  };

  const onRemoveCompany = (companyId: string) => {
    removeFromWatchlistMutation({
      variables: {
        companyId,
      },
    });
  };

  return (
    <WithQuery query={query}>
      {({
        data: {
          currentActor: { watchlist },
        },
      }) => (
        <Flex direction="column" gap={8}>
          <SlideAnimation>
            <Card>
              <CardBody>
                <WatchlistCombobox
                  onSelectCompany={onSelectCompany}
                  watchlist={watchlist}
                />
                <WatchlistSelectedCompaniesList
                  watchlist={watchlist}
                  onRemoveCompany={onRemoveCompany}
                />
              </CardBody>
            </Card>
          </SlideAnimation>
          <Flex justifyContent="flex-end" w="full">
            <HiiveButton
              onClick={onNext}
              isLoading={isTransitioningCurrentStep}
              size="xl"
              w={{ base: `full`, sm: `unset` }}
              maxW="unset"
              variant="rounded-solid-salmon"
              observabilityLabel="[WatchlistPage] Next"
              isDisabled={watchlist.length === 0}
            >
              {t(`next`)}
            </HiiveButton>
          </Flex>
        </Flex>
      )}
    </WithQuery>
  );
};
