import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Link, Text, VStack } from "@chakra-ui/react";

import { SlideAnimation } from "@/components/onboarding-v2";
import { DefaultUnaccreditedSellerDashboardPageMyCompanyFragment } from "@/gql";

interface UnlistedCompanyCardProps {
  readonly company?: DefaultUnaccreditedSellerDashboardPageMyCompanyFragment;
}

export const DelistedCompanyCard = ({ company }: UnlistedCompanyCardProps) => {
  const { t } = useTranslation(`dashboard`);

  const companyName = company?.name || ``;
  return (
    <VStack height="100vh">
      <Flex
        direction="column"
        w="full"
        maxW="45rem"
        mt={28}
        px={{ base: 4, lg: 0 }}
      >
        <VStack spacing={2} alignItems="flex-start" mb={8}>
          <Text color="grey.900" textStyle="heading-3xl">
            {t(`company_is_not_currently_tradeable_on_hiive`, {
              companyName,
            })}
          </Text>
        </VStack>
        <SlideAnimation>
          <Card>
            <CardBody>
              <Flex direction="column" gap={4}>
                <Text>
                  {t(`unfortunately_shares_cannot_be_bought`, {
                    companyName,
                  })}
                </Text>
                <Text>
                  {t(`any_active_listings_have_been_removed`, {
                    companyName,
                  })}
                </Text>
                <Text>
                  {t(`if_you_are_currently_in_the_middle_of_a_transaction`, {
                    companyName,
                  })}
                </Text>
                <Text>
                  <Trans
                    i18nKey="if_you_have_any_questions"
                    ns="dashboard"
                    components={{
                      mailto: (
                        <Link
                          fontWeight="medium"
                          textDecoration="underline"
                          href="mailto:support@hiive.com"
                        />
                      ),
                    }}
                  />
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </SlideAnimation>
      </Flex>
    </VStack>
  );
};
