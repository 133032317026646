import { useTranslation } from "react-i18next";

import { CardBody, Flex, HStack, Stack } from "@chakra-ui/react";

import {
  ActivityCardListingSolicitedCardBody,
  SensitiveText,
} from "@/components/common";
import { PostingNumSharesPriceDetails } from "@/components/companies";
import {
  CompanyStandingBidCardBodyCompanyFragment,
  CompanyStandingBidCardBodyStandingBidFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  formatShares,
  getIsBroker,
  getIsConditionallyCompletedStandingBid,
  getIsPartiallyAcceptedStandingBid,
} from "@/utils";

const CompanyStandingBidCardBodyV2 = ({
  standingBid,
  company,
}: {
  readonly standingBid: CompanyStandingBidCardBodyStandingBidFragment;
  readonly company?: CompanyStandingBidCardBodyCompanyFragment;
}) => {
  const actor = useCurrentActor();
  const { t } = useTranslation(`company`);
  const {
    pricePerShare,
    numSharesOriginal,
    numSharesAvailable,
    numSharesAccepted,
  } = standingBid;

  if (getIsBroker(actor) && standingBid.solicited)
    return (
      <CardBody pt={2} pb={2}>
        <HStack justify="space-between">
          <ActivityCardListingSolicitedCardBody
            spacing={0}
            alignItems="flex-start"
            textAlign="left"
            company={company}
          />
        </HStack>
      </CardBody>
    );

  return (
    <CardBody p={3}>
      <Stack
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        gap={0.5}
      >
        <Flex wrap="wrap" columnGap={1.5}>
          <SensitiveText>
            <PostingNumSharesPriceDetails
              isPartiallyAccepted={getIsPartiallyAcceptedStandingBid(
                standingBid,
              )}
              isConditionallyCompleted={getIsConditionallyCompletedStandingBid(
                standingBid,
              )}
              numSharesAvailable={numSharesAvailable!}
              numSharesOriginal={numSharesOriginal!}
              pricePerShare={pricePerShare}
            />
          </SensitiveText>
        </Flex>
        {numSharesAccepted > 0 && (
          <SensitiveText textStyle="text-xs" color="grey.700">
            {t(`standing_bid_shares_purchased`, {
              numSharesAccepted: formatShares(numSharesAccepted),
            })}
          </SensitiveText>
        )}
      </Stack>
    </CardBody>
  );
};

export default CompanyStandingBidCardBodyV2;
