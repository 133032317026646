import { useTranslation } from "react-i18next";

import { HStack } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { BidPostingCardFragment } from "@/gql";
import { getIsHiiveFund } from "@/utils";

type BidPostingCardWithdrawnHeaderProps = {
  bid: BidPostingCardFragment;
};

export function BidPostingCardWithdrawnHeader({
  bid,
}: BidPostingCardWithdrawnHeaderProps) {
  const {
    displayId,
    listing: { transferMethod },
  } = bid;

  const { t } = useTranslation(`bids`);

  const isHiiveFund = getIsHiiveFund(transferMethod);

  return (
    <PostingCard.Header
      title={
        <HStack opacity={0.6}>
          <PostingCard.Label variant="bid">
            {isHiiveFund ? t(`order`) : t(`bid`)}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{displayId}</PostingCard.HeaderTitle>
        </HStack>
      }
    />
  );
}
