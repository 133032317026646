import { useTranslation } from "react-i18next";

import { CardBody, CardHeader, Flex, HStack, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardListingCardFooterV2,
  SensitiveText,
} from "@/components/common";
import { PostingNumSharesPriceDetails } from "@/components/companies";
import { UserActivityListingFragment } from "@/gql";
import {
  getIsConditionallySoldListing,
  getIsPartiallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
} from "@/utils";

export const DashboardYourListingCardV2 = ({
  listing,
}: {
  readonly listing: UserActivityListingFragment;
}) => {
  const { t } = useTranslation(`listings`);
  const { listingPricePerShare: pricePerShare } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);

  return (
    <ActivityCard href={`/listings/${listing.id}`}>
      <CardHeader px={3} py={2}>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="listing" title={t`listing`} />
            <Text
              color="grey.900"
              textStyle={{ base: `text-sm`, lg: `text-lg` }}
            >
              {listing.displayId}
            </Text>
          </HStack>
          <ActivityCardCompany company={listing.company} />
        </HStack>
      </CardHeader>
      <CardBody p={3}>
        <Flex wrap="wrap" columnGap={1.5}>
          <SensitiveText>
            <PostingNumSharesPriceDetails
              isPartiallyAccepted={getIsPartiallySoldListing(listing)}
              isConditionallyCompleted={getIsConditionallySoldListing(listing)}
              numSharesOriginal={numSharesOriginal}
              numSharesAvailable={numSharesAvailable}
              pricePerShare={pricePerShare!}
            />
          </SensitiveText>
        </Flex>
      </CardBody>
      <ActivityCardListingCardFooterV2 listing={listing} />
    </ActivityCard>
  );
};
