import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";

import {
  ButtonFooterWrapper,
  HiiveButton,
  HiiveModalFooter,
  HiiveModalHeader,
} from "@/components/common";
import { EntityFormFields } from "@/components/entities";
import { FormikQL } from "@/components/form";
import { withCurrentActor } from "@/components/hoc";
import {
  AddEntityInput,
  AddEntityMutationVariables,
  CurrentContextDocument,
  TransactionPageTransactionByIdDocument,
  UserWithInstitutionFragment,
  useAddEntityMutation,
  EntityType,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { Resources } from "@/i18n/locales";

export enum CreateEntityModalTypes {
  Buyer,
  Seller,
}

export type CreateEntityModalProps = Omit<ModalProps, "children"> & {
  actor: UserWithInstitutionFragment;
  transactionId?: string;
  type?: CreateEntityModalTypes;
};

const mapVariables = (input: AddEntityInput): AddEntityMutationVariables => ({
  input,
});

export const DEFAULT_ENTITY_FORM_VALUES = {
  jurisdictionOfFormationId: ``,
  legalName: ``,
  otherType: undefined,
  type: `` as EntityType,
};

const CreateEntityModal = withCurrentActor(
  ({ actor, transactionId, type, ...props }: CreateEntityModalProps) => {
    const { t } = useTranslation(`account`);
    const { onClose } = props;
    const mutation = useAddEntityMutation({
      refetchQueries: [
        CurrentContextDocument,
        TransactionPageTransactionByIdDocument,
      ],
    });
    const { successToast } = useCustomToast();
    const onSuccess = () => {
      onClose();
      successToast(t(`entities.entity_created_successfully`));
    };
    const validationSchema = Yup.object().shape({
      jurisdictionOfFormationId: Yup.string().required(
        t(`entities.jurisdiction_of_formation_required`),
      ),
      legalName: Yup.string().required(
        t(`entities.legal_entity_name_required`),
      ),
      otherType: Yup.string().when(`type`, {
        is: (type: EntityType) => type === EntityType.Other,
        then: (schema) =>
          schema.required(t(`entities.entity_type_name_is_required`)),
      }),
      type: Yup.string()
        .nullable()
        .required(t(`entities.entity_type_is_required`)),
    });
    const isInstitution = !!actor.institutionId;
    const entities = isInstitution
      ? actor.institution?.entities
      : actor?.entities;
    const individualEntities = entities?.filter(
      (entity) => entity.type === EntityType.Individual,
    );
    const hasAnIndividualEntity =
      individualEntities && individualEntities?.length > 0;

    const getDefaultValues = () => {
      if (isInstitution) {
        return {
          ...DEFAULT_ENTITY_FORM_VALUES,
          transactionId,
        };
      }

      return hasAnIndividualEntity
        ? {
            ...DEFAULT_ENTITY_FORM_VALUES,
            transactionId,
          }
        : {
            ...DEFAULT_ENTITY_FORM_VALUES,
            jurisdictionOfFormationId: actor.country?.id ?? ``,
            legalName: actor.name,
            transactionId,
            type: EntityType.Individual,
          };
    };

    const titleTranslationKey = match<
      { type?: CreateEntityModalTypes },
      keyof Resources["account"]["entities"]
    >({
      type,
    })
      .with(
        {
          type: CreateEntityModalTypes.Buyer,
        },
        () => `new_purchasing_entity`,
      )
      .with(
        {
          type: CreateEntityModalTypes.Seller,
        },
        () => `new_selling_entity`,
      )
      .otherwise(() => `new_entity`);

    const isAssigningEntity = !!transactionId;

    return (
      <Modal {...props} size="xl">
        <ModalOverlay />
        <FormikQL
          initialValues={getDefaultValues()}
          mapVariables={mapVariables}
          mutation={mutation}
          mutationNames={[]}
          onSuccess={onSuccess}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalContent>
                <HiiveModalHeader closeModal={onClose}>
                  <Text>{t(`entities.${titleTranslationKey}`)}</Text>
                </HiiveModalHeader>
                <ModalBody>
                  <EntityFormFields
                    filterIndividualEntityType={hasAnIndividualEntity}
                    type={type}
                    transactionId={transactionId}
                  />
                </ModalBody>
                <HiiveModalFooter>
                  <ButtonFooterWrapper
                    direction={{
                      base: `row`,
                    }}
                  >
                    <HiiveButton
                      onClick={onClose}
                      size="xl"
                      observabilityLabel="[CreateTransactingEntities] Cancel"
                      variant="rounded-outline-grey"
                    >
                      {t(`cancel`, { ns: `translation` })}
                    </HiiveButton>
                    <HiiveButton
                      type="submit"
                      size="xl"
                      observabilityLabel="[CreateTransactingEntities] Create Entity"
                      variant="rounded-solid-salmon"
                      isLoading={isSubmitting}
                    >
                      {isAssigningEntity
                        ? t(`assign_entity`, {
                            ns: `transactions`,
                          })
                        : t(`entities.create_entity`)}
                    </HiiveButton>
                  </ButtonFooterWrapper>
                </HiiveModalFooter>
              </ModalContent>
            </Form>
          )}
        </FormikQL>
      </Modal>
    );
  },
);

export default CreateEntityModal;
