import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  BidWithdrawnPostingCard,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  PostActivityUnaccreditedSellerDashboardPagePastBidsPageBidFragment,
  usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

import { PastBidCard } from "./PastBidCard";

const PastBidsEmptyState = () => {
  const { t } = useTranslation();

  return <ActivityGroupEmptyState message={t(`no_past_bids`)} />;
};

const PastBidsPageContent = ({
  pastBids,
}: {
  readonly pastBids: readonly PostActivityUnaccreditedSellerDashboardPagePastBidsPageBidFragment[];
}) => {
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivitySection pt={{ base: 4, lg: 8 }}>
      <VStack alignItems="flex-start" spacing={4}>
        <Text textStyle="heading-sm" textTransform="uppercase">
          Past Bids
        </Text>
        <Text textStyle="text-md">
          Bids you&apos;ve received in the past. These bids are no longer
          active.
        </Text>
        <ActivityGroup emptyState={<PastBidsEmptyState />}>
          {pastBids.map((pastBid) =>
            cardUpdatesEnabled ? (
              <BidWithdrawnPostingCard key={pastBid.id} bid={pastBid} />
            ) : (
              <PastBidCard key={pastBid.id} pastBid={pastBid} />
            ),
          )}
        </ActivityGroup>
      </VStack>
    </ActivitySection>
  );
};

const PastBidsPageSkeleton = () => (
  <Flex direction="column" gap={4} w="full" pt={{ base: 4, lg: 8 }}>
    <Skeleton h="20px" w="full" maxW="128px" />
    <Skeleton h="16px" maxW="55%" />
    <ActivityCardSkeleton includeBody />
  </Flex>
);

const PastBidsPage = () => {
  const query =
    usePostActivityUnaccreditedSellerDashboardPagePastBidsPageQuery();
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<PastBidsPageSkeleton />}>
      {({
        data: {
          unaccreditedSellerMyActivity: { myListing },
          unaccreditedSellerMyListingActivity: { pastBids },
        },
      }) => {
        if (!myListing) {
          router.replace(`/dashboard`);
          return null;
        }

        return <PastBidsPageContent pastBids={pastBids} />;
      }}
    </WithQuery>
  );
};

export default PastBidsPage;
