const locale = {
  place_standing_bid: `Place Standing Bid`,
  submit_standing_bid: `Submit Standing Bid`,
  place_standing_bid_accepted_transfer_methods_label: `Which transfer types are you interested in?`,
  direct: `Direct`,
  direct_text: `You buy a direct shareholding`,
  fund: `Fund`,
  fund_text: `You invest in a vehicle that holds shares`,
  other: `Other`,
  other_text: `You work out another arrangement with the seller`,
  legacy_place_standing_bid_accepted_transfer_methods_label: `I am also willing to purchase shares indirectly via`,
  which_company: `Which company?`,
  number_of_shares: `Number of shares`,
  price_per_share: `Price per share`,
  share_type: `Share type`,
  allow_partial_acceptance: `Allow partial acceptance?`,
  minimum_number_of_shares: `Minimum number of shares`,
  submit_standing_bid_accepted_transfer_methods_label: `The buyer is also willing to purchase shares indirectly via:`,
  required: `Required`,
  must_be_at_least_one: `Must be at least 1`,
  required_when_partial_acceptance_is_allowed: `Required when partial acceptance is allowed`,
  partial_accept_amount_for_a_bid_must_have_minimum: `Partial accept amount for a Bid must have a minimum value of {{ minStandingBidLot }}`,
  must_be_less_than: `Must be less than or equal to the total number of shares`,
  must_select_at_least_one: `Must select at least one`,
  must_be_a_positive_number: `Must be a positive number.`,
  bid_must_be_a_minimum_value_of_try_adjusting: `Bid must be a minimum value of {{ minStandingBidLot }}. Try adjusting the minimum lot size and/or price per share.`,
  expiration_date: `Expiration date`,
  additional_standing_bid_notes: `Additional standing bid notes`,
  broker_submit_bid_affiliate_question: `Are you posting this bid on behalf of an insider or affiliate of {{companyName}}?`,
  broker_submit_bid_unsolicited_question: `Is this an unsolicited bid?`,
  broker_submit_bid_acknowledgement: `I confirm that I am submitting this bid on behalf of a potential buyer with whom I am in direct contact and who has a serious and bona fide interest in pursuing a transaction on the posted terms.`,
  place_standing_bid_confirm_intent_prompt: `I understand that by placing this standing bid, I am communicating a serious, but not binding, intention to complete a sale transaction on these terms.`,
  place_standing_bid_confirm_intent_description: `Sellers cannot counter standing bids; they must accept the terms you initially set. However, a seller can message you to propose a different price, allowing for negotiation. You can modify or withdraw your standing bid at any time.`,
  hiive_advanced_options: `Hiive Advanced Options`,
  do_not_send_activity_notifications: `Do not send activity notifications about this change`,
  represented_user_info: `Name and email of who you are representing`,
  first_name: `First name`,
  last_name: `Last name`,
  notify_represented_user: `Notify the person being represented`,
  standing_bid_submitted: `Standing bid successfully {{verb}}`,
  placed: `placed`,
  submitted: `submitted`,
  represented_email: `Email`,
  your_standing_bid_is_in_review: `Your {{companyName}} standing bid for {{numShares}} shares at {{pricePerShare}}/sh is now in review.`,
  your_standing_bid_is_being_reviewed: `Your standing bid is being reviewed and may then be placed on the Hiive marketplace. You will be notified once it gets posted, and Hiive will keep you informed of any interest shown by potential sellers in response to your bid.`,
  you_may_also_reach_out: `You may also reach out to the <mailto> </mailto> for any help.`,
  your_standing_bid_is_live: `Your {{companyName}} standing bid for {{numShares}} shares at {{pricePerShare}}/sh is now live on Hiive!`,
  potential_sellers_will_now_be_notified: `Potential {{companyName}} sellers will now be notified of your bid. Sellers may view, accept, or message you on your standing bid. You will be notified of any interest in your bid from potential sellers.`,
  you_may_also_reach_out_for_assistance: `You may also reach out to the <mailto> </mailto> for assistance on your transaction.`,
  standing_bid_shares_purchased: `{{ numSharesAccepted }} shares purchased`,
  standing_bid: `Standing Bid`,
};

export default locale;
