import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { InquiryEntityPostingCardFragment } from "@/gql";

type InquiryEntityPostingCardHeaderProps = {
  discussion: InquiryEntityPostingCardFragment;
};

export function InquiryEntityPostingCardHeader({
  discussion: { displayId, topic },
}: InquiryEntityPostingCardHeaderProps) {
  const { displayId: entityDisplayId } = topic;

  const { t } = useTranslation(`discussions`);

  const { text: discussionLabelText, variant: discussionLabelVariant } =
    useMemo(
      () =>
        match(topic)
          .with({ __typename: `StandingBid` }, () => ({
            variant: `standing-bid`,
            text: t(`standing_bid_inquiry`),
          }))
          .with({ __typename: `Listing` }, () => ({
            variant: `listing`,
            text: t(`listing_inquiry`),
          }))
          .otherwise(() => ({
            variant: undefined,
            text: undefined,
          })),
      [t, topic],
    );

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant={discussionLabelVariant}>
            {discussionLabelText}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{entityDisplayId}</PostingCard.HeaderTitle>
        </>
      }
    >
      <Text ml="auto" textStyle="text-xs" whiteSpace="nowrap">
        {displayId}
      </Text>
    </PostingCard.Header>
  );
}
