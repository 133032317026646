import { useTranslation } from "react-i18next";

import { Box, Fade, Stack, Text } from "@chakra-ui/react";

import { ActivityCardNewBadge, PostingCard } from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { BidPostingCardFragment } from "@/gql";
import { toTimestamp } from "@/utils";

type BidPostingCardUnaccreditedSellerFooterProps = {
  bid: BidPostingCardFragment;
};

export function BidPostingCardUnaccreditedSellerFooter({
  bid,
}: BidPostingCardUnaccreditedSellerFooterProps) {
  const { t } = useTranslation(`bids`);

  const { getIsNewBid, getInquiryNumNewMessages } =
    useUnaccreditedSellerInlineNotifications();

  const { discussion, insertedAt } = bid;

  const totalNumMessages = !!discussion ? discussion.totalNumMessages : 0;

  const numNewMessages = !!discussion
    ? getInquiryNumNewMessages(discussion)
    : 0;

  const isNewBid = getIsNewBid(bid);
  const hasMessages = totalNumMessages > 0;

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm" whiteSpace="nowrap">
        {t(`placed`, {
          timestamp: toTimestamp(insertedAt),
        })}
      </Text>
      <Box flexGrow={1} />
      <Stack flexDirection={{ base: `column`, sm: `row` }}>
        {hasMessages && (
          <MessagesBadge
            numUnreadMessages={numNewMessages}
            totalNumMessages={totalNumMessages}
          />
        )}
        {isNewBid && (
          <Fade in>
            <ActivityCardNewBadge />
          </Fade>
        )}
      </Stack>
    </PostingCard.Footer>
  );
}
