import { filter, orderBy, pipe, update } from "lodash/fp";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActivityGroup, ActivitySection, WithQuery } from "@/components/common";
import {
  CompanyMarketActivityListingCard,
  CompanyMarketActivityStandingBidCard,
} from "@/components/companies";
import {
  CompanyPageMarketActivityCompanyFragment,
  CompanyPageMarketActivityListingFragment,
  CompanyPageMarketActivityStandingBidFragment,
  useCompanyPageMarketActivityCompanyByIdV2Query,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useOrderBookTruncation } from "@/hooks/featureFlags";
import {
  byOpenOrConditionallySoldListings,
  byListingsNotByMyInstitution,
  byOpenOrConditionallyCompletedStandingBids,
  byStandingBidsNotByMyInstitution,
} from "@/utils";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { MarketActivityContentV2 } from "./MarketActivityContentV2";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";

type MarketActivityListingsProps = {
  readonly listings: readonly CompanyPageMarketActivityListingFragment[];
  readonly standingBids: readonly CompanyPageMarketActivityStandingBidFragment[];
  readonly company: CompanyPageMarketActivityCompanyFragment;
};

type MarketActivityStandingBidsProps = {
  readonly standingBids: readonly CompanyPageMarketActivityStandingBidFragment[];
  readonly listings: readonly CompanyPageMarketActivityListingFragment[];
  readonly company: CompanyPageMarketActivityCompanyFragment;
};

type MarketActivityContentProps = {
  readonly company: CompanyPageMarketActivityCompanyFragment;
};

const orderByHighestBidFirst = orderBy([`pricePerShare`], [`desc`]);
const orderByLowestAskFirst = orderBy([`listingPricePerShare`], [`asc`]);

function MarketActivityListings({
  listings,
  standingBids,
  company,
}: MarketActivityListingsProps) {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup
      title={t(`sellers`)}
      emptyState={
        <ListingsEmptyState
          company={company}
          numberOfStandingBids={standingBids.length}
        />
      }
    >
      {listings.map((listing) => (
        <CompanyMarketActivityListingCard
          key={listing.id}
          listing={listing}
          company={company}
        />
      ))}
    </ActivityGroup>
  );
}

function MarketActivityStandingBids({
  standingBids,
  listings,
  company,
}: MarketActivityStandingBidsProps) {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup
      title={t(`buyers`)}
      emptyState={
        <StandingBidsEmptyState
          company={company}
          numberOfListings={listings.length}
        />
      }
    >
      {standingBids.map((standingBid) => (
        <CompanyMarketActivityStandingBidCard
          key={standingBid.id}
          standingBid={standingBid}
        />
      ))}
    </ActivityGroup>
  );
}

const MarketActivityContent = ({ company }: MarketActivityContentProps) => {
  const actor = useCurrentActor();
  const { t } = useTranslation(`company`);

  const { institutionId } = actor;

  const { othersListings, othersStandingBids } = useMemo(
    () =>
      pipe([
        update(`othersListings`, filter(byOpenOrConditionallySoldListings)),
        update(`othersListings`, orderByLowestAskFirst),
        update(
          `othersListings`,
          filter(byListingsNotByMyInstitution(institutionId)),
        ),
        update(
          `othersStandingBids`,
          filter(byOpenOrConditionallyCompletedStandingBids),
        ),
        update(`othersStandingBids`, orderByHighestBidFirst),
        update(
          `othersStandingBids`,
          filter(byStandingBidsNotByMyInstitution(institutionId)),
        ),
      ])(company.activity),
    [company],
  );

  return (
    <ActivitySection title={t(`market_activity`)}>
      <MarketActivityListings
        listings={othersListings}
        standingBids={othersStandingBids}
        company={company}
      />
      <MarketActivityStandingBids
        standingBids={othersStandingBids}
        listings={othersListings}
        company={company}
      />
    </ActivitySection>
  );
};

export const MarketActivity = ({
  companyId,
}: {
  readonly companyId: string;
}) => {
  const orderBookTruncationEnabled = useOrderBookTruncation();

  const query = useCompanyPageMarketActivityCompanyByIdV2Query({
    variables: {
      id: companyId,
      includeMarketActivityV2: !!orderBookTruncationEnabled,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({ data: { company } }) =>
        orderBookTruncationEnabled ? (
          <MarketActivityContentV2 company={company} />
        ) : (
          <MarketActivityContent company={company} />
        )
      }
    </WithQuery>
  );
};
