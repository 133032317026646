import { Trans, useTranslation } from "react-i18next";

import {
  CardBody,
  Text,
  VStack,
  SimpleGrid,
  FormLabel,
  GridItem,
  Card,
} from "@chakra-ui/react";

import {
  FormPhoneNumberInput,
  FormRadioTile,
  FormTextInput,
  TileCheckboxInput,
} from "@/components/react-hook-form";
import type { TrustedContactFormValues } from "@/components/trusted-contact-form";
import { useFormQL } from "@/hooks/react-hook-form";

type TrustedContactFormProps = {
  readonly trustedContactForm: Pick<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReturnType<typeof useFormQL<TrustedContactFormValues, any, any>>,
    `watch` | `handleSubmit` | `control`
  >;
  width?: string | object;
};

const TrustedContactForm = ({
  trustedContactForm,
  width = `full`,
}: TrustedContactFormProps) => {
  const { t } = useTranslation();
  const { control, watch } = trustedContactForm;
  const shouldShowTrustedPersonForm = !!watch(`collectTrustedContactPerson`);
  return (
    <Card variant="darkened-footer" w={width} flex="1">
      <CardBody p={{ base: 4, lg: 10 }}>
        <VStack spacing={4} alignItems="flex-start">
          <Text textStyle="heading-lg">
            <Trans
              i18nKey="add_trusted_contact_person"
              components={{ italic: <i /> }}
            />
            {` `}*
          </Text>
          <TileCheckboxInput.Tooltip>
            <VStack spacing={4} alignItems="flex-start" px={2}>
              <Text>{t`add_trusted_contact_person_disclaimer`}</Text>
              <Text>{t`add_trusted_contact_person_disclaimer_continued`}</Text>
            </VStack>
          </TileCheckboxInput.Tooltip>
          <SimpleGrid
            gridTemplateColumns="1fr 1fr"
            rowGap={6}
            width={{ base: `full`, lg: 144 }}
          >
            <GridItem colSpan={2}>
              <FormRadioTile.Group
                size="md"
                gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
              >
                <FormRadioTile.Tile
                  control={control}
                  name="collectTrustedContactPerson"
                  value
                  alignItems="center"
                >
                  <Text textStyle="text-md">{t`yes`}</Text>
                </FormRadioTile.Tile>
                <FormRadioTile.Tile
                  control={control}
                  name="collectTrustedContactPerson"
                  value={false}
                  alignItems="center"
                >
                  <Text textStyle="text-md">{t`no`}</Text>
                </FormRadioTile.Tile>
              </FormRadioTile.Group>
            </GridItem>
            {shouldShowTrustedPersonForm && (
              <>
                <GridItem colSpan={2}>
                  <FormLabel>{t`name`} *</FormLabel>
                  <SimpleGrid
                    gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
                    rowGap={{ base: 2, lg: 0 }}
                    columnGap={2}
                  >
                    <GridItem colSpan={1}>
                      <FormTextInput
                        control={control}
                        name="firstName"
                        placeholder={t`first`}
                        label=""
                        labelSrOnly
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormTextInput
                        control={control}
                        name="lastName"
                        placeholder={t`last`}
                        label=""
                        labelSrOnly
                      />
                    </GridItem>
                  </SimpleGrid>
                </GridItem>

                <GridItem colSpan={2}>
                  <FormPhoneNumberInput
                    control={control}
                    isRequired
                    name="phoneNumber"
                    label={t(`telephone`)}
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <FormTextInput
                    type="email"
                    isRequired
                    name="email"
                    label={t(`email`)}
                    placeholder={t(`email_address`)}
                    control={control}
                  />
                </GridItem>

                <GridItem colSpan={2}>
                  <FormTextInput
                    isRequired
                    name="relationship"
                    label={t(`relationship`)}
                    placeholder={t(`relationship_placeholder`)}
                    control={control}
                  />
                </GridItem>
              </>
            )}
          </SimpleGrid>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default TrustedContactForm;
