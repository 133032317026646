import { X } from "@phosphor-icons/react";
import { useField } from "formik";
import get from "lodash/get";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { CustomTooltip, FormField, FormListbox } from "@/components/form";
import { ShareSeriesMakeupElement } from "@/components/postings";
import { ShareSeries, useValidShareSeriesQuery } from "@/gql";
import { useIsDesktop } from "@/hooks";
import { getShareSeriesText } from "@/utils";

const XButton = ({ onClick }: { readonly onClick: () => void }) => (
  <CustomTooltip tooltipContent="Remove" placement="top">
    <Center
      w={10}
      h={10}
      cursor="pointer"
      onClick={onClick}
      color="black"
      transition=".1s all ease-in-out"
      _hover={{
        color: `grey.600`,
      }}
    >
      <X fill="current" />
    </Center>
  </CustomTooltip>
);

interface ShareSeriesMakeupInputProps {
  readonly excludedErrorMessages?: readonly string[];
  readonly onChange?: (
    shareSeriesMakeupElements: ShareSeriesMakeupElement[],
  ) => void;
}

const ShareSeriesMakeupInput = ({
  excludedErrorMessages = [],
  onChange,
}: ShareSeriesMakeupInputProps) => {
  const { loading, data } = useValidShareSeriesQuery();
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const name = `shareSeriesMakeup`;

  const [field, { touched, error }, { setValue }] = useField(name);

  const selectedShareSeriesMakeupElements: ShareSeriesMakeupElement[] =
    field.value;

  useEffect(() => {
    onChange?.(selectedShareSeriesMakeupElements);
  }, [selectedShareSeriesMakeupElements]);

  if (loading) {
    return (
      <Center m={20}>
        <Spinner />
      </Center>
    );
  }

  const checkSharesSeriesIsSelected = (
    shareSeries: string,
    selectedSharesSeriesElements: readonly ShareSeriesMakeupElement[],
  ) =>
    selectedSharesSeriesElements.some(
      (element) => element.shareSeries === shareSeries,
    );

  const filteredShareSeriesItems = !!data?.validShareSeries
    ? data.validShareSeries.filter((_shareSeries) => {
        if (
          checkSharesSeriesIsSelected(
            _shareSeries,
            selectedShareSeriesMakeupElements,
          )
        ) {
          return false;
        }
        // This option is deprecated unless the user is modifying there listing and has already selected it
        if (_shareSeries === ShareSeries.CommonOptions) {
          return false;
        }
        return true;
      })
    : [];

  const onClickRemoveShareMakeup = (key: string) => {
    setValue(
      selectedShareSeriesMakeupElements.filter(({ key: _key }) => _key !== key),
    );
  };

  const getLabelSrOnly = (index: number) => {
    if (isDesktop) return index > 0 ? true : undefined;
    return undefined;
  };

  const singleShareMakeup = selectedShareSeriesMakeupElements.length === 1;

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <SimpleGrid
        columns={2}
        gridTemplateColumns={{ base: `1fr auto`, md: `1fr 1fr` }}
        columnGap={{ base: 0, md: 9 }}
        rowGap={2}
        alignItems="end"
      >
        {selectedShareSeriesMakeupElements.map(({ key }, index: number) => {
          const errorMessage = get(error, `[0].numShares`);

          return (
            <Fragment key={key}>
              <GridItem colSpan={{ base: 1, md: singleShareMakeup ? 2 : 1 }}>
                {singleShareMakeup && (
                  <>
                    <FormLabel mb={0}>{t(`share_series`)}</FormLabel>
                    <Text textStyle="text-sm" mb={2}>
                      {t(`share_series_anonymity`)}
                    </Text>
                  </>
                )}
                <FormListbox
                  name={`${name}[${index}].shareSeries`}
                  label={t(`share_series`)}
                  items={filteredShareSeriesItems}
                  labelSrOnly={singleShareMakeup || getLabelSrOnly(index)}
                  itemToString={(item) => getShareSeriesText(item)}
                  getItemKey={(item) => item}
                />
              </GridItem>
              {!isDesktop && !singleShareMakeup && (
                <GridItem colSpan={1} rowSpan={2}>
                  <VStack justify="center" h="full" alignItems="flex-end">
                    <XButton onClick={() => onClickRemoveShareMakeup(key)} />
                  </VStack>
                </GridItem>
              )}
              {selectedShareSeriesMakeupElements.length !== 1 && (
                <GridItem colSpan={1}>
                  <HStack
                    w="full"
                    alignItems="flex-end"
                    mb={{ base: 5, md: 0 }}
                  >
                    <FormField.Control name={`${name}[${index}].numShares`}>
                      <FormField.NumberInput
                        label={t(`number_of_shares_per_series`)}
                        data-testid={`num-shares-${index}`}
                        labelSrOnly={getLabelSrOnly(index)}
                        rightElement={
                          isDesktop && (
                            <XButton
                              onClick={() => onClickRemoveShareMakeup(key)}
                            />
                          )
                        }
                      />
                      {!(
                        errorMessage &&
                        excludedErrorMessages.includes(errorMessage)
                      ) && <FormField.Error />}
                    </FormField.Control>
                  </HStack>
                </GridItem>
              )}
            </Fragment>
          );
        })}
        <GridItem colSpan={2}>
          {typeof error === `string` && (
            <FormErrorMessage>{error}</FormErrorMessage>
          )}
        </GridItem>
      </SimpleGrid>
    </FormControl>
  );
};

export default ShareSeriesMakeupInput;
