export const ROUTES = {
  ACCOUNT_REQUEST_UPDATED_EMAIL_VERIFICATION: `/account/request-updated-email-verification`,
  ACCOUNT_PASSWORD_UPDATE_CONFIRMED: `/account/password-update-confirmed`,
  ACCOUNT_SETTINGS_CONTACT: `/account/settings/contact`,
  ACCOUNT_SETTINGS_CHANGE_EMAIL: `/account/settings/change-email`,
  DASHBOARD: `/dashboard`,
  DEFAULT_LOGIN: `/login`,
  FORGOT_PASSWORD: `/forgot-password`,
  INDEX: `/`,
  REQUEST_EMAIL_VERIFICATION: `/request-email-verification`,
  WELCOME: `/welcome`,
};
