import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";

import { HiiveTab, Skeleton } from "@/components/common";
import {
  Transaction,
  useCurrentContextQuery,
  useUserActivityMyActivityQuery,
} from "@/gql";
import { useTabRouter, useTransactionDetails } from "@/hooks";

import ActiveTransactionTabPanel from "./ActiveTransactionTabPanel";
import ActiveTransactionsCardHeader from "./ActiveTransactionsCardHeader";

enum ActiveTransactionTabs {
  MY_TRANSACTIONS = `MY_TRANSACTIONS`,
  OTHER_TRANSACTIONS = `OTHER_TRANSACTIONS`,
}

const filterOtherTransactions = (
  myTransactions: Pick<Transaction, "id">[] | undefined,
  institutionTransaction: Pick<Transaction, "id">,
) =>
  !myTransactions?.some(
    (myTransaction) => myTransaction?.id === institutionTransaction?.id,
  );

const InstitutionActiveTransactionsCard = () => {
  const { t } = useTranslation(`dashboard`);
  const { tabControls, tabsInfo } = useTabRouter<ActiveTransactionTabs>({
    initialTab: ActiveTransactionTabs.MY_TRANSACTIONS,
  });

  const { data, loading } = useUserActivityMyActivityQuery({
    fetchPolicy: `network-only`,
    variables: {
      isInstitution: true,
    },
  });
  const { data: currentContextData } = useCurrentContextQuery();
  const legalName =
    currentContextData?.currentContext?.currentActor.institution?.legalName;
  const { isNotTerminal } = useTransactionDetails();

  const myTransactions = data?.myActivity.myTransactions;
  const otherTransactions = data?.myActivity.myInstitutionTransactions?.filter(
    (institutionTransaction: Transaction) =>
      filterOtherTransactions(myTransactions, institutionTransaction),
  );

  const activeTransactions = [
    ...(myTransactions ?? []),
    ...(otherTransactions ?? []),
  ].filter(isNotTerminal);

  if (activeTransactions?.length === 0 && !loading) return null;

  const showMyTransactions =
    tabsInfo.currentTabKey === ActiveTransactionTabs.MY_TRANSACTIONS;
  const showOtherTransactions =
    tabsInfo.currentTabKey === ActiveTransactionTabs.OTHER_TRANSACTIONS;

  return (
    <Card w="full">
      <ActiveTransactionsCardHeader
        transactionsCount={activeTransactions?.length}
      />
      <Tabs w="full">
        <Box p="6" pb="0">
          <TabList alignItems="center" border={0} pb={0}>
            <HiiveTab
              isActive={showMyTransactions}
              onClick={() =>
                tabControls.jumpToTab(ActiveTransactionTabs.MY_TRANSACTIONS)
              }
            >
              {t(`my_transactions`)}
            </HiiveTab>
            <HiiveTab
              isActive={showOtherTransactions}
              onClick={() =>
                tabControls.jumpToTab(ActiveTransactionTabs.OTHER_TRANSACTIONS)
              }
            >
              {t(`other_transactions`, {
                name: legalName,
              })}
            </HiiveTab>
          </TabList>
        </Box>
        <CardBody
          alignItems="start"
          as={VStack}
          maxH="sm"
          overflowY="auto"
          gap={4}
        >
          <TabPanels>
            {loading && (
              <VStack gap={4} w="full">
                <Skeleton h={10} />
                <Skeleton h={10} />
                <Skeleton h={10} />
              </VStack>
            )}
            {!loading && (
              <>
                {showMyTransactions && (
                  <ActiveTransactionTabPanel transactions={myTransactions} />
                )}
                {showOtherTransactions && (
                  <ActiveTransactionTabPanel transactions={otherTransactions} />
                )}
              </>
            )}
          </TabPanels>
        </CardBody>
      </Tabs>
    </Card>
  );
};

export default InstitutionActiveTransactionsCard;
