import HiiveButton, { HiiveButtonProps } from "./HiiveButton";

interface HiiveSubmitButtonProps extends HiiveButtonProps {
  readonly submitText: string;
}

const HiiveSubmitButton = ({
  observabilityLabel,
  submitText,
  ...buttonProps
}: HiiveSubmitButtonProps) => (
  <HiiveButton
    variant="rounded-solid-salmon"
    w={{ base: `full`, sm: `unset` }}
    maxW="unset"
    size="xl"
    observabilityLabel={observabilityLabel}
    {...buttonProps}
  >
    {submitText}
  </HiiveButton>
);

export default HiiveSubmitButton;
