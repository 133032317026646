import { Text } from "@chakra-ui/react";

import { useCardUpdates } from "@/hooks/featureFlags";

const ActivityCardDisplayId = ({
  displayId,
}: {
  readonly displayId: string;
}) => {
  const areCardUpdatesEnabled = useCardUpdates();
  return (
    <Text
      textStyle={
        areCardUpdatesEnabled
          ? { base: `text-sm`, lg: `text-lg` }
          : { base: `text-md`, sm: `text-lg` }
      }
      whiteSpace="nowrap"
    >
      {displayId}
    </Text>
  );
};

export default ActivityCardDisplayId;
