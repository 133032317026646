import currency from "currency.js";
import dayjs from "dayjs";
import { Form } from "formik";
import { AnimatePresence } from "framer-motion";
import uniqueId from "lodash/uniqueId";

import { AnimatedModalStep, StepFormikQL } from "@/components/form";
import {
  CompanyWatchlistActorDocument,
  CreateListingSelectCompanyInputCompanyFragment,
  ListingInput,
  MarketActivityDocument,
  SigningProcedure,
  TransferMethod,
  useCreateListingMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { constants, sumShareSeriesMakeup } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  BrokerSubmitListingSequenceModalFormValues,
  BrokerSubmitListingModal,
  BrokerSubmitListingSuccessModal,
  StepKeys,
  stepKeys,
  BrokerSubmitListingSequenceModalStepFormContext,
} from "./steps";

interface BrokerSubmitListingSequenceModalContentProps {
  readonly initialCompany?: CreateListingSelectCompanyInputCompanyFragment;
}

const buildInitialValues = (
  initialCompany?: CreateListingSelectCompanyInputCompanyFragment,
) => ({
  affiliate: false,
  company: initialCompany || null,
  confirmed: false,
  expireAt: datetime
    .add(`week`, constants.default_listing_expire_after_weeks, dayjs())
    .toDate(),
  hasExpirationDate: true,
  manualSigningProcedure: false,
  muteNotifyWatchers: false,
  notifyRepresentingEmail: true,
  otherDetails: null,
  pricePerShare: null,
  representedEmail: ``,
  shareSeriesMakeup: [{ shareSeries: null, numShares: null, key: uniqueId() }],
  solicited: false,
  transferMethod: TransferMethod.Direct,
});

const mapVariables = ({
  confirmed: _confirmed,
  representedEmail: _representedEmail,
  manualSigningProcedure,
  shareSeriesMakeup,
  hasExpirationDate,
  company,
  ...values
}: BrokerSubmitListingSequenceModalFormValues) => ({
  input: {
    ...values,
    companyId: company?.id,
    numShares: sumShareSeriesMakeup(shareSeriesMakeup),
    shareSeriesMakeup: shareSeriesMakeup.map(({ key: _key, ...rest }) => ({
      ...rest,
    })),
    pricePerShare: !!values.pricePerShare
      ? currency(values.pricePerShare).intValue
      : null,
    expireAt:
      hasExpirationDate && !!values.expireAt
        ? datetime.endOf(`day`, values.expireAt).format()
        : null,
    signingProcedure: manualSigningProcedure
      ? SigningProcedure.Manual
      : SigningProcedure.Automated,
  } as ListingInput,
});

export const BrokerSubmitListingSequenceModalContent = ({
  initialCompany,
}: BrokerSubmitListingSequenceModalContentProps) => {
  const mutation = useCreateListingMutation({
    refetchQueries: [
      MarketActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
    ],
  });

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.brokerSubmitListingShareDetails,
      stepKeys.brokerSubmitListingAdditionalDetails,
      stepKeys.brokerSubmitListingSuccess,
    ],
  });

  const {
    stepsInfo: { currentStepKey },
  } = stepRouter;

  const createListingModalStepKeys: readonly StepKeys[] = [
    stepKeys.brokerSubmitListingShareDetails,
    stepKeys.brokerSubmitListingAdditionalDetails,
  ];

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`createListing`]}
      initialValues={buildInitialValues(initialCompany)}
      mapVariables={mapVariables}
      context={BrokerSubmitListingSequenceModalStepFormContext}
    >
      {({ formikProps }) => (
        <Form>
          <AnimatePresence mode="wait" initial={false}>
            {createListingModalStepKeys.includes(currentStepKey) && (
              <AnimatedModalStep key={createListingModalStepKeys.join()}>
                <BrokerSubmitListingModal
                  initialCompany={initialCompany}
                  stepRouter={stepRouter}
                  {...formikProps}
                />
              </AnimatedModalStep>
            )}
            {stepKeys.brokerSubmitListingSuccess === currentStepKey && (
              <AnimatedModalStep key={stepKeys.brokerSubmitListingSuccess}>
                <BrokerSubmitListingSuccessModal />
              </AnimatedModalStep>
            )}
          </AnimatePresence>
        </Form>
      )}
    </StepFormikQL>
  );
};
