export default {
  requested: `Requested`,
  in_progress: `In Progress`,
  closed: `Closed`,
  standing_bid_inquiry: `Standing Bid Inquiry`,
  listing_inquiry: `Listing Inquiry`,
  inquiry: `Inquiry`,
  reply: `Reply`,
  inquiries: `Inquiries`,
};
