import { useField } from "formik";

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import { FormSelectListingInputListingFragment } from "@/gql";
import { useIsDesktop } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getListingNumOfShares,
  getPricePerShare,
  lot,
  transferMethodToString,
} from "@/utils";

const ListingItem = ({
  listing,
  isSelected,
  onClick,
}: {
  readonly listing: FormSelectListingInputListingFragment;
  readonly isSelected: boolean;
  readonly onClick: () => void;
}) => {
  const isDesktop = useIsDesktop();
  const numShares = getListingNumOfShares(listing);
  const formattedNumShares = formatShares(numShares);

  const pricePerShare = getPricePerShare(listing);
  const formattedPricePerShare = !!pricePerShare
    ? formatPricePerShare(pricePerShare)
    : null;

  const transferMethod = transferMethodToString(listing.transferMethod);

  const _lot = !!pricePerShare ? lot(numShares, pricePerShare) : null;

  return (
    <ListItem w="full">
      <Button
        onClick={onClick}
        variant="unstyled"
        maxW="unset"
        w="full"
        _hover={{
          bg: `grey.50`,
        }}
        _active={{
          bg: `grey.75`,
        }}
        transition="all .1s ease-in-out"
        bg={isSelected ? `grey.50` : `transparent`}
        borderRadius={0}
      >
        <Grid
          px={{ base: 5, md: 10 }}
          gridTemplateColumns={{
            base: `1fr 1fr 2fr 1fr`,
            md: `1fr 1fr 2fr 1fr 1fr`,
          }}
          w="full"
          justifyContent="flex-start"
          textStyle={{ base: `heading-xs`, md: `heading-md` }}
          textAlign="left"
        >
          <Text>{isDesktop ? listing.displayId : listing.shortId}</Text>
          <Text>{formattedNumShares}</Text>
          <Box>
            {!!formatPricePerShare && (
              <>
                <Text as="span">@{` `}</Text>
                <Text as="span">{formattedPricePerShare}</Text>
                <Text as="span">{` `}/sh</Text>
              </>
            )}
          </Box>
          <Text display={{ base: `none`, md: `block` }}>{transferMethod}</Text>
          {!!_lot && <Text textAlign="right">{_lot}</Text>}
        </Grid>
      </Button>
    </ListItem>
  );
};

const FormSelectListingInput = ({
  name = `listingId`,
  options,
  onSelectListing,
}: {
  readonly name?: string;
  readonly options: readonly FormSelectListingInputListingFragment[];
  readonly onSelectListing: (
    listing: FormSelectListingInputListingFragment,
  ) => void;
}) => {
  const [field, { touched, error }, { setValue }] = useField(name);

  const handleClick = (listing: FormSelectListingInputListingFragment) => {
    setValue(listing.id);
    onSelectListing(listing);
  };

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <List
        bg="white"
        as={VStack}
        spacing="unset"
        w="full"
        alignItems="flex-start"
      >
        {options.map((listing) => {
          const isSelected = listing.id === field.value;

          return (
            <ListingItem
              key={listing.id}
              onClick={() => handleClick(listing)}
              isSelected={isSelected}
              listing={listing}
            />
          );
        })}
      </List>
      <Box px={10}>
        <FormErrorMessage>{error}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};
export default FormSelectListingInput;
