import { useEffect, useState } from "react";

import {
  Box,
  Card,
  GridItem,
  Link,
  SimpleGrid,
  Text,
  VStack,
  keyframes,
} from "@chakra-ui/react";

import { LongHyphen } from "@/components/common";
import { useIndexCompaniesPreviewQuery } from "@/gql";
import { useRedirectCompanyLink } from "@/hooks";
import {
  roundPercentage,
  formatPricePerShare,
  getTrendPercentageColor,
} from "@/utils";

const fade = keyframes`
  0%, 100% {
    opacity: 0;
  }
  15%, 85% {
    opacity: 1;
  }
}`;

const ANIMATION_DURATION = 4.6;

export const H50Ticker = () => {
  const redirectCompanyLink = useRedirectCompanyLink();

  const [resetTicker, setResetTicker] = useState(Math.random());
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);

  const { data } = useIndexCompaniesPreviewQuery();

  const baseItems =
    data?.indexCompanies?.edges?.flatMap((edge) => {
      if (!edge || !edge.node) return [];

      return [edge.node];
    }) || [];

  const itemsToRepeat = baseItems.slice(
    0,
    Math.floor(baseItems.length / 5) * 5,
  );

  // multiply items to rerender 10 times less often
  const items = Array.from({ length: 10 }, () => itemsToRepeat).flat();

  const totalItems = Math.floor(items.length / 5) * 5;

  // split items into even groups of 5
  const itemGroups = Array.from({ length: Math.ceil(totalItems / 5) }, (_, i) =>
    items.slice(i * 5, i * 5 + 5),
  );

  const totalDuration = itemGroups.length * ANIMATION_DURATION * 1000;

  useEffect(() => {
    // rerender row every full pass to avoid animation looping between two last rows
    const resetIntervalId = setInterval(() => {
      setResetTicker(Math.random());
    }, totalDuration);

    return () => clearInterval(resetIntervalId);
  }, [totalDuration]);

  const handleAnimationStart = (index: number) => {
    // because animation is based on opacity
    // we're using pointer events management to avoid click events on hidden items
    setActiveGroupIndex(index);
  };

  return (
    <Box
      w="full"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      position="relative"
      height={18}
      transition="opacity 0.15s ease-out"
      sx={{
        "&:hover > .ticker-row": {
          animationPlayState: `paused`,
        },
      }}
    >
      {itemGroups.map((group, index) => (
        <SimpleGrid
          // eslint-disable-next-line react/no-array-index-key
          key={`${group[0].id}-${resetTicker}-${index}`}
          w="full"
          columns={5}
          columnGap={2}
          opacity={0}
          animation={`${fade} 5s infinite linear ${
            index * ANIMATION_DURATION
          }s`}
          position="absolute"
          top={0}
          className="ticker-row"
          sx={{
            pointerEvents: activeGroupIndex === index ? `auto` : `none`,
          }}
          onAnimationStart={() => handleAnimationStart(index)}
        >
          {group.map((item) => {
            const { company, todaysPriceGraph } = item;
            const { priceChangeNinetyDays, indexPrice } =
              todaysPriceGraph || {};
            return (
              <GridItem key={item.id}>
                <Link
                  href={redirectCompanyLink(company)}
                  textDecoration="none"
                  _hover={{
                    ".company-name": { textDecoration: `underline` },
                  }}
                >
                  <Card variant="flat" border="none">
                    <VStack spacing={1} alignItems="start">
                      <Text
                        className="company-name"
                        w="full"
                        textStyle={{
                          base: `text-xs`,
                          sm: `text-sm`,
                        }}
                        maxW={32}
                        noOfLines={1}
                      >
                        {company.name}
                      </Text>
                      <Text
                        textStyle={{
                          base: `heading-xs`,
                          sm: `heading-sm`,
                        }}
                        w="full"
                        maxW={32}
                        noOfLines={1}
                      >
                        {indexPrice ? (
                          formatPricePerShare(indexPrice)
                        ) : (
                          <LongHyphen />
                        )}
                      </Text>
                      <Text
                        textStyle={{
                          base: `heading-xs`,
                          sm: `heading-sm`,
                        }}
                        color={getTrendPercentageColor(priceChangeNinetyDays)}
                        w="full"
                        maxW={32}
                        noOfLines={1}
                      >
                        {roundPercentage(parseFloat(priceChangeNinetyDays), 1)}
                      </Text>
                    </VStack>
                  </Card>
                </Link>
              </GridItem>
            );
          })}
        </SimpleGrid>
      ))}
    </Box>
  );
};
