import { CardHeader, Flex } from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardDisplayId,
} from "@/components/common";
import { UserActivityTransactionFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { bidLabel } from "@/utils";

const DashboardTransactionCardHeader = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const areCardUpdatesEnabled = useCardUpdates();
  const bidOrOrder = bidLabel(transaction.bid.listing);
  const title = transaction.bid.standingBid
    ? `Transaction on Standing Bid`
    : `Transaction on ${bidOrOrder}`;

  const displayId =
    transaction.bid.standingBid?.displayId || transaction.bid.displayId;

  return (
    <CardHeader
      px={areCardUpdatesEnabled ? 3 : 4}
      py={areCardUpdatesEnabled ? 2 : 4}
    >
      <Flex alignItems="center" gap={2}>
        <ActivityCardBadge variant="transaction" title={title} />
        <ActivityCardDisplayId displayId={displayId} />
        <ActivityCardCompany ml="auto" company={transaction.company} />
      </Flex>
    </CardHeader>
  );
};

export default DashboardTransactionCardHeader;
