import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { ObscureText, PostingCard } from "@/components/common";
import { InquiryPostingCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getDiscussionHasCounterpartyMessage,
  getIsSensitiveMessage,
} from "@/utils";

type InquiryPostingCardBodyProps = {
  discussion: InquiryPostingCardFragment;
};

export function InquiryPostingCardBody({
  discussion,
}: InquiryPostingCardBodyProps) {
  const { mostRecentMessage } = discussion;

  const { t } = useTranslation(`discussions`);
  const actor = useCurrentActor();

  const hasCounterpartyMessage = getDiscussionHasCounterpartyMessage(
    actor,
    discussion,
  );

  if (!hasCounterpartyMessage || !mostRecentMessage) {
    return null;
  }

  const isSensitiveMessage = getIsSensitiveMessage(
    actor,
    mostRecentMessage,
    discussion,
  );

  const { messageBody } = mostRecentMessage;

  return (
    <PostingCard.Body>
      <VStack alignItems="flex-start" gap={1}>
        <ObscureText obscure={isSensitiveMessage} textStyle="text-sm">
          {messageBody}
        </ObscureText>
        <Text textStyle="heading-xs">{t(`reply`)}</Text>
      </VStack>
    </PostingCard.Body>
  );
}
