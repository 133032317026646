import isNil from "lodash/isNil";

import { TransferMethod } from "@/gql";
import { Maybe } from "@/types";
import { constants } from "@/utils";

interface Listing {
  readonly transferMethod: TransferMethod;
}

export const getAreFeesHigh = ({
  pricePerShare,
  numShares,
}: {
  readonly pricePerShare?: number | null;
  readonly numShares?: number | null;
}) =>
  !isNil(pricePerShare) && !isNil(numShares)
    ? pricePerShare * numShares < constants.high_fees_warning_threshold
    : false;

export const getAreFeesHighForListing = ({
  pricePerShare,
  numberOfShares,
}: {
  readonly pricePerShare?: Maybe<number>;
  readonly numberOfShares: number;
}) => {
  const isWithinWarningThreshold = pricePerShare
    ? getAreFeesHigh({ pricePerShare, numShares: numberOfShares })
    : false;

  return isWithinWarningThreshold;
};

export const isFund = (listing: Listing) =>
  listing.transferMethod === TransferMethod.Fund;

export const isHiiveFund = (listing: Listing) =>
  listing.transferMethod === TransferMethod.HiiveFund;

export const isEitherFund = (listing: Listing) =>
  isFund(listing) || isHiiveFund(listing);

export const isOtherMethod = (listing: Listing) =>
  listing.transferMethod === TransferMethod.Other;

export const bidLabel = (listing: Listing) =>
  isHiiveFund(listing) ? `Order` : `Bid`;

export const countLabel = (listing: Listing) =>
  isEitherFund(listing) ? `unit` : `share`;

export const abbrCountLabel = (listing: Listing) =>
  isEitherFund(listing) ? `unit` : `sh`;

export const getIsHiiveFund = (transferMethod: TransferMethod) =>
  transferMethod === TransferMethod.HiiveFund;
