import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { InquiryPostingCardFragment, InvestorType } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { InquiryPostingCardBody } from "./InquiryPostingCardBody";
import { InquiryPostingCardFooter } from "./InquiryPostingCardFooter";
import { InquiryPostingCardHeader } from "./InquiryPostingCardHeader";
import { InquiryUnaccreditedSellerPostingCardFooter } from "./InquiryUnaccreditedSellerPostingCardFooter";

type InquiryPostingCardProps = {
  discussion: InquiryPostingCardFragment;
};

function InquiryTraderPostingCard({ discussion }: InquiryPostingCardProps) {
  const { id } = discussion;
  const url = `/discussions/${id}`;

  return (
    <PostingCard href={url}>
      <InquiryPostingCardHeader discussion={discussion} />
      <InquiryPostingCardBody discussion={discussion} />
      <InquiryPostingCardFooter discussion={discussion} />
    </PostingCard>
  );
}

function InquiryUnaccreditedSellerPostingCard({
  discussion,
}: InquiryPostingCardProps) {
  const { id } = discussion;
  const url = `/discussions/${id}`;

  return (
    <PostingCard href={url}>
      <InquiryPostingCardHeader discussion={discussion} />
      <InquiryPostingCardBody discussion={discussion} />
      <InquiryUnaccreditedSellerPostingCardFooter discussion={discussion} />
    </PostingCard>
  );
}

export function InquiryPostingCard({ discussion }: InquiryPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.UnaccreditedSeller, () => (
      <InquiryUnaccreditedSellerPostingCard discussion={discussion} />
    ))
    .otherwise(() => <InquiryTraderPostingCard discussion={discussion} />);
}
