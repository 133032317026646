import dayjs from "dayjs";
import { t } from "i18next";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import Link from "next/link";

import {
  Button,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  HStack,
  Text,
  Stack,
  Box,
} from "@chakra-ui/react";

import {
  SensitiveText,
  ShareDetailsStatsWrapper,
  ShareDetailsPricePerShareStat,
  ShareDetailsStat,
  ShareDetailsStats,
  Tile,
  TileHeader,
  TileRow,
  FocusedShareDetails,
  ActivityCardStandingBidSolicitedCardBody,
} from "@/components/common";
import {
  ConditionallyCompletedBadge,
  PartiallyAcceptedBadge,
  hasMultipleAcceptedBidSharePrices,
  findLowestAcceptedBidSharePrice,
  findHighestAcceptedBidSharePrice,
  hasSingleAcceptedBidSharePrice,
} from "@/components/companies";
import { withConfig, withCurrentActor } from "@/components/hoc";
import { StandingBidHighFeesWarning } from "@/components/postings";
import {
  RootConfigFragment,
  StandingBidState,
  BidState,
  StandingBidInfoCardStandingBidFragment,
  StandingBidInfoCardDiscussionFragment,
  StandingBidInfoCardBrokerFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  acceptsSharesToString,
  acceptsTransferMethodsToString,
  formatPricePerShare,
  formatShares,
  getAreFeesHighForStandingBid,
  getAvailableStandingBidActions,
  getNumOfShares,
  getStandingBidLot,
  getHasExpired,
  getIsBroker,
  gqlTransferMethodOptionsToTemp,
  toTimestamp,
  lot,
  getIsPartiallyAcceptedStandingBid,
  getIsConditionallyCompletedStandingBid,
  getLongDocumentTitleByDocumentType,
  getShortDocumentTitleByDocumentType,
  getIsBrokerForStandingBid,
} from "@/utils";
import { getStandingBidBackOfficeLink } from "@/utils/backOffice";
import {
  getIsBrokerCounterpartyForStandingBid,
  getIsBuyerForStandingBid,
  getIsSolicitedStandingBid,
} from "@/utils/standing-bid";

import { StandingBidActionsTile } from "./StandingBidActionsTile";

const SensitivePropertyBlock = ({
  property,
  value,
  forceSensitive,
  ...flexProps
}: FlexProps & {
  readonly property: string;
  readonly value: string | number;
  readonly forceSensitive?: boolean;
}) => (
  <Tile {...flexProps} h="full" data-testid={property}>
    <TileHeader w="full" maxW={275}>
      {property}
    </TileHeader>
    {forceSensitive ? (
      <Text cursor="default" w="full">
        <Box as="span" layerStyle="sensitive">
          {value}
        </Box>
      </Text>
    ) : (
      <SensitiveText w="full">{value}</SensitiveText>
    )}
  </Tile>
);

const PropertyGrid = withCurrentActor(
  ({
    standingBid,
    actor,
  }: {
    readonly standingBid: StandingBidInfoCardStandingBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();

    const lotValue = getStandingBidLot(standingBid);

    const isSolicited = getIsSolicitedStandingBid(standingBid);

    const numShares = getNumOfShares(standingBid);

    const isSensitive =
      isSolicited &&
      getIsBrokerCounterpartyForStandingBid({ user: actor, standingBid });

    return (
      <Flex direction="column" gap={3}>
        <Grid
          gap={3}
          gridTemplateColumns={{ base: `1fr`, md: `repeat(2, 1fr)` }}
        >
          <GridItem>
            <SensitivePropertyBlock
              property={t(
                standingBid.allowPartialAccept
                  ? `maximum_lot_value`
                  : `lot_value`,
              )}
              value={lotValue || `-`}
              forceSensitive={isSensitive}
            />
          </GridItem>

          <GridItem>
            <SensitivePropertyBlock
              property={t(`asking_pps`)}
              value={
                standingBid.pricePerShare
                  ? formatPricePerShare(standingBid.pricePerShare)
                  : `Not Specified`
              }
              forceSensitive={isSensitive}
            />
          </GridItem>
        </Grid>

        <Grid gap={3} gridTemplateColumns="1fr">
          {!isNil(numShares) && (
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <SensitivePropertyBlock
                property={t(
                  standingBid.allowPartialAccept
                    ? `maximum_number_of_shares`
                    : `number_of_shares`,
                )}
                value={formatShares(numShares)}
                forceSensitive={isSensitive}
              />
            </GridItem>
          )}
          {standingBid.allowPartialAccept && (
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <SensitivePropertyBlock
                property={t(`minimum_number_of_shares_accept`)}
                value={
                  standingBid.minPartialAcceptNumShares
                    ? formatShares(standingBid.minPartialAcceptNumShares)
                    : `-`
                }
              />
            </GridItem>
          )}

          <GridItem colSpan={{ base: 1, md: 2 }}>
            <SensitivePropertyBlock
              property={t(`accepted_share_types`)}
              value={acceptsSharesToString(standingBid.acceptsShares)}
            />
          </GridItem>

          <GridItem colSpan={{ base: 1, md: 2 }}>
            <SensitivePropertyBlock
              property={t(`accepted_transfer_types`)}
              value={acceptsTransferMethodsToString(
                gqlTransferMethodOptionsToTemp(
                  standingBid.acceptsTransferMethods,
                ),
              )}
            />
          </GridItem>
        </Grid>
      </Flex>
    );
  },
);

const StandingBidStatus = withConfig(
  ({
    standingBid,
    config,
  }: {
    readonly standingBid: StandingBidInfoCardStandingBidFragment;
    readonly config: RootConfigFragment;
  }) => {
    const actor = useCurrentActor();
    const isBuyer = getIsBuyerForStandingBid(actor, standingBid);
    const { t } = useTranslation();

    const statusText = match({ state: standingBid.state, isBuyer })
      .with({ state: StandingBidState.Open, isBuyer: false }, () => undefined)
      .with({ state: StandingBidState.Open, isBuyer: true }, () =>
        t(`standing_bid_open_status`),
      )
      .with({ state: StandingBidState.Closed }, () =>
        t(`standing_bid_closed_status`),
      )
      .with({ state: StandingBidState.Expired }, () =>
        t(`standing_bid_closed_status`),
      )
      .with({ state: StandingBidState.Withdrawn }, () =>
        t(`standing_bid_closed_status`),
      )
      .with({ state: StandingBidState.InReview }, () =>
        t(`standing_bid_in_review_status`, {
          date: toTimestamp(standingBid.insertedAt),
        }),
      )
      .with({ state: StandingBidState.ConditionallyCompleted }, () => {
        const acceptedBids = standingBid.bids.filter(
          (bid) => bid.state === BidState.Accepted,
        );
        const hasOneAcceptedBid = acceptedBids.length === 1;

        if (hasOneAcceptedBid && !!acceptedBids[0].transaction?.document) {
          const documentShortName = getShortDocumentTitleByDocumentType(
            acceptedBids[0].transaction.document.type,
          );

          const documentLongName = getLongDocumentTitleByDocumentType(
            acceptedBids[0].transaction.document.type,
          );

          const expireDateTime = dayjs().add(
            config.stnLoiHoursValidHours,
            `hours`,
          );
          const expireTime = expireDateTime.format(`H:MM A`);
          const expireDate = expireDateTime.format(`MMM D, YYYY`);

          return t(`standing_bid_conditionally_completed_status`, {
            expireTime,
            expireDate,
            documentShortName,
            documentLongName,
          });
        }

        return t(`standing_bid_conditionally_completed_status_generic`);
      })
      .with(P._, () => t(`standing_bid_closed_status`))
      .exhaustive();
    return statusText ? (
      <TileRow>
        <TileHeader>Status</TileHeader>
        <SensitiveText data-testid="standingbid-status-tile">
          {statusText}
        </SensitiveText>
      </TileRow>
    ) : null;
  },
);

const BackOfficeLink = ({
  standingBid,
}: {
  readonly standingBid: StandingBidInfoCardStandingBidFragment;
}) => {
  const { t } = useTranslation();
  return (
    <Link
      target="_blank"
      href={getStandingBidBackOfficeLink(standingBid.id)}
      passHref
    >
      <Button variant="text-salmon" px={0}>
        <TileHeader>{t`details`}</TileHeader>
      </Button>
    </Link>
  );
};

const StandingBidNumShareAndPriceDetails = ({
  standingBid,
}: {
  readonly standingBid: StandingBidInfoCardStandingBidFragment;
}) => {
  const {
    pricePerShare,
    numSharesOriginal,
    numSharesAvailable,
    allowPartialAccept,
  } = standingBid;

  // These fields are required for v2 StandingBids so we can safely remove non-null assertion lint rules here.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const sharesOriginal = numSharesOriginal!!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const sharesAvailable = numSharesAvailable!!;

  switch (true) {
    case getIsPartiallyAcceptedStandingBid(standingBid):
      return (
        <ShareDetailsStatsWrapper data-dd-privacy="mask">
          <ShareDetailsStat
            title={t(allowPartialAccept ? `maximum_shares` : `shares`)}
          >
            <HStack>
              <Text as="del" color="grey.200">
                {formatShares(sharesOriginal)}
              </Text>
              <Text data-testid="shares-available">
                {formatShares(sharesAvailable)}
              </Text>
            </HStack>
          </ShareDetailsStat>
          <ShareDetailsPricePerShareStat pricePerShare={pricePerShare} />
          <ShareDetailsStat
            title={t(allowPartialAccept ? `maximum_total` : `total`)}
          >
            {lot(sharesAvailable, pricePerShare)}
          </ShareDetailsStat>
        </ShareDetailsStatsWrapper>
      );
    case getIsConditionallyCompletedStandingBid(standingBid):
      return (
        <ShareDetailsStatsWrapper data-dd-privacy="mask">
          <ShareDetailsStat
            title={t(allowPartialAccept ? `maximum_shares` : `shares`)}
          >
            <Text as="del" color="grey.200">
              {formatShares(sharesOriginal)}
            </Text>
          </ShareDetailsStat>
          <ShareDetailsPricePerShareStat
            strikethrough
            pricePerShare={pricePerShare}
          />
          <ShareDetailsStat
            title={t(allowPartialAccept ? `maximum_total` : `total`)}
          >
            <Text as="del" color="grey.200">
              {lot(sharesOriginal, pricePerShare)}
            </Text>
          </ShareDetailsStat>
        </ShareDetailsStatsWrapper>
      );

    default:
      return (
        <ShareDetailsStats
          numberOfShares={numSharesOriginal}
          pricePerShare={pricePerShare}
          allowPartialAccept={allowPartialAccept}
          data-dd-privacy="mask"
        />
      );
  }
};

const PartnerBrokerTile = ({
  standingBidState,
  broker,
}: {
  readonly standingBidState: StandingBidState;
  readonly broker: StandingBidInfoCardBrokerFragment;
}) =>
  standingBidState === StandingBidState.InReview ? (
    <>
      <TileHeader>Representing Hiive Connect</TileHeader>
      <div>{`${broker.firstName} ${broker.lastName} - ${broker.email}`}</div>
    </>
  ) : (
    <>
      <TileHeader>Representing</TileHeader>
      <Text>
        <div>
          This standing bid was placed by a Hiive Security Specialist via
          HiiveConnect.
        </div>
        <div>Name: {`${broker.firstName} ${broker.lastName}`}</div>
        <div>Email: {broker.email}</div>
      </Text>
    </>
  );

const AcceptedBidSharePriceDetails = ({
  standingBid,
}: {
  readonly standingBid: StandingBidInfoCardStandingBidFragment;
}) => {
  const { acceptedBidPrices, numSharesAccepted } = standingBid;

  return (
    <Stack
      direction={{ base: `column`, sm: `row` }}
      w="full"
      wrap="wrap"
      textAlign={{ base: `center`, sm: `left` }}
      borderTopColor="grey.100 !important"
      borderTop="1px"
      align="center"
      justify="center"
      pt={3}
      spacing={{ base: 2, sm: 3 }}
    >
      {hasMultipleAcceptedBidSharePrices(acceptedBidPrices) && (
        <SensitiveText color="grey.700" textStyle="text-sm">
          {formatShares(numSharesAccepted)} shares accepted from
          {` `}
          <Text as="span" textStyle="heading-xs">
            {formatPricePerShare(
              findLowestAcceptedBidSharePrice(acceptedBidPrices),
            )}
            /sh
          </Text>
          {` `}
          to{` `}
          <Text as="span" textStyle="heading-xs">
            {formatPricePerShare(
              findHighestAcceptedBidSharePrice(acceptedBidPrices),
            )}
            /sh
          </Text>
        </SensitiveText>
      )}
      {hasSingleAcceptedBidSharePrice(acceptedBidPrices) && (
        <SensitiveText color="grey.700" textStyle="text-sm">
          {formatShares(numSharesAccepted)} shares accepted at
          {` `}
          <Text as="span" textStyle="heading-xs">
            {formatPricePerShare(acceptedBidPrices[0])}
            /sh
          </Text>
        </SensitiveText>
      )}
      {getIsPartiallyAcceptedStandingBid(standingBid) && (
        <PartiallyAcceptedBadge />
      )}
      {getIsConditionallyCompletedStandingBid(standingBid) && (
        <ConditionallyCompletedBadge />
      )}
    </Stack>
  );
};

const StatusTile = ({
  standingBid,
}: {
  readonly standingBid: StandingBidInfoCardStandingBidFragment;
}) => {
  const actor = useCurrentActor();
  const isBuyer = getIsBuyerForStandingBid(actor, standingBid);

  const showInsertedAt = !getIsBrokerForStandingBid({
    user: actor,
    standingBid,
  });

  const showRepresentingPartnerBroker = actor.isHiiveUser && isBuyer;

  return (
    <Tile
      py={2}
      gap={4}
      display="grid"
      gridTemplateColumns={{
        base: `1fr`,
        md: `auto 1fr`,
      }}
      alignItems="baseline"
    >
      {showInsertedAt && (
        <TileRow>
          <TileHeader>Placed</TileHeader>
          <SensitiveText>{toTimestamp(standingBid.placedAt)}</SensitiveText>
        </TileRow>
      )}
      {standingBid.expireAt && (
        <TileRow>
          <TileHeader>
            {getHasExpired(standingBid.expireAt) ? `Expired` : `Expires`}
          </TileHeader>
          <SensitiveText>{toTimestamp(standingBid.expireAt)}</SensitiveText>
        </TileRow>
      )}
      <StandingBidStatus standingBid={standingBid} />
      {showRepresentingPartnerBroker && !!standingBid.broker && (
        <TileRow>
          <PartnerBrokerTile
            standingBidState={standingBid.state}
            broker={standingBid.broker}
          />
        </TileRow>
      )}
      {actor.isHiiveUser && (
        <TileRow>
          <BackOfficeLink standingBid={standingBid} />
        </TileRow>
      )}
    </Tile>
  );
};

const PlacedByHiive = ({
  standingBid,
}: {
  readonly standingBid: StandingBidInfoCardStandingBidFragment;
}) => {
  const { t } = useTranslation();
  const actor = useCurrentActor();

  const getIsMyBrokerBid = (
    brokerId: StandingBidInfoCardStandingBidFragment["brokerId"],
  ) => !!brokerId && actor.id === brokerId;

  return (
    <Flex gap={3} direction="column">
      <Text textStyle="heading-sm">{t(`placed_by_hiive`)}</Text>
      <Text>
        {match(standingBid.brokerId)
          .with(P.when(getIsMyBrokerBid), () =>
            t(`broker_standing_bid_placed_by_hiive_broker_perspective`),
          )
          .with(P.string, () => t(`broker_standing_bid_placed_by_hiive`))
          .otherwise(() => t(`standing_bid_placed_by_hiive`))}
      </Text>
    </Flex>
  );
};

const StandingBidInfoCard = ({
  standingBid,
  discussion,
}: {
  readonly standingBid: StandingBidInfoCardStandingBidFragment;
  readonly discussion?: StandingBidInfoCardDiscussionFragment | null;
}) => {
  const actor = useCurrentActor();
  const { canActOnStandingBid } = getAvailableStandingBidActions(standingBid);

  const isBuyer = getIsBuyerForStandingBid(actor, standingBid);
  const isBroker = getIsBroker(actor);
  const isSolicited = getIsSolicitedStandingBid(standingBid);

  const numberOfShares = getNumOfShares(standingBid);
  const showBidBuyerActions = canActOnStandingBid && !isBroker;

  const showSolicitedStandingBid =
    isSolicited &&
    getIsBrokerCounterpartyForStandingBid({ user: actor, standingBid });

  const isHighFeesWarningVisible =
    !isBuyer &&
    standingBid.state === StandingBidState.Open &&
    !isNil(numberOfShares) &&
    getAreFeesHighForStandingBid({
      pricePerShare: Math.round(standingBid.pricePerShare / 100),
      numberOfShares,
    });

  const hasAcceptedBidPrices = standingBid.acceptedBidPrices.length >= 1;

  return (
    <FocusedShareDetails.Card variant={discussion ? `discussion` : `bid`}>
      <FocusedShareDetails.Header
        title={
          discussion
            ? `Inquiry for Standing Bid ${standingBid.displayId}`
            : `Standing Bid ${standingBid.displayId}`
        }
        company={standingBid.company}
      >
        <FocusedShareDetails.HeaderCard>
          {showSolicitedStandingBid ? (
            <ActivityCardStandingBidSolicitedCardBody
              spacing={0}
              p={5}
              alignItems="center"
              textAlign="center"
              standingBid={standingBid}
              data-testid="unquotable-on-hiive-connect"
            />
          ) : (
            <StandingBidNumShareAndPriceDetails standingBid={standingBid} />
          )}
          {hasAcceptedBidPrices && (
            <AcceptedBidSharePriceDetails standingBid={standingBid} />
          )}
        </FocusedShareDetails.HeaderCard>
      </FocusedShareDetails.Header>

      <FocusedShareDetails.Content>
        <FocusedShareDetails.ContentSection>
          <Flex direction="column" gap={8}>
            {showBidBuyerActions && (
              <StandingBidActionsTile standingBid={standingBid} />
            )}
            <StatusTile standingBid={standingBid} />
            <PropertyGrid standingBid={standingBid} />
          </Flex>
        </FocusedShareDetails.ContentSection>

        {standingBid.otherDetails && (
          <FocusedShareDetails.ContentSection p={6} pb={5}>
            <Flex gap={3} direction="column">
              <Text textStyle="heading-sm">Standing Bid Notes</Text>
              <SensitiveText>{standingBid.otherDetails}</SensitiveText>
            </Flex>
          </FocusedShareDetails.ContentSection>
        )}

        {standingBid.fromHiive && (
          <FocusedShareDetails.ContentSection
            p={6}
            pb={5}
            data-testid="standing-bid-placed"
          >
            <PlacedByHiive standingBid={standingBid} />
          </FocusedShareDetails.ContentSection>
        )}

        {isHighFeesWarningVisible && (
          <FocusedShareDetails.ContentSection>
            <StandingBidHighFeesWarning textStyle="heading-xs" />
          </FocusedShareDetails.ContentSection>
        )}
      </FocusedShareDetails.Content>
    </FocusedShareDetails.Card>
  );
};

export default StandingBidInfoCard;
