import difference from "lodash/difference";
import { ChangeEvent, PropsWithChildren } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Checkbox,
  HStack,
  Text,
  CheckboxProps,
} from "@chakra-ui/react";

// eslint-disable-next-line no-restricted-imports
import styles from "@/components/form/CheckboxInput.module.css";
import {
  StandingBidAcceptsTransferMethodsOptions,
  UserPermissionV2,
} from "@/gql";
import { iHaveEntityPermission, useCurrentActor } from "@/hooks";

const includesAll = (superset: readonly string[], subset: readonly string[]) =>
  difference(subset, superset).length === 0;

type StandingBidAcceptedTransferMethodsProps<TFieldValues extends FieldValues> =
  {
    readonly name: Path<TFieldValues>;
    readonly label?: string;
    readonly control: Control<TFieldValues>;
  };

const AcceptedTransferMethodCheckbox = ({
  onChange,
  isChecked,
  isDisabled,
  id,
  label,
  children,
  ...otherCheckboxProps
}: PropsWithChildren<CheckboxProps> & {
  readonly label: string;
}) => (
  <HStack spacing={4} alignItems="flex-start">
    <Checkbox
      spacing={2}
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={onChange}
      size="lg"
      className={`${styles.checkbox} ${styles.checkboxValid}`}
      alignItems="flex-start"
      colorScheme="white"
      iconColor="h-dark-grey"
      _focus={{
        boxShadow: `focus`,
      }}
      id={id}
      {...otherCheckboxProps}
    />
    <HStack spacing={1} as={FormLabel} htmlFor={id}>
      <Text fontWeight={500}>{label}</Text>
      <Text fontSize="14px" fontWeight="normal">
        {children}
      </Text>
    </HStack>
  </HStack>
);

const StandingBidAcceptedTransferMethods2 = <TFieldValues extends FieldValues>({
  name,
  label,
  control,
}: StandingBidAcceptedTransferMethodsProps<TFieldValues>) => {
  const { t } = useTranslation(`standingBids`);

  const actor = useCurrentActor();

  const hasBidOtherPermission = iHaveEntityPermission(
    actor,
    UserPermissionV2.BidOther,
  );

  const {
    field: { value: fieldValue, onChange: fieldOnChange },
    fieldState: { isTouched: touched, error },
  } = useController<TFieldValues>({ name, control });
  const value = fieldValue as readonly string[];

  const getIsChecked = (options: readonly string[]) =>
    includesAll(value, options);

  const onChange =
    (options: readonly string[]) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        fieldOnChange([...value, ...options]);

        return;
      }

      fieldOnChange(value.filter((x) => !options.includes(x)));
    };

  return (
    <FormControl id={name} isInvalid={(error && touched) || false}>
      <FormLabel htmlFor={name}>
        {label || t`place_standing_bid_accepted_transfer_methods_label`}
      </FormLabel>
      <Stack spacing={1} pt={2}>
        <AcceptedTransferMethodCheckbox
          isChecked
          isDisabled
          onChange={onChange([StandingBidAcceptsTransferMethodsOptions.Direct])}
          label={t(`direct`)}
          iconColor="h-light-grey"
          id={`${StandingBidAcceptsTransferMethodsOptions.Direct}-checkbox`}
        >
          {t(`direct_text`)}
        </AcceptedTransferMethodCheckbox>

        <AcceptedTransferMethodCheckbox
          isChecked={getIsChecked([
            StandingBidAcceptsTransferMethodsOptions.Fund,
          ])}
          onChange={onChange([StandingBidAcceptsTransferMethodsOptions.Fund])}
          label={t(`fund`)}
          id={`${StandingBidAcceptsTransferMethodsOptions.Fund}-checkbox`}
        >
          {t(`fund_text`)}
        </AcceptedTransferMethodCheckbox>

        {hasBidOtherPermission && (
          <AcceptedTransferMethodCheckbox
            isChecked={getIsChecked([
              StandingBidAcceptsTransferMethodsOptions.Other,
            ])}
            onChange={onChange([
              StandingBidAcceptsTransferMethodsOptions.Other,
            ])}
            label={t(`other`)}
            id={`${StandingBidAcceptsTransferMethodsOptions.Other}-checkbox`}
          >
            {t(`other_text`)}
          </AcceptedTransferMethodCheckbox>
        )}
      </Stack>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default StandingBidAcceptedTransferMethods2;
