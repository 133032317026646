import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";

import { useRouter } from "next/router";

import { Box, HStack, VStack } from "@chakra-ui/react";

import { InternalLink, Logo } from "@/components/common";
import {
  MyCompanyLinkV2,
  NotificationMenu,
  useHoldingSwitcher,
} from "@/components/nav";

import { HoldingMenu } from "./HoldingMenu";
import { ProfileMenu } from "./ProfileMenu";
import UnaccreditedSellerNavLink from "./UnaccreditedSellerNavLink";

const UnaccreditedSellerDesktopNavBarV2 = () => {
  const router = useRouter();
  const { pathname } = router;
  const { t } = useTranslation();
  const { holdings, holdingCompany } = useHoldingSwitcher();

  const isDashboardPage = pathname.startsWith(`/dashboard`);

  const getBorderColor = () => {
    if (isDashboardPage && window.scrollY === 0) {
      return `white`;
    }

    return `grey.200`;
  };

  const [borderColor, setBorderColor] = useState(getBorderColor());

  const handleScroll = () => {
    setBorderColor((prevBorderColor) => {
      const newBorderColor = getBorderColor();

      if (prevBorderColor === newBorderColor) return prevBorderColor;

      return newBorderColor;
    });
  };

  useEffect(() => {
    // Run once initially
    handleScroll();
    window.addEventListener(`scroll`, handleScroll, { passive: true });

    return () => {
      window.removeEventListener(`scroll`, handleScroll);
    };
  }, [isDashboardPage]);

  const transition = isDashboardPage ? `.15s all linear` : `none`;

  const maxW = match(pathname)
    .with(`/`, () => `max-width-md`)
    .with(`/dashboard/`, () => `max-width-md`)
    .with(`/create-listing`, () => `max-width-lg`)
    .with(`/modify-listing`, () => `max-width-lg`)
    .with(
      P.when((pathname: string) => pathname.startsWith(`/account`)),
      () => `max-width-lg`,
    )
    .with(
      P.when((pathname: string) => pathname.endsWith(`connect-account`)),
      () => `max-width-lg`,
    )
    .with(P.any, () => `max-width-md`)
    .exhaustive();

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="modal"
      data-dd-privacy="mask"
    >
      <VStack
        bg="white"
        borderBottom="0.5px solid"
        transition={transition}
        borderColor={borderColor}
        h="desktop-navbar-height"
        px={8}
      >
        <HStack maxW={maxW} justifyContent="space-between" h="full" w="full">
          <HStack spacing={10}>
            <InternalLink href={`/dashboard/${holdingCompany?.companyId}`}>
              <Logo.Grey />
            </InternalLink>

            <HoldingMenu holdings={holdings} holdingCompany={holdingCompany} />
          </HStack>
          <HStack spacing={5}>
            <UnaccreditedSellerNavLink
              href={`/dashboard/${holdingCompany?.companyId}`}
              isActive={pathname.includes(`/dashboard/`)}
            >
              {t(`dashboard`)}
            </UnaccreditedSellerNavLink>
            <MyCompanyLinkV2 holding={holdingCompany} />
            <NotificationMenu key={maxW} />
            <ProfileMenu />
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default UnaccreditedSellerDesktopNavBarV2;
