import { defineStyleConfig } from "@chakra-ui/react";

export const PostingCardLabel = defineStyleConfig({
  baseStyle: {
    display: `flex`,
    alignItems: `center`,
    pt: 1.5,
    pb: 1,
    px: 2.5,
    borderWidth: 0.5,
    borderStyle: `solid`,
    borderRadius: `md`,
    lineHeight: 4,
    w: `fit-content`,
    flexBasis: {
      base: `min-content`,
      sm: `auto`,
    },
    textTransform: `uppercase`,
    textStyle: `heading-2xs`,
  },
  variants: {
    listing: {
      bg: `olive.50`,
      borderColor: `olive.900`,
      color: `olive.900`,
    },
    bid: {
      bg: `plum.50`,
      borderColor: `plum.900`,
      color: `plum.900`,
    },
    "standing-bid": {
      bg: `plum.50`,
      borderColor: `plum.900`,
      color: `plum.900`,
    },
    discussion: {
      bg: `grey.50`,
      borderColor: `grey.900`,
      color: `grey.900`,
    },
    transaction: {
      bg: `teal.50`,
      borderColor: `teal.900`,
      color: `teal.900`,
    },
  },
});
