import { Form } from "formik";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { SimpleGrid, GridItem, Text, ModalBody, Show } from "@chakra-ui/react";

import {
  HiiveCancelButton,
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
} from "@/components/common";
import { FormikQL } from "@/components/form";
import {
  UnaccreditedSellerWithdrawListingModalListingFragment,
  useWithdrawListingMutation,
} from "@/gql";
import { useCustomToast, useModal } from "@/hooks";

interface UnaccreditedSellerWithdrawListingModalProps {
  readonly listing: UnaccreditedSellerWithdrawListingModalListingFragment;
}

const mapVariables = ({
  listingId,
  muteNotifications,
}: {
  listingId: string;
  muteNotifications: boolean;
}) => ({
  listingId,
  input: {
    muteNotifications,
  },
});

const UnaccreditedSellerWithdrawListingModalV2 = ({
  listing,
}: UnaccreditedSellerWithdrawListingModalProps) => {
  const { t } = useTranslation();
  const mutation = useWithdrawListingMutation();

  const router = useRouter();

  const { closeModal } = useModal();

  const { successToast } = useCustomToast();

  const onSuccess = () => {
    closeModal();
    successToast(t(`listing_withdrawn`));

    router.push(`/dashboard/${listing.companyId}`);
  };

  return (
    <HiiveModalContentWrapper>
      <FormikQL
        initialValues={{
          listingId: listing.id,
          muteNotifications: false,
        }}
        mutation={mutation}
        mutationNames={[`withdrawListing`]}
        mapVariables={mapVariables}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <HiiveModalHeader>
              <Text as="span">{t(`withdraw_listing`)} </Text>
              <Text as="span">{listing.displayId}</Text>
            </HiiveModalHeader>
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={7} w="full">
                <GridItem colSpan={2}>
                  <Text textStyle="text-md">
                    {t(`withdraw_listing_notifications`)}
                  </Text>
                  <Text mt={2} textStyle="text-md">
                    {t(`withdraw_listing_after`)}
                  </Text>
                </GridItem>
              </SimpleGrid>
            </ModalBody>
            <HiiveModalFooter>
              <Show above="md" ssr={false}>
                <HiiveCancelButton
                  observabilityLabel="[UnaccreditedSellerWithdrawListing/Cancel]"
                  onCancel={closeModal}
                />
              </Show>
              <HiiveSubmitButton
                observabilityLabel="[UnaccreditedSellerWithdrawListing/Submit]"
                submitText="Withdraw"
                type="submit"
                isLoading={isSubmitting}
              />
            </HiiveModalFooter>
          </Form>
        )}
      </FormikQL>
    </HiiveModalContentWrapper>
  );
};

export default UnaccreditedSellerWithdrawListingModalV2;
