import { QueryResult } from "@apollo/client";
import { useMemo } from "react";
import { validate as uuidValidate, version as uuidVersion } from "uuid";

import { useRouter } from "next/router";

import {
  CompanyPageContentV2QueryVariables,
  CompanyPageContentV2Query,
  useCompanyPageContentV2Query,
} from "@/gql";

const UUID_VERSION = 4;

const LOADING_STATE = {
  loading: true,
  data: null,
  error: null,
} as unknown as QueryResult<
  CompanyPageContentV2Query,
  CompanyPageContentV2QueryVariables
>;

function useUuidRedirect() {
  const { replace } = useRouter();

  return async (nameSlug: string) => replace(`/companies/${nameSlug}`);
}

function useCompanyQuery(slugOrId: string) {
  const isUuidV4 =
    uuidValidate(slugOrId) && uuidVersion(slugOrId) === UUID_VERSION;

  const variables = useMemo(() => {
    if (isUuidV4) {
      return {
        id: slugOrId,
      };
    }

    return {
      nameSlug: slugOrId,
    };
  }, [slugOrId, isUuidV4]);

  const query = useCompanyPageContentV2Query({
    variables,
    fetchPolicy: `cache-and-network`,
  });

  return { query, isUuidV4 };
}

export function useCompanySlugOrRedirect(slugOrId: string) {
  const { query, isUuidV4 } = useCompanyQuery(slugOrId);
  const { data } = query;
  const uuidRedirect = useUuidRedirect();

  if (isUuidV4 && data?.company) {
    const {
      company: { nameSlug },
    } = data;

    uuidRedirect(nameSlug);

    return LOADING_STATE;
  }

  return query;
}
