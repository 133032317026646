import config from "@/utils/constants";

export const getTransactionBackOfficeLink = (id: string) => {
  const baseUrl =
    config.back_office_url[process.env.NEXT_PUBLIC_INFRA_ENV || `local`];

  return `${baseUrl}/listings/${id}`;
};

export const getStandingBidBackOfficeLink = (id: string) => {
  const baseUrl =
    config.back_office_url[process.env.NEXT_PUBLIC_INFRA_ENV || `local`];

  return `${baseUrl}/standing-bids/${id}`;
};
