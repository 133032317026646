import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Fade,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveButton,
  UnableToTransact,
  UnaccreditedSellerComplianceReminder,
} from "@/components/common";
import { UnaccreditedSellerAcceptAStandingBidToSellNowSvg } from "@/components/svg";
import { UnaccreditedSellerCompanyByIdActionsFragment } from "@/gql";
import { useCurrentActor, useRedirectCompanyLink } from "@/hooks";
import { TradeIntentType } from "@/types";

export const SellNowCard = ({
  company,
}: {
  readonly company: Pick<
    UnaccreditedSellerCompanyByIdActionsFragment,
    | `id`
    | `nameSlug`
    | `securitySpecialist`
    | `name`
    | `tradeableSellSide`
    | `myPermissionsV2`
  >;
}) => {
  const redirectCompanyLink = useRedirectCompanyLink();
  const actor = useCurrentActor();
  const router = useRouter();
  const { t } = useTranslation();

  const isCompanyTradeable = !!company ? company.tradeableSellSide : true;

  return (
    <Card position="relative" overflow="hidden" h="full">
      <CardBody h="full" position="relative">
        <VStack
          alignItems="flex-start"
          justifyContent="space-between"
          h="full"
          spacing={5}
        >
          <VStack alignItems="flex-start" h="full" spacing={3}>
            <Text textStyle="heading-3xl" zIndex="1">
              {t(`accept_standing_bid_to_sell`)}
            </Text>
            <Text
              textStyle="text-md"
              maxW={{ base: `unset`, xl: `55%` }}
              zIndex="1"
            >
              {t(`accept_standing_bid_to_sell_description`)}
            </Text>
          </VStack>
          {actor.identityVerified && isCompanyTradeable && (
            <Box>
              <HiiveButton
                variant="rounded-solid-salmon"
                size="xl"
                boxShadow="card"
                px={16}
                onClick={() => router.push(redirectCompanyLink(company))}
                observabilityLabel="[UnaccreditedSellerDashboardPage/SellNowCard] See Available Standing Bids"
              >
                {t(`see_available_standing_bids`)}
              </HiiveButton>
            </Box>
          )}

          {actor.identityVerified && company && !isCompanyTradeable && (
            <Box zIndex={2}>
              <UnableToTransact
                company={company}
                tradeIntent={TradeIntentType.Sell}
              />
            </Box>
          )}
        </VStack>
        <Show above="xl" ssr={false}>
          <Fade in>
            <Box
              position="absolute"
              zIndex="0"
              h="full"
              w="50%"
              right="0"
              top="0"
              preserveAspectRatio="xMinYMid slice"
              as={UnaccreditedSellerAcceptAStandingBidToSellNowSvg}
            />
          </Fade>
        </Show>
      </CardBody>

      {!actor.identityVerified && (
        <CardFooter bg="grey.25">
          <UnaccreditedSellerComplianceReminder />
        </CardFooter>
      )}
    </Card>
  );
};
