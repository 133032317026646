import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import { CardBody, CardFooter, HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import {
  EntityCardLayout,
  EntityCardProps,
  EntityCardTypes,
  EntityItem,
  linkEntityValidationSchema,
} from "@/components/entities";
import {
  BasicUserEntityFragment,
  LinkEntityInput,
  TransactionPageTransactionByIdDocument,
  useLinkEntityMutation,
} from "@/gql";
import { useCustomToast } from "@/hooks";
import { Resources } from "@/i18n/locales";

import CreateNewInternalEntityButton from "./CreateNewInternalEntityButton";
import InternalEntityCombobox from "./InternalEntityCombobox";

export type InternalEntityFormValues = LinkEntityInput & {
  searchText?: string;
};

type InternalEntityCardProps = {
  entity?: BasicUserEntityFragment | null;
  transactionId: string;
} & Pick<EntityCardProps, "type">;
type CardTitleProps = { hasEntity: boolean } & Pick<EntityCardProps, "type">;

const getCardTitleKey = (data: CardTitleProps) =>
  match<CardTitleProps, keyof Resources["transactions"]>(data)
    .with(
      {
        hasEntity: false,
        type: EntityCardTypes.Buyer,
      },
      () => `confirm_purchasing_party`,
    )
    .with(
      {
        hasEntity: false,
        type: EntityCardTypes.Seller,
      },
      () => `confirm_selling_party`,
    )
    .otherwise(() => `transacting_party`);

const InternalEntityCard = ({
  entity,
  transactionId,
  type,
}: InternalEntityCardProps) => {
  const { t } = useTranslation(`transactions`);
  const { successToast, errorToast } = useCustomToast();
  const validationSchema = Yup.object()
    .shape({
      entityId: Yup.string().required(),
      searchText: Yup.string().notRequired(),
    })
    .concat(linkEntityValidationSchema(t))
    .required();

  const [linkEntity, { loading }] = useLinkEntityMutation({
    refetchQueries: [TransactionPageTransactionByIdDocument],
  });

  const methods = useForm<InternalEntityFormValues>({
    defaultValues: {
      transactionId,
    },
    resolver: yupResolver(
      validationSchema,
    ) as unknown as Resolver<InternalEntityFormValues>,
  });

  const {
    formState: { isValid },
    getValues,
  } = methods;

  const onError = (message = t(`couldnt_link_entity`)) => errorToast(message);

  const onSubmit = async () => {
    const { entityId, transactionId } = getValues();

    await linkEntity({
      variables: {
        input: {
          entityId,
          transactionId,
        },
      },
    }).catch((error) => onError(error?.message));
    successToast(
      t(`entities.entity_saved_successfully`, {
        ns: `account`,
      }),
    );
  };

  const hasEntity = !!entity;

  return (
    <EntityCardLayout
      form={
        !hasEntity ? (
          <FormProvider {...methods}>
            <CardBody as={VStack} gap={3}>
              <Text>{t(`select_or_add_party`)}</Text>
              <InternalEntityCombobox />
            </CardBody>
            <CardFooter py={6}>
              <HStack justifyContent="flex-end" w="full">
                <CreateNewInternalEntityButton
                  type={type}
                  transactionId={transactionId}
                />
                <HiiveButton
                  onClick={onSubmit}
                  isDisabled={!isValid}
                  isLoading={loading}
                  maxW="unset"
                  observabilityLabel="[InternalEntityCard] Confirm"
                  size="xl"
                  type="submit"
                  variant="rounded-solid-salmon"
                  w={{ base: `full`, md: `170px` }}
                >
                  {t(`confirm`, {
                    ns: `translation`,
                  })}
                </HiiveButton>
              </HStack>
            </CardFooter>
          </FormProvider>
        ) : undefined
      }
      hasEntity={hasEntity}
      title={
        <Text textStyle={{ base: `heading-xl`, md: `heading-2xl` }}>
          {t(
            getCardTitleKey({
              hasEntity,
              type,
            }),
          )}
        </Text>
      }
    >
      {hasEntity && (
        <EntityItem
          entity={entity}
          showDate={false}
          showRemoveButton={false}
          showStatus={false}
        />
      )}
    </EntityCardLayout>
  );
};

export default InternalEntityCard;
