import currency from "currency.js";
import dayjs from "dayjs";
import { Form } from "formik";
import isNil from "lodash/isNil";

import {
  HiiveModalContentWrapper,
  HiiveModalHeader,
} from "@/components/common";
import { StepFormikQL, StepsHeader } from "@/components/form";
import {
  CompanyWatchlistActorDocument,
  MarketActivityDocument,
  ModifyStandingBidModalStandingBidFragment,
  ModifyStandingBidMutationVariables,
  StandingBidPageStandingBidByIdDocument,
  useModifyStandingBidMutation,
  UserActivityMyActivityDocument,
} from "@/gql";
import { useStepRouter } from "@/hooks";
import { Nullable, constants } from "@/utils";
import * as datetime from "@/utils/datetime";

import {
  AdditionalDetails,
  BidDetails,
  additionalDetailsValidationSchema,
  createBidDetailsValidationSchema,
  ModifyStandingBidModalFormValues,
  stepKeys,
  StepKeys,
} from "./steps";
import { ModifyStandingBidModalStepFormContext } from "./steps/ModifyStandingBidModalStepFormContext";

const createInitialValues = (
  standingBid: ModifyStandingBidModalStandingBidFragment,
): Nullable<ModifyStandingBidModalFormValues> => ({
  standingBidId: standingBid.id,
  numShares: !!standingBid.numSharesAvailable
    ? standingBid.numSharesAvailable
    : null,
  acceptsShares: standingBid.acceptsShares,
  minPartialAcceptNumShares: !!standingBid.minPartialAcceptNumShares
    ? standingBid.minPartialAcceptNumShares
    : null,
  allowPartialAccept: !isNil(standingBid.allowPartialAccept)
    ? standingBid.allowPartialAccept
    : null,
  acceptsTransferMethods: standingBid.acceptsTransferMethods,
  pricePerShare: standingBid.pricePerShare / 100,
  otherDetails: standingBid.otherDetails ?? ``,
  expireAt: !!standingBid.expireAt
    ? new Date(standingBid.expireAt)
    : datetime
        .add(`day`, constants.default_standing_bid_expire_after_days, dayjs())
        .toDate(),
  confirmed: false,
  muteNotifyWatchers: false,
});

const mapVariables = ({
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  confirmed,
  standingBidId,
  ...values
}: ModifyStandingBidModalFormValues): ModifyStandingBidMutationVariables => ({
  standingBidId,
  input: {
    ...values,
    expireAt: dayjs(values.expireAt).endOf(`day`).format(),
    pricePerShare: currency(values.pricePerShare).intValue,
  },
});

const ModifyStandingBidSequenceModal = ({
  standingBid,
}: {
  readonly standingBid: ModifyStandingBidModalStandingBidFragment;
}) => {
  const mutation = useModifyStandingBidMutation({
    refetchQueries: [
      MarketActivityDocument,
      UserActivityMyActivityDocument,
      CompanyWatchlistActorDocument,
      StandingBidPageStandingBidByIdDocument,
    ],
  });

  const initialValues = createInitialValues(standingBid);

  const stepRouter = useStepRouter<StepKeys>({
    stepKeys: [
      stepKeys.modifyStandingBidBidDetails,
      stepKeys.modifyStandingBidAdditionalDetails,
    ],
  });

  return (
    <StepFormikQL
      stepRouter={stepRouter}
      mutation={mutation}
      mutationNames={[`modifyStandingBid`]}
      initialValues={initialValues}
      mapVariables={mapVariables}
      context={ModifyStandingBidModalStepFormContext}
    >
      {({ formikProps }) => {
        const { stepsInfo } = stepRouter;
        const { values, initialValues } = formikProps;

        const isBidDetailsStepValid = createBidDetailsValidationSchema(
          standingBid,
          initialValues,
        ).isValidSync(values);
        const isAdditionalDetailsStepValid =
          additionalDetailsValidationSchema.isValidSync(values);

        return (
          <div data-testid="modify-standing-bid-modal">
            <HiiveModalContentWrapper>
              <Form>
                <HiiveModalHeader>
                  Modify Standing Bid {standingBid.displayId}
                </HiiveModalHeader>
                <StepsHeader
                  stepRouter={stepRouter}
                  steps={[
                    {
                      stepKey: stepKeys.modifyStandingBidBidDetails,
                      stepTitle: `Bid Details`,
                      isStepValid: isBidDetailsStepValid,
                    },
                    {
                      stepKey: stepKeys.modifyStandingBidAdditionalDetails,
                      stepTitle: `Additional Details`,
                      isStepValid: isAdditionalDetailsStepValid,
                    },
                  ]}
                />
                {stepsInfo.currentStepKey ===
                  stepKeys.modifyStandingBidBidDetails && (
                  <BidDetails
                    standingBid={standingBid}
                    stepRouter={stepRouter}
                    {...formikProps}
                  />
                )}
                {stepsInfo.currentStepKey ===
                  stepKeys.modifyStandingBidAdditionalDetails && (
                  <AdditionalDetails stepRouter={stepRouter} {...formikProps} />
                )}
              </Form>
            </HiiveModalContentWrapper>
          </div>
        );
      }}
    </StepFormikQL>
  );
};

export default ModifyStandingBidSequenceModal;
