import { CaretLeft } from "@phosphor-icons/react";

import { Center } from "@chakra-ui/react";

import HiiveButton, { HiiveButtonProps } from "./HiiveButton";

const LeftArrow = () => (
  <Center px={2.5} position="absolute" left={0} top={0} h="full">
    <CaretLeft size={20} />
  </Center>
);

const HiiveBackButton = ({
  observabilityLabel,
  ...buttonProps
}: HiiveButtonProps) => (
  <HiiveButton
    variant="rounded-outline-salmon"
    size="xl"
    w={{ base: `full`, sm: `unset` }}
    maxW="unset"
    leftIcon={<LeftArrow />}
    observabilityLabel={observabilityLabel}
    {...buttonProps}
  >
    Back
  </HiiveButton>
);

export default HiiveBackButton;
