import { useTranslation } from "react-i18next";

import { HStack, Text } from "@chakra-ui/react";

import { PostingCard, SensitiveText } from "@/components/common";
import { BidPostingCardFragment } from "@/gql";
import { formatPricePerShare, formatShares, lot } from "@/utils";
import { getBidNumSharesActual } from "@/utils/bid";

type BidPostingCardBodyProps = {
  bid: BidPostingCardFragment;
};

export function BidPostingCardBody({ bid }: BidPostingCardBodyProps) {
  const { pricePerShare } = bid;
  const { t } = useTranslation(`bids`);

  const numShares = getBidNumSharesActual(bid);

  const numSharesFormatted = formatShares(numShares);
  const pricePerShareFormatted = formatPricePerShare(pricePerShare, true);
  const lotSizeFormatted = lot(numShares, pricePerShare);

  return (
    <PostingCard.Body>
      <PostingCard.BodyTitle>
        <HStack gap={4}>
          <HStack gap={2}>
            <SensitiveText>{numSharesFormatted}</SensitiveText>
            <SensitiveText>
              {pricePerShareFormatted}
              <Text as="span" fontWeight={400}>
                {t(`sh`)}
              </Text>
            </SensitiveText>
          </HStack>
          <SensitiveText>{lotSizeFormatted}</SensitiveText>
        </HStack>
      </PostingCard.BodyTitle>
    </PostingCard.Body>
  );
}
