import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { useRef } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { HStack, Text, Box, VStack } from "@chakra-ui/react";

import { FullDivider } from "@/components/common";
import {
  DropdownControls,
  HeadlessDropdown,
  QuestionTooltip,
} from "@/components/form";
import { PlaceStandingBidSequenceModalFormValues } from "@/components/modals";
import { FormCheckboxInput, FormTextInput } from "@/components/react-hook-form";
import { RepresentedUserTooltip } from "@/components/tooltip";

type HiiveAdvancedOptionsProps = {
  readonly required: boolean;
  readonly control: Control<PlaceStandingBidSequenceModalFormValues>;
};
const HiiveAdvancedOptions = ({
  required,
  control,
}: HiiveAdvancedOptionsProps) => {
  const dropdownRef = useRef<DropdownControls>(null);
  const { t } = useTranslation(`standingBids`);

  return (
    <HeadlessDropdown ref={dropdownRef} show={required}>
      {({ showDropdown, onClickDropdown }) => (
        <>
          <FullDivider />
          <HStack
            as="button"
            justifyContent="space-between"
            w="full"
            type="button"
            onClick={onClickDropdown}
            cursor="pointer"
            py={7}
          >
            <Text color="salmon.900" fontWeight="medium" textStyle="">
              {t`hiive_advanced_options`}
            </Text>
            {showDropdown ? <CaretDown /> : <CaretRight />}
          </HStack>
          {showDropdown && (
            <Box pb={7}>
              <VStack spacing={4} alignItems="flex-start">
                <FormCheckboxInput
                  name="muteNotifyWatchers"
                  label={t(`do_not_send_activity_notifications`)}
                  control={control}
                />
                <Text
                  align="center"
                  textStyle="deprecated-heading-lg"
                  color="h-dark-grey"
                >
                  {t(`represented_user_info`)}
                  <QuestionTooltip
                    translateY={1.5}
                    translateX={1.0}
                    transform="auto"
                    tooltipContent={<RepresentedUserTooltip />}
                  />
                </Text>
                <HStack gap={4} w="full" alignItems="top">
                  <FormTextInput
                    control={control}
                    data-testid="represented-user-first-name"
                    name="representedFirstName"
                    labelSrOnly
                    label={t`represented_first_name`}
                    placeholder={t`first_name`}
                  />
                  <FormTextInput
                    control={control}
                    data-testid="represented-user-last-name"
                    name="representedLastName"
                    labelSrOnly
                    label={t`represented_last_name`}
                    placeholder={t`last_name`}
                  />
                </HStack>

                <FormTextInput
                  control={control}
                  name="representedEmail"
                  labelSrOnly
                  label={t`represented_email`}
                  placeholder={t`represented_email`}
                />
                <FormCheckboxInput
                  name="notifyRepresentingEmail"
                  label={t(`notify_represented_user`)}
                  control={control}
                />
              </VStack>
            </Box>
          )}
          <FullDivider />
        </>
      )}
    </HeadlessDropdown>
  );
};

export default HiiveAdvancedOptions;
