import { useTranslation } from "react-i18next";

import { Box, CardHeader, HStack, Text, VStack } from "@chakra-ui/react";

import { ActivityCardBadge, Logo } from "@/components/common";
import { CompanyCardHeaderStringPill } from "@/components/companies";
import { CustomTooltip } from "@/components/form";
import { CompanyListingCardHeaderListingV2Fragment } from "@/gql";
import { useIsDesktop } from "@/hooks";
import {
  isHiiveFund,
  shareTypeToString,
  transferMethodToString,
} from "@/utils";

type CompanyListingCardHeaderV2Props = {
  readonly listing: CompanyListingCardHeaderListingV2Fragment;
};

function CompanyListingCardHeaderV2HiiveFundTooltip() {
  const { t } = useTranslation(`listings`);

  return (
    <VStack
      justifyContent="center"
      borderWidth={0.5}
      borderColor="grey.100"
      borderRadius="md"
      px={{ base: 2, md: 3 }}
      py={{ base: `2px`, md: 2 }}
    >
      <CustomTooltip
        placement="bottom"
        tooltipContent={t(`this_fund_is_organized_by_hiive`)}
        pos="absolute"
      >
        <Logo.DarkGreyIcon
          width={{ base: 3, md: 4 }}
          height={{ base: 4, md: 5 }}
        />
      </CustomTooltip>
    </VStack>
  );
}

export function CompanyListingCardHeaderV2({
  listing,
}: CompanyListingCardHeaderV2Props) {
  const { t } = useTranslation(`listings`);
  const isDesktop = useIsDesktop();
  const { displayId, transferMethod, shareTypeV2 } = listing;

  return (
    <CardHeader py={2} px={3}>
      <HStack justifyContent="space-between">
        <HStack w="full">
          <ActivityCardBadge variant="listing" title={t(`listing`)} />
          {isDesktop && (
            <Text textStyle="text-md" color="grey.900">
              {displayId}
            </Text>
          )}
          <Box flexGrow={1} />
          {isHiiveFund(listing) && (
            <CompanyListingCardHeaderV2HiiveFundTooltip />
          )}
          <CompanyCardHeaderStringPill
            title={t(`transfer_method`)}
            value={transferMethodToString(transferMethod)}
          />
          <CompanyCardHeaderStringPill
            title={t(`share_type`)}
            value={shareTypeToString(shareTypeV2, true)}
          />
        </HStack>
      </HStack>
    </CardHeader>
  );
}
