import { useTranslation } from "react-i18next";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  InquiryEntityPostingCard,
  WithQuery,
  BidPostingCard,
} from "@/components/common";
import {
  CompanyYourActivityListingCard,
  CompanyYourActivityStandingBidCard,
  CompanyYourBidCard,
  CompanyYourDiscussionCard,
  BrokerCompanyBidCard,
  BrokerCompanyStandingBidCard,
} from "@/components/companies";
import {
  CompanyPageYourActivityBidFragment,
  CompanyPageYourActivityCompanyFragment,
  CompanyPageYourActivityDiscussionFragment,
  CompanyPageYourActivityListingFragment,
  CompanyPageYourActivityStandingBidFragment,
  UserWithInstitutionFragment,
  useCompanyPageYourActivityCompanyByIdV2Query,
} from "@/gql";
import { useCurrentActor, useIsDesktop } from "@/hooks";
import { useCardUpdates } from "@/hooks/featureFlags";
import { getIsBroker, getIsInstitutionUser } from "@/utils";

import { YourActivitySkeleton } from "./YourActivitySkeleton";

type YourActivityListingsProps = {
  readonly listings: readonly CompanyPageYourActivityListingFragment[];
  readonly company: CompanyPageYourActivityCompanyFragment;
};

type YourActivityBidsProps = {
  readonly bids: readonly CompanyPageYourActivityBidFragment[];
};

type YourActivityStandingBidsProps = {
  readonly standingBids: readonly CompanyPageYourActivityStandingBidFragment[];
  readonly actor: UserWithInstitutionFragment;
  readonly company: CompanyPageYourActivityCompanyFragment;
};

type YourActivityDiscussionsProps = {
  readonly discussions: readonly CompanyPageYourActivityDiscussionFragment[];
};

type YourActivityContentProps = {
  readonly company: CompanyPageYourActivityCompanyFragment;
};

type YourActivityProps = {
  readonly companyId: string;
};

function YourActivityListings({
  listings,
  company,
}: YourActivityListingsProps) {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t(`listings`)}>
      {listings.map((listing) => (
        <CompanyYourActivityListingCard
          key={listing.id}
          listing={listing}
          company={company}
        />
      ))}
    </ActivityGroup>
  );
}

const YourActivityBids = ({ bids }: YourActivityBidsProps) => {
  const actor = useCurrentActor();
  const cardUpdatesEnabled = useCardUpdates();
  const { t } = useTranslation(`company`);
  const isBroker = getIsBroker(actor);

  if (cardUpdatesEnabled) {
    return (
      <ActivityGroup title={t(`bids`)}>
        {bids
          .filter((bid) => !bid?.transaction?.rofr)
          .map((bid) => {
            const { id } = bid;

            return <BidPostingCard key={id} bid={bid} />;
          })}
      </ActivityGroup>
    );
  }

  return (
    <ActivityGroup title={t(`bids`)}>
      {bids
        .filter((bid) => !bid?.transaction?.rofr)
        .map((bid) =>
          isBroker ? (
            <BrokerCompanyBidCard key={bid.id} bid={bid} />
          ) : (
            <CompanyYourBidCard key={bid.id} bid={bid} />
          ),
        )}
    </ActivityGroup>
  );
};

const YourActivityStandingBids = ({
  standingBids,
  actor,
  company,
}: YourActivityStandingBidsProps) => {
  const { t } = useTranslation(`company`);
  const isBroker = getIsBroker(actor);

  return (
    <ActivityGroup title={t(`standing_bids`)}>
      {standingBids.map((standingBid) =>
        isBroker ? (
          <BrokerCompanyStandingBidCard
            key={standingBid.id}
            standingBid={standingBid}
            company={company}
          />
        ) : (
          <CompanyYourActivityStandingBidCard
            key={standingBid.id}
            standingBid={standingBid}
            company={company}
          />
        ),
      )}
    </ActivityGroup>
  );
};

function YourActivityDiscussions({
  discussions,
}: YourActivityDiscussionsProps) {
  const { t } = useTranslation(`company`);
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivityGroup title={t(`inquiries`)}>
      {discussions.map((discussion) =>
        cardUpdatesEnabled ? (
          <InquiryEntityPostingCard
            key={discussion.id}
            discussion={discussion}
          />
        ) : (
          <CompanyYourDiscussionCard
            key={discussion.id}
            discussion={discussion}
          />
        ),
      )}
    </ActivityGroup>
  );
}

const YourActivityContent = ({ company }: YourActivityContentProps) => {
  const actor = useCurrentActor();

  const isDesktopView = useIsDesktop();
  const isInstitutionUser = getIsInstitutionUser(actor);

  const { t } = useTranslation(`company`);

  const { institution: myInstitution } = actor;

  const activities = company.activity;

  const {
    myBids,
    myStandingBids,
    myListings,
    myActiveDiscussions,
    myInstitutionBids,
    myInstitutionStandingBids,
    myInstitutionListings,
  } = activities;

  const hasPersonalActivity =
    myBids.length > 0 ||
    myStandingBids.length > 0 ||
    myListings.length > 0 ||
    myActiveDiscussions.length > 0;

  const hasInstitutionalActivity =
    myInstitutionBids.length > 0 ||
    myInstitutionStandingBids.length > 0 ||
    myInstitutionListings.length > 0;

  const myActivityTitle = isInstitutionUser
    ? t(`your_activity_on_behalf_of_institution`, {
        legalName: myInstitution?.legalName,
      })
    : t(`your_activity`);

  const myInstitutionActivityTitle = hasPersonalActivity
    ? t(`other_institution_activity`, {
        legalName: myInstitution?.legalName,
      })
    : t(`institution_activity`, {
        legalName: myInstitution?.legalName,
      });

  const showEmptyState =
    !isDesktopView && !hasPersonalActivity && !hasInstitutionalActivity;

  return (
    <>
      {hasPersonalActivity && (
        <ActivitySection title={myActivityTitle}>
          <YourActivityListings listings={myListings} company={company} />
          <YourActivityBids bids={myBids} />
          <YourActivityStandingBids
            standingBids={myStandingBids}
            actor={actor}
            company={company}
          />
          <YourActivityDiscussions discussions={myActiveDiscussions} />
        </ActivitySection>
      )}
      {hasInstitutionalActivity && (
        <ActivitySection title={myInstitutionActivityTitle}>
          <YourActivityListings
            listings={myInstitutionListings}
            company={company}
          />
          <YourActivityBids bids={myInstitutionBids} />
          <YourActivityStandingBids
            standingBids={myInstitutionStandingBids}
            actor={actor}
            company={company}
          />
        </ActivitySection>
      )}
      {showEmptyState && (
        <ActivitySection title={t(`your_activity`)}>
          <ActivityGroupEmptyState
            w="full"
            message={t(`currently_no_activity`, {
              name: company.name,
            })}
          />
        </ActivitySection>
      )}
    </>
  );
};

export const YourActivity = ({ companyId }: YourActivityProps) => {
  const query = useCompanyPageYourActivityCompanyByIdV2Query({
    variables: { id: companyId },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<YourActivitySkeleton />}>
      {({ data: { company } }) => <YourActivityContent company={company} />}
    </WithQuery>
  );
};
