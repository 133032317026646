import { TFunction } from "i18next";
import * as Yup from "yup";

import {
  CreateTrustedContactPersonInput,
  UpdateTrustedContactPersonInput,
} from "@/gql";
import { validPhoneNumber, constants } from "@/utils";

type TrustedContactFormValues = {
  readonly collectTrustedContactPerson?: boolean | null;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly relationship: string;
};

const { EMAIL_REGEX } = constants;

const DEFAULT_TRUSTED_CONTACT = {
  email: ``,
  firstName: ``,
  lastName: ``,
  phoneNumber: ``,
  relationship: ``,
};
const getTrustedContactFormSchema = (t: TFunction) =>
  Yup.object().shape({
    collectTrustedContactPerson: Yup.boolean().required(t`required`),
    firstName: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) => schema.required(t`first_name_required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`required`),
    lastName: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) => schema.required(t`last_name_required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`required`),
    phoneNumber: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) =>
          schema.required(t`phone_number_is_required`).test({
            name: `isPhoneNumberValid`,
            message: t`enter_valid_phone_number`,
            test: (value) => !value || validPhoneNumber(value),
          }),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`enter_valid_phone_number`),
    email: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) =>
          schema.required(t`email_is_required`).test({
            name: `isEmailValid`,
            message: t`enter_valid_email_address`,
            test: (value) => !value || EMAIL_REGEX.test(value),
          }),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`invalid_email_address`),
    relationship: Yup.string()
      .when(`collectTrustedContactPerson`, {
        is: true,
        then: (schema) => schema.required(t`relationship_is_required`),
        otherwise: (schema) => schema.optional(),
      })
      .typeError(t`required`),
  });

const mapTrustedContactVariables = (
  values: TrustedContactFormValues,
): {
  input: CreateTrustedContactPersonInput | UpdateTrustedContactPersonInput;
} => ({
  input: {
    isContactProvided: !!values.collectTrustedContactPerson,
    contactInfo: !!values.collectTrustedContactPerson
      ? {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          relationship: values.relationship,
        }
      : {
          ...DEFAULT_TRUSTED_CONTACT,
        },
  },
});
export {
  getTrustedContactFormSchema,
  mapTrustedContactVariables,
  type TrustedContactFormValues,
  DEFAULT_TRUSTED_CONTACT,
};
