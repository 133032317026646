import { useRouter } from "next/router";

import { Card, CardBody, CardFooter, Flex } from "@chakra-ui/react";

import {
  ActivityCardTransactionStatus,
  SensitiveText,
} from "@/components/common";
import { PostingNumSharesPriceDetails } from "@/components/companies";
import { UserActivityTransactionFragment } from "@/gql";
import { makeUrl } from "@/utils";

import DashboardTransactionCardHeader from "./DashboardTransactionCardHeader";

export const DashboardYourPendingPurchaseCardV2 = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const { company, bid, numShares, pricePerShare } = transaction;

  const router = useRouter();

  return (
    <Card
      variant="activity"
      as="button"
      onClick={() => router.push(makeUrl(transaction))}
    >
      <DashboardTransactionCardHeader transaction={transaction} />
      <CardBody p={3}>
        <Flex wrap="wrap" columnGap={1.5}>
          <SensitiveText>
            <PostingNumSharesPriceDetails
              isPartiallyAccepted={false}
              isConditionallyCompleted={false}
              numSharesOriginal={numShares}
              numSharesAvailable={numShares}
              pricePerShare={pricePerShare}
            />
          </SensitiveText>
        </Flex>
      </CardBody>
      <CardFooter px={3} py={3}>
        <ActivityCardTransactionStatus
          bid={bid}
          company={company}
          transaction={transaction}
        />
      </CardFooter>
    </Card>
  );
};
