import { ReactElement, useState } from "react";

import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Flex,
  HStack,
  List,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardListingNumShareAndPriceDetails,
  ActivityCardTimestamp,
  HiiveButton,
  ActivityCardListingSolicitedCardBody,
  WithQuery,
  SensitiveText,
} from "@/components/common";
import { PostingNumSharesPriceDetails } from "@/components/companies";
import {
  LatestActivityListListingFragment,
  LatestActivityListStandingBidFragment,
  useLatestMarketActivityQuery,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useCardUpdates } from "@/hooks/featureFlags";
import {
  constants,
  getIsConditionallySoldListing,
  getIsPartiallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
  getShowListingSolicitedState,
} from "@/utils";

import { DashboardLatestMarketActivitySkeleton } from "./DashboardLatestMarketActivitySkeleton";
import { LatestStandingBidSubcard } from "./LatestStandingBidSubcard";

type TabKind = `LISTINGS` | `STANDING_BIDS`;

const Tab = ({
  isActive,
  onClick,
  children,
}: {
  readonly isActive: boolean;
  readonly onClick: () => void;
  readonly children: ReactElement | readonly ReactElement[] | string;
}) => (
  <HiiveButton
    _hover={{ color: `grey.900` }}
    _active={{ color: `grey.900` }}
    _focus={{ outline: `none` }}
    color={isActive ? `grey.900` : `grey.500`}
    backgroundColor="transparent"
    rounded="none"
    size="sm"
    fontWeight="normal"
    onClick={onClick}
    paddingLeft={0}
    observabilityLabel="[DashboardLatestMarketActivity] Tab"
  >
    <Box
      borderBottomColor={isActive ? `salmon.900` : `transparent`}
      borderBottomWidth="3px"
      paddingX="3px"
      paddingBottom="8px"
    >
      <Text textStyle="heading-md">{children}</Text>
    </Box>
  </HiiveButton>
);

const MarketActivityListingCard = ({
  listing,
}: {
  readonly listing: LatestActivityListListingFragment;
}) => {
  const actor = useCurrentActor();
  const areCardUpdatesEnabled = useCardUpdates();
  const showListingSolicitedState = getShowListingSolicitedState(
    listing,
    actor,
  );
  const { listingPricePerShare: pricePerShare } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);

  if (showListingSolicitedState) {
    return (
      <CardBody w="full">
        <ActivityCardListingSolicitedCardBody
          spacing={0}
          alignItems="flex-start"
          textAlign="left"
          company={listing.company}
        />
      </CardBody>
    );
  }

  return (
    <>
      <CardBody w="full">
        {areCardUpdatesEnabled ? (
          <Flex wrap="wrap" columnGap={1.5}>
            <SensitiveText>
              <PostingNumSharesPriceDetails
                isPartiallyAccepted={getIsPartiallySoldListing(listing)}
                isConditionallyCompleted={getIsConditionallySoldListing(
                  listing,
                )}
                numSharesOriginal={numSharesOriginal}
                numSharesAvailable={numSharesAvailable}
                pricePerShare={pricePerShare!}
              />
            </SensitiveText>
          </Flex>
        ) : (
          <ActivityCardListingNumShareAndPriceDetails
            listing={listing}
            textStyle="heading-md"
          />
        )}
      </CardBody>
      <CardFooter px={4} py={3}>
        <ActivityCardTimestamp title="Placed" date={listing.placedAt} />
      </CardFooter>
    </>
  );
};

const LatestListingSubcard = ({
  listing,
}: {
  readonly listing: LatestActivityListListingFragment;
}) => {
  const router = useRouter();
  const areCardUpdatesEnabled = useCardUpdates();

  return (
    <Card
      variant="activity-subcard"
      as="button"
      w="full"
      onClick={() => router.push(`/listings/${listing.id}`)}
      data-dd-action-name={`Click on listing ${listing.id}`}
    >
      <CardHeader
        w="full"
        px={areCardUpdatesEnabled ? 3 : 4}
        py={areCardUpdatesEnabled ? 2 : 4}
      >
        <HStack>
          <ActivityCardBadge title="Listing" variant="listing" />
          <Text
            textStyle={
              areCardUpdatesEnabled
                ? { base: `text-sm`, lg: `text-lg` }
                : `text-lg`
            }
            align="left"
          >
            {listing.company.name}
          </Text>
        </HStack>
      </CardHeader>
      <MarketActivityListingCard listing={listing} />
    </Card>
  );
};

const LatestListings = ({
  latestListings,
}: {
  readonly latestListings: readonly LatestActivityListListingFragment[];
}) => (
  <List spacing={4}>
    {latestListings.map((listing: LatestActivityListListingFragment) => (
      <LatestListingSubcard key={listing.id} listing={listing} />
    ))}
  </List>
);

const LatestStandingBids = ({
  latestStandingBids,
}: {
  readonly latestStandingBids: readonly LatestActivityListStandingBidFragment[];
}) => (
  <List spacing={4}>
    {latestStandingBids.map(
      (standingBid: LatestActivityListStandingBidFragment) => (
        <LatestStandingBidSubcard
          key={standingBid.id}
          standingBid={standingBid}
        />
      ),
    )}
  </List>
);

const RegularUserDashboardLatestMarketActivity = ({
  ...cardProps
}: CardProps) => {
  const [currentTab, setCurrentTab] = useState<TabKind>(`LISTINGS`);

  const onClick = (tab: TabKind) => {
    setCurrentTab(tab);
  };

  const query = useLatestMarketActivityQuery({
    variables: {
      numListings: constants.number_of_latest_listings,
      numStandingBids: constants.number_of_latest_standing_bids,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery
      query={query}
      fallback={<DashboardLatestMarketActivitySkeleton {...cardProps} />}
    >
      {({ data: { latestListings, latestStandingBids } }) => (
        <Card
          data-testid="latest-market-activity"
          w="full"
          h="min-content"
          {...cardProps}
        >
          <CardHeader>
            <Text textStyle="heading-sm">Latest Market Activity</Text>
          </CardHeader>
          <HStack px={{ base: 4, md: 6 }} pt={{ base: 4, md: 6 }}>
            <Tab
              isActive={currentTab === `LISTINGS`}
              onClick={() => onClick(`LISTINGS`)}
            >
              Listings
            </Tab>
            <Tab
              isActive={currentTab === `STANDING_BIDS`}
              onClick={() => onClick(`STANDING_BIDS`)}
            >
              Standing Bids
            </Tab>
          </HStack>
          <CardBody h="auto" overflowY="auto" maxH="1408px">
            {currentTab === `LISTINGS` && (
              <LatestListings latestListings={latestListings} />
            )}
            {currentTab === `STANDING_BIDS` && (
              <LatestStandingBids latestStandingBids={latestStandingBids} />
            )}
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};

export const DashboardLatestMarketActivity = ({ ...cardProps }: CardProps) => (
  <RegularUserDashboardLatestMarketActivity {...cardProps} />
);
