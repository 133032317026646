import { useSlugsAsCompanyUrl } from "./featureFlags";

type UseRedirectCompanyLinkCompany = {
  id: string;
  nameSlug: string;
};

export function useRedirectCompanyLink() {
  const slugsAsCompanyUrl = useSlugsAsCompanyUrl();

  return ({ id, nameSlug }: UseRedirectCompanyLinkCompany) =>
    slugsAsCompanyUrl ? `/companies/${nameSlug}` : `/companies/${id}`;
}
