import { useRouter } from "next/router";

import { Card, CardBody, CardFooter, HStack } from "@chakra-ui/react";

import {
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTransactionStatus,
} from "@/components/common";
import { UserActivityTransactionFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { makeUrl } from "@/utils";

import DashboardTransactionCardHeader from "./DashboardTransactionCardHeader";
import { DashboardYourPendingPurchaseCardV2 } from "./DashboardYourPendingPurchaseCardV2";

const DashboardYourPendingPurchaseCardDetails = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const { numShares, pricePerShare } = transaction;

  return (
    <HStack justifyContent="space-between">
      <ActivityCardPricingWrapper>
        <ActivityCardPricingNumSharesAndPrice
          numShares={numShares}
          price={pricePerShare}
        />
        <ActivityCardPricingTotal numShares={numShares} price={pricePerShare} />
      </ActivityCardPricingWrapper>
    </HStack>
  );
};

export const DashboardYourPendingPurchaseCard = ({
  transaction,
}: {
  readonly transaction: UserActivityTransactionFragment;
}) => {
  const { company, bid } = transaction;
  const areCardUpdatesEnabled = useCardUpdates();

  const router = useRouter();

  if (areCardUpdatesEnabled)
    return <DashboardYourPendingPurchaseCardV2 transaction={transaction} />;

  return (
    <Card
      variant="activity"
      as="button"
      onClick={() => router.push(makeUrl(transaction))}
    >
      <DashboardTransactionCardHeader transaction={transaction} />
      <CardBody>
        <DashboardYourPendingPurchaseCardDetails transaction={transaction} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTransactionStatus
              bid={bid}
              company={company}
              transaction={transaction}
            />
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </Card>
  );
};
