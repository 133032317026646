import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { BidState, UserActivityBidFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { getBidNumSharesActual, makeUrl, bidLabel } from "@/utils";

import { DashboardYourBidCardV2 } from "./DashboardYourBidCardV2";

const DashboardYourBidCardDetails = ({
  bid,
}: {
  readonly bid: UserActivityBidFragment;
}) => {
  const numShares = getBidNumSharesActual(bid);
  const price = bid.pricePerShare;

  return (
    <ActivityCardPricingWrapper>
      <ActivityCardPricingNumSharesAndPrice
        numShares={numShares}
        price={price}
      />
      <ActivityCardPricingTotal numShares={numShares} price={price} />
    </ActivityCardPricingWrapper>
  );
};

export const DashboardYourBidCard = ({
  bid,
}: {
  readonly bid: UserActivityBidFragment;
}) => {
  const areCardUpdatesEnabled = useCardUpdates();
  const isExpired = bid.state === BidState.Expired;
  const title = bidLabel(bid.listing);

  if (areCardUpdatesEnabled) return <DashboardYourBidCardV2 bid={bid} />;

  return (
    <ActivityCard href={makeUrl(bid)}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title={title} />
            <Text textStyle="text-lg" color="grey.900">
              {bid.displayId}
            </Text>
          </HStack>
          <ActivityCardCompany company={bid.company} />
        </HStack>
      </CardHeader>
      <CardBody>
        <DashboardYourBidCardDetails bid={bid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          {!!bid.expireAt && (
            <ActivityCardTimestamp
              title={isExpired ? `Expired` : `Expires`}
              date={bid.expireAt}
            />
          )}
          {!!bid.discussion && bid.discussion.totalNumMessages > 0 && (
            <MessagesBadge
              totalNumMessages={bid.discussion.totalNumMessages}
              numUnreadMessages={bid.discussion.numUnreadMessages}
            />
          )}
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};
