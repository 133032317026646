import { ActivityCard } from "@/components/common";
import {
  CompanyListingCardBody,
  CompanyListingCardHeader,
  CompanyListingCardFooter,
  CompanyListingCardHeaderV2,
  CompanyListingCardBodyV2,
  CompanyListingCardFooterV2,
} from "@/components/companies";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyYourActivityListingCardListingFragment,
  CompanyYourActivityListingCardCompanyFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";
import { appendSellerCompanyIdToUrl, getIsUnaccreditedSeller } from "@/utils";

const CompanyYourActivityListingCard = withCurrentActor(
  ({
    listing,
    company,
    dataTestId,
    actor,
  }: {
    readonly listing: CompanyYourActivityListingCardListingFragment;
    readonly company: CompanyYourActivityListingCardCompanyFragment;
    readonly dataTestId?: string;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
    const areCardsUpdatesEnabled = useCardUpdates();

    const url = `/listings/${listing.id}`;

    if (areCardsUpdatesEnabled)
      return (
        <ActivityCard
          href={
            isUnaccreditedSeller
              ? appendSellerCompanyIdToUrl(url, company.id)
              : url
          }
          data-testid={dataTestId}
        >
          <CompanyListingCardHeaderV2 listing={listing} />
          <CompanyListingCardBodyV2 listing={listing} company={company} />
          <CompanyListingCardFooterV2 listing={listing} />
        </ActivityCard>
      );

    return (
      <ActivityCard
        href={
          isUnaccreditedSeller
            ? appendSellerCompanyIdToUrl(url, company.id)
            : url
        }
        data-testid={dataTestId}
      >
        <CompanyListingCardHeader listing={listing} />
        <CompanyListingCardBody listing={listing} company={company} />
        <CompanyListingCardFooter listing={listing} />
      </ActivityCard>
    );
  },
);

export default CompanyYourActivityListingCard;
