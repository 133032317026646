import { useTranslation } from "react-i18next";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  InquiryPostingCard,
  WithQuery,
} from "@/components/common";
import {
  MessageTopicType,
  StandingBidBuyerDiscussionsStandingBidFragment,
  useMyDiscussionListDiscussionsQuery,
  MyDiscussionListDiscussionFragment,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

import { StandingBidBuyerDiscussionsSkeleton } from "./StandingBidBuyerDiscussionsSkeleton";
import { YourDiscussionCard } from "./YourDiscussionCard";

type StandingBidBuyerDiscussionsListProps = {
  myDiscussions: MyDiscussionListDiscussionFragment[];
};

const DiscussionsEmptyState = () => (
  <ActivityGroupEmptyState message="You have no active inquiries on your Standing Bid, you will be notified when you receive a message from an interested seller." />
);

function StandingBidBuyerDiscussionsList({
  myDiscussions,
}: StandingBidBuyerDiscussionsListProps) {
  const { t } = useTranslation(`discussions`);
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivityGroup
      title={t(`inquiries`)}
      emptyState={<DiscussionsEmptyState />}
    >
      {myDiscussions.map((discussion) =>
        cardUpdatesEnabled ? (
          <InquiryPostingCard key={discussion.id} discussion={discussion} />
        ) : (
          <YourDiscussionCard key={discussion.id} discussion={discussion} />
        ),
      )}
    </ActivityGroup>
  );
}

export const StandingBidBuyerDiscussions = ({
  standingBid,
}: {
  readonly standingBid: StandingBidBuyerDiscussionsStandingBidFragment;
}) => {
  const query = useMyDiscussionListDiscussionsQuery({
    variables: {
      topicFilter: { type: MessageTopicType.StandingBid, id: standingBid.id },
    },
  });

  return (
    <WithQuery query={query} fallback={<StandingBidBuyerDiscussionsSkeleton />}>
      {({ data: { myDiscussions } }) => (
        <StandingBidBuyerDiscussionsList myDiscussions={myDiscussions} />
      )}
    </WithQuery>
  );
};
