import { CircleDashed } from "@phosphor-icons/react";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { useColors } from "@/hooks";

const PartiallyAcceptedBadge = () => {
  const [teal800] = useColors([`teal.800`]);

  return (
    <CustomTooltip
      placement="bottom"
      tooltipContent="A portion of this standing bid has been completed or is pending execution of a transaction document. Sellers can still accept this bid for the remaining number of shares the buyer is seeking."
    >
      <Badge variant="rounded-teal">
        <HStack spacing={1.5}>
          <CircleDashed color={teal800} weight="bold" size={14} />
          <Text fontSize={{ base: 12, lg: `inherit` }}>Partially accepted</Text>
        </HStack>
      </Badge>
    </CustomTooltip>
  );
};

export default PartiallyAcceptedBadge;
