import { Check, Code, Copy } from "@phosphor-icons/react";
import { Fragment, useContext, useState } from "react";

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Portal,
  SimpleGrid,
  Switch,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react";

import { LDStatus, LDStatusContext } from "@/components/async-with-ld-provider";
import {
  UserWithInstitutionFragment,
  useToggleFeatureFlagMutation,
} from "@/gql";
import { useCurrentActor, useIsDesktop, useMutationWithError } from "@/hooks";
import { FlagValue, useFeatureFlags } from "@/hooks/featureFlags";

const API_PARAM = `api`;
const IS_PRODUCTION = process.env.NEXT_PUBLIC_INFRA_ENV === `production`;
const DEFAULT_API_URL =
  process.env.NEXT_PUBLIC_API_HOST ||
  `http://localhost:${process.env.NEXT_PUBLIC_PORT || 4000}`;

function UserInformation({
  actor,
}: {
  readonly actor: UserWithInstitutionFragment;
}) {
  const { onCopy: onCopyUserId, hasCopied: hasCopiedUserId } = useClipboard(
    actor.id,
  );

  const actorProperties = {
    "User ID": (
      <Flex gap={5} alignItems="center">
        <Text>{actor.id}</Text>
        <IconButton
          variant="unstyled"
          m="0 auto"
          justifySelf="center"
          h={6}
          margin={0}
          minW="unset"
          aria-label={hasCopiedUserId ? `Copied` : `Copy`}
          onClick={onCopyUserId}
          icon={
            <Box
              as={hasCopiedUserId ? Check : Copy}
              size={24}
              weight="fill"
              color={hasCopiedUserId ? `green.400` : `white`}
              _hover={hasCopiedUserId ? {} : { color: `grey.200` }}
            />
          }
        />
      </Flex>
    ),
    "User Name": actor.name,
    "User Email": actor.email,
    "Institution ID": actor.institution?.id || `None`,
    Superadmin: actor.isSuperadmin.toString() === `true` ? `Yes` : `No`,
  };

  return (
    <Grid
      templateColumns="max-content 1fr"
      columnGap={6}
      rowGap={1}
      alignItems="center"
    >
      {Object.entries(actorProperties).map(([actorProperty, value]) => (
        <Fragment key={actorProperty}>
          <GridItem>
            <Text textStyle="heading-2xs" color="grey.100">
              {actorProperty}
            </Text>
          </GridItem>
          <GridItem>
            <Text color="grey.100" textStyle="text-sm">
              {value}
            </Text>
          </GridItem>
        </Fragment>
      ))}
    </Grid>
  );
}

function ShowFlagsButton({
  onClick,
  showFlags,
}: {
  readonly onClick: () => void;
  readonly showFlags: boolean;
}) {
  return (
    <Button
      onClick={onClick}
      bg="grey.900"
      color="white"
      _hover={{
        bg: `grey.800`,
      }}
      _active={{
        bg: `grey.700`,
      }}
      borderRadius={0}
      borderTopRightRadius="md"
      minW="unset"
      px={4}
      py={2}
      h="unset"
      m={0}
    >
      <HStack>
        <Text color="white" fontSize="sm">
          {showFlags ? `Hide` : `Show`} Developer Menu
        </Text>
        <Code color="white" />
      </HStack>
    </Button>
  );
}

function setApiQueryParam(apiUrl: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(API_PARAM);
  url.searchParams.append(API_PARAM, apiUrl);
  window.location.replace(url);
}

const FeatureFlagsHelper = (props: BoxProps) => {
  const initApiUrl = localStorage.getItem(API_PARAM) || DEFAULT_API_URL;
  const [apiUrl, setApiUrl] = useState(initApiUrl);
  const { status } = useContext(LDStatusContext);

  const [showFlags, setShowFlags] = useState(false);
  const flags = useFeatureFlags();
  const isDesktopView = useIsDesktop();
  const actor = useCurrentActor();
  const mutation = useToggleFeatureFlagMutation();
  const [toggleFeatureFlagMutation] = useMutationWithError(
    mutation,
    `toggleFeatureFlag`,
  );

  if (
    IS_PRODUCTION ||
    !flags ||
    !isDesktopView ||
    Object.keys(flags).length === 0
  ) {
    return null;
  }

  const handleUseDefaultApiUrl = () => {
    setApiUrl(DEFAULT_API_URL);
  };
  const handleSetApiUrl = () => {
    setApiQueryParam(apiUrl);
  };
  const handleClickShowFlags = () => {
    setShowFlags(!showFlags);
  };
  const handleToggleFlag = (flagKey: string) => {
    toggleFeatureFlagMutation({
      variables: { input: { flagKey } },
    });
  };

  const flagEntries = Object.entries(flags).sort(([flagKeyA], [flagKeyB]) =>
    flagKeyA.localeCompare(flagKeyB),
  );

  return (
    <Portal>
      <Box zIndex={10000} position="fixed" bottom="0" left="0" {...props}>
        <ShowFlagsButton showFlags={showFlags} onClick={handleClickShowFlags} />
        {showFlags && (
          <Flex
            direction="column"
            gap={4}
            borderTopRightRadius="md"
            p={4}
            bg="grey.900"
            maxH="80vh"
            overflow="auto"
          >
            <SimpleGrid
              columns={2}
              gridTemplateColumns="min-content"
              columnGap={10}
              rowGap={1}
            >
              {status === LDStatus.Failure && (
                <Text color="grey.100" textStyle="heading-xl">
                  LaunchDarkly failed to load
                </Text>
              )}
              {flagEntries.map(
                ([flagKey, flagValue]: readonly [string, FlagValue]) => (
                  <Fragment key={flagKey}>
                    <Text color="grey.100" gridColumn={1}>
                      {flagKey}
                    </Text>
                    <Switch
                      gridColumn={2}
                      onChange={() => handleToggleFlag(flagKey)}
                      isChecked={!!flagValue}
                      isDisabled={!actor}
                    />
                  </Fragment>
                ),
              )}
            </SimpleGrid>
            <Divider borderColor="grey.600" />
            <VStack h="16">
              <VStack alignItems="flex-start" w="full">
                <Text textStyle="heading-2xs" color="grey.100">
                  API URL
                </Text>
                <HStack w="full">
                  <Input
                    bg="grey.800"
                    color="grey.100"
                    value={apiUrl}
                    onChange={(event) => setApiUrl(event.target.value)}
                  />
                  <Button
                    minW="unset"
                    h="full"
                    bg="grey.800"
                    borderColor="grey.600"
                    _hover={{
                      bg: `grey.700`,
                    }}
                    borderWidth="1px"
                    color="grey.100"
                    onClick={handleUseDefaultApiUrl}
                  >
                    Use Default
                  </Button>
                  <Button
                    minW="unset"
                    h="full"
                    bg="white"
                    isDisabled={apiUrl === initApiUrl}
                    onClick={handleSetApiUrl}
                  >
                    Set
                  </Button>
                </HStack>
              </VStack>
            </VStack>
            {actor && process.env.NEXT_PUBLIC_ENV === `dev` && (
              <>
                <Divider borderColor="grey.600" />
                <HStack
                  alignItems="center"
                  w="full"
                  justifyContent="space-between"
                >
                  <Text textStyle="heading-2xs" color="grey.100">
                    Bypass Identity Verification
                  </Text>
                  <Button
                    minW="unset"
                    bg="grey.800"
                    borderColor="grey.600"
                    _hover={{
                      bg: `grey.700`,
                    }}
                    borderWidth="1px"
                    color="grey.100"
                    onClick={() => {
                      fetch(`${apiUrl}/e2e/start_identity_verification`, {
                        headers: {
                          Accept: `application/json`,
                          "Content-Type": `application/json`,
                        },
                        method: `POST`,
                        body: JSON.stringify({ user_id: actor.id }),
                      });
                    }}
                  >
                    Fire
                  </Button>
                </HStack>
                <Divider borderColor="grey.600" />
                <HStack
                  alignItems="center"
                  w="full"
                  justifyContent="space-between"
                >
                  <Text textStyle="heading-2xs" color="grey.100">
                    Fake Persona Webhook Request
                  </Text>
                  <Button
                    minW="unset"
                    bg="grey.800"
                    borderColor="grey.600"
                    _hover={{
                      bg: `grey.700`,
                    }}
                    borderWidth="1px"
                    color="grey.100"
                    onClick={() => {
                      fetch(`${apiUrl}/e2e/fake_persona_webhook_call`, {
                        headers: {
                          Accept: `application/json`,
                          "Content-Type": `application/json`,
                        },
                        method: `POST`,
                        body: JSON.stringify({ user_id: actor.id }),
                      });
                    }}
                  >
                    Fire
                  </Button>
                </HStack>
              </>
            )}
            {actor && (
              <>
                <Divider borderColor="grey.600" />
                <UserInformation actor={actor} />
              </>
            )}
          </Flex>
        )}
      </Box>
    </Portal>
  );
};

export default FeatureFlagsHelper;
