import { WarningCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { Badge, HStack, Text } from "@chakra-ui/react";

import { CustomTooltip } from "@/components/form";
import { TransferMethod } from "@/gql";
import { useColors } from "@/hooks";
import {
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
} from "@/utils";

const SoldOutBadge = () => {
  const { t } = useTranslation();
  const [grey100] = useColors([`grey.100`]);

  return (
    <Badge variant="rounded-grey">
      <HStack spacing={1.5}>
        <WarningCircle color={grey100} weight="bold" size={14} />
        <Text fontSize={{ base: 12, lg: `inherit` }}>
          {t(`listing_conditionally_sold_out`)}
        </Text>
      </HStack>
    </Badge>
  );
};

const BadgeWithToolTip = ({ content }: { readonly content: string }) => (
  <CustomTooltip placement="bottom" tooltipContent={content}>
    <SoldOutBadge />
  </CustomTooltip>
);

const ConditionallySoldOutBadge = ({
  transferMethod,
}: {
  readonly transferMethod: TransferMethod;
}) => {
  const { t } = useTranslation();

  const documentTypeLongText =
    getLongDocumentTitleByTransferMethod(transferMethod);
  const documentTypeShortText =
    getShortDocumentTitleByTransferMethod(transferMethod);
  const isFund =
    transferMethod === TransferMethod.Fund ||
    transferMethod === TransferMethod.HiiveFund;

  const content =
    transferMethod === TransferMethod.Direct
      ? t(`listing_conditionally_sold_status_direct`)
      : t(`listing_conditionally_sold_status`, {
          documentTypeLongText,
          documentTypeShortText,
          hoursToSign: `14`,
        });

  return isFund ? <SoldOutBadge /> : <BadgeWithToolTip content={content} />;
};

export default ConditionallySoldOutBadge;
