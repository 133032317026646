import { Minus, Plus } from "@phosphor-icons/react";
import React from "react";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Flex,
  IconButton,
} from "@chakra-ui/react";

type EntityCardLayoutProps = {
  children?: React.ReactNode;
  form: React.ReactNode;
  hasEntity: boolean;
  title: React.ReactNode;
};

const EntityCardLayout = ({
  children,
  form,
  hasEntity,
  title,
}: EntityCardLayoutProps) => (
  <Card variant="darkened-footer" w="full">
    <Accordion allowToggle borderColor="transparent" defaultIndex={0}>
      <AccordionItem w="full">
        {({ isExpanded }) => (
          <>
            <CardHeader
              py={4}
              borderBottom={
                isExpanded || !hasEntity ? `1px solid gray.200` : `none`
              }
            >
              <Flex w="full" alignItems="center" justifyContent="space-between">
                {title}
                {hasEntity && (
                  <AccordionButton as="div" w="initial" p={0} rounded="full">
                    <IconButton
                      background="transparent"
                      h={8}
                      icon={
                        isExpanded ? <Minus size={16} /> : <Plus size={16} />
                      }
                      isRound
                      minW="fit-content"
                      variant="ghost"
                      aria-label={
                        isExpanded
                          ? `hide transacting entity`
                          : `expand transacting entity`
                      }
                      w={8}
                    />
                  </AccordionButton>
                )}
              </Flex>
            </CardHeader>
            {children && (
              <AccordionPanel p={0}>
                <CardBody>{children}</CardBody>
              </AccordionPanel>
            )}
          </>
        )}
      </AccordionItem>
    </Accordion>
    {form && form}
  </Card>
);

export default EntityCardLayout;
