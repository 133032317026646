import { AnimatePresence } from "framer-motion";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";

import { ModalBody, Text, VStack } from "@chakra-ui/react";

import {
  HiiveModalContentWrapper,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  MailtoLink,
} from "@/components/common";
import { AnimatedModalStep } from "@/components/form";
import { CreateListingSuccessModalListingFragment } from "@/gql";
import { useModal } from "@/hooks";
import {
  formatPricePerShare,
  formatShares,
  getListingNumOfShares,
  getSecuritySpecialistContact,
} from "@/utils";

const UnaccreditedSellerCreateListingSuccessModal = ({
  listing,
}: {
  readonly listing: CreateListingSuccessModalListingFragment;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const { listingPricePerShare, company } = listing;

  const { name: companyName } = company;

  const securitySpecialistContact = getSecuritySpecialistContact(company);
  const numShares = getListingNumOfShares(listing, false);

  const formattedShares = formatShares(numShares);
  const formattedPrice = !isNil(listingPricePerShare)
    ? formatPricePerShare(listingPricePerShare)
    : ``;

  return (
    <HiiveModalContentWrapper>
      <AnimatePresence mode="wait" initial={false}>
        <AnimatedModalStep>
          <HiiveModalHeader>Listing made!</HiiveModalHeader>
          <ModalBody>
            <VStack alignItems="flex-start">
              <Text textStyle="heading-xl">
                Your {companyName} listing for {formattedShares} shares at{` `}
                {formattedPrice}/sh is now live on Hiive!
              </Text>
              <Text>{t(`create_listing_success_body`)}</Text>
              <Text>
                You can also reach out anytime to the {company.name} Security
                Specialist{` `}
                <Text as="span" fontWeight="medium">
                  <MailtoLink
                    email={securitySpecialistContact}
                    subject={`Contact ${companyName} Security Specialist`}
                  >
                    {securitySpecialistContact}
                  </MailtoLink>
                </Text>
                {` `}
                for assistance on your transaction.
              </Text>
            </VStack>
          </ModalBody>
          <HiiveModalFooter>
            <HiiveSubmitButton
              observabilityLabel="[CreateListingSuccess/Submit]"
              type="submit"
              submitText="Okay"
              onClick={closeModal}
            />
          </HiiveModalFooter>
        </AnimatedModalStep>
      </AnimatePresence>
    </HiiveModalContentWrapper>
  );
};

export default UnaccreditedSellerCreateListingSuccessModal;
