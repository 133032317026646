import { useTranslation } from "react-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  Box,
  Card,
  CardBody,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  HStack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import {
  LongHyphen,
  SensitiveText,
  WatchlistButton,
} from "@/components/common";
import { useIndexCompaniesQuery } from "@/gql";
import { useRedirectCompanyLink } from "@/hooks";
import {
  roundPercentage,
  formatPricePerShare,
  getTrendPercentageColor,
} from "@/utils";

import { SparklineChart } from "./SparklineChart";
import { TableSkeleton } from "./TableSkeleton";

const mobileBorderStyle = {
  content: `""`,
  position: `absolute`,
  right: `-1px`,
  top: `0`,
  height: `100%`,
  width: `1px`,
  bgColor: `grey.50`,
  zIndex: `1`,
};

export const TableCard = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const redirectCompanyLink = useRedirectCompanyLink();

  const isDevice = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false },
  );

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    { ssr: false },
  );

  const { data, loading } = useIndexCompaniesQuery();

  const handleOnRowClick = (
    e: React.MouseEvent<HTMLTableRowElement>,
    url: string,
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(url, `_blank`);
    } else {
      router.push(url);
    }
  };

  const items =
    data?.indexCompanies?.edges?.flatMap((edge) => {
      if (!edge || !edge.node) return [];

      return [edge.node];
    }) || [];

  return (
    <Card w="full">
      <CardBody pb="0 !important">
        <VStack alignItems="start" spacing={1}>
          <Text textStyle="heading-3xl">{t(`index_constituents`)}</Text>
          <Box w="full" maxW="784" h="full">
            <Text textStyle="text-sm">
              {t(`index_constituents_description`)}
            </Text>
          </Box>
        </VStack>
        <Box
          position="relative"
          marginX={{ base: -4, md: -6 }}
          mt={{ base: 2, md: 4 }}
          borderY="1px solid"
          borderColor={{ base: `grey.50`, md: `transparent` }}
        >
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            width="6px"
            zIndex={2}
            pointerEvents="none"
            backgroundImage={{
              base: `linear-gradient(to right, rgba(50, 50, 50, 0) 0%, rgba(50, 50, 50, 0.1) 100%)`,
              md: `none`,
            }}
          />
          <Box overflowX="auto">
            <Table variant="simple" size={{ base: `sm`, md: `lg` }}>
              <Thead>
                <Tr>
                  <Th
                    position="sticky"
                    left="0"
                    bg="white"
                    zIndex="1"
                    borderBottom="none"
                    // right border for mobile scroll
                    {...(isMobile && {
                      _after: mobileBorderStyle,
                    })}
                  >
                    <Text textStyle="heading-2xs">{t(`company`)}</Text>
                  </Th>
                  <Th borderBottom="none">
                    <Text textStyle="heading-2xs">{t(`trend`)}</Text>
                  </Th>
                  <Th borderBottom="none">
                    <Text textStyle="heading-2xs">{t(`change_over_3m`)}</Text>
                  </Th>
                  <Th borderBottom="none">
                    <Text textStyle="heading-2xs">{t(`bid`)}</Text>
                  </Th>
                  <Th borderBottom="none">
                    <Text textStyle="heading-2xs">{t(`ask`)}</Text>
                  </Th>
                  <Th borderBottom="none">
                    <Text textStyle="heading-2xs">
                      {t(`last_accepted_bid`)}
                    </Text>
                  </Th>
                  <Th borderBottom="none" />
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <TableSkeleton />
                ) : (
                  items.map((item, index) => {
                    const { id, sortedIndexPrices, company, todaysPriceGraph } =
                      item;
                    const { priceChangeNinetyDays } = todaysPriceGraph || {};
                    return (
                      <Tr
                        key={id}
                        onClick={(e) =>
                          handleOnRowClick(e, redirectCompanyLink(company))
                        }
                        {...(!isMobile && {
                          _hover: {
                            cursor: `pointer`,
                            bg: `grey.25`,
                            "& td": { bg: `transparent` },
                          },
                        })}
                        borderY={index === 0 ? `none` : `1px solid`}
                        borderColor={{ base: `transparent`, md: `grey.50` }}
                      >
                        <Td
                          maxW={{ base: `35vw`, sm: `none` }}
                          position="sticky"
                          left="0"
                          bg="white"
                          zIndex="1"
                          border="none"
                          {...(!isMobile && {
                            _hover: {
                              bg: `grey.25 !important`,
                            },
                          })}
                          // right border for mobile scroll
                          {...(isMobile && {
                            _after: mobileBorderStyle,
                          })}
                        >
                          <Link href={redirectCompanyLink(company)}>
                            <HStack alignItems="center">
                              {company.logoUrl && (
                                <Box h={6} w={7}>
                                  <Image
                                    m="0 auto"
                                    h={6}
                                    maxW={6}
                                    objectFit="contain"
                                    src={company.logoUrl}
                                    alt={company.name}
                                  />
                                </Box>
                              )}
                              <Text
                                textStyle={{
                                  base: `heading-xs`,
                                  sm: `heading-md`,
                                }}
                                noOfLines={1}
                              >
                                {company.name}
                              </Text>
                            </HStack>
                          </Link>
                        </Td>
                        <Td border="none">
                          <Link href={redirectCompanyLink(company)}>
                            <SparklineChart indexPrices={sortedIndexPrices} />
                          </Link>
                        </Td>
                        <Td border="none">
                          <Link href={redirectCompanyLink(company)}>
                            <Text
                              textStyle={{
                                base: `heading-xs`,
                                sm: `heading-md`,
                              }}
                              color={getTrendPercentageColor(
                                priceChangeNinetyDays,
                              )}
                            >
                              {roundPercentage(
                                parseFloat(priceChangeNinetyDays),
                                1,
                              )}
                            </Text>
                          </Link>
                        </Td>
                        <Td border="none">
                          <Link href={redirectCompanyLink(company)}>
                            <SensitiveText>
                              {company.currentPrices?.highestBid ? (
                                <Text
                                  textStyle={{
                                    base: `heading-xs`,
                                    sm: `heading-md`,
                                  }}
                                >
                                  {formatPricePerShare(
                                    company.currentPrices.highestBid,
                                  )}
                                </Text>
                              ) : (
                                <LongHyphen />
                              )}
                            </SensitiveText>
                          </Link>
                        </Td>
                        <Td border="none">
                          <Link href={redirectCompanyLink(company)}>
                            <SensitiveText>
                              {company.currentPrices?.lowestAsk ? (
                                <Text
                                  textStyle={{
                                    base: `heading-xs`,
                                    sm: `heading-md`,
                                  }}
                                >
                                  {formatPricePerShare(
                                    company.currentPrices.lowestAsk,
                                  )}
                                </Text>
                              ) : (
                                <LongHyphen />
                              )}
                            </SensitiveText>
                          </Link>
                        </Td>
                        <Td border="none">
                          <Link href={redirectCompanyLink(company)}>
                            <SensitiveText>
                              {company.currentPrices?.lastTransaction ? (
                                <Text
                                  textStyle={{
                                    base: `heading-xs`,
                                    sm: `heading-md`,
                                  }}
                                >
                                  {formatPricePerShare(
                                    company.currentPrices.lastTransaction,
                                  )}
                                </Text>
                              ) : (
                                <LongHyphen />
                              )}
                            </SensitiveText>
                          </Link>
                        </Td>
                        <Td w={{ base: 10, lg: 40 }} border="none">
                          <WatchlistButton
                            maxW={40}
                            w={isDevice ? `fit-content` : `full`}
                            companyId={company.id}
                            isCollapsed={isDevice}
                          />
                        </Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </CardBody>
    </Card>
  );
};
