import { Trans, useTranslation } from "react-i18next";

import { Flex, HStack, Link, Text, VStack } from "@chakra-ui/react";

import { FocusedShareDetails, HiiveButton } from "@/components/common";
import {
  BidActions,
  BidFeeBreakdown,
  BidHighFeesWarning,
  BidInfoCardBidShareDetails,
  BidInfoCardStatusTile,
  PlacedByHiive,
} from "@/components/postings";
import { BidInfoCardBidFragment, BidState, TransactionState } from "@/gql";
import { useModal } from "@/hooks";
import {
  constants,
  getAreFeesHighForBid,
  getAvailableBidActions,
  getBidNumSharesActual,
} from "@/utils";

const SellerBidInfoCard = ({
  bid,
}: {
  readonly bid: BidInfoCardBidFragment;
}) => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();
  const placedAt = bid.counteredAt || bid.expireAt;
  const hasCounterBid = bid.state === BidState.Countered;
  const { company } = bid.listing;
  const showPlacedByHiive = bid.fromHiive;
  const showHighFeesWarning = getAreFeesHighForBid({
    pricePerShare: bid.pricePerShare,
    numberOfShares: getBidNumSharesActual(bid),
  });
  const { canAcceptOriginalBid } = getAvailableBidActions(bid);

  return (
    <Flex direction="column" gap={7}>
      <Flex direction="column" gap={7} data-testid="bid-info-card">
        {hasCounterBid && (
          <Text textStyle="heading-sm">{t`bid_info_original_bid`}</Text>
        )}
        <FocusedShareDetails.Card variant="bid">
          <FocusedShareDetails.Header
            title={`Bid ${bid.displayId}`}
            company={company}
          >
            <BidInfoCardBidShareDetails bid={bid} />
          </FocusedShareDetails.Header>

          <FocusedShareDetails.Content>
            <FocusedShareDetails.ContentSection>
              <Flex direction="column" gap={6}>
                {placedAt && (
                  <BidInfoCardStatusTile
                    bid={bid}
                    showCounterBidDetails={false}
                  />
                )}
                <BidFeeBreakdown
                  numberOfShares={getBidNumSharesActual(bid)}
                  pricePerShare={bid.pricePerShare}
                  commission={bid.commission}
                  transactionState={bid.transaction?.state as TransactionState}
                  feeDiscountApplications={bid.feeDiscountApplications}
                />
              </Flex>
            </FocusedShareDetails.ContentSection>
            {showPlacedByHiive && (
              <FocusedShareDetails.ContentSection p={{ base: 4, md: 6 }} pb={5}>
                <PlacedByHiive bid={bid} />
              </FocusedShareDetails.ContentSection>
            )}
            {showHighFeesWarning && (
              <FocusedShareDetails.ContentSection>
                <BidHighFeesWarning textStyle="heading-xs" bid={bid} />
              </FocusedShareDetails.ContentSection>
            )}
            {canAcceptOriginalBid && (
              <FocusedShareDetails.ContentFooter justifyContent="flex-end">
                <HStack justifyContent="space-between" w="full">
                  <VStack align="left">
                    <Text textStyle="heading-sm" textTransform="uppercase">
                      {t(`accept_bid`)}
                    </Text>
                    <Text textStyle="text-sm">
                      <Trans
                        i18nKey="seller_accept_bid_terms_and_conditions"
                        components={{
                          italic: (
                            <Link
                              key="terms-and-conditions"
                              href={constants.t_and_c_url}
                              target="_blank"
                              textDecorationLine="underline"
                            />
                          ),
                        }}
                      />
                    </Text>
                  </VStack>
                  <HiiveButton
                    size="xl"
                    variant="rounded-solid-salmon"
                    onClick={onOpenModal(modals.acceptBid(bid))}
                    observabilityLabel="[BidActions] Accept"
                  >
                    Accept
                  </HiiveButton>
                </HStack>
              </FocusedShareDetails.ContentFooter>
            )}
          </FocusedShareDetails.Content>
        </FocusedShareDetails.Card>
      </Flex>
      <BidActions bid={bid} isBuyer={false} />
    </Flex>
  );
};

export default SellerBidInfoCard;
