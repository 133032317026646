import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton, Skeleton } from "@/components/common";
import { FormRadioTile, FormTextAreaInput } from "@/components/react-hook-form";
import { useAnswerInstitutionAccreditationQuestionsMutation } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";
import { shadows } from "@/styles/theme";

import { InstitutionAccreditationFormProps } from "./InstitutionAccreditationForm";

const OtherExplainQualificationKey = `other-institutional-explain-qualification`;

interface InstitutionAccreditationFormValues {
  readonly accreditationOptionKey?: string | null;
  readonly accreditationOptionText?: string | null;
}

export const OtherInstitutionAccreditationForm = ({
  onSuccess,
}: Omit<InstitutionAccreditationFormProps, `questionGroup`>) => {
  const actor = useCurrentActor();
  const { t } = useTranslation();
  const mutation = useAnswerInstitutionAccreditationQuestionsMutation();

  const validationSchema = Yup.object().shape({
    accreditationOptionKey: Yup.string()
      .oneOf([OtherExplainQualificationKey])
      .required(t`required`),
    accreditationOptionText: Yup.string().required(t`required`),
  });

  const previousAnswers = actor.institution?.accreditationAnswers;
  const hasPreviousAnswers = !!previousAnswers && previousAnswers.length > 0;
  const initialValues = {
    accreditationOptionKey: OtherExplainQualificationKey,
    accreditationOptionText: hasPreviousAnswers
      ? previousAnswers[0].text
      : null,
  };

  const mapVariables = ({
    accreditationOptionKey,
    accreditationOptionText,
  }: InstitutionAccreditationFormValues) => ({
    input: {
      answers: [
        {
          key: accreditationOptionKey || ``,
          text:
            accreditationOptionKey === OtherExplainQualificationKey
              ? accreditationOptionText
              : null,
        },
      ],
    },
  });

  const { handleSubmit, isLoading, control, formState } = useFormQL({
    mutation,
    mode: `onChange`,
    mapVariables,
    initialValues,
    validationSchema,
    onSuccess,
  });

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      id="institution-accreditation-form"
    >
      <FormRadioTile.Group
        size="md"
        p={{ base: 4, lg: 6 }}
        borderRadius="md"
        bg="white"
        borderColor="grey.200"
        borderWidth={0.5}
        boxShadow={shadows.card}
        gap={4}
        w={{ base: `full`, lg: 160 }}
      >
        <Text>{t`other_institution_accreditation_description_1`}</Text>
        <Text>{t`other_institution_accreditation_description_2`}</Text>
        <FormTextAreaInput
          w="full"
          control={control}
          name="accreditationOptionText"
          label={t`other_institution_accreditation_input_label`}
          placeholder={t`description`}
          displayCounter
          maxLength={400}
        />
      </FormRadioTile.Group>
      <HStack p={2} mt={6} justifyContent="flex-end" w="full">
        <HiiveButton
          observabilityLabel="[InstitutionAccreditationPageV2/Submit]"
          variant="rounded-solid-salmon"
          type="submit"
          size="xl"
          maxW="unset"
          form="institution-accreditation-form"
          w={{ base: `full`, lg: `unset` }}
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={!formState?.isValid}
        >
          {t(`next`)}
        </HiiveButton>
      </HStack>
    </form>
  );
};

export const OtherInstitutionAccreditationFormSkeleton = () => (
  <VStack w={{ base: `full`, lg: 160 }} gap={2}>
    <Skeleton h={50} />
    <Skeleton h={75} />
    <VStack h={25} />
    <Skeleton h={50} />
    <Skeleton h={150} />
    <VStack alignItems="flex-end" w="full">
      <Skeleton h={10} w={155} />
    </VStack>
  </VStack>
);
