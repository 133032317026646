import { useFieldArray } from "react-hook-form";
import { Trans } from "react-i18next";

import { Box, Text, VStack } from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import { FormCheckboxInput } from "@/components/react-hook-form";
import {
  HoldingDetailsFormFieldValues,
  HoldingField,
} from "@/components/unaccredited-seller";
import { HoldingDetailsPageCompanyFragment, HoldingType } from "@/gql";
import { useFormQL } from "@/hooks/react-hook-form";

import { AddHoldingCombobox, SelectedHoldingsList } from ".";

type AddHoldingsFormProps = Pick<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ReturnType<typeof useFormQL<HoldingDetailsFormFieldValues, any, any>>,
  `watch` | `handleSubmit` | `control`
> & {
  readonly formId: string;
  readonly maxWidth?: number;
  readonly existentHoldings?: HoldingField[];
};

export const AddHoldingsForm = ({
  control,
  watch,
  handleSubmit,
  formId,
  maxWidth,
  existentHoldings = [],
}: AddHoldingsFormProps) => {
  const { fields, append, replace } = useFieldArray({
    control,
    name: `holdings`,
  });

  const holdingFields = watch(`holdings`);

  const populatedHoldings = fields.map((field, index) => ({
    ...field,
    ...holdingFields[index],
  }));

  const onSelectCompany = (company: HoldingDetailsPageCompanyFragment) => {
    append({
      companyId: company.id,
      name: company.name,
      logoUrl: company.logoUrl,
      numShares: undefined,
      type: HoldingType.Listed,
    });
  };

  const onRemoveCompany = (indexToRemove: number) => {
    const holdingsWithoutCompany = [...populatedHoldings].filter(
      (_, index) => indexToRemove !== index,
    );
    replace(holdingsWithoutCompany);
  };

  const onAddCompanyManually = (companyName: string) => {
    append({
      name: companyName,
      companyId: undefined,
      logoUrl: undefined,
      numShares: undefined,
      type: HoldingType.Unlisted,
    });
  };

  const populatedAndPreviousHoldings = [
    ...populatedHoldings,
    ...existentHoldings,
  ];

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit}
      alignItems="flex-start"
      justifyContent="space-between"
      h="100%"
      w="full"
    >
      <VStack w={{ base: `full`, xl: maxWidth || `full` }} pt={2}>
        <AddHoldingCombobox
          onSelectCompany={onSelectCompany}
          onAddCompanyManually={onAddCompanyManually}
          holdings={populatedAndPreviousHoldings || []}
        />

        <SelectedHoldingsList
          holdings={populatedHoldings}
          onRemoveCompany={onRemoveCompany}
          control={control}
        />
      </VStack>
      {populatedHoldings.length > 0 && (
        <Box mt={6}>
          <FormCheckboxInput
            name="sellerLotDisclaimer"
            label={
              <Text textStyle="text-md" fontWeight={400}>
                <Trans
                  i18nKey="seller_lot_details_terms_and_conditions"
                  components={{
                    italic: (
                      <InternalLink
                        target="_blank"
                        textDecorationLine="underline"
                        href="/terms-and-conditions"
                      />
                    ),
                  }}
                />
              </Text>
            }
            control={control}
            align="flex-start"
          />
        </Box>
      )}
    </VStack>
  );
};
