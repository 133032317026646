import { Text } from "@chakra-ui/react";

import { MailtoLink, PleaseNote } from "@/components/common";
import { UnauthorizedModalBodyWrapper } from "@/components/modals";
import { InsufficientTradingPermissions } from "@/components/postings";
import {
  PlaceBidSequenceModalAuthorizationCompanyFragment,
  PlaceBidSequenceModalAuthorizationListingFragment,
  UserPermissionV2,
} from "@/gql";
import { useIHavePermission } from "@/hooks";
import {
  getAvailableCompanyActions,
  getSecuritySpecialistContact,
} from "@/utils";

import { PlaceBidSequenceModalContent } from "./PlaceBidSequenceModalContent";

const PlaceBidNotTradable = ({
  company,
}: {
  readonly company: PlaceBidSequenceModalAuthorizationCompanyFragment;
}) => {
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <PleaseNote>
      <Text textStyle="text-lg">
        Automated bidding for{` `}
        {company.name}
        {` `}is not currently enabled. However, you may still place a bid for
        {` `}${company.name}
        {` `}by contacting our{` `}
        <Text textStyle="deprecated-heading-lg" as="span">
          {company.name} Security Specialist at{` `}
          <MailtoLink
            email={securitySpecialistEmail}
            textStyle="deprecated-heading-lg"
          >
            {securitySpecialistEmail}
          </MailtoLink>
        </Text>
      </Text>
    </PleaseNote>
  );
};

export const PlaceBidSequenceModalAuthorization = ({
  company,
  initialListing,
  allowDirectBidPlacing,
}: {
  readonly company: PlaceBidSequenceModalAuthorizationCompanyFragment;
  readonly initialListing?: PlaceBidSequenceModalAuthorizationListingFragment;
  readonly allowDirectBidPlacing?: boolean;
}) => {
  const { canPlaceBid } = getAvailableCompanyActions(company);

  const authorizedToPlaceBids = useIHavePermission(UserPermissionV2.PlaceBid);

  if (!authorizedToPlaceBids)
    return (
      <UnauthorizedModalBodyWrapper
        title="Place a Bid"
        observabilityLabelPrefix="PlaceBid/Unauthorized"
      >
        <InsufficientTradingPermissions type="PLACE_BID" />
      </UnauthorizedModalBodyWrapper>
    );

  if (!canPlaceBid)
    return (
      <UnauthorizedModalBodyWrapper
        title="Place a Bid"
        observabilityLabelPrefix="PlaceBid/NotTradable"
      >
        <PlaceBidNotTradable company={company} />
      </UnauthorizedModalBodyWrapper>
    );

  return (
    <PlaceBidSequenceModalContent
      company={company}
      initialListing={initialListing}
      allowDirectBidPlacing={allowDirectBidPlacing}
    />
  );
};
