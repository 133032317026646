import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { BidWithdrawnPostingCardFragment, InvestorType } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { makeUrl } from "@/utils";

import { BidPostingCardBody } from "./BidPostingCardBody";
import { BidPostingCardWithdrawnHeader } from "./BidPostingCardWithdrawnHeader";
import { BidWithdrawnPostingCardUnaccreditedSellerFooter } from "./BidWithdrawnPostingCardUnaccreditedSellerFooter";

type BidWithdrawnPostingCardProps = {
  bid: BidWithdrawnPostingCardFragment;
};

function BidWithdrawnPostingTraderCard({ bid }: BidWithdrawnPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidPostingCardWithdrawnHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
    </PostingCard>
  );
}

function BidWithdrawnPostingUnaccreditedSellerCard({
  bid,
}: BidWithdrawnPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidPostingCardWithdrawnHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
      <BidWithdrawnPostingCardUnaccreditedSellerFooter bid={bid} />
    </PostingCard>
  );
}

export function BidWithdrawnPostingCard({ bid }: BidWithdrawnPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.UnaccreditedSeller, () => (
      <BidWithdrawnPostingUnaccreditedSellerCard bid={bid} />
    ))
    .otherwise(() => <BidWithdrawnPostingTraderCard bid={bid} />);
}
