import { Trans } from "react-i18next";
import { match } from "ts-pattern";

import { HStack, Text } from "@chakra-ui/react";

import { formatPricePerShare, formatShares, lot } from "@/utils";

const PostingNumSharesPriceDetails = ({
  isPartiallyAccepted,
  isConditionallyCompleted,
  pricePerShare,
  numSharesOriginal,
  numSharesAvailable,
}: {
  readonly isPartiallyAccepted: boolean;
  readonly isConditionallyCompleted: boolean;
  readonly pricePerShare: number;
  readonly numSharesOriginal: number;
  readonly numSharesAvailable: number;
}) => {
  if (!numSharesAvailable && !numSharesOriginal) return null;
  const components = {
    b: (
      <Text
        as="span"
        fontWeight={500}
        fontStyle="inherit"
        fontSize={{ base: 14, lg: `inherit` }}
      />
    ),
    r: (
      <Text
        as="span"
        fontWeight={400}
        fontStyle="inherit"
        fontSize={{ base: 14, lg: `inherit` }}
      />
    ),
    u: (
      <Text
        as="span"
        textDecorationLine="line-through"
        color="grey.200"
        fontStyle="inherit"
        fontSize={{ base: 14, lg: `inherit` }}
      />
    ),
    spacer: <Text as="span" mr={1} />,
  };

  const values = {
    numSharesOriginal: formatShares(numSharesOriginal),
    numSharesAvailable: formatShares(numSharesAvailable),
    pricePerShare: formatPricePerShare(pricePerShare),
  };

  return match({ isPartiallyAccepted, isConditionallyCompleted })
    .with({ isPartiallyAccepted: true }, () => (
      <HStack spacing={2}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_partially_sold_num_shares_price"
            ns="company"
            values={values}
            components={components}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price"
          ns="company"
          values={{
            lot: lot(numSharesAvailable, pricePerShare),
          }}
          components={components}
        />
      </HStack>
    ))
    .with({ isConditionallyCompleted: true }, () => (
      <HStack spacing={2}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_sold_out_num_shares_price"
            ns="company"
            values={values}
            components={components}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price_sold_out"
          ns="company"
          values={{
            lot: lot(numSharesOriginal, pricePerShare),
          }}
          components={components}
        />
      </HStack>
    ))
    .otherwise(() => (
      <HStack spacing={2}>
        <Trans
          i18nKey="posting_num_shares_price"
          ns="company"
          values={values}
          components={components}
        />
        <Trans
          i18nKey="posting_lot_price"
          ns="company"
          values={{
            lot: lot(numSharesOriginal, pricePerShare),
          }}
          components={components}
        />
      </HStack>
    ));
};

export { PostingNumSharesPriceDetails };
