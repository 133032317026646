import isNil from "lodash/isNil";
import { match } from "ts-pattern";

import { ShareSeriesMakeupElement } from "@/components/postings";
import {
  GetIsSellerForListingListingFragment,
  InvestorType,
  GetIsConditionallySoldListingListingFragment,
  GetIsPartiallySoldListingListingFragment,
  GetListingNumSharesAvailableListingFragment,
  GetListingNumSharesOriginalListingFragment,
  CalculateListingNumSharesSoldConditionallyListingFragment,
  ListingState,
  GetIsSellerForListingUserFragment,
  GetIsBrokerForListingUserFragment,
  GetIsBrokerForListingListingFragment,
  GetShowListingActivityListingFragment,
  GetShowListingActivityUserFragment,
  GetShowListingSolicitedStateListingFragment,
  UserWithInstitutionFragment,
  GetListingBidCountListingFragment,
  GetListingHasAcceptedBidPricesListingFragment,
  GetListingHasBidsListingFragment,
  ByOpenOrConditionallySoldListingsFragment,
  ByListingsNotByMyInstitutionFragment,
  ShareSeries,
  TransferMethod,
} from "@/gql";
import { getIsBroker, getIsInstitutionViewer } from "@/utils";

export const getListingBidCount = (
  listing: GetListingBidCountListingFragment,
) => listing.numActiveBids + listing.numCounteredBids;

export const getListingHasAcceptedBidPrices = (
  listing: GetListingHasAcceptedBidPricesListingFragment,
) => listing.acceptedBidPrices.length >= 1;

export const getListingHasBids = (listing: GetListingHasBidsListingFragment) =>
  getListingBidCount(listing) > 0;

export const getIsBrokerForListing = (
  user: GetIsBrokerForListingUserFragment,
  listing: GetIsBrokerForListingListingFragment,
) => !!listing.brokerId && user.id === listing.brokerId;

export const getIsSellerForListing = (
  user: GetIsSellerForListingUserFragment,
  listing: GetIsSellerForListingListingFragment,
) => {
  const { id, institutionId } = user;

  return (
    listing.sellerId === id ||
    (!!listing.sellerInstitutionId &&
      listing.sellerInstitutionId === institutionId)
  );
};

const getShowUnaccreditedSellerListingActivity = (
  user: GetShowListingActivityUserFragment,
  listing: GetShowListingActivityListingFragment,
) => {
  const isSellerForListing = getIsSellerForListing(user, listing);

  return isSellerForListing;
};

/**
 * @deprecated Remove when all legacy sellers are off platform
 */
const getShowLegacySellerListingActivity = (
  user: GetShowListingActivityUserFragment,
  listing: GetShowListingActivityListingFragment,
) => {
  const isSellerForListing = getIsSellerForListing(user, listing);

  return isSellerForListing;
};

const getShowTraderListingActivity = (
  user: GetShowListingActivityUserFragment,
) => {
  const isInstitutionViewer = getIsInstitutionViewer(user);

  const showTraderListingActivity = !isInstitutionViewer;

  return showTraderListingActivity;
};

export const getShowListingActivity = (
  user: GetShowListingActivityUserFragment,
  listing: GetShowListingActivityListingFragment,
) => {
  const showListingActivity = match(user.investorType)
    .with(InvestorType.Broker, () => true)
    .with(InvestorType.Trader, () => getShowTraderListingActivity(user))
    .with(InvestorType.UnaccreditedSeller, () =>
      getShowUnaccreditedSellerListingActivity(user, listing),
    )
    .with(InvestorType.Seller, () =>
      getShowLegacySellerListingActivity(user, listing),
    )
    .otherwise(() => {
      throw new Error(`Invalid investor type in getShowListingActivity`);
    });

  return showListingActivity;
};

export const getIsPartiallySoldListing = (
  listing: GetIsPartiallySoldListingListingFragment,
): boolean =>
  listing.state === ListingState.Open &&
  listing.acceptPartialBid &&
  listing.acceptedBidPrices.length >= 1;

export const getIsConditionallySoldListing = (
  listing: GetIsConditionallySoldListingListingFragment,
): boolean => listing.state === ListingState.ConditionallySold;

export const getListingNumSharesOriginal = ({
  numSharesOriginalRounded,
  numSharesOriginal,
}: GetListingNumSharesOriginalListingFragment) =>
  !numSharesOriginal ? numSharesOriginalRounded : numSharesOriginal;

export const getListingNumSharesAvailable = ({
  numSharesAvailableRounded,
  numSharesAvailable,
}: GetListingNumSharesAvailableListingFragment) =>
  !numSharesAvailable ? numSharesAvailableRounded : numSharesAvailable;

export const calculateListingNumSharesSoldConditionally = (
  listing: CalculateListingNumSharesSoldConditionallyListingFragment,
) => {
  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);
  return numSharesOriginal - numSharesAvailable;
};

export const getListingHasConditionallySoldShares = (
  listing: CalculateListingNumSharesSoldConditionallyListingFragment,
) => calculateListingNumSharesSoldConditionally(listing) > 0;

export const getShowListingSolicitedState = (
  listing: GetShowListingSolicitedStateListingFragment,
  actor: UserWithInstitutionFragment,
) => {
  const isBrokersListing = listing.brokerId === actor.id;
  return getIsBroker(actor) && !isBrokersListing && listing.solicited;
};

export const byOpenOrConditionallySoldListings = ({
  state,
}: ByOpenOrConditionallySoldListingsFragment) =>
  state === ListingState.Open || state === ListingState.ConditionallySold;

export const byListingsNotByMyInstitution =
  (institutionId?: string | null) =>
  ({ sellerInstitutionId }: ByListingsNotByMyInstitutionFragment) =>
    isNil(institutionId) || sellerInstitutionId !== institutionId;

function checkShareSeriesMakeupRestricted(shareSeriesMakeup: ShareSeries) {
  return [ShareSeries.RestrictedStockUnits].includes(shareSeriesMakeup);
}

export function checkContainsRestrictedStockUnit(
  shareSeriesMakeup: ShareSeriesMakeupElement[],
) {
  const containsRestrictedStockUnit = shareSeriesMakeup.some(
    ({ shareSeries }) =>
      shareSeries ? checkShareSeriesMakeupRestricted(shareSeries) : false,
  );

  return containsRestrictedStockUnit;
}

export function checkTransferMethodManual(transferMethod: TransferMethod) {
  return [
    TransferMethod.Fund,
    TransferMethod.HiiveFund,
    TransferMethod.Other,
  ].includes(transferMethod);
}
