import { CardBody, CardHeader, HStack, Text } from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardCompany,
  ActivityCardStandingBidCardFooter,
  ActivityCardStandingBidNumShareAndPriceDetails,
} from "@/components/common";
import { UserActivityStandingBidFragment } from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

import { DashboardYourStandingBidCardV2 } from "./DashboardYourStandingBidCardV2";

export const DashboardYourStandingBidCard = ({
  standingBid,
}: {
  readonly standingBid: UserActivityStandingBidFragment;
}) => {
  const areCardUpdatesEnabled = useCardUpdates();

  if (areCardUpdatesEnabled) {
    return <DashboardYourStandingBidCardV2 standingBid={standingBid} />;
  }
  return (
    <ActivityCard href={`/standing-bids/${standingBid.id}`}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title="Standing Bid" />
            <Text textStyle="text-lg" color="grey.900">
              {standingBid.displayId}
            </Text>
          </HStack>
          <ActivityCardCompany company={standingBid.company} />
        </HStack>
      </CardHeader>
      <CardBody>
        <ActivityCardStandingBidNumShareAndPriceDetails
          standingBid={standingBid}
        />
      </CardBody>
      <ActivityCardStandingBidCardFooter standingBid={standingBid} />
    </ActivityCard>
  );
};
