import { useTranslation } from "react-i18next";

import { CardFooter, Spacer, HStack } from "@chakra-ui/react";

import {
  calculateTotalOpenBids,
  ExpiryDate,
  ConditionallySoldOutBadge,
  PartiallySoldBadge,
  OpenBidsBadge,
  InquiriesBadge,
} from "@/components/companies";
import {
  ActivityCardListingCardFooterListingFragment,
  ListingState,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { useCardUpdates } from "@/hooks/featureFlags";
import {
  getIsBroker,
  getShowListingSolicitedState,
  toTimestamp,
} from "@/utils";

import ActivityCardFooterWrapper from "./ActivityCardFooterWrapper";

const ActivityCardListingCardFooter = ({
  listing,
}: {
  readonly listing: ActivityCardListingCardFooterListingFragment;
}) => {
  const {
    acceptedBidPrices,
    bids,
    expireAt,
    insertedAt,
    transferMethod,
    state,
    numActiveBids,
    numCounteredBids,
    numInquiries,
    numUnreadMessages,
  } = listing;

  const cardUpdatesEnabled = useCardUpdates();

  const actor = useCurrentActor();
  const { t } = useTranslation();
  const hasExpirationDate = !!expireAt;
  const hasInquiries = numInquiries >= 1;

  const isConditionallySold = state === ListingState.ConditionallySold;

  const isPartiallySold =
    state === ListingState.Open && acceptedBidPrices.length >= 1;

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const isInReview = state === ListingState.InReview;

  const isPlaced = getIsBroker(actor) && state === ListingState.Open;

  const hasOpenBids = totalOpenBids >= 1;

  const showListingSolicitedState = getShowListingSolicitedState(
    listing,
    actor,
  );

  const hasFooter =
    hasInquiries ||
    hasExpirationDate ||
    isPartiallySold ||
    isConditionallySold ||
    hasOpenBids ||
    isInReview ||
    isPlaced;

  if (!hasFooter || showListingSolicitedState) return null;

  return (
    <CardFooter p={cardUpdatesEnabled ? 3 : undefined}>
      <ActivityCardFooterWrapper>
        {isInReview &&
          t(`listing_submitted_in_review`, { date: toTimestamp(insertedAt) })}
        {isPlaced &&
          t(`listing_placed_status`, { date: toTimestamp(insertedAt) })}
        {hasExpirationDate ? <ExpiryDate expireAt={expireAt} /> : <Spacer />}
        <HStack wrap="wrap" align="flex-end" spacing={{ base: 2, md: 3 }}>
          {isConditionallySold && (
            <ConditionallySoldOutBadge transferMethod={transferMethod} />
          )}
          {isPartiallySold && (
            <PartiallySoldBadge transferMethod={transferMethod} />
          )}
          {hasOpenBids && (
            <OpenBidsBadge
              totalOpenBids={totalOpenBids}
              bids={bids}
              transferMethod={transferMethod}
            />
          )}
          {hasInquiries && (
            <InquiriesBadge
              numInquiries={numInquiries}
              numUnreadMessages={numUnreadMessages}
            />
          )}
        </HStack>
      </ActivityCardFooterWrapper>
    </CardFooter>
  );
};

export default ActivityCardListingCardFooter;
